import { FC, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useTypedSelector } from '../../store'
import { useToast } from '../../hooks/useToast'
import { HiUser } from 'react-icons/hi'
import { Badge, Button, Modal, Table } from 'flowbite-react'
import DataTableController from '../DataTableController'
import Loading from '../Loading'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import {
  Module,
  clearModuleForm,
  getModule,
} from '../../features/module/moduleSlice'
import {
  clearFilters,
  getAllModules,
  handleChange as handleModuleChange,
} from '../../features/allModules/allModulesSlice'
import { useParams } from 'react-router-dom'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

const FilterModuleSelect: FC<{
  handleChange: ActionCreatorWithPayload<any, any>
  filterModule: string
}> = ({ handleChange, filterModule }) => {
  const {
    error,
    search,
    sort,
    searchStatus,
    modules: allModules,
  } = useTypedSelector((store: any) => store.allModules)

  // it's possible to preselect filter user or param if passing info in route
  const { moduleId } = useParams()
  const { module, error: errorModule } = useTypedSelector(
    (store) => store.module
  )

  useEffect(() => {
    if (moduleId) {
      dispatch(getModule(moduleId))
    } else {
      dispatch(clearModuleForm())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleId])

  const [isOpen, setOpen] = useState(false)
  const [selectedModules, setSelectedModules] = useState<Module[]>([])

  useEffect(() => {
    if (module._id) {
      const newModules = [module]
      setSelectedModules(newModules)
    } else {
      setSelectedModules([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [module])

  const modulesMinusSelectedModules = useMemo(() => {
    const castModules = allModules as Module[]
    return castModules.filter(
      (s) => !selectedModules.find((selected) => selected._id === s._id)
    )
  }, [selectedModules, allModules])

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
    }
    if (errorModule !== '') {
      toast('error', errorModule)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, errorModule])

  const dispatch = useAppDispatch()
  const toast = useToast()

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(false)
  }

  useEffect(() => {
    // apply filter when closing and if filterUser is changed
    if (!isOpen) {
      const reducedSelectedModules = selectedModules.map((s) => {
        return s._id
      })
      if (moduleId) {
        if (module) {
          if (
            JSON.stringify(reducedSelectedModules) !==
            JSON.stringify(filterModule)
          ) {
            dispatch(
              handleChange({
                name: 'filterModule',
                value: reducedSelectedModules,
              })
            )
          }
        }
      } else {
        if (
          JSON.stringify(reducedSelectedModules) !==
          JSON.stringify(filterModule)
        ) {
          dispatch(
            handleChange({
              name: 'filterModule',
              value: reducedSelectedModules,
            })
          )
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, selectedModules, moduleId, module])

  const addSelectedModule = (module: Module) => {
    setSelectedModules([...selectedModules, module])
  }

  const removeSelectedModule = (module: Module) => {
    setSelectedModules(selectedModules.filter((s) => s._id !== module._id))
  }

  useEffect(() => {
    if (moduleId === undefined) {
      dispatch(getAllModules({}))
    }
  }, [dispatch, search, sort, searchStatus, moduleId])

  return (
    <>
      <Button
        id={'search-module'}
        color='primary'
        onClick={() => setOpen(!isOpen)}
        disabled={typeof moduleId === 'string'}
      >
        <HiUser className='mr-2 text-lg' />
        <span className='hidden lg:block '>{`${selectedModules.length} module(s) selectionné(s)`}</span>
        <span className='block lg:hidden '>{`${selectedModules.length} module(s)`}</span>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <Modal.Header
          theme={{
            base: `flex items-start justify-between rounded-t-2xl border-b p-5 dark:border-gray-600`,
            popup: 'border-b-0 p-2',
          }}
        >
          <span>Selectionnez un ou plusieurs module(s)</span>
        </Modal.Header>
        <Modal.Body className='p-5'>
          <div className='w-full lg:w-1/2 sm:flex'>
            <DataTableController
              placeholder='rechercher un module'
              handleChange={handleModuleChange}
              sort={sort}
              search={search}
              // @ts-ignore
              clean={clearFilters}
              initFilters={[
                {
                  name: 'searchStatus',
                  value: searchStatus,
                  control: 'approved',
                  text: 'Validé uniquement',
                },
              ]}
            ></DataTableController>
          </div>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
            <div className='flex flex-col'>
              <h2 className='py-3 text-white text-bold'>
                Tableau de recherche
              </h2>
              <div className='overflow-x-auto'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='overflow-hidden shadow'>
                    <SearchTable
                      modules={modulesMinusSelectedModules}
                      onMoveCallback={addSelectedModule}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-col'>
              <h2 className='py-3 text-white text-bold'>
                Tableau de selection
              </h2>
              <div className='overflow-x-auto'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='overflow-hidden shadow'>
                    <SelectedTable
                      modules={selectedModules}
                      onMoveCallback={removeSelectedModule}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className='self-end' color='primary' onClick={handleSubmit}>
            Fermer le filtre module
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const SearchTable: FC<{
  modules: Module[]
  onMoveCallback: (module: Module) => void
}> = function ({ modules, onMoveCallback }) {
  const { isLoading } = useTypedSelector((store: any) => store.allModules)
  if (isLoading) {
    return <Loading center />
  }
  if (modules.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>Pas de modules à afficher...</span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>Nom du module</Table.HeadCell>
        <Table.HeadCell>Status</Table.HeadCell>
        <Table.HeadCell>Selection</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {modules.map((module) => {
          return (
            <Table.Row
              key={module._id}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white'>
                <div className='flex items-center'>
                  {module.status === 'pending' && (
                    <Badge color='gray'>
                      {/* <HiStatusOffline className='mr-2 text-lg' /> */}
                      <span>En cours de validation</span>
                    </Badge>
                  )}
                  {module.status === 'testing' && (
                    <Badge color='purple'>
                      {/* <HiStatusOffline className='mr-2 text-lg' /> */}
                      <span>DEBUG</span>
                    </Badge>
                  )}
                  {module.status === 'approved' && (
                    <Badge color='primary'>
                      {/* <HiStatusOnline className='mr-2 text-lg' /> */}
                      <span>Validé</span>
                    </Badge>
                  )}
                  {module.status === 'closed' && (
                    <Badge color='dark'>
                      {/* <HiLockClosed className='mr-2 text-lg' /> */}
                      <span>Inactif</span>
                    </Badge>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {module.name}
                </div>
                <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                  {module.lrsObjectId}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                <Button color={'gray'} onClick={() => onMoveCallback(module)}>
                  <FaArrowRight className='h-4 w-4' />
                </Button>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

const SelectedTable: FC<{
  modules: Module[]
  onMoveCallback: (module: Module) => void
}> = function ({ modules, onMoveCallback }) {
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>Déselection</Table.HeadCell>
        <Table.HeadCell>Nom du module</Table.HeadCell>
        <Table.HeadCell>Status</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {modules.map((module) => {
          return (
            <Table.Row
              key={module._id}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                <Button color={'gray'} onClick={() => onMoveCallback(module)}>
                  <FaArrowLeft className='h-4 w-4' />
                </Button>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white'>
                <div className='flex items-center'>
                  {module.status === 'pending' && (
                    <Badge color='gray'>
                      {/* <HiStatusOffline className='mr-2 text-lg' /> */}
                      <span>En cours de validation</span>
                    </Badge>
                  )}
                  {module.status === 'testing' && (
                    <Badge color='purple'>
                      {/* <HiStatusOffline className='mr-2 text-lg' /> */}
                      <span>DEBUG</span>
                    </Badge>
                  )}
                  {module.status === 'approved' && (
                    <Badge color='primary'>
                      {/* <HiStatusOnline className='mr-2 text-lg' /> */}
                      <span>Validé</span>
                    </Badge>
                  )}
                  {module.status === 'closed' && (
                    <Badge color='dark'>
                      {/* <HiLockClosed className='mr-2 text-lg' /> */}
                      <span>Inactif</span>
                    </Badge>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {module.name}
                </div>
                <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                  {module.lrsObjectId}
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default FilterModuleSelect
