const StateText = ({ bool, text }) => {
  return (
    <div className='flex flex-row items-center text-white'>
      {bool ? (
        <div className='mr-2 h-2.5 w-2.5 rounded-full bg-green-400'></div>
      ) : (
        <div className='mr-2 h-2.5 w-2.5 rounded-full bg-red-400'></div>
      )}
      {text}
    </div>
  )
}
export default StateText
