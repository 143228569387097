import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  initAssessmentThunk,
  initEvaluationThunk,
  removeInteractionThunk,
  updateAssessmentThunk,
  updateEvaluationThunk,
} from './interactionThunk'

export interface Interaction {
  _id: string
  activity: string
  lrsObjectId: string
  title: string
  numOfLaunched: number
  kind: string
}

export enum InteractionEnum {
  EVALUATION = 'Evaluation',
  ASSESSMENT = 'Assessment',
}

export interface Answer {
  _id?: string
  lrsObjectId?: string
  text: string
  correct?: boolean
  url?: string
}

export interface Evaluation extends Interaction {
  answers: Answer[]
  weight: number
  strictAnswerOrder: boolean
  thresholdSuccess: number
  correction: boolean
  avgScore: number
}

export interface Assessment extends Interaction {
  answers: Answer[]
}

export interface InteractionForm {
  _id?: string
  title: string
  activity: string
  answers?: Answer[]
  weight?: number
  thresholdSuccess?: number
  strictAnswerOrder?: boolean
  correction?: boolean
  fields?: any[]
}

export interface EvaluationForm extends InteractionForm {
  answers: Answer[]
  weight: number
  strictAnswerOrder: boolean
  thresholdSuccess: number
  correction: boolean
}

export interface AssessmentForm extends InteractionForm {
  answers: Answer[]
}

export const addEvaluation = createAsyncThunk(
  'interaction/evaluation/new',
  async (evaluation: EvaluationForm, thunkAPI) => {
    return initEvaluationThunk('/evaluations', evaluation, thunkAPI)
  }
)

export const addAssessment = createAsyncThunk(
  'interaction/assessment/new',
  async (assessment: AssessmentForm, thunkAPI) => {
    return initAssessmentThunk('/assessments', assessment, thunkAPI)
  }
)

export const editEvaluation = createAsyncThunk(
  'interaction/evaluation/edit',
  async (evaluation: EvaluationForm, thunkAPI) => {
    return updateEvaluationThunk('/evaluations/:id', evaluation, thunkAPI)
  }
)

export const editAssessment = createAsyncThunk(
  'interaction/assessment/edit',
  async (assessment: AssessmentForm, thunkAPI) => {
    return updateAssessmentThunk('/assessments/:id', assessment, thunkAPI)
  }
)

export const removeInteraction = createAsyncThunk(
  'interaction/remove',
  async (interaction: InteractionForm, thunkAPI) => {
    return removeInteractionThunk('/interactions/:id', interaction, thunkAPI)
  }
)

export interface InteractionState {
  isLoading: boolean
  interaction: Interaction
  error: string
  success: boolean
}

const initialState: InteractionState = {
  isLoading: false,
  interaction: {} as Interaction,
  error: '',
  success: false,
}

const interactionSlice = createSlice({
  name: 'interaction',
  initialState,
  reducers: {
    clearInteractionForm: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addAssessment.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(addAssessment.fulfilled, (state, { payload }) => {
        const { interaction } = payload
        state.interaction = interaction
        state.isLoading = false
      })
      .addCase(addAssessment.rejected, (state, { payload }) => {
        state.isLoading = false
        state.interaction = {} as Interaction
        state.error = payload as string
      })
      .addCase(addEvaluation.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(addEvaluation.fulfilled, (state, { payload }) => {
        const { interaction } = payload
        state.interaction = interaction
        state.isLoading = false
      })
      .addCase(addEvaluation.rejected, (state, { payload }) => {
        state.isLoading = false
        state.interaction = {} as Interaction
        state.error = payload as string
      })
      .addCase(editAssessment.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(editAssessment.fulfilled, (state, { payload }) => {
        const { interaction } = payload
        state.isLoading = false
        state.interaction = interaction
      })
      .addCase(editAssessment.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addCase(editEvaluation.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(editEvaluation.fulfilled, (state, { payload }) => {
        const { interaction } = payload
        state.isLoading = false
        state.interaction = interaction
      })
      .addCase(editEvaluation.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addCase(removeInteraction.pending, (state) => {
        state.isLoading = true
        state.error = ''
        state.success = false
      })
      .addCase(removeInteraction.fulfilled, (state) => {
        state.isLoading = false
        state.success = true
      })
      .addCase(removeInteraction.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
        state.success = false
      })
  },
})

export const { clearInteractionForm } = interactionSlice.actions

export default interactionSlice.reducer
