import AngleLeftIcon from './AngleLeft'
import BarsIcon from './Bars'
import CarretDownIcon from './CarretDown'
import CarretUpIcon from './CarretUp'
import CheckIcon from './Check'
import CloseIcon from './Close'
import FolderDuplicatesIcon from './FolderDuplicates'
import LinkedinIcon from './Linkedin'
import OpenBookIcon from './OpenBook'
import PlusIcon from './Plus'
import SearchLoopIcon from './SearchLoop'
import ShareNodesIcon from './ShareNodes'
import ShutDownIcon from './ShutDown'
import StarEmptyIcon from './StarEmpty'
import StarFullIcon from './StarFull'
import UsersGroupIcon from './UsersGroup'
import VideoPlayIcon from './VideoPlay'

export interface IIconProps {
  className?: string
}

export const IIconPropsInitialValues: IIconProps = {
  className: 'w-5 h-5',
}

export {
  SearchLoopIcon,
  UsersGroupIcon,
  FolderDuplicatesIcon,
  OpenBookIcon,
  CarretDownIcon,
  CarretUpIcon,
  BarsIcon,
  ShutDownIcon,
  LinkedinIcon,
  ShareNodesIcon,
  StarEmptyIcon,
  StarFullIcon,
  VideoPlayIcon,
  AngleLeftIcon,
  CloseIcon,
  CheckIcon,
  PlusIcon,
}
