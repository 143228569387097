import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllSpecialUsersThunk } from './allSpecialUsersThunk'

const initialFiltersState = {
  search: '',
  role: 'special',
  sort: 'latest',
  sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],
}

const initialState = {
  users: [],
  isLoading: false,
  totalUsers: 0,
  numOfPages: 1,
  page: 1,
  result: null,
  error: '',
  ...initialFiltersState,
}

export const getAllSpecialUsers = createAsyncThunk(
  'users/special',
  getAllSpecialUsersThunk
)

const allSpecialUsersSlice = createSlice({
  name: 'allSpecialUsers',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true
    },
    hideLoading: (state) => {
      state.isLoading = false
    },
    clearModal: (state) => {
      state.error = ''
      state.result = null
    },
    changeInputValues: (state, { payload: { name, value } }) => {
      state.page = 1
      state[name] = value
    },
    clearFilters: (state) => {
      return { ...state, ...initialFiltersState }
    },
    changePage: (state, { payload }) => {
      state.page = payload
    },
    clearAllUsersState: () => initialState,
    addSpecialUserToDataTable: (state, { payload }) => {
      // edit first if exist
      var exist = false
      state.users = state.users.map((user) => {
        if (user._id === payload._id) {
          user = { ...payload }
          exist = true
        }
        return user
      })
      if (!exist) {
        state.users.unshift(payload)
        state.totalUsers++
      }
    },
    removeSpecialUserToDataTable: (state, { payload }) => {
      state.users = state.users.filter((user) => {
        return user._id !== payload._id
      })
      state.totalUsers--
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSpecialUsers.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(getAllSpecialUsers.fulfilled, (state, { payload }) => {
        const { users, numOfPages, totalUsers } = payload
        state.isLoading = false
        state.users = users
        state.totalUsers = totalUsers
        state.numOfPages = numOfPages
      })
      .addCase(getAllSpecialUsers.rejected, (state, { payload }) => {
        state.isLoading = false
        state.users = []
        state.error = payload
      })
  },
})

export const {
  showLoading,
  hideLoading,
  changeInputValues,
  clearFilters,
  changePage,
  clearAllUsersState,
  clearModal,
  addSpecialUserToDataTable,
  removeSpecialUserToDataTable,
} = allSpecialUsersSlice.actions

export default allSpecialUsersSlice.reducer
