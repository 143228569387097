/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  Label,
  TextInput,
} from 'flowbite-react'
import { useEffect, type FC, useState } from 'react'
import { HiDesktopComputer, HiHome } from 'react-icons/hi'
import { useAppDispatch, useTypedSelector } from '../../store'
import { ColorPicker, Loading, ResourceElement } from '../../components'
import { useToast } from '../../hooks/useToast'
import { useNavigate, useParams } from 'react-router-dom'
import {
  ConfigModuleModalForm,
  ModuleBackgroundImageForm,
  ModuleCategoryEnum,
  ModuleForm,
  ModuleThumbForm,
  ModuleTrainerForm,
  editBackgroundImageModule,
  editConfigModuleModalForm,
  editModule,
  editThumbModule,
  editTrainerModule,
  getModule,
} from '../../features/module/moduleSlice'
import {
  AddModuleVersionModal,
  CopyModuleVersionModal,
  DeleteModuleVersionModal,
  EditModuleVersionModal,
  // EditModuleVersionModal,
  EndModuleVersionBetaModal,
} from '../versions/list'
import {
  AddLearningObjectiveModal,
  DeleteLearningObjectiveModal,
  EditLearningObjectiveModal,
} from '../learning-objectives/list'
import { ChangeStatusModuleModal, moduleCategoryToString } from './list'
import { TagSelector } from '../../components/TagSelector'
import { getAllTags } from '../../features/allTags/allTagsSlice'
import { Tag, TagSelectorValue } from '../../features/tag/tagSlice'
import { SelectMediaModal } from '../medias/list'
import { SelectTrainerModal } from '../trainers/list'
import moment from 'moment'
import StateText from '../../components/StateText'
import env from 'react-dotenv'
import { handleSaveStringToPC } from '../../utils/handleSaveToPC'
import { FaFileExport } from 'react-icons/fa'
import {
  clearBddControl,
  getBDDExportModule,
} from '../../features/bddControl/bddControlSlice'
import { MdDataObject } from 'react-icons/md'
import { clearModuleVersionForm } from '../../features/moduleVersion/moduleVersionSlice'
import EditorSwitch from '../../components/Editor/EditorSwitch'

const ModulePage: FC = function () {
  const { error, isLoading, module } = useTypedSelector((store) => store.module)
  const { isLoading: isLoadingTag } = useTypedSelector((store) => store.allTags)
  const { error: errorLearningObjective } = useTypedSelector(
    (store) => store.learningObjective
  )
  const { error: errorModuleVersion } = useTypedSelector(
    (store) => store.moduleVersion
  )
  const {
    error: errorBddControl,
    data,
    isLoading: isLoadingBddControl,
  } = useTypedSelector((store) => store.bddControl)
  const dispatch = useAppDispatch()
  const toast = useToast()
  const { id } = useParams()

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
    }
    if (errorLearningObjective !== '') {
      toast('error', errorLearningObjective)
    }
    if (errorModuleVersion !== '') {
      toast('error', errorModuleVersion)
      dispatch(clearModuleVersionForm())
    }
    if (errorBddControl !== '') {
      toast('error', errorBddControl)
      dispatch(clearBddControl())
    }
    // eslint-disable-next-line
  }, [error, errorLearningObjective, errorModuleVersion, errorBddControl])

  useEffect(() => {
    dispatch(getModule(id as string))
  }, [dispatch, id])

  useEffect(() => {
    if (!isLoadingTag) {
      dispatch(getAllTags({}))
    }
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    if (!isLoadingBddControl && data !== null) {
      // convert json to string
      const fileData = JSON.stringify(data)
      handleSaveStringToPC(fileData, id ? id.toString() : 'module', 'json')
      dispatch(clearBddControl())
    }
    // eslint-disable-next-line
  }, [isLoadingBddControl, data])

  return (
    <>
      <div className='grid grid-cols-1 gap-y-6 px-4 pt-6 dark:bg-gray-900 xl:grid-cols-3 xl:gap-4'>
        <div className='col-span-full'>
          <Breadcrumb className='mb-4'>
            <Breadcrumb.Item href='#'>
              <div className='flex items-center gap-x-3'>
                <HiHome className='text-xl' />
                <span className='dark:text-white'>Home</span>
              </div>
            </Breadcrumb.Item>
            <Breadcrumb.Item href='/modules'>Modules</Breadcrumb.Item>
            <Breadcrumb.Item>{module.name}</Breadcrumb.Item>
          </Breadcrumb>
          <h1 className='text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl'>
            Configuration du module
          </h1>
          <div className='block items-center sm:flex'>
            <div className='flex w-full items-center sm:justify-end'>
              <Button
                color='primary'
                className=''
                disabled={isLoadingBddControl}
                onClick={() => {
                  dispatch(getBDDExportModule(id as string))
                }}
              >
                <FaFileExport className='mr-3 text-sm' />
                Exporter le module
              </Button>
            </div>
          </div>
        </div>
        {isLoading ? (
          <Loading center />
        ) : (
          <>
            {module._id ? (
              <>
                <div className='col-span-full xl:col-auto'>
                  <div className='grid grid-cols-1 gap-y-4'>
                    <ModuleCard />
                    <ModuleBackgroundImageCard />
                    <ModuleConfigModalCard />
                    <ModuleInformationCard />
                  </div>
                </div>
                <div className='col-span-2'>
                  <div className='grid grid-cols-1 gap-y-4'>
                    <ModuleVersionCard />
                    <LearningObjectivesCard />
                    <RecordingsCard />
                    <ModuleTrainerCard />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-red-800 dark:text-red-400'>
                  <span className='font-medium'>{error}</span>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

const ModuleCard: FC = function () {
  const { module } = useTypedSelector((store) => store.module)
  const {
    _id,
    name,
    thumbResource,
    lrsObjectId,
    countMasterVersions,
    countLearningObjectivesValid,
    trainer,
  } = module
  return (
    <Card>
      <div className='items-center sm:flex sm:space-x-4 xl:block xl:space-x-0 2xl:flex 2xl:space-x-4'>
        <div>
          <ResourceElement resource={thumbResource} size='lg' />
          {!thumbResource && (
            <span className='whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white'>
              Ajouter une miniature
            </span>
          )}
          <ChangeThumb />
        </div>
        <div>
          <h3 className='mb-1 text-2xl font-bold text-gray-900 dark:text-white'>
            {name}
          </h3>
          <div className='text-base font-normal text-gray-500 dark:text-gray-400'>
            {lrsObjectId}
          </div>
          <a
            className='text-primary-600 font-normal underline'
            href={`${env.CLIENT_URL}/module/${_id}`}
            target='_blank'
            rel='noreferrer'
          >
            Accès au module
          </a>
        </div>
      </div>
      <div className='whitespace-nowrap p-4 text-base font-normal text-white'>
        <div className='flex items-center'>
          {countMasterVersions && countMasterVersions > 0 ? (
            <StateText
              bool={true}
              text={'Au moins une version master trouvée'}
            />
          ) : (
            <StateText bool={false} text={'Pas de version master trouvée'} />
          )}
        </div>
        <div className='flex items-center'>
          {countLearningObjectivesValid && countLearningObjectivesValid > 0 ? (
            <StateText
              bool={true}
              text={'Au moins un objectif pédagogique validé'}
            />
          ) : (
            <StateText
              bool={false}
              text={"Pas d'objectif pédagogique validé"}
            />
          )}
        </div>
        <div className='flex items-center'>
          {trainer ? (
            <StateText bool={true} text={'Un(e) formatrice(teur) associé(e)'} />
          ) : (
            <StateText
              bool={false}
              text={'Pas de formatrice(teur) associé(e)'}
            />
          )}
        </div>
      </div>
      {/* {countMasterVersions &&
      countMasterVersions > 0 &&
      countLearningObjectivesValid &&
      countLearningObjectivesValid > 0 &&
      trainer ? (
        <div className='whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white'>
          <ChangeStatusModuleModal />
        </div>
      ) : (
        <></>
      )} */}
      <div className='whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white'>
        <ChangeStatusModuleModal />
      </div>
    </Card>
  )
}

const ModuleBackgroundImageCard: FC = function () {
  const { module } = useTypedSelector((store) => store.module)
  const { backgroundImageResource } = module
  return (
    <Card>
      <h3 className='mb-4 text-xl font-bold dark:text-white'>Image de fond</h3>
      <div className='items-center sm:flex sm:space-x-4 xl:block xl:space-x-0 2xl:flex 2xl:space-x-4'>
        <div>
          <ResourceElement resource={backgroundImageResource} />
          {!backgroundImageResource && (
            <span className='whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white'>
              Ajouter une image de fond
            </span>
          )}
          <ChangeBackgroundImage />
        </div>
      </div>
    </Card>
  )
}

const ChangeThumb: FC = function () {
  const { isLoading, module } = useTypedSelector((store) => store.module)
  const initialValues: ModuleThumbForm = {
    _id: module._id,
    thumb: module.thumb,
  }
  const [values, setValues] = useState<ModuleThumbForm>(initialValues)
  const [newThumb, setNewThumb] = useState<boolean>(false)

  const [thumbSelected, setThumbSelected] = useState(module.thumbResource)

  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (thumbSelected && thumbSelected._id) {
      setValues({
        ...values,
        thumb: thumbSelected._id,
      })
    } else {
      setValues({
        ...values,
        thumb: null,
      })
    }
    if (thumbSelected && thumbSelected._id !== values.thumb) {
      setNewThumb(true)
    }
    // eslint-disable-next-line
  }, [thumbSelected])

  useEffect(() => {
    if (newThumb) {
      newThumbSelected()
      setNewThumb(false)
    }
    // eslint-disable-next-line
  }, [newThumb])

  const newThumbSelected = () => {
    dispatch(editThumbModule(values))
    toast('success', `La miniature a bien été éditée`)
  }
  if (isLoading) {
    return <></>
  }

  return (
    <>
      <SelectMediaModal setImageSelected={setThumbSelected} />
    </>
  )
}

const ChangeBackgroundImage: FC = function () {
  const { isLoading, module } = useTypedSelector((store) => store.module)
  const initialValues: ModuleBackgroundImageForm = {
    _id: module._id,
    backgroundImage: module.backgroundImage,
  }
  const [values, setValues] = useState<ModuleBackgroundImageForm>(initialValues)
  const [newBackgroundImage, setNewBackgroundImage] = useState<boolean>(false)

  const [backgroundImageSelected, setBackgroundImageSelected] = useState(
    module.backgroundImageResource
  )

  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (backgroundImageSelected && backgroundImageSelected._id) {
      setValues({
        ...values,
        backgroundImage: backgroundImageSelected._id,
      })
    } else {
      setValues({
        ...values,
        backgroundImage: null,
      })
    }
    if (
      backgroundImageSelected &&
      backgroundImageSelected._id !== values.backgroundImage
    ) {
      setNewBackgroundImage(true)
    }
    // eslint-disable-next-line
  }, [backgroundImageSelected])

  useEffect(() => {
    if (newBackgroundImage) {
      newBackgroundImageSelected()
      setNewBackgroundImage(false)
    }
    // eslint-disable-next-line
  }, [newBackgroundImage])

  const newBackgroundImageSelected = () => {
    dispatch(editBackgroundImageModule(values))
    toast('success', `L'image de fond a bien été éditée`)
  }
  if (isLoading) {
    return <></>
  }

  return (
    <>
      <SelectMediaModal setImageSelected={setBackgroundImageSelected} />
    </>
  )
}

const ChangeTrainer: FC = function () {
  const { isLoading, module } = useTypedSelector((store) => store.module)
  const initialValues: ModuleTrainerForm = {
    _id: module._id,
    trainer: module.trainer,
  }
  const [values, setValues] = useState<ModuleTrainerForm>(initialValues)
  const [newTrainer, setNewTrainer] = useState<boolean>(false)

  const [trainerSelected, setTrainerSelected] = useState(module.trainerInfo)

  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (trainerSelected && trainerSelected._id) {
      setValues({
        ...values,
        trainer: trainerSelected._id,
      })
    } else {
      setValues({
        ...values,
        trainer: null,
      })
    }
    if (trainerSelected && trainerSelected._id !== values.trainer) {
      setNewTrainer(true)
    }
    // eslint-disable-next-line
  }, [trainerSelected])

  useEffect(() => {
    if (newTrainer) {
      newTrainerSelected()
      setNewTrainer(false)
    }
    // eslint-disable-next-line
  }, [newTrainer])

  const newTrainerSelected = () => {
    dispatch(editTrainerModule(values))
    toast('success', `Un nouveau formateur a été associé`)
  }
  if (isLoading) {
    return <></>
  }

  return (
    <>
      <SelectTrainerModal setTrainerSelected={setTrainerSelected} />
    </>
  )
}

const ModuleConfigModalCard: FC = function () {
  const { isLoading, module } = useTypedSelector((store) => store.module)
  const { _id, configModuleModal } = module
  const initialValues: ConfigModuleModalForm = {
    _id: _id,
    configModuleModal: configModuleModal || {
      burgerColor: '#FFFFFF',
      headerBackgroundColor: '#00ACBA1A',
      mainColor: '#00ACBA',
    },
  }
  const [values, setValues] = useState<ConfigModuleModalForm>(initialValues)

  const dispatch = useAppDispatch()
  const toast = useToast()

  const handleColorChange = (name: string, value: string) => {
    setValues({
      ...values,
      configModuleModal: { ...values['configModuleModal'], [name]: value },
    })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { mainColor, headerBackgroundColor, burgerColor } =
      values.configModuleModal
    if (
      mainColor !== '' &&
      headerBackgroundColor !== '' &&
      burgerColor !== ''
    ) {
      dispatch(editConfigModuleModalForm(values))
      toast('success', `La modale du menu de ${module.name} a bien été éditée`)
    } else {
      toast('error', `Vous avez besoin de renseigner les elements requis`)
    }
  }

  return (
    <Card>
      <h3 className='mb-4 text-xl font-bold dark:text-white'>
        Configuration des couleurs de la modale menu
      </h3>
      <form onSubmit={onSubmit}>
        <div className='grid grid-cols-6 gap-6'>
          <div className='col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3'>
            <Label>Couleur principale</Label>
            <ColorPicker
              initColor={values.configModuleModal.mainColor}
              onChange={handleColorChange}
              name='mainColor'
            />
          </div>
          <div className='col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3'>
            <Label>Couleur de fond du header</Label>
            <ColorPicker
              initColor={values.configModuleModal.headerBackgroundColor}
              onChange={handleColorChange}
              name='headerBackgroundColor'
            />
          </div>
          <div className='col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3'>
            <Label>Couleur du burger</Label>
            <ColorPicker
              initColor={values.configModuleModal.burgerColor}
              onChange={handleColorChange}
              name='burgerColor'
            />
          </div>
          <div className='col-span-6'>
            <Button disabled={isLoading} type='submit' color='primary'>
              Editer
            </Button>
          </div>
        </div>
      </form>
    </Card>
  )
}

const ModuleInformationCard: FC = function () {
  const { isLoading, module } = useTypedSelector((store) => store.module)
  const {
    _id,
    name,
    shortResume,
    longResume,
    tags,
    thumb,
    backgroundImage,
    category,
  } = module
  const { tags: alltags } = useTypedSelector((store) => store.allTags)
  const initialValues: ModuleForm = {
    _id: _id,
    name: name,
    shortResume: shortResume,
    longResume: longResume,
    tags: tags,
    thumb: thumb,
    backgroundImage: backgroundImage,
    category: category,
  }
  const [values, setValues] = useState<ModuleForm>(initialValues)
  const [suggestionsTag, setSuggestionsTag] = useState(
    new Array<TagSelectorValue>()
  )
  const [tagsSelected, setTagsSelected] = useState(
    new Array<TagSelectorValue>()
  )
  const dispatch = useAppDispatch()
  const toast = useToast()

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const handleDropDownChange = (mc: ModuleCategoryEnum) => {
    setValues({ ...values, category: mc })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { name, shortResume } = values
    if (name !== '' && shortResume !== '') {
      dispatch(editModule(values))
      toast('success', `Le ${name} a bien été édité`)
    } else {
      toast('error', `Vous avez besoin de renseigner les elements requis`)
    }
  }

  useEffect(() => {
    const castTags = alltags as Tag[]
    setSuggestionsTag(
      castTags.map((tag) => {
        const newTag = { value: tag._id, label: tag.title }
        return newTag
      })
    )
    if (module) {
      setTagsSelected(
        castTags
          .filter((tag) => module.tags.indexOf(tag._id) > -1)
          .map((tag) => {
            const newTag = { value: tag._id, label: tag.title }
            return newTag
          })
      )
    }
  }, [alltags, module])

  useEffect(() => {
    setValues({
      ...values,
      tags: tagsSelected.map((tag) => {
        return tag.value
      }),
    })
    // eslint-disable-next-line
  }, [tagsSelected])

  return (
    <Card>
      <h3 className='mb-4 text-xl font-bold dark:text-white'>
        Informations générales
      </h3>
      <form onSubmit={onSubmit}>
        <div className='grid grid-cols-6 gap-6'>
          <div className='col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3'>
            <Label htmlFor='name'>Nom du module</Label>
            <TextInput
              id='name'
              name='name'
              placeholder='FOH 1'
              type='text'
              required={true}
              value={values.name}
              onChange={handleChange}
            />
          </div>
          <div className='col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3'>
            <span className='text-sm font-medium text-gray-900 dark:text-white'>
              Categorie
            </span>
            <div className='mt-2'>
              <Dropdown
                id='category'
                color={'primary'}
                label={moduleCategoryToString(values.category)}
              >
                <Dropdown.Item
                  onClick={() =>
                    handleDropDownChange(ModuleCategoryEnum.NOTCATEGORIZED)
                  }
                >
                  {moduleCategoryToString(ModuleCategoryEnum.NOTCATEGORIZED)}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleDropDownChange(ModuleCategoryEnum.NR)}
                >
                  {moduleCategoryToString(ModuleCategoryEnum.NR)}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleDropDownChange(ModuleCategoryEnum.CLIMATE)
                  }
                >
                  {moduleCategoryToString(ModuleCategoryEnum.CLIMATE)}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleDropDownChange(ModuleCategoryEnum.RSE)}
                >
                  {moduleCategoryToString(ModuleCategoryEnum.RSE)}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleDropDownChange(ModuleCategoryEnum.PREVENTION)
                  }
                >
                  {moduleCategoryToString(ModuleCategoryEnum.PREVENTION)}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleDropDownChange(ModuleCategoryEnum.PERSONALDEV)
                  }
                >
                  {moduleCategoryToString(ModuleCategoryEnum.PERSONALDEV)}
                </Dropdown.Item>
              </Dropdown>
            </div>
          </div>
          <div className='col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3'>
            <Label htmlFor='shortResume'>
              Petite description (-200 caractères)
            </Label>
            <TextInput
              id='shortResume'
              name='shortResume'
              placeholder=''
              type='text'
              value={values.shortResume}
              onChange={handleChange}
              required={true}
            />
          </div>
          <div className='col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3'>
            <Label>Tags</Label>
            <TagSelector
              suggestions={[...suggestionsTag]}
              tagsSelected={tagsSelected}
              setTagsSelected={setTagsSelected}
            />
          </div>
          <div className='col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-6'>
            <span className='text-sm font-medium text-gray-900 dark:text-white'>
              Grande description
            </span>
            <EditorSwitch
              value={values.longResume?.version ? values.longResume : {}}
              callback={(e: any) => setValues({ ...values, longResume: e })}
            />
          </div>
          <div className='col-span-6'>
            <Button disabled={isLoading} type='submit' color='primary'>
              Editer
            </Button>
          </div>
        </div>
      </form>
    </Card>
  )
}

const ModuleTrainerCard: FC = function () {
  const { module } = useTypedSelector((store) => store.module)
  const { trainerInfo } = module
  return (
    <Card>
      <h3 className='mb-4 text-xl font-bold dark:text-white'>
        Formateur Associé
      </h3>
      <div className='items-center sm:flex sm:space-x-4 xl:block xl:space-x-0 2xl:flex 2xl:space-x-4'>
        <div>
          {trainerInfo && (
            <div className='mb-4'>
              <div className='items-center sm:flex sm:space-x-4 xl:block xl:space-x-0 2xl:flex 2xl:space-x-4'>
                <div>
                  <ResourceElement
                    resource={trainerInfo.imageResource}
                    size='lg'
                  />
                </div>
                <div>
                  <div className='text-base font-semibold text-gray-900 dark:text-white'>
                    {trainerInfo.name} {trainerInfo.lastName}
                  </div>
                  <div className='text-base font-normal text-gray-900 dark:text-white'>
                    {trainerInfo.email}
                  </div>
                  {trainerInfo.job && (
                    <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                      {trainerInfo.job}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <ChangeTrainer />
        </div>
      </div>
    </Card>
  )
}

const ModuleVersionCard: FC = function () {
  const { isLoading, module } = useTypedSelector((store) => store.module)
  const { lastVersion } = module
  const navigate = useNavigate()
  return (
    <Card>
      <div className='flow-root'>
        <h3 className='text-xl font-bold dark:text-white'>
          Dernière version du module
        </h3>
        <ul className='divide-y divide-gray-200 dark:divide-gray-700'>
          {lastVersion && lastVersion._id ? (
            <li className='py-4'>
              <div className='flex items-center space-x-4'>
                <div className='shrink-0'>
                  <HiDesktopComputer className='text-2xl dark:text-white' />
                </div>

                <div className='min-w-0 flex-1'>
                  <p className='truncate text-base font-semibold text-gray-900 dark:text-white'>
                    {lastVersion.version}
                  </p>
                  <p className='truncate text-sm font-normal text-gray-500 dark:text-gray-400'>
                    {lastVersion.isBeta ? (
                      <span>Beta</span>
                    ) : (
                      <span>Master</span>
                    )}
                  </p>
                </div>
                <Button
                  color='primary'
                  onClick={() => navigate(`./version/${lastVersion._id}`)}
                >
                  <MdDataObject className='mr-2 text-lg' />
                  <span className='hidden lg:block '>Accès aux activités</span>
                </Button>
                <EditModuleVersionModal {...lastVersion} />
                {lastVersion.isBeta && (
                  <EndModuleVersionBetaModal {...lastVersion} />
                )}
                <div className='inline-flex items-center'>
                  <DeleteModuleVersionModal {...lastVersion} />
                </div>
              </div>
            </li>
          ) : (
            <li className='py-4'>
              <div className='flex items-center space-x-4'>
                <div className='shrink-0'>
                  <HiDesktopComputer className='text-2xl dark:text-white' />
                </div>

                <div className='min-w-0 flex-1'>
                  <p className='truncate text-base font-semibold text-gray-900 dark:text-white'>
                    Pas de version trouvée
                  </p>
                </div>
              </div>
            </li>
          )}
        </ul>
        <div className='flex items-center gap-x-3'>
          {lastVersion && lastVersion._id ? (
            !lastVersion.isBeta && <CopyModuleVersionModal />
          ) : (
            <AddModuleVersionModal />
          )}
          {lastVersion && (
            <Button
              disabled={isLoading}
              color='purple'
              onClick={() => navigate(`./versions`)}
            >
              Voir toutes les versions
            </Button>
          )}
        </div>
      </div>
    </Card>
  )
}

const LearningObjectivesCard: FC = function () {
  const { isLoading, module } = useTypedSelector((store) => store.module)
  const { learningObjectives } = module
  const navigate = useNavigate()
  return (
    <Card>
      <div className='flex flex-col'>
        <div className='mb-4 w-full'>
          <h3 className='text-xl font-bold dark:text-white'>
            {module.totalLearningTime
              ? `Objectifs pédagogiques : environ ${moment(
                  module.totalLearningTime * 1000
                ).format('mm')} minutes`
              : `Pas de temps total`}
          </h3>
        </div>
        <div className='overflow-x-auto'>
          <div className='inline-block min-w-full align-middle'>
            <div className='overflow-hidden shadow'>
              <ul className='divide-y divide-gray-200 dark:divide-gray-700'>
                {learningObjectives &&
                  learningObjectives.map((learningObjective, index) => (
                    <li className='py-4' key={learningObjective._id}>
                      <div className='flex items-center space-x-4'>
                        <div className='shrink-0'>
                          <Badge
                            style={{
                              backgroundColor: learningObjective.color,
                              color: 'white',
                            }}
                            size='lg'
                          >
                            {(index + 1).toString()}
                          </Badge>
                        </div>

                        <div className='min-w-0 flex-1 max-w-[200px] lg:max-w-[500px] overflow-hidden text-ellipsis whitespace-nowrap'>
                          <p className='truncate text-base font-semibold text-gray-900 dark:text-white'>
                            {learningObjective.name}
                          </p>
                          {learningObjective.taxonomiesCount ? (
                            <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                              Nombre de taxonomies :{' '}
                              {learningObjective.taxonomiesCount}
                            </div>
                          ) : (
                            <>
                              <div
                                className='text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400'
                                role='alert'
                              >
                                <span className='font-medium'>
                                  Pas de taxonomie
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                        <div className='inline-flex items-center'>
                          <EditLearningObjectiveModal {...learningObjective} />
                        </div>
                        <div className='inline-flex items-center'>
                          <DeleteLearningObjectiveModal
                            {...learningObjective}
                          />
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
              {!learningObjectives ||
                (learningObjectives.length === 0 && (
                  <>
                    <div
                      className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
                      role='alert'
                    >
                      <span className='font-medium'>
                        Pas d'objectifs pédagogiques à afficher...
                      </span>
                    </div>
                  </>
                ))}
            </div>
          </div>
        </div>
        {module && module._id && (
          <div className='flex items-center gap-x-3'>
            <AddLearningObjectiveModal />
            {learningObjectives && learningObjectives.length > 0 && (
              <Button
                disabled={isLoading}
                color='purple'
                onClick={() => navigate(`./learning-objectives`)}
              >
                Configurer les taxonomies
              </Button>
            )}
          </div>
        )}
      </div>
    </Card>
  )
}

const RecordingsCard: FC = function () {
  const { isLoading, module } = useTypedSelector((store) => store.module)
  const { numOfLaunched, numOfFinished, numOfSubscribed } = module
  const navigate = useNavigate()
  return (
    <Card>
      <div className='flex flex-col'>
        <div className='mb-4 w-full'>
          <h3 className='text-xl font-bold dark:text-white'>
            {`Enregistrements :`}
          </h3>
        </div>
        <div className='overflow-x-auto'>
          <div className='inline-block min-w-full align-middle'>
            <div className='overflow-hidden shadow'>
              <div className='grid md:grid-cols-3 gap-3'>
                <div>
                  <p className='truncate text-base font-semibold text-gray-900 dark:text-white'>
                    Démarré
                  </p>
                  <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                    {numOfLaunched} fois
                  </div>
                </div>
                <div>
                  <p className='truncate text-base font-semibold text-gray-900 dark:text-white'>
                    Terminé
                  </p>
                  <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                    {numOfFinished} fois
                  </div>
                </div>
                <div>
                  <p className='truncate text-base font-semibold text-gray-900 dark:text-white'>
                    Nombre de souscriptions
                  </p>
                  <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                    {numOfSubscribed}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {module && module._id && (
          <div className='mt-3 flex items-center gap-x-3'>
            <Button
              disabled={isLoading}
              color='purple'
              onClick={() => navigate(`./recordings`)}
            >
              Voir les enregistrements
            </Button>
            <Button
              disabled={isLoading}
              color='purple'
              onClick={() => navigate(`./lagoon-form-answers`)}
            >
              Voir les enquêtes beta
            </Button>
          </div>
        )}
      </div>
    </Card>
  )
}

export default ModulePage
