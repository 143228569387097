import env from 'react-dotenv'
import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const getAllLearningObjectivesThunk = async (_, thunkAPI) => {
  const { module } = thunkAPI.getState().module
  const limit = env.DEFAULT_LIMIT_PAGINATION
  const { sort } = thunkAPI.getState().allLearningObjectives
  let url = `/learningobjectives?module=${module._id}&sort=${sort}&limit=${limit}`

  try {
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
