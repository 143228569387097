import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { deleteBetaLagoonFormAnswersThunk } from './betaLagoonFormAnswersThunk'
import { BetaContact } from '../betaContact/betaContactSlice'

export enum DataMonitoringEnum {
  WIDGETS = 'widgets',
  IA = 'ia',
  MAIL = 'mail',
}

export enum DataPriceComparisonEnum {
  NO = 'no',
  PLUSTWOTIMES = '+2x',
  PLUS = '+',
  EQUAL = '=',
  MINUS = '-',
  MINUS2TIMES = '-2x',
}

export enum DataInvestmentEnum {
  YES = 'yes',
  NO = 'no',
  DONTKNOW = 'dontknow',
}

export interface GlobalBestAspects {
  data?: boolean
  moduleQuality?: boolean
  navigation?: boolean
  usageSimplicity?: boolean
  trainingSubjects?: boolean
  other?: string
}

export interface ConceptLMSUsage {
  moodle?: boolean
  cornerstone?: boolean
  learning360?: boolean
  syfadis?: boolean
  other?: string
}

export interface ConceptLMSConstraintsVA {
  dataRepresentation?: boolean
  dataQuantity?: boolean
  dataQuality?: boolean
  learnerProfiling?: boolean
  externalTools?: boolean
  userAccounts?: boolean
  other?: string
}

export interface ContentFavoriteType {
  interactiveVideo?: boolean
  motionDesign?: boolean
  podCast?: boolean
  reportVideo?: boolean
  other?: string
}

export interface DataMyPersonalData {
  moduleProgression?: boolean
  scoreProgression?: boolean
  hardSkills?: boolean
  softSkills?: boolean
  history?: boolean
  sharing?: boolean
  other?: string
}

export interface DataUserDashboard {
  advices?: boolean
  notifications?: boolean
  lastTrainings?: boolean
  searchTraining?: boolean
  other?: string
}

export interface DataAdminDashboard {
  lastUsers?: boolean
  bestTrainings?: boolean
  worstResults?: boolean
  motivationCenter?: boolean
  other?: string
}

export interface BetaLagoonFormAnswers {
  _id?: string
  betaContact: string
  globalSatisfactionNote?: number
  globalBestAspects?: GlobalBestAspects
  globalImprovements?: string
  globalDeviceAnomalies?: boolean
  globalFuturesFeatures?: string
  globalCurrentFeaturesNoInterest?: string
  conceptNote?: number
  conceptLMSUsage?: ConceptLMSUsage
  conceptLMSConstraints?: ConceptLMSConstraintsVA
  conceptLMSVA?: ConceptLMSConstraintsVA
  contentCatalogRelevant?: string
  contentOtherSubjects?: string
  contentFavoriteType?: ContentFavoriteType
  contentPrivateSection?: boolean
  uiAttractiveNote?: number
  uiNavigation?: number
  uiPageModuleImprovements?: string
  uiCategoryModuleImprovements?: string
  uixLoadingAcceptable?: boolean
  uixReadability?: boolean
  uixNavigationBetweenPages?: string
  dataMyPersonalData?: DataMyPersonalData
  dataUserDashboard?: DataUserDashboard
  dataAdminDashboard?: DataAdminDashboard
  dataMonitoring?: DataMonitoringEnum
  dataNbInscriptions?: number
  dataNbSubscriptions?: number
  dataPriceRange?: number
  dataPriceComparison?: DataPriceComparisonEnum
  dataInvestment?: DataInvestmentEnum
  paramFormTimeElapsed?: number
  paramFormNbSaved?: number
  updatedAt: Date
  betaContactInfo: BetaContact
}

export interface BetaLagoonFormAnswersState {
  isLoading: boolean
  betaLagoonFormAnswers: BetaLagoonFormAnswers
  error: string
  success: boolean
}

const initialState: BetaLagoonFormAnswersState = {
  isLoading: false,
  betaLagoonFormAnswers: {} as BetaLagoonFormAnswers,
  error: '',
  success: false,
}

export const deleteBetaLagoonFormAnswers = createAsyncThunk(
  'roselagon/beta-lagoon-form-answers/delete',
  async (id: string, thunkAPI) => {
    return deleteBetaLagoonFormAnswersThunk(
      '/roselagon/betacontacts/',
      id,
      thunkAPI
    )
  }
)

const betaLagoonFormAnswersSlice = createSlice({
  name: 'betaLagoonFormAnswers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteBetaLagoonFormAnswers.pending, (state) => {
        state.isLoading = true
        state.error = ''
        state.success = false
      })
      .addCase(deleteBetaLagoonFormAnswers.fulfilled, (state) => {
        state.isLoading = false
        state.success = true
      })
      .addCase(deleteBetaLagoonFormAnswers.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
        state.success = false
      })
  },
})

export default betaLagoonFormAnswersSlice.reducer
