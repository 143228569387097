import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllModulesThunk } from './allModulesThunk'

const initialFiltersState = {
  search: '',
  searchStatus: 'all',
  sort: 'latest',
  sortOptions: ['latest', 'oldest', 'a-z', 'z-a', 'category'],
}

const initialState = {
  isLoading: false,
  modules: [],
  totalModules: 0,
  numOfPages: 1,
  page: 1,
  error: '',
  // stats: {},
  // monthlyApplications: [],
  ...initialFiltersState,
}

export const getAllModules = createAsyncThunk(
  'modules/list',
  getAllModulesThunk
)

// export const showStats = createAsyncThunk('allJobs/showStats', showStatsThunk)

const allModulesSlice = createSlice({
  name: 'allModules',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true
    },
    hideLoading: (state) => {
      state.isLoading = false
    },
    handleChange: (state, { payload: { name, value } }) => {
      state.page = 1
      state[name] = value
    },
    clearFilters: (state) => {
      return { ...state, ...initialFiltersState }
    },
    changePage: (state, { payload }) => {
      state.page = payload
    },
    addModuleToDataTable: (state, { payload }) => {
      state.modules.unshift(payload)
      state.totalModules++
    },
    editModuleToDataTable: (state, { payload }) => {
      state.modules = state.modules.map((module) => {
        if (module._id === payload._id) {
          module = { ...payload }
        }
        return module
      })
    },
    removeModuleToDataTable: (state, { payload }) => {
      state.modules = state.modules.filter((module) => {
        return module._id !== payload._id
      })
      state.totalModules--
    },
    clearAllModulesState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllModules.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(getAllModules.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.modules = payload.modules.map((module) => {
          return module
        })
        state.numOfPages = payload.numOfPages
        state.totalModules = payload.totalModules
      })
      .addCase(getAllModules.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
  },
})

export const {
  showLoading,
  hideLoading,
  handleChange,
  clearFilters,
  changePage,
  clearAllModulesState,
  addModuleToDataTable,
  editModuleToDataTable,
  removeModuleToDataTable,
} = allModulesSlice.actions

export default allModulesSlice.reducer
