import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllBetaLagoonFormAnswersThunk } from './allBetaLagoonFormAnswersThunk'

const initialFiltersState = {
  filterBetaContact: [],
  sort: 'latest',
  sortOptions: ['latest', 'oldest'],
}

const initialState = {
  isLoading: false,
  betaLagoonFormAnswers: [],
  totalBetaLagoonFormAnswers: 0,
  numOfPages: 1,
  page: 1,
  error: '',
  ...initialFiltersState,
}

export const getAllBetaLagoonFormAnswers = createAsyncThunk(
  'beta-lagoon-form-answers/list',
  getAllBetaLagoonFormAnswersThunk
)

const allBetaLagoonFormAnswersSlice = createSlice({
  name: 'allBetaLagoonFormAnswers',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true
    },
    hideLoading: (state) => {
      state.isLoading = false
    },
    handleChange: (state, { payload: { name, value } }) => {
      state.page = 1
      state[name] = value
    },
    clearFilters: (state) => {
      return { ...state, ...initialFiltersState }
    },
    changePage: (state, { payload }) => {
      state.page = payload
    },
    removeBetaLagoonFormAnswersToDataTable: (state, { payload }) => {
      state.betaLagoonFormAnswers = state.betaLagoonFormAnswers.filter(
        (betaLagoonFormAnswer) => {
          return betaLagoonFormAnswer.betaContact !== payload.betaContact
        }
      )
      state.totalRecording--
    },
    clearAllBetaLagoonFormAnswersState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBetaLagoonFormAnswers.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(getAllBetaLagoonFormAnswers.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.betaLagoonFormAnswers = payload.betaLagoonFormAnswers
        state.numOfPages = payload.numOfPages
        state.totalBetaLagoonFormAnswers = payload.totalBetaLagoonFormAnswers
      })
      .addCase(getAllBetaLagoonFormAnswers.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
  },
})

export const {
  showLoading,
  hideLoading,
  handleChange,
  clearFilters,
  changePage,
  clearAllBetaLagoonFormAnswersState,
  removeBetaLagoonFormAnswersToDataTable,
} = allBetaLagoonFormAnswersSlice.actions

export default allBetaLagoonFormAnswersSlice.reducer
