import { Button } from 'flowbite-react'
import { FaCopy } from 'react-icons/fa'
import { useToast } from '../hooks/useToast'

const CopyToClipBoard = ({ textToCopy }) => {
  const toast = useToast()
  return (
    <Button
      size={'xs'}
      color='gray'
      onClick={() => {
        navigator.clipboard.writeText(textToCopy)
        toast('success', `${textToCopy} a été collé dans le presse papiers`)
      }}
    >
      <FaCopy></FaCopy>
    </Button>
  )
}
export default CopyToClipBoard
