import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'
import { useDispatch } from 'react-redux'

const Pagination = ({ numOfPages, page, total, changePage }) => {
  const dispatch = useDispatch()

  // for displaying specific pages buttons
  // const pages = Array.from({ length: numOfPages }, (_, index) => {
  //   return index + 1
  // })

  const nextPage = () => {
    let newPage = page + 1
    if (newPage > numOfPages) {
      newPage = 1
    }
    dispatch(changePage(newPage))
  }
  const prevPage = () => {
    let newPage = page - 1
    if (newPage < 1) {
      newPage = numOfPages
    }
    dispatch(changePage(newPage))
  }

  return (
    <div className='sticky bottom-0 right-0 w-full items-center border-t border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex sm:justify-between'>
      <div className='mb-4 flex items-center sm:mb-0'>
        {numOfPages > 1 && (
          <>
            <button
              onClick={prevPage}
              className='inline-flex cursor-pointer justify-center rounded p-1 text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
            >
              <span className='sr-only'>Page précédente</span>
              <HiChevronLeft className='text-2xl' />
            </button>
            <button
              onClick={nextPage}
              className='mr-2 inline-flex cursor-pointer justify-center rounded p-1 text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
            >
              <span className='sr-only'>Page suivante</span>
              <HiChevronRight className='text-2xl' />
            </button>
          </>
        )}

        <span className='text-sm font-normal text-gray-500 dark:text-gray-400'>
          Pages&nbsp;
          <span className='font-semibold text-gray-900 dark:text-white'>
            {page}/{numOfPages}
          </span>
          &nbsp;Résultats&nbsp;
          <span className='font-semibold text-gray-900 dark:text-white'>
            {total}
          </span>
        </span>
      </div>
      <div className='flex items-center space-x-3'>
        {numOfPages > 1 && (
          <>
            <button
              onClick={prevPage}
              className='inline-flex flex-1 items-center justify-center rounded-lg bg-primary-700 px-3 py-2 text-center text-sm font-medium text-white hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800'
            >
              <HiChevronLeft className='mr-1 text-base' />
              Précédent
            </button>
            <button
              onClick={nextPage}
              className='inline-flex flex-1 items-center justify-center rounded-lg bg-primary-700 px-3 py-2 text-center text-sm font-medium text-white hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800'
            >
              Suivant
              <HiChevronRight className='ml-1 text-base' />
            </button>
          </>
        )}
      </div>
    </div>
  )
}
export default Pagination
