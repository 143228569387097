import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  addTrainerThunk,
  editTrainerThunk,
  getTrainerThunk,
  removeTrainerThunk,
} from './trainerThunk'
import { Resource } from '../resource/resourceSlice'

export interface Trainer {
  _id: string
  name: string
  lastName: string
  email: string
  job: string | undefined
  link: string | undefined
  image: string | null
  imageResource: Resource | null
}

export interface TrainerForm {
  _id?: string
  name: string
  lastName: string
  email: string
  job?: string | undefined
  link?: string | undefined
  image?: string | undefined | null
}

export interface TrainerState {
  isLoading: boolean
  trainer: Trainer
  error: string
}

const initialState: TrainerState = {
  isLoading: false,
  trainer: {} as Trainer,
  error: '',
}

export const getTrainer = createAsyncThunk(
  'trainer/get',
  async (id: string, thunkAPI) => {
    return getTrainerThunk('/trainers', id, thunkAPI)
  }
)

export const addTrainer = createAsyncThunk(
  'trainer/new',
  async (trainer: TrainerForm, thunkAPI) => {
    return addTrainerThunk('/trainers', trainer, thunkAPI)
  }
)

export const removeTrainer = createAsyncThunk(
  'trainer/remove',
  async (trainer: TrainerForm, thunkAPI) => {
    return removeTrainerThunk('/trainers/:id', trainer, thunkAPI)
  }
)

export const editTrainer = createAsyncThunk(
  'trainer/edit',
  async (trainer: TrainerForm, thunkAPI) => {
    return editTrainerThunk('/trainers/:id', trainer, thunkAPI)
  }
)

const trainerSlice = createSlice({
  name: 'trainer',
  initialState,
  reducers: {
    clearTrainerForm: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTrainer.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(getTrainer.fulfilled, (state, { payload }) => {
        const { trainer } = payload
        state.trainer = trainer
        state.isLoading = false
      })
      .addCase(getTrainer.rejected, (state, { payload }) => {
        state.isLoading = false
        state.trainer = {} as Trainer
        state.error = payload as string
      })
      .addCase(addTrainer.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(addTrainer.fulfilled, (state, { payload }) => {
        const { trainer } = payload
        state.trainer = trainer
        state.isLoading = false
      })
      .addCase(addTrainer.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addCase(editTrainer.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(editTrainer.fulfilled, (state, { payload }) => {
        state.isLoading = false
        const { trainer } = payload
        state.trainer = trainer
      })
      .addCase(editTrainer.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addCase(removeTrainer.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(removeTrainer.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(removeTrainer.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
  },
})

export const { clearTrainerForm } = trainerSlice.actions

export default trainerSlice.reducer
