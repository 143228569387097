import { Checkbox, Dropdown, Label, Modal, TextInput } from 'flowbite-react'
import { FC } from 'react'
import {
  BetaContactAdminForm,
  ElearningUsageEnum,
} from '../../features/betaContact/betaContactSlice'
import { CloseIcon } from '../icons'

interface BetaContactModalBodyProps {
  values: BetaContactAdminForm
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void
  handleCheckEmployment: (e: React.FormEvent<HTMLInputElement>) => void
  handleCheckAddedValue: (e: React.FormEvent<HTMLInputElement>) => void
  handleDropDownChange: (e: ElearningUsageEnum) => void
  handleRemoveValue: (e: string) => void
}

export const BetaContactModalBody: FC<BetaContactModalBodyProps> = ({
  values,
  handleChange,
  handleCheckEmployment,
  handleCheckAddedValue,
  handleDropDownChange,
  handleRemoveValue,
}: BetaContactModalBodyProps) => {
  return (
    <Modal.Body>
      <div className='grid grid-cols-1 gap-6 lg:grid-cols-2'>
        <div>
          <Label htmlFor='emailBetaContact'>Entrez l'email</Label>
          <div className='mt-1'>
            <TextInput
              id='emailBetaContact'
              name='email'
              type='email'
              placeholder='aaa@bbb.ccc'
              onChange={handleChange}
              value={values.email}
              required={true}
              disabled={values._id !== undefined}
            />
          </div>
        </div>
        <div>
          <Label htmlFor='nameBetaContact'>Entrez le nom</Label>
          <div className='mt-1'>
            <TextInput
              id='nameBetaContact'
              name='name'
              type='text'
              placeholder='Dupont'
              onChange={handleChange}
              value={values.name}
              required={true}
            />
          </div>
        </div>
        <div>
          <Label htmlFor='nameLastNameContact'>Entrez le prénom</Label>
          <div className='mt-1'>
            <TextInput
              id='nameLastNameContact'
              name='lastName'
              type='text'
              placeholder='Michel'
              onChange={handleChange}
              value={values.lastName}
              required={true}
            />
          </div>
        </div>
        <div>
          <Label className='flex items-center' htmlFor='elearningUsage'>
            Fréquence elearning
            {values.elearningUsage && (
              <span onClick={() => handleRemoveValue('elearningUsage')}>
                <CloseIcon className='ml-2 w-2 h-2'></CloseIcon>
              </span>
            )}
          </Label>
          <div className='mt-2'>
            <Dropdown
              id='elearningUsage'
              color={'primary'}
              label={
                values.elearningUsage === undefined
                  ? 'Choisissez une fréquence'
                  : values.elearningUsage === ElearningUsageEnum.OFTEN
                  ? 'Souvent'
                  : values.elearningUsage === ElearningUsageEnum.SOMETIMES
                  ? 'Parfois'
                  : values.elearningUsage === ElearningUsageEnum.NEVER &&
                    'Jamais'
              }
            >
              <Dropdown.Item
                onClick={() => handleDropDownChange(ElearningUsageEnum.OFTEN)}
              >
                Souvent
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  handleDropDownChange(ElearningUsageEnum.SOMETIMES)
                }
              >
                Parfois
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleDropDownChange(ElearningUsageEnum.NEVER)}
              >
                Jamais
              </Dropdown.Item>
            </Dropdown>
          </div>
        </div>
        <div className='text-white'>
          <span className='text-sm'>Fonction</span>
          <div className='grid grid-cols-3 gap-x-1'>
            <div>
              <Checkbox
                name='digitalTraining'
                id='digitalTraining'
                className='mr-2'
                onChange={handleCheckEmployment}
                checked={values.employment?.digitalTraining}
              ></Checkbox>
              <Label htmlFor='digitalTraining'>Formation digitale</Label>
            </div>
            <div>
              <Checkbox
                name='facetofaceTraining'
                id='facetofaceTraining'
                className='mr-2'
                onChange={handleCheckEmployment}
                checked={values.employment?.facetofaceTraining}
              ></Checkbox>
              <Label htmlFor='facetofaceTraining'>Formation présentielle</Label>
            </div>
            <div>
              <Checkbox
                name='futureChallenges'
                id='futureChallenges'
                className='mr-2'
                onChange={handleCheckEmployment}
                checked={values.employment?.futureChallenges}
              ></Checkbox>
              <Label htmlFor='futureChallenges'>Enjeux climatiques</Label>
            </div>
            <div>
              <Checkbox
                name='hr'
                id='hr'
                className='mr-2'
                onChange={handleCheckEmployment}
                checked={values.employment?.hr}
              ></Checkbox>
              <Label htmlFor='hr'>Ressources humaines</Label>
            </div>
            <div>
              <Checkbox
                name='ceo'
                id='ceo'
                className='mr-2'
                onChange={handleCheckEmployment}
                checked={values.employment?.ceo}
              ></Checkbox>
              <Label htmlFor='ceo'>Entrepreneur</Label>
            </div>
            <div className='col-span-3'>
              <Label htmlFor='otherEmployment'>Autre(s) fonction(s)</Label>
              <div className='mt-1'>
                <TextInput
                  id='otherEmployment'
                  name='other'
                  type='text'
                  onChange={handleCheckEmployment}
                  value={values.employment?.other}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='text-white'>
          <span className='text-sm'>Expertise</span>
          <div className='grid grid-cols-2 gap-x-1'>
            <div>
              <Checkbox
                name='educational'
                id='educational'
                className='mr-2'
                onChange={handleCheckAddedValue}
                checked={values.addedvalue?.educational}
              ></Checkbox>
              <Label htmlFor='educational'>Expertise Pédagogique</Label>
            </div>
            <div>
              <Checkbox
                name='graphics'
                id='graphics'
                className='mr-2'
                onChange={handleCheckAddedValue}
                checked={values.addedvalue?.graphics}
              ></Checkbox>
              <Label htmlFor='graphics'>Graphismes/UI</Label>
            </div>
            <div>
              <Checkbox
                name='data'
                id='data'
                className='mr-2'
                onChange={handleCheckAddedValue}
                checked={values.addedvalue?.data}
              ></Checkbox>
              <Label htmlFor='data'>Données/Technique</Label>
            </div>
            {/* <div>
                    <Checkbox
                      name='classic'
                      id='classic'
                      className='mr-2'
                      onChange={handleCheckAddedValue}
                      checked={values.addedvalue?.classic}
                    ></Checkbox>
                    <Label htmlFor='classic'>Utilisation utilisateur</Label>
                  </div> */}
            <div className='col-span-2'>
              <Label htmlFor='otherAddedValue'>Autre(s) Expertise(s)</Label>
              <div className='mt-1'>
                <TextInput
                  id='otherAddedValue'
                  name='other'
                  type='text'
                  onChange={handleCheckAddedValue}
                  value={values.addedvalue?.other}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal.Body>
  )
}
