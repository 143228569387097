import axios from 'axios'
import { backofficePath } from '../../utils/axios'

export const showMeThunk = async (url, thunkAPI) => {
  try {
    // not using custom fetch for avoid infinite looping with check senderID
    const resp = await axios
      .create({
        baseURL: backofficePath,
        withCredentials: true,
      })
      .get(url)
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg)
  }
}

export const logoutThunk = async (url, thunkAPI) => {
  try {
    const resp = await axios
      .create({
        baseURL: backofficePath,
        withCredentials: true,
      })
      .delete(url)
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue('Unable to logout')
  }
}

export const loginUserThunk = async (url, user, thunkAPI) => {
  try {
    const resp = await axios
      .create({
        baseURL: backofficePath,
        withCredentials: true,
      })
      .post(url, {
        password: user.password,
        email: user.email,
      })
    return resp.data
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg)
  }
}

// export const clearStoreThunk = async (message, thunkAPI) => {
//   try {
//     // logout user
//     thunkAPI.dispatch(logoutUser(message))
//     // clear modules value
//     thunkAPI.dispatch(clearAllModulesState())
//     return Promise.resolve()
//   } catch (error) {
//     // console.log(error);
//     return Promise.reject()
//   }
// }
