import { useRef, useEffect } from 'react'
import EditorJS from '@editorjs/editorjs'
import Header from '@editorjs/header'
import List from '@editorjs/list'
import Delimiter from '@editorjs/delimiter'
import RawTool from '@editorjs/raw'
import { Button } from 'flowbite-react'

// Define the Editor component
const EditorRow = ({ initialData, callback }) => {
  // Create a ref to hold the EditorJS instance
  const editorRef = useRef(null)

  // Function to handle saving the editor content
  const handleSave = async () => {
    if (editorRef.current) {
      const output = await editorRef.current.save()
      callback(output)
    }
  }

  // Initialize the editor when the component mounts
  useEffect(() => {
    // Initialize EditorJS
    editorRef.current = new EditorJS({
      holder: 'editor-container', // Specify the container element by its id
      autofocus: true, // Autofocus on the editor when it loads
      tools: {
        header: {
          class: Header,
          inlineToolbar: ['link'],
        },
        list: {
          class: List,
          inlineToolbar: true,
        },
        delimiter: Delimiter,
        raw: {
          class: RawTool,
        },
      }, // Add your custom tools here
      data: initialData, // Pass the initial data to the editor
    })

    // Cleanup function to destroy the editor when the component unmounts
    return () => {
      if (editorRef && editorRef.current && editorRef.current.destroy) {
        editorRef.current.destroy()
      }
    }
  }, [initialData])

  return (
    <div className='rounded-lg p-5 bg-white'>
      <div id='editor-container'></div>
      <Button color={'primary'} onClick={handleSave}>
        Enregistrer la description
      </Button>
    </div>
  )
}

export default EditorRow
