import { useToastDispatchContext } from '../context/ToastContext'

export function useCleanToasts() {
  const dispatch = useToastDispatchContext()

  function cleanToasts() {
    dispatch({ type: 'CLEAN_TOASTS', toast: {} })
  }

  return cleanToasts
}
