import { FC, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useTypedSelector } from '../../store'
import { useToast } from '../../hooks/useToast'
import {
  changeInputValues,
  clearFilters,
  getAllSpecialUsers,
} from '../../features/allSpecialUsers/allSpecialUsersSlice'
import { HiUser } from 'react-icons/hi'
import { Avatar, Button, Modal, Table } from 'flowbite-react'
import DataTableController from '../DataTableController'
import {
  SpecialUser,
  clearSpecialUserForm,
  getUser,
} from '../../features/specialUser/specialUserSlice'
import Loading from '../Loading'
import profile_icon from '../../assets/images/profile_icon.svg'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

const FilterUserSelect: FC<{
  handleChange: ActionCreatorWithPayload<any, any>
}> = ({ handleChange }) => {
  const { filterUser } = useTypedSelector((store) => store.allRecordings)

  const {
    error,
    search,
    sort,
    role,
    users: specialUsers,
  } = useTypedSelector((store: any) => store.allSpecialUsers)

  // it's possible to preselect filter user or param if passing info in route
  const { userId } = useParams()
  const { specialUser, error: errorSpecialUser } = useTypedSelector(
    (store) => store.specialUser
  )

  useEffect(() => {
    if (userId) {
      dispatch(getUser(userId))
    } else {
      dispatch(clearSpecialUserForm())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  const [isOpen, setOpen] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState<SpecialUser[]>([])

  useEffect(() => {
    if (specialUser._id) {
      const newSpecialUser = [specialUser]
      setSelectedUsers(newSpecialUser)
    } else {
      setSelectedUsers([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialUser])

  const specialUsersMinusSelectedUser = useMemo(() => {
    const castSpecialUsers = specialUsers as SpecialUser[]
    return castSpecialUsers.filter(
      (s) => !selectedUsers.find((selected) => selected._id === s._id)
    )
  }, [selectedUsers, specialUsers])

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
    }
    if (errorSpecialUser !== '') {
      toast('error', errorSpecialUser)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, errorSpecialUser])

  const dispatch = useAppDispatch()
  const toast = useToast()

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(false)
  }

  useEffect(() => {
    // apply filter when closing and if filterUser is changed
    if (!isOpen) {
      const reducedSelectedUsers = selectedUsers.map((s) => {
        return s._id
      })
      if (userId) {
        if (specialUser) {
          if (
            JSON.stringify(reducedSelectedUsers) !== JSON.stringify(filterUser)
          ) {
            dispatch(
              handleChange({
                name: 'filterUser',
                value: reducedSelectedUsers,
              })
            )
          }
        }
      } else {
        if (JSON.stringify(reducedSelectedUsers) !== JSON.stringify(filterUser))
          dispatch(
            handleChange({
              name: 'filterUser',
              value: reducedSelectedUsers,
            })
          )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, selectedUsers, userId, specialUser])

  const addSelectedUser = (newUser: SpecialUser) => {
    setSelectedUsers([...selectedUsers, newUser])
  }

  const removeSelectedUser = (newUser: SpecialUser) => {
    setSelectedUsers(selectedUsers.filter((s) => s._id !== newUser._id))
  }

  useEffect(() => {
    if (userId === undefined) {
      dispatch(getAllSpecialUsers({}))
    }
  }, [dispatch, search, sort, role, userId])

  return (
    <>
      <Button
        id={'search-user'}
        color='primary'
        onClick={() => setOpen(!isOpen)}
        disabled={typeof userId === 'string'}
      >
        <HiUser className='mr-2 text-lg' />
        <span className='hidden lg:block '>{`${selectedUsers.length} utilisateur(s) selectionné(s)`}</span>
        <span className='block lg:hidden '>{`${selectedUsers.length} user(s)`}</span>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <Modal.Header
          theme={{
            base: `flex items-start justify-between rounded-t-2xl border-b p-5 dark:border-gray-600`,
            popup: 'border-b-0 p-2',
          }}
        >
          <span>Selectionnez un ou plusieurs utilisateur(s)</span>
        </Modal.Header>
        <Modal.Body className='p-5'>
          <div className='w-full lg:w-1/2 sm:flex'>
            <DataTableController
              placeholder='rechercher un utilisateur'
              handleChange={changeInputValues}
              sort={sort}
              search={search}
              // @ts-ignore
              clean={clearFilters}
              initFilters={[
                {
                  name: 'role',
                  value: role,
                  control: 'beta',
                  text: 'Beta uniquement',
                },
                {
                  name: 'role',
                  value: role,
                  control: 'admin',
                  text: 'Admin uniquement',
                },
              ]}
            ></DataTableController>
          </div>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
            <div className='flex flex-col'>
              <h2 className='py-3 text-white text-bold'>
                Tableau de recherche
              </h2>
              <div className='overflow-x-auto'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='overflow-hidden shadow'>
                    <SearchTable
                      specialUsers={specialUsersMinusSelectedUser}
                      onMoveCallback={addSelectedUser}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-col'>
              <h2 className='py-3 text-white text-bold'>
                Tableau de selection
              </h2>
              <div className='overflow-x-auto'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='overflow-hidden shadow'>
                    <SelectedTable
                      specialUsers={selectedUsers}
                      onMoveCallback={removeSelectedUser}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className='self-end' color='primary' onClick={handleSubmit}>
            Fermer le filtre utilisateur
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const SearchTable: FC<{
  specialUsers: SpecialUser[]
  onMoveCallback: (specialUser: SpecialUser) => void
}> = function ({ specialUsers, onMoveCallback }) {
  const { isLoading } = useTypedSelector((store: any) => store.allSpecialUsers)
  if (isLoading) {
    return <Loading center />
  }
  if (specialUsers.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>Pas d'utilisateurs à afficher...</span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>Nom</Table.HeadCell>
        <Table.HeadCell>Role</Table.HeadCell>
        <Table.HeadCell>Status</Table.HeadCell>
        <Table.HeadCell>Selection</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {specialUsers.map((specialUser) => {
          return (
            <Table.Row
              key={specialUser._id}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='mr-12 flex items-center space-x-6 whitespace-nowrap p-4 lg:mr-0'>
                <Avatar
                  alt=''
                  img={profile_icon}
                  rounded
                  size='sm'
                  className='shrink-0'
                />
                <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                  <div className='text-base font-semibold text-gray-900 dark:text-white'>
                    {specialUser.lastName} {specialUser.name}
                  </div>
                  <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                    {specialUser.email}
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                {specialUser.role}
              </Table.Cell>
              {specialUser.isVerified ? (
                <Table.Cell className='whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white'>
                  <div className='flex items-center'>
                    <div className='mr-2 h-2.5 w-2.5 rounded-full bg-green-400'></div>{' '}
                    Active
                  </div>
                </Table.Cell>
              ) : (
                <Table.Cell className='whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white'>
                  <div className='flex items-center'>
                    <div className='mr-2 h-2.5 w-2.5 rounded-full bg-red-500'></div>{' '}
                    Doit valider son mdp
                  </div>
                </Table.Cell>
              )}
              <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                <Button
                  color={'gray'}
                  onClick={() => onMoveCallback(specialUser)}
                >
                  <FaArrowRight className='h-4 w-4' />
                </Button>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

const SelectedTable: FC<{
  specialUsers: SpecialUser[]
  onMoveCallback: (specialUser: SpecialUser) => void
}> = function ({ specialUsers, onMoveCallback }) {
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>Déselection</Table.HeadCell>
        <Table.HeadCell>Nom</Table.HeadCell>
        <Table.HeadCell>Role</Table.HeadCell>
        <Table.HeadCell>Status</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {specialUsers.map((specialUser) => {
          return (
            <Table.Row
              key={specialUser._id}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                <Button
                  color={'gray'}
                  onClick={() => onMoveCallback(specialUser)}
                >
                  <FaArrowLeft className='h-4 w-4' />
                </Button>
              </Table.Cell>
              <Table.Cell className='mr-12 flex items-center space-x-6 whitespace-nowrap p-4 lg:mr-0'>
                <Avatar
                  alt=''
                  img={profile_icon}
                  rounded
                  size='sm'
                  className='shrink-0'
                />
                <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                  <div className='text-base font-semibold text-gray-900 dark:text-white'>
                    {specialUser.lastName} {specialUser.name}
                  </div>
                  <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                    {specialUser.email}
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                {specialUser.role}
              </Table.Cell>
              {specialUser.isVerified ? (
                <Table.Cell className='whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white'>
                  <div className='flex items-center'>
                    <div className='mr-2 h-2.5 w-2.5 rounded-full bg-green-400'></div>{' '}
                    Active
                  </div>
                </Table.Cell>
              ) : (
                <Table.Cell className='whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white'>
                  <div className='flex items-center'>
                    <div className='mr-2 h-2.5 w-2.5 rounded-full bg-red-500'></div>{' '}
                    Doit valider son mdp
                  </div>
                </Table.Cell>
              )}
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default FilterUserSelect
