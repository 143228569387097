/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Breadcrumb,
  Button,
  Checkbox,
  Label,
  Modal,
  Progress,
  Table,
} from 'flowbite-react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { HiHome, HiOutlineExclamationCircle, HiTrash } from 'react-icons/hi'
import { DataTableController, Loading, Pagination } from '../../components'
import { useAppDispatch, useTypedSelector } from '../../store'
import { useToast } from '../../hooks/useToast'
import {
  getAllRecordings,
  removeRecordingToDataTable,
  changePage,
  clearFilters,
  handleChange,
} from '../../features/allRecordings/allRecordingsSlice'
import {
  Recording,
  RecordingInteraction,
  removeRecording,
} from '../../features/recording/recordingSlice'
import moment from 'moment'
import FilterUserSelect from '../../components/filtersSelect/FilterUserSelect'
import FilterModuleSelect from '../../components/filtersSelect/FilterModuleSelect'
import { useParams } from 'react-router-dom'
import { MdCheck, MdClose } from 'react-icons/md'
import {
  Assessment,
  Evaluation,
  InteractionEnum,
} from '../../features/interaction/interactionSlice'
import {
  clearHistoryInteractionRecordingForm,
  getHistoryRecordingInteraction,
} from '../../features/historyRecordingInteraction/historyRecordingInteractionSlice'

const RecordingsListPage: FC = function () {
  const {
    error,
    page,
    totalRecordings,
    numOfPages,
    sort,
    filterUser,
    filterProgress,
    filterModuleVersion,
    filterModule,
    filterFinished,
    sortOptions,
  } = useTypedSelector((store) => store.allRecordings)
  // const { error: errorSkill } = useTypedSelector((store) => store.skill)
  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  // possibility to access this page directly in module or user page
  const { moduleId, userId } = useParams()
  const { module } = useTypedSelector((store) => store.module)
  const { specialUser } = useTypedSelector((store) => store.specialUser)

  useEffect(() => {
    dispatch(getAllRecordings({}))
  }, [
    dispatch,
    page,
    sort,
    filterUser,
    filterFinished,
    filterModule,
    filterModuleVersion,
    filterProgress,
  ])
  return (
    <>
      <div className='block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex'>
        <div className='mb-1 w-full'>
          <div className='mb-4'>
            <Breadcrumb className='mb-4'>
              <Breadcrumb.Item href='#'>
                <div className='flex items-center gap-x-3'>
                  <HiHome className='text-xl' />
                  <span className='dark:text-white'>Home</span>
                </div>
              </Breadcrumb.Item>
              {moduleId ? (
                <>
                  <Breadcrumb.Item href='/modules'>Modules</Breadcrumb.Item>
                  <Breadcrumb.Item href={`/modules/${module._id}`}>
                    {module.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item href={`/modules/${module._id}/recordings`}>
                    Enregistrements
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Liste</Breadcrumb.Item>
                </>
              ) : userId ? (
                <>
                  <Breadcrumb.Item href='/administration/users'>
                    Utilisateurs
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                  // href={`/administration/users/${specialUser._id}`}
                  >
                    {specialUser.lastName} {specialUser.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    href={`/administration/users/${specialUser._id}/recordings`}
                  >
                    Enregistrements
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Liste</Breadcrumb.Item>
                </>
              ) : (
                <>
                  <Breadcrumb.Item href={`/recordings`}>
                    Enregistrements
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Liste</Breadcrumb.Item>
                </>
              )}
            </Breadcrumb>
            <h1 className='text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl'>
              Tous les enregistrements{' '}
              {moduleId
                ? ` du module ${module.name}`
                : userId &&
                  ` de l'utilisateur ${specialUser.lastName} ${specialUser.name}`}
            </h1>
          </div>
          <div className='block items-center sm:flex'>
            <DataTableController
              placeholder='rechercher un enregistrement'
              handleChange={handleChange}
              sort={sort}
              sortOptions={sortOptions}
              selectOptions={[
                <div
                  key={'FilterUserSelect'}
                  className='mb-1 grid grid-cols-1 gap-y-2'
                >
                  <Label htmlFor={`search-user`}>
                    Rechercher un utilisateur
                  </Label>
                  <FilterUserSelect handleChange={handleChange} />
                </div>,
                <div
                  key={'FilterModuleSelect'}
                  className='mb-1 grid grid-cols-1 gap-y-2'
                >
                  <Label htmlFor={`search-module`}>Rechercher un module</Label>
                  <FilterModuleSelect
                    handleChange={handleChange}
                    filterModule={filterModule as unknown as string}
                  />
                </div>,
              ]}
              initFilters={[
                {
                  name: 'filterFinished',
                  value: filterFinished,
                  control: 'true',
                  text: 'Uniquement les enregistrements terminés',
                },
                {
                  name: 'filterFinished',
                  value: filterFinished,
                  control: 'false',
                  text: 'Uniquement les enregistrements non terminés',
                },
              ]}
              // @ts-ignore
              clean={clearFilters}
            ></DataTableController>
          </div>
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='overflow-x-auto'>
          <div className='inline-block min-w-full align-middle'>
            <div className='overflow-hidden shadow'>
              <RecordingsTable />
            </div>
          </div>
        </div>
      </div>
      <Pagination
        numOfPages={numOfPages}
        total={totalRecordings}
        page={page}
        changePage={changePage}
      />
    </>
  )
}

const DeleteRecordingModal: FC<{ recordingSelected: Recording }> = function ({
  recordingSelected,
}) {
  const [isOpen, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const toast = useToast()
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    toast('success', `L'enregistrement a bien été supprimé`)
    dispatch(removeRecording(recordingSelected))
    dispatch(removeRecordingToDataTable(recordingSelected))
    setOpen(false)
  }
  return (
    <>
      <Button color='failure' onClick={() => setOpen(!isOpen)}>
        <HiTrash className='mr-2 text-lg' />
        <span className='hidden lg:block '>Supprimer l'enregistrement'</span>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen} size='md'>
        <Modal.Header className='px-3 pb-0 pt-3'>
          <span className='sr-only'>Supprimer l'enregistrement</span>
        </Modal.Header>
        <Modal.Body className='px-6 pb-6 pt-0'>
          <div className='flex flex-col items-center gap-y-6 text-center'>
            <HiOutlineExclamationCircle className='text-7xl text-red-600' />
            <p className='text-lg text-gray-500 dark:text-gray-300'>
              Êtes vous sur de vouloir détruire l'enregistrement ?
            </p>
            <div className='flex items-center gap-x-3'>
              <Button color='failure' onClick={handleSubmit}>
                Oui, je suis sur
              </Button>
              <Button color='gray' onClick={() => setOpen(false)}>
                Non, annuler
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const RecordingsTable: FC = function () {
  const { recordings, isLoading } = useTypedSelector(
    (store) => store.allRecordings
  )
  const castRecordings = recordings as Recording[]
  if (isLoading) {
    return <Loading center />
  }
  if (recordings.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>
            Pas d'enregistrements à afficher...
          </span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>
          <span className='sr-only'>Selectionner tous</span>
          <Checkbox />
        </Table.HeadCell>
        <Table.HeadCell>Utilisateur</Table.HeadCell>
        <Table.HeadCell>Module Info</Table.HeadCell>
        <Table.HeadCell>Statut</Table.HeadCell>
        <Table.HeadCell>Données</Table.HeadCell>
        <Table.HeadCell>Mis à jour</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {castRecordings.map((recording) => {
          return (
            <Table.Row
              key={recording._id}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='w-4 p-4'>
                <Checkbox />
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {recording.userInfo
                    ? `${recording.userInfo.name} ${recording.userInfo.lastName}`
                    : 'Utilisateur supprimé'}
                </div>
                <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                  {recording.userInfo
                    ? `${recording.userInfo.role}`
                    : recording.user}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {recording.moduleInfo
                    ? `${recording.moduleInfo.name}`
                    : 'Module supprimé'}
                </div>
                <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                  {recording.moduleVersionInfo
                    ? `version : ${recording.moduleVersionInfo.version}`
                    : 'Version supprimée'}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {recording.finished
                    ? `Terminé ${moment(recording.finished).fromNow()}`
                    : 'Pas terminé'}
                </div>
                <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                  Avancement global :{' '}
                  {recording.progress ? recording.progress : 0}%
                </div>
                <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                  Score global : {recording.score ? recording.score : 0}%
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <GetObjectivesModal recording={recording} />
                <GetInteractionsModal recording={recording} />
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {`${moment(recording.updatedAt).fromNow()}`}
                </div>
              </Table.Cell>
              <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                <div className='flex items-center gap-x-3'>
                  <DeleteRecordingModal recordingSelected={recording} />
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

const GetObjectivesModal: FC<{ recording: Recording }> = function ({
  recording,
}) {
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <div className='flex text-base font-semibold text-gray-900 dark:text-white'>
        <span className='mr-2'>Objectifs pédagogiques : </span>
        {recording.recordingLearningObjectives.length > 0 ? (
          <Button color={'gray'} size={'xs'} onClick={() => setOpen(!isOpen)}>
            {recording.recordingLearningObjectives.length}
          </Button>
        ) : (
          <span>0</span>
        )}
      </div>
      <Modal onClose={() => setOpen(false)} show={isOpen} size='md'>
        <Modal.Header className='px-3 pb-0 pt-3'>
          <span className='sr-only'>Objectifs pédagogiques</span>
        </Modal.Header>
        <Modal.Body className='px-6 pb-6 pt-0'>
          <div className='flex flex-col'>
            <div className='overflow-x-auto'>
              <div className='inline-block min-w-full align-middle'>
                <div className='overflow-hidden shadow'>
                  <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
                    <Table.Head className='bg-gray-100 dark:bg-gray-700'>
                      <Table.HeadCell>Objectif</Table.HeadCell>
                      <Table.HeadCell>Temps passé</Table.HeadCell>
                      <Table.HeadCell>Progression</Table.HeadCell>
                      <Table.HeadCell>Score</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
                      {recording.recordingLearningObjectives.map((record) => {
                        return (
                          <Table.Row
                            key={record._id}
                            className='hover:bg-gray-100 dark:hover:bg-gray-700'
                          >
                            <Table.Cell className='mr-12 flex items-center space-x-6 whitespace-nowrap p-4 lg:mr-0'>
                              <div>
                                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                                  {record.learningObjectiveInfo
                                    ? record.learningObjectiveInfo.name
                                    : record.learningObjective}
                                </div>
                                <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                                  {record.learningObjectiveInfo &&
                                    record.learningObjectiveInfo.lrsObjectId}
                                </div>
                              </div>
                            </Table.Cell>
                            <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                              <div>
                                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                                  Temps passé :
                                  {` ${moment(record.timeSpent * 1000).format(
                                    'm'
                                  )}min`}
                                </div>
                                <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                                  Recommandé :
                                  {record.learningObjectiveInfo &&
                                    ` ${moment(
                                      record.learningObjectiveInfo
                                        .learningTime * 1000
                                    ).format('m')}min`}
                                </div>
                              </div>
                            </Table.Cell>
                            <Table.Cell className='p-4 text-base font-medium text-gray-900 dark:text-white'>
                              <div className='text-base w-24 font-semibold text-gray-900 dark:text-white'>
                                <span>{record.progress}%</span>
                                <Progress
                                  progress={record.progress}
                                  color='dark'
                                />
                              </div>
                            </Table.Cell>
                            <Table.Cell className='p-4 text-base font-medium text-gray-900 dark:text-white'>
                              <div className='w-24 text-base font-semibold text-gray-900 dark:text-white'>
                                <span>{record.score}%</span>
                                <Progress
                                  progress={record.score}
                                  color='dark'
                                />
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        )
                      })}
                    </Table.Body>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const GetInteractionsModal: FC<{ recording: Recording }> = function ({
  recording,
}) {
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <div className='flex text-base font-semibold text-gray-900 dark:text-white'>
        <span className='mr-2'>Intéractions : </span>
        {recording.recordingInteractions.length > 0 ? (
          <Button color={'gray'} size={'xs'} onClick={() => setOpen(!isOpen)}>
            {recording.recordingInteractions.length}
          </Button>
        ) : (
          <span>0</span>
        )}
      </div>
      <Modal onClose={() => setOpen(false)} show={isOpen} size='2xl'>
        <Modal.Header className='px-3 pb-0 pt-3'>
          <span className='sr-only'>Interactions</span>
        </Modal.Header>
        <Modal.Body className='px-6 pb-6 pt-0'>
          <div className='flex flex-col'>
            <div className='overflow-x-auto'>
              <div className='inline-block min-w-full align-middle'>
                <div className='overflow-hidden shadow'>
                  <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
                    <Table.Head className='bg-gray-100 dark:bg-gray-700'>
                      <Table.HeadCell>Intéraction</Table.HeadCell>
                      <Table.HeadCell>Type</Table.HeadCell>
                      <Table.HeadCell>Evaluation</Table.HeadCell>
                      <Table.HeadCell>Données</Table.HeadCell>
                      <Table.HeadCell>Date</Table.HeadCell>
                      <Table.HeadCell>Actions</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
                      {recording.recordingInteractions.map((record) => (
                        <TableRecordingsInteractions
                          record={record}
                          history={true}
                          key={record._id}
                        />
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const GetHistoryInteractionsSubModal: FC<{
  recordingInteraction: RecordingInteraction
}> = function ({ recordingInteraction }) {
  const [isOpen, setOpen] = useState(false)
  const { error, historyRecordingInteraction, isLoading } = useTypedSelector(
    (store) => store.historyRecordingInteraction
  )
  // const { error: errorSkill } = useTypedSelector((store) => store.skill)
  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    if (isOpen) {
      dispatch(getHistoryRecordingInteraction(recordingInteraction._id))
    }
  }, [isOpen, dispatch, recordingInteraction])

  useEffect(() => {
    if (!isOpen) {
      dispatch(clearHistoryInteractionRecordingForm())
    }
  }, [isOpen, dispatch])

  if (isLoading) {
    return <Loading center />
  }
  return (
    <>
      <div className='flex text-base font-semibold text-gray-900 dark:text-white'>
        <Button
          color='purple'
          onClick={() => setOpen(!isOpen)}
          disabled={
            recordingInteraction.answered === undefined ||
            recordingInteraction.answered === 0
          }
        >
          Historique (
          {recordingInteraction.answered ? recordingInteraction.answered : 0})
        </Button>
      </div>
      <Modal onClose={() => setOpen(false)} show={isOpen} size='2xl'>
        <Modal.Header className='px-3 pb-0 pt-3'>
          <span className='sr-only'>History</span>
        </Modal.Header>
        <Modal.Body className='px-6 pb-6 pt-0'>
          {historyRecordingInteraction.length > 0 ? (
            <div className='flex flex-col'>
              <div className='overflow-x-auto'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='overflow-hidden shadow'>
                    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
                      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
                        <Table.HeadCell>Intéraction</Table.HeadCell>
                        <Table.HeadCell>Type</Table.HeadCell>
                        <Table.HeadCell>Evaluation</Table.HeadCell>
                        <Table.HeadCell>Données</Table.HeadCell>
                        <Table.HeadCell>Date</Table.HeadCell>
                      </Table.Head>
                      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
                        {historyRecordingInteraction.map((record) => (
                          <TableRecordingsInteractions
                            record={record}
                            history={false}
                            key={record._id}
                          />
                        ))}
                      </Table.Body>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className='text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400'
              role='alert'
            >
              <span className='font-medium'>Pas d'historique trouvé</span>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

const TableRecordingsInteractions: FC<{
  record: RecordingInteraction
  history?: boolean
}> = function ({ record, history = false }) {
  return (
    <Table.Row
      key={record._id}
      className='hover:bg-gray-100 dark:hover:bg-gray-700'
    >
      <Table.Cell className='mr-12 flex items-center space-x-6 whitespace-nowrap max-w-[300px] overflow-hidden text-ellipsis p-4 lg:mr-0'>
        <div>
          <div className='text-base font-semibold text-gray-900 dark:text-white'>
            {record.interactionInfo
              ? record.interactionInfo.title
              : record.interaction}
          </div>
          <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
            {record.interactionInfo?.lrsObjectId}
          </div>
        </div>
      </Table.Cell>
      <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
        <div>
          <div className='text-base font-semibold text-gray-900 dark:text-white'>
            {record.kind === 'RecordingInteractionEvaluation'
              ? 'Evaluation'
              : 'Enquête'}
          </div>
        </div>
      </Table.Cell>
      <Table.Cell className='p-4 text-base font-medium text-gray-900 dark:text-white'>
        <div className='text-base font-semibold text-gray-900 dark:text-white'>
          {record.score !== null && record.score !== undefined && (
            <>
              <span>Score : {record.score}%</span>
              <Progress progress={record.score} color='dark' />
            </>
          )}
          {record.success !== undefined && (
            <div>
              <span>Réussi : {record.success === true ? 'Oui' : 'Non'}</span>
            </div>
          )}
          {record.nbErrors !== undefined &&
            record.nbErrors !== null &&
            record.nbErrors > 0 && (
              <div>
                <span>Erreurs : {record.nbErrors}</span>
              </div>
            )}
        </div>
      </Table.Cell>
      <Table.Cell className='space-x-2 max-w-[300px] overflow-hidden text-ellipsis whitespace-nowrap p-4'>
        <div>
          {record.userAnswers.map((userAnswer, index) => {
            return (
              <div key={userAnswer.answer}>
                {record.interactionInfo &&
                  record.interactionInfo.kind ===
                    InteractionEnum.ASSESSMENT && (
                    <>
                      <span>Reponse {index + 1} : </span>
                      <span>
                        {(record.interactionInfo as Assessment)
                          ? (record.interactionInfo as Assessment).answers.find(
                              (a) => {
                                return a.lrsObjectId === userAnswer.answer
                              }
                            )?.text
                          : ''}
                      </span>
                      {userAnswer.value !== undefined && (
                        <div>
                          {userAnswer.value.toString() === 'true'
                            ? 'oui'
                            : userAnswer.value.toString() === 'false'
                            ? 'non'
                            : userAnswer.value.toString()}
                        </div>
                      )}
                    </>
                  )}
                {record.interactionInfo &&
                  record.interactionInfo.kind ===
                    InteractionEnum.EVALUATION && (
                    <>
                      <span>
                        {userAnswer.correct !== undefined ? (
                          userAnswer.correct === true ? (
                            <MdCheck className='text-green-500 inline' />
                          ) : (
                            <MdClose className='text-red-500 inline' />
                          )
                        ) : (
                          <></>
                        )}
                      </span>
                      <span>Reponse {index + 1} : </span>
                      <span>
                        {(record.interactionInfo as Evaluation)
                          ? (record.interactionInfo as Evaluation).answers.find(
                              (a) => {
                                return a.lrsObjectId === userAnswer.answer
                              }
                            )?.text
                          : ''}
                      </span>
                    </>
                  )}
              </div>
            )
          })}
        </div>
      </Table.Cell>
      <Table.Cell className='p-4 text-base font-medium text-gray-900 dark:text-white'>
        <div className='text-base font-semibold text-gray-900 dark:text-white'>{`${moment(
          record.updatedAt
        ).fromNow()}`}</div>
      </Table.Cell>
      {history && (
        <Table.Cell className='p-4 text-base font-medium text-gray-900 dark:text-white'>
          <div className='flex items-center gap-x-3'>
            <GetHistoryInteractionsSubModal recordingInteraction={record} />
          </div>
        </Table.Cell>
      )}
    </Table.Row>
  )
}

export default RecordingsListPage
