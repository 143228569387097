import env from 'react-dotenv'
import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const getAllSpecialUsersThunk = async (_, thunkAPI) => {
  try {
    const { page, search, sort, role } = thunkAPI.getState().allSpecialUsers
    const limit = env.DEFAULT_LIMIT_PAGINATION
    let url = `/users?role=${role}&sort=${sort}&page=${page}&limit=${limit}`
    if (search) {
      url = url + `&search=${search}`
    }

    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    console.error(error)
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
