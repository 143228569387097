import { Link } from 'react-router-dom'
import { setCurrentPage } from '../features/user/userSlice'
import { FC, ReactElement } from 'react'
import { useAppDispatch, useTypedSelector } from '../store'

interface SidebarInternalLinkProps {
  path: string
  label: string
  icon?: ReactElement<any, any>
  n?: string
}

const SidebarInternalLink: FC<SidebarInternalLinkProps> = ({
  path,
  label,
  icon = <></>,
  n,
}: SidebarInternalLinkProps) => {
  const { currentPage } = useTypedSelector((store) => store.user.user)
  const dispatch = useAppDispatch()
  return (
    <Link
      to={path}
      onClick={() => dispatch(setCurrentPage(path))}
      className={`flex items-center justify-center rounded-lg p-2 text-base font-medium text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 ${
        path === currentPage ? 'bg-gray-100 dark:bg-gray-700' : ''
      }`}
    >
      {icon}
      <span className='px-3 flex-1 whitespace-nowrap'>{label}</span>
      {n && (
        <span className='flex h-fit items-center gap-1 font-semibold bg-blue-100 text-blue-800 dark:bg-blue-200 dark:text-blue-800 group-hover:bg-blue-200 dark:group-hover:bg-blue-300 p-1 text-xs rounded-full px-2 py-1'>
          <span>{n}</span>
        </span>
      )}
    </Link>
  )
}
export default SidebarInternalLink
