import ReactDOM from 'react-dom/client'
import 'normalize.css'
import './index.css'
import App from './App'
import { Provider } from 'react-redux'
import store from './store'
import moment from 'moment'
import 'moment/locale/fr'
import { ToastProvider } from './context/ToastContext'
import ToastContainer from './components/ToastContainer'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import customFetch from './utils/axios'
import { getUserFromLocalStorage } from './utils/localStorage'
import { logout, showMe } from './features/user/userSlice'
moment.locale('fr')

/** Intercept any response where userId in localstorage.
 * and senderId in response differ and call showMe **/
customFetch.interceptors.response.use(
  (response) => {
    try {
      const user = getUserFromLocalStorage()
      // console.log(user)
      // console.log(user?.userId)
      // console.log(response.data.sender)
      if (user == null && response.data.sender) {
        // console.log('localstorage not up to date')
        const { dispatch } = store
        dispatch(showMe())
      }
      if (user && response.data.sender !== undefined) {
        // console.log('need to check if same person')
        if (user.userId !== response.data.sender) {
          // console.log('need to refresh and call showMe')
          const { dispatch } = store
          dispatch(showMe())
        }
      }
    } catch (error) {
      console.log(error)
    }
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

/** Intercept any request is user is not an admin, auto logout **/
customFetch.interceptors.request.use(
  (config) => {
    try {
      const user = getUserFromLocalStorage()
      // console.log(user)
      // console.log(user?.role)
      if (!user || user.role !== 'admin') {
        const { dispatch } = store
        dispatch(logout())
      }
    } catch (error) {
      console.error(error)
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <StrictMode>
  <Provider store={store}>
    <ToastProvider>
      <ToastContainer />
      <div className='min-h-screen w-full bg-white dark:bg-gray-900'>
        <App />
      </div>
    </ToastProvider>
  </Provider>
  // </StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()
