import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  initActivityQuizThunk,
  initActivityWebObjectThunk,
  removeActivityThunk,
  updateWebObjectThunk,
  updateQuizThunk,
  getActivityThunk,
  toggleInteractionStatusThunk,
} from './activityThunk'
import { LearningObjective } from '../learningObjective/learningObjectiveSlice'
import { ModuleVersion } from '../moduleVersion/moduleVersionSlice'

export interface Activity {
  _id: string
  lrsObjectId: string
  learningScenario: string
  chapter: string
  subChapters: string[]
  order: number
  kind: string
  totalInteractions: number
  learningObjective?: string
  learningObjectiveInfo?: LearningObjective
}

export enum ActivityEnum {
  WEBOBJECT = 'WebObject',
  QUIZ = 'Quiz',
  PRESENTIEL = 'Presentiel',
}

export interface WebObject extends Activity {
  url: string
}

export interface Quiz extends Activity {
  onlyOnce: boolean
}

export interface ActivityForm {
  _id?: string
  learningScenario: string
  chapter?: string
  subChapters?: string[]
  order?: number
  learningObjective?: string
  url?: string
  onlyOnce?: boolean
}

export interface WebObjectForm extends ActivityForm {
  url: string
}

export interface QuizForm extends ActivityForm {
  onlyOnce: boolean
}

export const getActivity = createAsyncThunk(
  'activity/get',
  async (id: string, thunkAPI) => {
    return getActivityThunk('/activities', id, thunkAPI)
  }
)

export const addWebObject = createAsyncThunk(
  'activity/webobject/new',
  async (webobject: WebObjectForm, thunkAPI) => {
    return initActivityWebObjectThunk('/webobjects', webobject, thunkAPI)
  }
)

export const addQuiz = createAsyncThunk(
  'activity/quiz/new',
  async (quiz: QuizForm, thunkAPI) => {
    return initActivityQuizThunk('/quiz', quiz, thunkAPI)
  }
)

export const editWebObject = createAsyncThunk(
  'activity/webobject/edit',
  async (webobject: WebObjectForm, thunkAPI) => {
    return updateWebObjectThunk('/webobjects/:id', webobject, thunkAPI)
  }
)

export const editQuiz = createAsyncThunk(
  'activity/quiz/edit',
  async (quiz: QuizForm, thunkAPI) => {
    return updateQuizThunk('/quiz/:id', quiz, thunkAPI)
  }
)

export const removeActivity = createAsyncThunk(
  'activity/remove',
  async (activity: ActivityForm, thunkAPI) => {
    return removeActivityThunk('/activities/:id', activity, thunkAPI)
  }
)

export const toggleInteractionStatus = createAsyncThunk(
  'modulesVersion/toggleInteractionStatus',
  async (id: string, thunkAPI) => {
    return toggleInteractionStatusThunk(
      '/interaction/:id/togglestatus',
      id,
      thunkAPI
    )
  }
)

export interface ActivityState {
  isLoading: boolean
  activity: Activity
  moduleVersion: ModuleVersion
  error: string
  success: boolean
}

const initialState: ActivityState = {
  isLoading: false,
  activity: {} as Activity,
  moduleVersion: {} as ModuleVersion,
  error: '',
  success: false,
}

const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    clearActivityForm: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActivity.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(getActivity.fulfilled, (state, { payload }) => {
        const { activity, moduleVersion } = payload
        state.activity = activity
        state.moduleVersion = moduleVersion
        state.isLoading = false
      })
      .addCase(getActivity.rejected, (state, { payload }) => {
        state.isLoading = false
        state.activity = {} as Activity
        state.moduleVersion = {} as ModuleVersion
        state.error = payload as string
      })
      .addCase(addWebObject.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(addWebObject.fulfilled, (state, { payload }) => {
        const { activity } = payload
        state.activity = activity
        state.isLoading = false
      })
      .addCase(addWebObject.rejected, (state, { payload }) => {
        state.isLoading = false
        state.activity = {} as Activity
        state.error = payload as string
      })
      .addCase(addQuiz.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(addQuiz.fulfilled, (state, { payload }) => {
        const { activity } = payload
        state.activity = activity
        state.isLoading = false
      })
      .addCase(addQuiz.rejected, (state, { payload }) => {
        state.isLoading = false
        state.activity = {} as Activity
        state.error = payload as string
      })
      .addCase(editWebObject.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(editWebObject.fulfilled, (state, { payload }) => {
        const { activity } = payload
        state.isLoading = false
        state.activity = activity
      })
      .addCase(editWebObject.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addCase(editQuiz.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(editQuiz.fulfilled, (state, { payload }) => {
        const { activity } = payload
        state.isLoading = false
        state.activity = activity
      })
      .addCase(editQuiz.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addCase(removeActivity.pending, (state) => {
        state.isLoading = true
        state.error = ''
        state.success = false
      })
      .addCase(removeActivity.fulfilled, (state) => {
        state.isLoading = false
        state.success = true
      })
      .addCase(removeActivity.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
        state.success = false
      })
      .addCase(toggleInteractionStatus.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(toggleInteractionStatus.fulfilled, (state, { payload }) => {
        const { changeLog } = payload
        state.isLoading = false
        state.moduleVersion.changeLog = changeLog
      })
      .addCase(toggleInteractionStatus.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
  },
})

export const { clearActivityForm } = activitySlice.actions

export default activitySlice.reducer
