/* eslint-disable jsx-a11y/anchor-is-valid */
import { Sidebar, SidebarItem } from 'flowbite-react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { HiAcademicCap, HiBriefcase, HiChartPie, HiUsers } from 'react-icons/hi'
import { twMerge } from 'tailwind-merge'

import { useSidebarContext } from '../context/SidebarContext'
import { GiAtSea, GiSkills, GiTestTubes } from 'react-icons/gi'
import { useTypedSelector } from '../store'
import { setCurrentPage } from '../features/user/userSlice'
import SidebarInternalLink from './SidebarInternalLink'
import { MdPermMedia } from 'react-icons/md'
import env from 'react-dotenv'

const ExampleSidebar: FC = function () {
  return (
    <>
      <div className='lg:hidden'>
        <MobileSidebar />
      </div>
      <div className='hidden lg:block'>
        <DesktopSidebar />
      </div>
    </>
  )
}

function DesktopSidebar() {
  const { isCollapsed, setCollapsed } = useSidebarContext().desktop
  const [isPreview, setIsPreview] = useState(isCollapsed)
  const [isModulesOpen, setModulesOpen] = useState(true)
  const [isSkillsOpen, setSkillsOpen] = useState(true)
  const [isBetaOpen, setBetaOpen] = useState(true)
  const [isAdministrationOpen, setAdministrationOpen] = useState(true)
  const [isMediasOpen, setMediasOpen] = useState(true)

  const { currentPage } = useTypedSelector((store) => store.user.user)

  useEffect(() => {
    let newPage = window.location.pathname
    if (currentPage === '' || !currentPage) {
      setCurrentPage(newPage)
    } else {
      newPage = currentPage
    }
    // setEcommerceOpen(newPage.includes('/e-commerce'))
    setModulesOpen(newPage.includes('/modules') && isPreview)
    setSkillsOpen(newPage.includes('/skills') && isPreview)
    setBetaOpen(newPage.includes('/beta') && isPreview)
    setAdministrationOpen(newPage.includes('/administration') && isPreview)
    setMediasOpen(newPage.includes('/medias') && isPreview)
  }, [currentPage, isPreview])

  useEffect(() => {
    if (isCollapsed) setIsPreview(false)
  }, [isCollapsed])

  const preview = {
    enable() {
      if (!isCollapsed) return

      setIsPreview(true)
      setCollapsed(false)
    },
    disable() {
      if (!isPreview) return

      setCollapsed(true)
    },
  }

  return (
    <Sidebar
      onMouseEnter={preview.enable}
      onMouseLeave={preview.disable}
      aria-label='Sidebar with multi-level dropdown example'
      collapsed={isCollapsed}
      className={twMerge(
        'fixed inset-y-0 left-0 z-20 flex h-full shrink-0 flex-col border-r border-gray-200 pt-16 duration-75 lg:flex dark:border-gray-700',
        isCollapsed && 'hidden w-16'
      )}
      id='sidebar'
    >
      <div className='flex h-full flex-col justify-between'>
        <div className='py-2'>
          <Sidebar.Items>
            <Sidebar.ItemGroup>
              <SidebarItem href='/' icon={HiChartPie}>
                {!isCollapsed ? 'Tableau de bord' : ''}
              </SidebarItem>
              <Sidebar.Collapse
                icon={HiBriefcase}
                label='Modules'
                open={isModulesOpen}
                onClick={() => setModulesOpen(!isModulesOpen)}
              >
                <SidebarInternalLink
                  path='/modules'
                  label='Gestion des modules'
                />
              </Sidebar.Collapse>
              <Sidebar.Collapse
                icon={GiSkills}
                label='Enregistrements'
                open={isSkillsOpen}
                onClick={() => setSkillsOpen(!isSkillsOpen)}
              >
                <SidebarInternalLink
                  path='/recordings'
                  label='Données formation'
                />
                <SidebarInternalLink
                  path='/skills'
                  label='Gestion des compétences'
                />
              </Sidebar.Collapse>
              <Sidebar.Collapse
                icon={MdPermMedia}
                label='Médias'
                open={isMediasOpen}
                onClick={() => setMediasOpen(!isMediasOpen)}
              >
                <SidebarInternalLink
                  path='/medias'
                  label='Gestion des médias'
                />
              </Sidebar.Collapse>
              <Sidebar.Collapse
                icon={GiTestTubes}
                label='Beta Lagoon'
                open={isBetaOpen}
                onClick={() => setBetaOpen(!isBetaOpen)}
              >
                <SidebarInternalLink
                  path='/beta/register'
                  label='Inscriptions beta'
                />
                <SidebarInternalLink
                  path='/beta/lagoon-form-answers'
                  label='Formulaire LAGOON'
                />
                <SidebarInternalLink
                  path='/beta/module-form-answers'
                  label='Formulaire Modules'
                />
              </Sidebar.Collapse>
              <Sidebar.Collapse
                icon={HiUsers}
                label='Administration'
                open={isAdministrationOpen}
                onClick={() => setAdministrationOpen(!isAdministrationOpen)}
              >
                <SidebarInternalLink
                  path='/administration/users'
                  label='Gestion des users'
                />
                <SidebarInternalLink
                  path='/administration/tags'
                  label='Gestion des tags'
                />
                <SidebarInternalLink
                  path='/administration/trainers'
                  label='Gestion des formateurs'
                />
                <SidebarInternalLink
                  path='/administration/bddcontrol'
                  label='Contrôle BDD'
                />
              </Sidebar.Collapse>
            </Sidebar.ItemGroup>
            <Sidebar.ItemGroup>
              <SidebarItem
                href={env.CLIENT_URL}
                icon={HiAcademicCap}
                target='_blank'
                rel='noreferrer'
              >
                {!isCollapsed ? 'Lagoon Client' : ''}
              </SidebarItem>
              <SidebarItem
                href='https://roselagon.com/'
                icon={GiAtSea}
                target='_blank'
                rel='noreferrer'
              >
                {!isCollapsed ? 'Roselagon Website' : ''}
              </SidebarItem>
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </div>
      </div>
    </Sidebar>
  )
}

function MobileSidebar() {
  const { isOpen, close } = useSidebarContext().mobile
  const [isModulesOpen, setModulesOpen] = useState(true)
  const [isSkillsOpen, setSkillsOpen] = useState(true)
  const [isBetaOpen, setBetaOpen] = useState(true)
  const [isAdministrationOpen, setAdministrationOpen] = useState(true)
  const [isMediasOpen, setMediasOpen] = useState(true)

  const { currentPage } = useTypedSelector((store) => store.user.user)

  useEffect(() => {
    let newPage = window.location.pathname
    if (currentPage === '' || !currentPage) {
      setCurrentPage(newPage)
    } else {
      newPage = currentPage
    }
    // setEcommerceOpen(newPage.includes('/e-commerce'))
    setModulesOpen(newPage.includes('/modules'))
    setSkillsOpen(newPage.includes('/skills'))
    setAdministrationOpen(newPage.includes('/administration'))
    setMediasOpen(newPage.includes('/medias'))
  }, [currentPage])

  if (!isOpen) return null

  return (
    <>
      <Sidebar
        aria-label='Sidebar with multi-level dropdown example'
        className={twMerge(
          'fixed inset-y-0 left-0 z-20 flex h-full shrink-0 flex-col border-r border-gray-200 pt-16 duration-75 dark:border-gray-700 lg:flex ',
          isOpen && 'flex'
        )}
        id='sidebar'
      >
        <div className='flex h-full flex-col justify-between'>
          <div className='py-2'>
            <Sidebar.Items>
              <Sidebar.ItemGroup>
                <SidebarItem href='/' icon={HiChartPie}>
                  Tableau de bord
                </SidebarItem>
                <Sidebar.Collapse
                  icon={HiBriefcase}
                  label='Modules'
                  open={isModulesOpen}
                  onClick={() => setModulesOpen(!isModulesOpen)}
                >
                  <SidebarInternalLink
                    path='/modules'
                    label='Gestion des modules'
                  />
                </Sidebar.Collapse>
                <Sidebar.Collapse
                  icon={GiSkills}
                  label='Compétences'
                  open={isSkillsOpen}
                  onClick={() => setSkillsOpen(!isSkillsOpen)}
                >
                  <SidebarInternalLink
                    path='/skills'
                    label='Gestion des compétences'
                  />
                </Sidebar.Collapse>
                <Sidebar.Collapse
                  icon={MdPermMedia}
                  label='Médias'
                  open={isMediasOpen}
                  onClick={() => setMediasOpen(!isMediasOpen)}
                >
                  <SidebarInternalLink
                    path='/medias'
                    label='Gestion des médias'
                  />
                </Sidebar.Collapse>
                <Sidebar.Collapse
                  icon={GiTestTubes}
                  label='Beta Lagoon'
                  open={isBetaOpen}
                  onClick={() => setBetaOpen(!isBetaOpen)}
                >
                  <SidebarInternalLink
                    path='/beta/register'
                    label='Inscriptions beta'
                  />
                  <SidebarInternalLink
                    path='/beta/lagoon-form-answers'
                    label='Formulaire LAGOON'
                  />
                  <SidebarInternalLink
                    path='/beta/module-form-answers'
                    label='Formulaire Modules'
                  />
                </Sidebar.Collapse>
                <Sidebar.Collapse
                  icon={HiUsers}
                  label='Administration'
                  open={isAdministrationOpen}
                  onClick={() => setAdministrationOpen(!isAdministrationOpen)}
                >
                  <SidebarInternalLink
                    path='/administration/users'
                    label='Gestion des users'
                  />
                  <SidebarInternalLink
                    path='/administration/tags'
                    label='Gestion des tags'
                  />
                  <SidebarInternalLink
                    path='/administration/trainers'
                    label='Gestion des formateurs'
                  />
                  <SidebarInternalLink
                    path='/administration/bddcontrol'
                    label='Contrôle BDD'
                  />
                </Sidebar.Collapse>
              </Sidebar.ItemGroup>
              <Sidebar.ItemGroup>
                <SidebarItem
                  href={env.CLIENT_URL}
                  icon={HiAcademicCap}
                  target='_blank'
                  rel='noreferrer'
                >
                  Lagoon Client
                </SidebarItem>
                <SidebarItem
                  href='https://roselagon.com/'
                  icon={GiAtSea}
                  target='_blank'
                  rel='noreferrer'
                >
                  Roselagon Website
                </SidebarItem>
              </Sidebar.ItemGroup>
            </Sidebar.Items>
          </div>
        </div>
      </Sidebar>
      <div
        onClick={close}
        aria-hidden='true'
        className='fixed inset-0 z-10 h-full w-full bg-gray-900/50 pt-16 dark:bg-gray-900/90'
      />
    </>
  )
}

export default ExampleSidebar
