import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  addResourceThunk,
  removeResourceThunk,
  editResourceThunk,
  moveResourceThunk,
  copyResourceThunk,
} from './resourceThunk'

export interface Resource {
  _id: string
  type: string
  src: string
  title: string
  folder: string
  size: number
  public: boolean
  used: number
  updatedAt: Date
}

export interface ResourceForm {
  file: File | null
  title: string
  public: boolean
  folder: string
}

export interface ResourceEditForm {
  _id: string
  file: File | null
  title: string
}

export interface ResourceMoveCopyForm {
  _id: string
  public: boolean
  folder: string
}

export interface ModuleVersionState {
  isLoading: boolean
  resource: Resource
  error: string
  success: boolean
}

const initialState: ModuleVersionState = {
  isLoading: false,
  resource: {} as Resource,
  error: '',
  success: false,
}

// export const addModule = createAsyncThunk('modules/new', addModuleThunk)
export const addResource = createAsyncThunk(
  'resource/new',
  async (resource: ResourceForm, thunkAPI) => {
    return addResourceThunk('/resources', resource, thunkAPI)
  }
)

export const removeResource = createAsyncThunk(
  'resource/remove',
  async (resource: Resource, thunkAPI) => {
    return removeResourceThunk('/resources/:id', resource, thunkAPI)
  }
)

export const editResource = createAsyncThunk(
  'resource/edit',
  async (resource: ResourceEditForm, thunkAPI) => {
    return editResourceThunk('/resources/:id', resource, thunkAPI)
  }
)

export const moveResource = createAsyncThunk(
  'resource/move',
  async (resource: ResourceMoveCopyForm, thunkAPI) => {
    return moveResourceThunk('/resources/:id/move', resource, thunkAPI)
  }
)
export const copyResource = createAsyncThunk(
  'resource/move',
  async (resource: ResourceMoveCopyForm, thunkAPI) => {
    return copyResourceThunk('/resources/:id/copy', resource, thunkAPI)
  }
)

const resourceSlice = createSlice({
  name: 'resource',
  initialState,
  reducers: {
    clearResourceForm: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addResource.pending, (state) => {
        state.isLoading = true
        state.error = ''
        state.success = false
      })
      .addCase(addResource.fulfilled, (state, { payload }) => {
        const { fileWrites } = payload
        state.resource = fileWrites[0]
        state.isLoading = false
        state.success = true
      })
      .addCase(addResource.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
        state.success = false
      })
      .addCase(editResource.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(editResource.fulfilled, (state, { payload }) => {
        const { resource } = payload
        state.resource = resource
        state.isLoading = false
      })
      .addCase(editResource.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addCase(moveResource.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(moveResource.fulfilled, (state, { payload }) => {
        const { resource } = payload
        state.resource = resource
        state.isLoading = false
      })
      .addCase(moveResource.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addCase(removeResource.pending, (state) => {
        state.isLoading = true
        state.error = ''
        state.success = false
      })
      .addCase(removeResource.fulfilled, (state) => {
        state.isLoading = false
        state.resource = {} as Resource
        state.success = true
      })
      .addCase(removeResource.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
        state.success = false
      })
  },
})

export const { clearResourceForm } = resourceSlice.actions

export default resourceSlice.reducer
