import { Table } from 'flowbite-react'
import { useSelector } from 'react-redux'
import { LevelTaxonomy } from '../../features/taxonomy/taxonomySlice'

const TaxonomiesByLevelTable = () => {
  const { taxonomiesByLevel } = useSelector((store) => store.skill.skill)
  return (
    <ul className='space-y-6'>
      {taxonomiesByLevel ? (
        <>
          <li className='w-full items-center sm:flex'>
            <div className='mb-3 flex items-center sm:mb-0'>
              <span className='mx-5 ml-3 w-32 text-base font-bold text-center text-gray-900 dark:text-white sm:flex-none'>
                {`Niveau ${LevelTaxonomy.level1} :`}
              </span>
              {taxonomiesByLevel['level1'] &&
                taxonomiesByLevel['level1'].length > 0 && (
                  <TaxonomyByLevel taxonomies={taxonomiesByLevel['level1']} />
                )}
            </div>
          </li>

          <li className='w-full items-center sm:flex'>
            <div className='mb-3 flex items-center sm:mb-0'>
              <span className='mx-5 ml-3 w-32 text-base font-bold text-center text-gray-900 dark:text-white sm:flex-none'>
                {`Niveau ${LevelTaxonomy.level2} :`}
              </span>
              {taxonomiesByLevel['level2'] &&
                taxonomiesByLevel['level2'].length > 0 && (
                  <TaxonomyByLevel
                    taxonomies={taxonomiesByLevel['level2']}
                    hasHeaders={false}
                  />
                )}
            </div>
          </li>

          <li className='w-full items-center sm:flex'>
            <div className='mb-3 flex items-center sm:mb-0'>
              <span className='mx-5 ml-3 w-32 text-base font-bold text-center text-gray-900 dark:text-white sm:flex-none'>
                {`Niveau ${LevelTaxonomy.level3} :`}
              </span>
              {taxonomiesByLevel['level3'] &&
                taxonomiesByLevel['level3'].length > 0 && (
                  <TaxonomyByLevel
                    taxonomies={taxonomiesByLevel['level3']}
                    hasHeaders={false}
                  />
                )}
            </div>
          </li>
        </>
      ) : (
        <>
          <div
            className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
            role='alert'
          >
            <span className='font-medium'>Pas de taxonomies à afficher...</span>
          </div>
        </>
      )}
    </ul>
  )
}
export default TaxonomiesByLevelTable

const TaxonomyByLevel = ({ taxonomies, hasHeaders = true }) => {
  return (
    <div className='flex flex-col'>
      <div className='overflow-x-auto rounded-lg'>
        <div className='inline-block min-w-full align-middle'>
          <div className='overflow-hidden shadow sm:rounded-lg'>
            <Table className='min-w-full table-fixed'>
              {hasHeaders && (
                <Table.Head className='bg-gray-100 dark:bg-gray-700'>
                  <Table.HeadCell>Nom</Table.HeadCell>
                  <Table.HeadCell>Poids</Table.HeadCell>
                  <Table.HeadCell>Temps d'apprentissage</Table.HeadCell>
                  <Table.HeadCell>Facteur d'impact</Table.HeadCell>
                </Table.Head>
              )}

              <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
                {taxonomies.map((taxonomy) => {
                  const factor =
                    (parseFloat(taxonomy.learningTime) / 60) *
                    (parseFloat(taxonomy.weight) / 100)
                  return (
                    <Table.Row
                      key={taxonomy._id}
                      className='hover:bg-gray-100 dark:hover:bg-gray-700'
                    >
                      <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                        <div className='text-base font-semibold text-gray-900 dark:text-white'>
                          {taxonomy.name}
                        </div>
                      </Table.Cell>
                      <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white max-w-[200px] overflow-hidden text-ellipsis'>
                        {taxonomy.weight}
                      </Table.Cell>
                      <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white max-w-[200px] overflow-hidden text-ellipsis'>
                        {taxonomy.learningTime}
                      </Table.Cell>
                      <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white max-w-[200px] overflow-hidden text-ellipsis'>
                        {factor}
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
    </div>
  )
}
