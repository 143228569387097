import { Breadcrumb, Button, Checkbox, Modal, Table } from 'flowbite-react'
import type { Dispatch, FC, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { FaPlus, FaSchool } from 'react-icons/fa'
import {
  HiArrowRight,
  HiHome,
  HiOutlineExclamationCircle,
  HiPencilAlt,
  HiTrash,
} from 'react-icons/hi'
import {
  DataTableController,
  Loading,
  Pagination,
  ThumbnailResource,
} from '../../components'
import { useAppDispatch, useTypedSelector } from '../../store'
import { useToast } from '../../hooks/useToast'
import {
  addTrainerToDataTable,
  editTrainerToDataTable,
  getAllTrainers,
  removeTrainerToDataTable,
  handleChange,
  changePage,
} from '../../features/allTrainers/allTrainersSlice'
import {
  addTrainer,
  clearTrainerForm,
  editTrainer,
  removeTrainer,
  Trainer,
  TrainerForm,
} from '../../features/trainer/trainerSlice'
import TrainerModalBody from '../../components/modal-body/Trainer'
import { Resource } from '../../features/resource/resourceSlice'

const TrainersPage: FC = function () {
  const { error, page, totalTrainers, numOfPages, search, sort } =
    useTypedSelector((store) => store.allTrainers)
  const { error: errorTrainer } = useTypedSelector((store) => store.trainer)
  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
    }
    if (errorTrainer !== '') {
      toast('error', errorTrainer)
      dispatch(clearTrainerForm())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, errorTrainer])

  useEffect(() => {
    dispatch(getAllTrainers({}))
    //clear form
    dispatch(clearTrainerForm())
  }, [dispatch, page, search, sort])
  return (
    <>
      <div className='block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex'>
        <div className='mb-1 w-full'>
          <div className='mb-4'>
            <Breadcrumb className='mb-4'>
              <Breadcrumb.Item href='#'>
                <div className='flex items-center gap-x-3'>
                  <HiHome className='text-xl' />
                  <span className='dark:text-white'>Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href='/trainers'>Formateurs</Breadcrumb.Item>
              <Breadcrumb.Item>Liste</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className='text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl'>
              Toutes les formateurs
            </h1>
          </div>
          <div className='block items-center sm:flex'>
            <DataTableController
              placeholder='rechercher un formateur'
              handleChange={handleChange}
              sort={sort}
              search={search}
              initFilters={null}
            ></DataTableController>
            <div className='flex w-full items-center sm:justify-end'>
              <AddTrainerModal />
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='overflow-x-auto'>
          <div className='inline-block min-w-full align-middle'>
            <div className='overflow-hidden shadow'>
              <TrainersTable />
            </div>
          </div>
        </div>
      </div>
      <Pagination
        numOfPages={numOfPages}
        total={totalTrainers}
        page={page}
        changePage={changePage}
      />
    </>
  )
}

const AddTrainerModal: FC = function () {
  const initialValues: TrainerForm = {
    name: '',
    lastName: '',
    email: '',
    job: '',
    link: '',
    image: null,
  }

  const [imageSelected, setImageSelected] = useState({} as Resource | null)

  const [isOpen, setOpen] = useState(false)
  const [values, setValues] = useState<TrainerForm>(initialValues)
  const dispatch = useAppDispatch()
  const { isLoading, trainer } = useTypedSelector((store) => store.trainer)
  const toast = useToast()

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { name, lastName, email } = values
    if (name !== '' && lastName !== '' && email !== '') {
      dispatch(addTrainer(values))
    } else {
      toast('error', `Vous avez besoin de renseigner les elements requis`)
    }
  }

  useEffect(() => {
    if (isOpen && trainer && trainer._id) {
      toast(
        'success',
        `La/le formatrice(teur) ${trainer.name} ${trainer.lastName} a bien été créé(e)`
      )
      //refresh list
      dispatch(addTrainerToDataTable(trainer))
      //clear form
      dispatch(clearTrainerForm())
      //close
      setOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, trainer, isLoading])

  useEffect(() => {
    if (!isOpen) {
      //clean
      setValues({
        name: '',
        lastName: '',
        email: '',
        job: '',
        link: '',
        image: null,
      })
      setImageSelected({} as Resource)
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      if (imageSelected && imageSelected._id) {
        setValues({
          ...values,
          image: imageSelected._id,
        })
      } else {
        setValues({
          ...values,
          image: null,
        })
      }
    }
    // eslint-disable-next-line
  }, [imageSelected, isOpen])

  return (
    <>
      <Button color='primary' onClick={() => setOpen(!isOpen)}>
        <FaPlus className='mr-3 text-sm' />
        Ajouter un formateur
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Ajouter formateur</strong>
          </Modal.Header>
          <TrainerModalBody
            values={values}
            handleChange={handleChange}
            imageSelected={imageSelected}
            setImageSelected={setImageSelected}
          />
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Ajouter
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

const EditTrainerModal: FC<{ trainerSelected: Trainer }> = function ({
  trainerSelected,
}) {
  const initialValues: TrainerForm = {
    _id: trainerSelected._id,
    name: trainerSelected.name,
    lastName: trainerSelected.lastName,
    email: trainerSelected.email,
    job: trainerSelected.job,
    link: trainerSelected.link,
    image: trainerSelected.image,
  }

  const [isOpen, setOpen] = useState(false)
  const [values, setValues] = useState<TrainerForm>(initialValues)
  const dispatch = useAppDispatch()
  const { isLoading, trainer } = useTypedSelector((store) => store.trainer)
  const toast = useToast()
  const [imageSelected, setImageSelected] = useState(
    trainerSelected.imageResource
  )

  useEffect(() => {
    if (isOpen) {
      if (imageSelected && imageSelected._id) {
        setValues({
          ...values,
          image: imageSelected._id,
        })
      } else {
        setValues({
          ...values,
          image: null,
        })
      }
    }
    // eslint-disable-next-line
  }, [imageSelected, isOpen])

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { name, lastName, email, _id } = values
    if (name !== '' && lastName !== '' && _id !== '' && email !== '') {
      dispatch(editTrainer(values))
    } else {
      toast('error', `Vous avez besoin de renseigner les elements requis`)
    }
  }

  useEffect(() => {
    if (isOpen && trainer && trainer._id) {
      toast(
        'success',
        `La/le formatrice(teur) ${trainer.name} ${trainer.lastName} a bien été édité(e)`
      )
      //refresh list
      dispatch(editTrainerToDataTable(trainer))
      //clear form
      dispatch(clearTrainerForm())
      //close
      setOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, trainer, isLoading])

  useEffect(() => {
    if (!isOpen) {
      //clean
      setImageSelected({} as Resource)
    }
  }, [isOpen])

  return (
    <>
      <Button color='primary' onClick={() => setOpen(!isOpen)}>
        <HiPencilAlt className='mr-2 text-lg' />
        <span className='hidden lg:block '>Editer le formateur</span>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Editer formateur {trainerSelected.name}</strong>
          </Modal.Header>
          <TrainerModalBody
            values={values}
            handleChange={handleChange}
            imageSelected={imageSelected}
            setImageSelected={setImageSelected}
          />
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Editer
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

const DeleteTrainerModal: FC<{ trainerSelected: Trainer }> = function ({
  trainerSelected,
}) {
  const [isOpen, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const toast = useToast()
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    toast(
      'success',
      `La/le formatrice(teur) ${trainerSelected.name} ${trainerSelected.lastName} a bien été suprimé(e)`
    )
    dispatch(removeTrainer(trainerSelected))
    dispatch(removeTrainerToDataTable(trainerSelected))
    setOpen(false)
  }
  return (
    <>
      <Button color='failure' onClick={() => setOpen(!isOpen)}>
        <HiTrash className='mr-2 text-lg' />
        <span className='hidden lg:block '>Supprimer le formateur</span>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen} size='md'>
        <Modal.Header className='px-3 pb-0 pt-3'>
          <span className='sr-only'>Supprimer formateur</span>
        </Modal.Header>
        <Modal.Body className='px-6 pb-6 pt-0'>
          <div className='flex flex-col items-center gap-y-6 text-center'>
            <HiOutlineExclamationCircle className='text-7xl text-red-600' />
            <p className='text-lg text-gray-500 dark:text-gray-300'>
              Êtes vous sur de vouloir détruire la(e) formatrice(teur){' '}
              {trainerSelected.name} {trainerSelected.lastName}?
            </p>
            <div className='flex items-center gap-x-3'>
              <Button color='failure' onClick={handleSubmit}>
                Oui, je suis sur
              </Button>
              <Button color='gray' onClick={() => setOpen(false)}>
                Non, annuler
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const TrainersTable: FC = function () {
  const { trainers, isLoading } = useTypedSelector((store) => store.allTrainers)
  const castTrainers = trainers as Trainer[]
  if (isLoading) {
    return <Loading center />
  }
  if (trainers.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>
            Pas de formateurs(trices) à afficher...
          </span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>
          <span className='sr-only'>Selectionner tous</span>
          <Checkbox />
        </Table.HeadCell>
        <Table.HeadCell>Infos</Table.HeadCell>
        <Table.HeadCell>Email</Table.HeadCell>
        <Table.HeadCell>Site Web</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {castTrainers.map((trainer) => {
          return (
            <Table.Row
              key={trainer._id}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='w-4 p-4'>
                <Checkbox />
              </Table.Cell>
              <Table.Cell className='mr-12 flex items-center space-x-6 whitespace-nowrap p-4 lg:mr-0'>
                <ThumbnailResource resource={trainer.imageResource} />
                <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                  <div className='text-base font-semibold text-gray-900 dark:text-white'>
                    {trainer.name} {trainer.lastName}
                  </div>
                  {trainer.job && (
                    <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                      {trainer.job}
                    </div>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white max-w-[200px] overflow-hidden text-ellipsis'>
                {trainer.email}
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white max-w-[200px] overflow-hidden text-ellipsis'>
                {trainer.link && (
                  <a
                    className='text-primary-600 dark:text-primary-300'
                    href={trainer.link}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {trainer.link}
                  </a>
                )}
              </Table.Cell>
              <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                <div className='flex items-center gap-x-3'>
                  <EditTrainerModal trainerSelected={trainer} />
                  <DeleteTrainerModal trainerSelected={trainer} />
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

// use this modal to choose media on simplified table
export const SelectTrainerModal: FC<{
  setTrainerSelected: Dispatch<SetStateAction<Trainer | null>>
}> = function ({ setTrainerSelected }) {
  const { trainers, isLoading } = useTypedSelector((store) => store.allTrainers)
  const { error, page, totalTrainers, numOfPages, search, sort } =
    useTypedSelector((store) => store.allTrainers)

  const [isOpen, setOpen] = useState(false)

  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    if (isOpen) {
      //force select only images
      dispatch(getAllTrainers({}))
    }
  }, [dispatch, page, search, sort, isOpen])

  const castTrainers = trainers as Trainer[]

  if (isLoading) {
    return <Loading center />
  }

  return (
    <>
      <Button
        color='primary'
        className='w-full'
        onClick={() => setOpen(!isOpen)}
      >
        <FaSchool className='mr-3 text-sm' />
        Selectionner un formateur
      </Button>
      <Modal
        onClose={() => setOpen(false)}
        show={isOpen}
        size='3xl'
        className='max-h-screen'
      >
        <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
          <strong>Selectionner formateur</strong>
        </Modal.Header>
        <Modal.Body>
          <div className='block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex'>
            <div className='mb-1 w-full'>
              <div className='block items-center sm:flex'>
                <DataTableController
                  placeholder='rechercher un formateur'
                  handleChange={handleChange}
                  sort={sort}
                  search={search}
                  initFilters={null}
                ></DataTableController>
                <div className='flex items-center sm:justify-end'>
                  <AddTrainerModal />
                </div>
              </div>
            </div>
          </div>

          <div className='flex flex-col'>
            <div className='overflow-x-auto'>
              <div className='inline-block min-w-full align-middle'>
                <div className='overflow-hidden shadow'>
                  <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
                    <Table.Head className='bg-gray-100 dark:bg-gray-700'>
                      <Table.HeadCell>Infos</Table.HeadCell>
                      <Table.HeadCell>Actions</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
                      {castTrainers.map((trainer) => {
                        return (
                          <Table.Row
                            key={trainer._id}
                            className='hover:bg-gray-100 dark:hover:bg-gray-700'
                          >
                            <Table.Cell className='mr-12 flex items-center space-x-6 whitespace-nowrap p-4 lg:mr-0'>
                              <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                                  {trainer.name} {trainer.lastName}
                                </div>
                                <div className='text-base font-normal text-gray-900 dark:text-white'>
                                  {trainer.email}
                                </div>
                                {trainer.job && (
                                  <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                                    {trainer.job}
                                  </div>
                                )}
                              </div>
                            </Table.Cell>
                            <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                              <div className='grid grid-cols-2 gap-3'>
                                <Button
                                  color='purple'
                                  onClick={() => {
                                    setTrainerSelected(trainer)
                                    setOpen(!isOpen)
                                  }}
                                >
                                  <HiArrowRight className='mr-2 text-lg' />
                                  Selectionner
                                </Button>
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        )
                      })}
                    </Table.Body>
                  </Table>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            numOfPages={numOfPages}
            total={totalTrainers}
            page={page}
            changePage={changePage}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default TrainersPage
