import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useToast } from '../hooks/useToast'

const ProtectedRoute = ({ children }) => {
  const { user } = useSelector((store) => store.user)
  const toast = useToast()
  if (!user || !user.userId || !user.role === 'admin') {
    toast('error', 'Vous devez vous connecter pour accéder à ce contenu')
    return <Navigate to='/authentication/sign-in' />
  }
  return children
}

export default ProtectedRoute
