import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const initEvaluationThunk = async (_, evaluation, thunkAPI) => {
  let url = `/evaluations`
  try {
    const resp = await customFetch.post(url, evaluation)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const initAssessmentThunk = async (_, assessment, thunkAPI) => {
  let url = `/assessments`
  try {
    const resp = await customFetch.post(url, assessment)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const updateEvaluationThunk = async (_, interaction, thunkAPI) => {
  let url = `/evaluations/${interaction._id}`
  try {
    const resp = await customFetch.patch(url, interaction)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const updateAssessmentThunk = async (_, interaction, thunkAPI) => {
  let url = `/assessments/${interaction._id}`
  try {
    const resp = await customFetch.patch(url, interaction)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const removeInteractionThunk = async (_, interaction, thunkAPI) => {
  let url = `/interactions/${interaction._id}`
  try {
    const resp = await customFetch.delete(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
