import env from 'react-dotenv'
import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const getAllModulesThunk = async (_, thunkAPI) => {
  const { page, search, searchStatus, sort } = thunkAPI.getState().allModules
  const limit = env.DEFAULT_LIMIT_PAGINATION
  let url = `/modules?status=${searchStatus}&sort=${sort}&page=${page}&limit=${limit}&nomydata=true`
  if (search) {
    url = url + `&search=${search}`
  }

  try {
    const resp = await customFetch.get(url)

    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

// export const showStatsThunk = async (_, thunkAPI) => {
//   try {
//     const resp = await customFetch.get('/jobs/stats')
//     return resp.data
//   } catch (error) {
//     return checkForUnauthorizedResponse(error, thunkAPI)
//   }
// }
