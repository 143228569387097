import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const getTrainerThunk = async (_, id, thunkAPI) => {
  try {
    let url = `/trainers/${id}`
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
export const addTrainerThunk = async (_, trainer, thunkAPI) => {
  let url = `/trainers`
  try {
    const resp = await customFetch.post(url, trainer)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
export const removeTrainerThunk = async (_, trainer, thunkAPI) => {
  let url = `/trainers/${trainer._id}`
  try {
    const resp = await customFetch.delete(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
export const editTrainerThunk = async (_, trainer, thunkAPI) => {
  let url = `/trainers/${trainer._id}`
  try {
    const resp = await customFetch.patch(url, trainer)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
