import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const removeRecordingThunk = async (_, recording, thunkAPI) => {
  let url = `/recordings/${recording._id}`
  try {
    const resp = await customFetch.delete(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
