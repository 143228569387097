import { Button } from 'flowbite-react'
import { useState } from 'react'
import { HexColorInput, HexColorPicker } from 'react-colorful'
import { HiChevronDown, HiChevronUp } from 'react-icons/hi'
import { MdColorize } from 'react-icons/md'
import _uniqueId from 'lodash/uniqueId'

const ColorPicker = ({ initColor, onChange, name = 'color' }) => {
  const [toggle, setToggle] = useState(false)
  const [color, setColor] = useState(initColor)
  const [componentID] = useState(_uniqueId())

  const onColorChange = (newColor) => {
    setColor(newColor)
    onChange(name, newColor)
  }

  if (!toggle) {
    return (
      <>
        <Button
          onClick={() => setToggle(!toggle)}
          style={{ backgroundColor: color }}
        >
          <MdColorize className='mr-2 h-5 w-5 mix-blend-difference' />
          <HiChevronDown className='mr-2 h-5 w-5 mix-blend-difference' />
        </Button>
      </>
    )
  }

  return (
    <>
      <HexColorPicker
        color={color}
        onChange={(newColor) => onColorChange(newColor)}
        id={`color-picker-${componentID}`}
      />
      <HexColorInput
        color={color}
        onChange={(newColor) => onColorChange(newColor)}
        prefixed
        alpha
        id={`color-picker-${componentID}`}
      />
      <Button
        onClick={() => setToggle(!toggle)}
        style={{ backgroundColor: color }}
      >
        <MdColorize className='mr-2 h-5 w-5 mix-blend-difference' />
        <HiChevronUp className='mr-2 h-5 w-5 mix-blend-difference' />
      </Button>
    </>
  )
}

export default ColorPicker
