import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const addTagThunk = async (_, tag, thunkAPI) => {
  let url = `/tags`
  try {
    const resp = await customFetch.post(url, tag)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
export const removeTagThunk = async (_, tag, thunkAPI) => {
  let url = `/tags/${tag._id}`
  try {
    const resp = await customFetch.delete(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
export const editTagsThunk = async (_, tag, thunkAPI) => {
  let url = `/tags/${tag._id}`
  try {
    const resp = await customFetch.patch(url, tag)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
