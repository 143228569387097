import env from 'react-dotenv'
import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const getAllBetaContactsThunk = async (_, thunkAPI) => {
  try {
    const {
      page,
      search,
      sort,
      searchIsVerified,
      searchIsInvited,
      searchPrevention,
      searchNr,
      searchClimate,
      searchRse,
      searchOrigin,
    } = thunkAPI.getState().allBetaContacts
    const limit = env.DEFAULT_LIMIT_PAGINATION
    let url = `/roselagon/betacontacts?sort=${sort}&page=${page}&limit=${limit}&verified=${searchIsVerified}&invited=${searchIsInvited}&prevention=${searchPrevention}&nr=${searchNr}&climate=${searchClimate}&rse=${searchRse}&origin=${searchOrigin}`
    if (search) {
      url = url + `&search=${search}`
    }

    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    console.error(error)
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
