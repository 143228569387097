import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllBetaModuleFormAnswersThunk } from './allBetaModuleFormAnswersThunk'

const initialFiltersState = {
  filterBetaContact: [],
  filterModule: [],
  sort: 'latest',
  sortOptions: ['latest', 'oldest'],
}

const initialState = {
  isLoading: false,
  betaModuleFormAnswers: [],
  totalBetaModuleFormAnswers: 0,
  numOfPages: 1,
  page: 1,
  error: '',
  ...initialFiltersState,
}

export const getAllBetaModuleFormAnswers = createAsyncThunk(
  'beta-module-form-answers/list',
  getAllBetaModuleFormAnswersThunk
)

const allBetaModuleFormAnswersSlice = createSlice({
  name: 'allBetaModuleFormAnswers',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true
    },
    hideLoading: (state) => {
      state.isLoading = false
    },
    handleChange: (state, { payload: { name, value } }) => {
      state.page = 1
      state[name] = value
    },
    clearFilters: (state) => {
      return { ...state, ...initialFiltersState }
    },
    changePage: (state, { payload }) => {
      state.page = payload
    },
    removeBetaModuleFormAnswersToDataTable: (state, { payload }) => {
      state.betaModuleFormAnswers = state.betaModuleFormAnswers.filter(
        (betaModuleFormAnswer) => {
          return (
            betaModuleFormAnswer.betaContact !== payload.betaContact &&
            betaModuleFormAnswer.module !== payload.module
          )
        }
      )
      state.totalRecording--
    },
    clearAllBetaModuleFormAnswersState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBetaModuleFormAnswers.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(getAllBetaModuleFormAnswers.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.betaModuleFormAnswers = payload.betaModuleFormAnswers
        state.numOfPages = payload.numOfPages
        state.totalBetaModuleFormAnswers = payload.totalBetaModuleFormAnswers
      })
      .addCase(getAllBetaModuleFormAnswers.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
  },
})

export const {
  showLoading,
  hideLoading,
  handleChange,
  clearFilters,
  changePage,
  clearAllBetaModuleFormAnswersState,
  removeBetaModuleFormAnswersToDataTable,
} = allBetaModuleFormAnswersSlice.actions

export default allBetaModuleFormAnswersSlice.reducer
