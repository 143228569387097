import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getHistoryRecordingInteractionThunk } from './historyRecordingInteractionThunk'
import { RecordingInteraction } from '../recording/recordingSlice'

interface PayloadRecordingsInteraction {
  recordingsInteraction: RecordingInteraction[]
}

export interface RecordingsInteractionState {
  isLoading: boolean
  historyRecordingInteraction: RecordingInteraction[]
  error: string
}

const initialState: RecordingsInteractionState = {
  isLoading: false,
  historyRecordingInteraction: [] as RecordingInteraction[],
  error: '',
}

export const getHistoryRecordingInteraction = createAsyncThunk(
  'recordingInteraction/:id/history',
  async (id: string, thunkAPI) => {
    return getHistoryRecordingInteractionThunk(
      '/recordingInteraction/:id/history',
      id,
      thunkAPI
    )
  }
)

const historyRecordingInteractionSlice = createSlice({
  name: 'historyRecordingInteraction',
  initialState,
  reducers: {
    clearHistoryInteractionRecordingForm: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHistoryRecordingInteraction.pending, (state) => {
        state.isLoading = true
        state.error = ''
        state.historyRecordingInteraction = []
      })
      .addCase(
        getHistoryRecordingInteraction.fulfilled,
        (state, { payload }) => {
          state.isLoading = false
          const { recordingsInteraction } =
            payload as PayloadRecordingsInteraction
          state.historyRecordingInteraction = recordingsInteraction
        }
      )
      .addCase(
        getHistoryRecordingInteraction.rejected,
        (state, { payload }) => {
          state.isLoading = false
          state.historyRecordingInteraction = []
          state.error = payload as string
        }
      )
  },
})

export const { clearHistoryInteractionRecordingForm } =
  historyRecordingInteractionSlice.actions

export default historyRecordingInteractionSlice.reducer
