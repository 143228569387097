import env from 'react-dotenv'
import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const getAllBetaLagoonFormAnswersThunk = async (_, thunkAPI) => {
  const { page, sort, filterBetaContact } =
    thunkAPI.getState().allBetaLagoonFormAnswers
  const limit = env.DEFAULT_LIMIT_PAGINATION
  let url = `/roselagon/beta-lagoon-form-answers?sort=${sort}&page=${page}&limit=${limit}&betaContacts=${filterBetaContact}`

  try {
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
