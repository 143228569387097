/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Breadcrumb,
  Button,
  Checkbox,
  Label,
  Modal,
  Select,
  Table,
  Textarea,
} from 'flowbite-react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import {
  HiAdjustments,
  HiHome,
  HiOutlineExclamationCircle,
  HiOutlineStatusOnline,
  HiPencilAlt,
  HiTrash,
} from 'react-icons/hi'
import { DataTableController, Loading, Pagination } from '../../components'
import { useAppDispatch, useTypedSelector } from '../../store'
import { handleChange } from '../../features/allModulesVersion/allModulesVersionSlice'
import { changePage } from '../../features/allModules/allModulesSlice'
import moment from 'moment/moment'
import { useToast } from '../../hooks/useToast'
import { useNavigate, useParams } from 'react-router-dom'
import { getAllModulesVersion } from '../../features/allModulesVersion/allModulesVersionSlice'
import {
  ModuleVersion,
  NewModuleVersionForm,
  ModuleVersionForm,
  SelectPatchVersion,
  addModuleVersion,
  clearModuleVersionForm,
  editModuleVersion,
  endModuleVersionBeta,
  removeModuleVersion,
  copyModuleVersion,
} from '../../features/moduleVersion/moduleVersionSlice'
import { getModule, newModuleVersion } from '../../features/module/moduleSlice'
import { FaPlus } from 'react-icons/fa'
import { MdDataObject } from 'react-icons/md'
import ModuleVersionChangeLog from '../../components/ModuleVersionChangeLog'

const ModulesVersionListPage: FC = function () {
  const { error, page, totalModulesVersion, numOfPages, search, sort } =
    useTypedSelector((store) => store.allModulesVersion)
  const { error: errorModuleVersion } = useTypedSelector(
    (store) => store.moduleVersion
  )
  const { module } = useTypedSelector((store) => store.module)
  const { lastVersion } = module
  const dispatch = useAppDispatch()
  const toast = useToast()
  const { id } = useParams()

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
    }
    if (errorModuleVersion !== '') {
      toast('error', errorModuleVersion)
      dispatch(clearModuleVersionForm())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, errorModuleVersion])

  useEffect(() => {
    dispatch(clearModuleVersionForm())
    dispatch(getModule(id as string))
  }, [id, dispatch])

  useEffect(() => {
    if (module && module._id) {
      dispatch(getAllModulesVersion({}))
    }
  }, [dispatch, page, search, sort, module])
  return (
    <>
      <div className='block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex'>
        <div className='mb-1 w-full'>
          <div className='mb-4'>
            <Breadcrumb className='mb-4'>
              <Breadcrumb.Item href='#'>
                <div className='flex items-center gap-x-3'>
                  <HiHome className='text-xl' />
                  <span className='dark:text-white'>Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href='/modules'>Modules</Breadcrumb.Item>
              <Breadcrumb.Item href={`/modules/${module._id}`}>
                {module.name}
              </Breadcrumb.Item>
              <Breadcrumb.Item>Versions</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className='text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl'>
              Toutes les versions de {module.name}
            </h1>
          </div>
          <div className='block items-center sm:flex'>
            <DataTableController
              placeholder='rechercher une version'
              handleChange={handleChange}
              sort={sort}
              search={search}
              initFilters={null}
            ></DataTableController>
            <div className='flex w-full items-center sm:justify-end'>
              {lastVersion && lastVersion._id ? (
                !lastVersion.isBeta && <CopyModuleVersionModal />
              ) : (
                <AddModuleVersionModal />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='overflow-x-auto'>
          <div className='inline-block min-w-full align-middle'>
            <div className='overflow-hidden shadow'>
              <ModulesVersionTable />
            </div>
          </div>
        </div>
      </div>
      <Pagination
        numOfPages={numOfPages}
        total={totalModulesVersion}
        page={page}
        changePage={changePage}
      />
    </>
  )
}

const ModulesVersionTable: FC = function () {
  const { modulesVersion, isLoading } = useTypedSelector(
    (store) => store.allModulesVersion
  )
  const navigate = useNavigate()
  const { module } = useTypedSelector((store) => store.module)
  const castModuleVersion = modulesVersion as ModuleVersion[]
  if (isLoading) {
    return <Loading center />
  }
  if (modulesVersion.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>Pas de versions à afficher...</span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>
          <span className='sr-only'>Selectionner tous</span>
          <Checkbox />
        </Table.HeadCell>
        <Table.HeadCell>Version</Table.HeadCell>
        <Table.HeadCell>Changements</Table.HeadCell>
        <Table.HeadCell>Dernière modification</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {castModuleVersion.map((moduleVersion) => {
          return (
            <Table.Row
              key={moduleVersion._id}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='w-4 p-4'>
                <Checkbox />
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {moduleVersion.version}
                </div>
                {moduleVersion.isBeta ? (
                  <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                    Beta
                  </div>
                ) : (
                  <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                    Master
                  </div>
                )}
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {moduleVersion.changes}
                </div>
                <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                  Nombre de changements : {moduleVersion.changeLog.length}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                {`${moment(moduleVersion.updatedAt).fromNow()}`}
              </Table.Cell>
              <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                <div className='flex items-center gap-x-3'>
                  <Button
                    color='primary'
                    onClick={() =>
                      navigate(
                        `/modules/${module._id}/version/${moduleVersion._id}`
                      )
                    }
                  >
                    <MdDataObject className='mr-2 text-lg' />
                    <span className='hidden lg:block '>
                      Accès aux activités
                    </span>
                  </Button>
                  <EditModuleVersionModal {...moduleVersion} />
                  {moduleVersion.isBeta && (
                    <EndModuleVersionBetaModal {...moduleVersion} />
                  )}
                  {module.lastVersion?._id === moduleVersion._id && (
                    <DeleteModuleVersionModal {...moduleVersion} />
                  )}
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default ModulesVersionListPage

export const AddModuleVersionModal: FC = function () {
  const { module: moduleSelected } = useTypedSelector((store) => store.module)

  let initialValues: NewModuleVersionForm = {
    module: '',
  }

  const { isLoading, moduleVersion, success } = useTypedSelector(
    (store) => store.moduleVersion
  )
  const [isOpen, setOpen] = useState(false)
  const [values, setValues] = useState<NewModuleVersionForm>({
    ...initialValues,
  })
  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (moduleSelected && moduleSelected._id) {
      setValues({ ...values, module: moduleSelected._id })
    }
    // eslint-disable-next-line
  }, [moduleSelected])

  useEffect(() => {
    if (!isOpen) {
      //clear props
      setValues({
        module: moduleSelected._id,
      })
      //clear form
      dispatch(clearModuleVersionForm())
    }
    // eslint-disable-next-line
  }, [isOpen, dispatch, moduleSelected])

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { module } = values
    if (module !== '') {
      dispatch(addModuleVersion(values))
    } else {
      toast('error', `Vous avez besoin de renseigner les elements requis`)
    }
  }

  useEffect(() => {
    if (isOpen && moduleVersion && moduleVersion._id && success) {
      toast(
        'success',
        `La première version du module ${moduleSelected.name} a bien été crée`
      )
      //refresh list
      dispatch(newModuleVersion(moduleVersion))
      //close
      setOpen(false)
    }
    // eslint-disable-next-line
  }, [isOpen, success])

  return (
    <>
      <Button color='primary' onClick={() => setOpen(!isOpen)}>
        <FaPlus className='mr-3 text-sm' />
        Créer une version
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Créer une version</strong>
          </Modal.Header>
          <Modal.Body>
            <div className='grid grid-cols-1 gap-6 lg:grid-cols-2'>
              <>
                <div>
                  <p className='italic text-green-600'>
                    Initialisation de la première version du module
                  </p>
                </div>
              </>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Créer
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}
export const CopyModuleVersionModal: FC = function () {
  const { module: moduleSelected } = useTypedSelector((store) => store.module)
  const oldVersion = moduleSelected.lastVersion?.version

  let initialValues: ModuleVersionForm = {
    module: '',
    changes: '',
    version: '',
    patch: '',
  }

  const { isLoading, moduleVersion, success } = useTypedSelector(
    (store) => store.moduleVersion
  )
  const [isOpen, setOpen] = useState(false)
  const [values, setValues] = useState<ModuleVersionForm>({ ...initialValues })
  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (moduleSelected && moduleSelected._id) {
      setValues({ ...values, module: moduleSelected._id })
    }
    // eslint-disable-next-line
  }, [moduleSelected])

  useEffect(() => {
    if (isOpen) {
      // calculate newVersion
      if (oldVersion) {
        const versionArray = oldVersion.split('.')
        var oldMajor = parseInt(versionArray[0])
        var oldMinor = parseInt(versionArray[1])
        var oldPatch = parseInt(versionArray[2])
        switch (values.patch) {
          case SelectPatchVersion.PATCH:
            oldPatch++
            break
          case SelectPatchVersion.MINOR:
            oldMinor++
            oldPatch = 0
            break
          case SelectPatchVersion.MAJOR:
            oldMajor++
            oldMinor = 0
            oldPatch = 0
            break
        }
        const newVersion = `${oldMajor}.${oldMinor}.${oldPatch}`
        setValues({ ...values, version: newVersion })
      } else {
        setValues({ ...values, version: '1.0.0', patch: 'MAJOR' })
      }
    } else {
      //clear props
      setValues({
        module: moduleSelected._id,
        changes: '',
        version: '',
        patch: '',
      })
      //clear form
      dispatch(clearModuleVersionForm())
    }
    // eslint-disable-next-line
  }, [isOpen, oldVersion, values.patch, dispatch, moduleSelected])

  const handleSelectChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const handleTextAreaChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { version, patch, module } = values
    if (module !== '' && version !== '' && patch !== '') {
      dispatch(copyModuleVersion(values))
    } else {
      toast('error', `Vous avez besoin de renseigner les elements requis`)
    }
  }

  useEffect(() => {
    if (isOpen && moduleVersion && moduleVersion._id && success) {
      toast(
        'success',
        `Une nouvelle version du module ${moduleSelected.name} a bien été créée`
      )
      //refresh list
      dispatch(newModuleVersion(moduleVersion))
      //close
      setOpen(false)
    }
    // eslint-disable-next-line
  }, [isOpen, success])

  return (
    <>
      <Button color='primary' onClick={() => setOpen(!isOpen)}>
        <FaPlus className='mr-3 text-sm' />
        Ajouter une version
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Ajouter une version</strong>
          </Modal.Header>
          <Modal.Body>
            <div className='grid grid-cols-1 gap-6 lg:grid-cols-2'>
              {oldVersion ? (
                <>
                  <div>
                    <p className='italic text-green-600'>
                      La version précédente ainsi que tous les sous objets
                      seront dupliqués tout en gardant les mêmes LrsObjectID
                    </p>
                  </div>
                  <div>
                    <Label htmlFor='patch'>
                      Nouvelle version : {values.version}
                    </Label>
                    <Select
                      id='patch'
                      name='patch'
                      onChange={handleSelectChange}
                      defaultValue={values.patch}
                      disabled={!moduleSelected.lastVersion}
                    >
                      <option value=''></option>
                      <option value={SelectPatchVersion.PATCH}>
                        Patch/Bug
                      </option>
                      <option value={SelectPatchVersion.MINOR}>
                        Mineur (demande à l'utilisateur de refaire de module
                        pour etre a jour)
                      </option>
                      <option value={SelectPatchVersion.MAJOR}>
                        Majeur (autre techno / contenu)
                      </option>
                    </Select>
                  </div>
                  <div className='lg:col-span-2'>
                    <Label htmlFor='changes'>Patch notes</Label>
                    <Textarea
                      id='changes'
                      name='changes'
                      placeholder='e.g. 3.8GHz 8-core 10th-generation Intel Core i7 processor, Turbo Boost up to 5.0GHz, Ram 16 GB DDR4 2300Mhz'
                      rows={6}
                      className='mt-1'
                      value={values.changes}
                      onChange={handleTextAreaChange}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <p className='italic text-green-600'>
                      Initialisation de la première version du module
                    </p>
                  </div>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Ajouter
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export const DeleteModuleVersionModal: FC<{
  _id: string
  module: string
  version: string
  changes: string
  isBeta: boolean
  patch: string
  updatedAt: Date
}> = function (moduleVersionSelected: ModuleVersionForm) {
  const { module } = useTypedSelector((store) => store.module)
  const { success } = useTypedSelector((store) => store.moduleVersion)
  const [isOpen, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const toast = useToast()

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    dispatch(removeModuleVersion(moduleVersionSelected))
  }
  useEffect(() => {
    if (isOpen && success) {
      toast(
        'success',
        `La version ${moduleVersionSelected.version} à bien été supprimée`
      )
      //refresh
      dispatch(getModule(module._id))
      dispatch(clearModuleVersionForm())
      //close
      setOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, module, success, moduleVersionSelected])
  return (
    <>
      <Button color='failure' onClick={() => setOpen(!isOpen)}>
        <HiTrash className='mr-2 text-lg' />
        <span className='hidden lg:block '>Supprimer la version</span>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen} size='md'>
        <Modal.Header className='px-3 pb-0 pt-3'>
          <span className='sr-only'>Supprimer version</span>
        </Modal.Header>
        <Modal.Body className='px-6 pb-6 pt-0'>
          <div className='flex flex-col items-center gap-y-6 text-center'>
            <HiOutlineExclamationCircle className='text-7xl text-red-600' />
            <p className='text-lg text-gray-500 dark:text-gray-300'>
              Êtes vous sur de vouloir détruire la version
              {moduleVersionSelected.version} du module {module.name} ?
            </p>
            <div className='flex items-center gap-x-3'>
              <Button color='failure' onClick={handleSubmit}>
                Oui, je suis sur
              </Button>
              <Button color='gray' onClick={() => setOpen(false)}>
                Non, annuler
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export const EditModuleVersionModal: FC<ModuleVersion> = function (
  moduleVersionSelected: ModuleVersion
) {
  const { module } = useTypedSelector((store) => store.module)
  var oldPatch = moduleVersionSelected.patch
  if (!oldPatch) {
    oldPatch = 'PATCH'
  }

  const initialValues: ModuleVersionForm = {
    module: module._id,
    changes: moduleVersionSelected.changes,
    version: moduleVersionSelected.version,
    patch: oldPatch,
    _id: moduleVersionSelected._id,
  }

  const { isLoading, moduleVersion } = useTypedSelector(
    (store) => store.moduleVersion
  )
  const [isOpen, setOpen] = useState(false)
  const [values, setValues] = useState<ModuleVersionForm>(initialValues)
  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (isOpen) {
      setValues(initialValues)
    }
    // eslint-disable-next-line
  }, [isOpen])

  const handleTextAreaChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { module, changes, version, patch, _id } = values
    if (
      module !== '' &&
      changes !== '' &&
      version !== '' &&
      patch !== '' &&
      _id !== ''
    ) {
      dispatch(editModuleVersion(values))
    } else {
      toast('error', `Vous avez besoin de renseigner les elements requis`)
    }
  }

  useEffect(() => {
    if (isOpen && moduleVersion && moduleVersion._id) {
      toast('success', `La version du module ${module.name} a été mise à jour`)
      //refresh list
      dispatch(getModule(module._id))
      //clear form
      dispatch(clearModuleVersionForm())
      //close
      setOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, module, moduleVersion])

  return (
    <>
      <Button color='primary' onClick={() => setOpen(!isOpen)}>
        <HiPencilAlt className='mr-2 text-lg' />
        <span className='hidden lg:block '>Modifier le changelog</span>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Modifier le changelog</strong>
          </Modal.Header>
          <Modal.Body>
            <div className='grid grid-cols-1 gap-6 lg:grid-cols-1'>
              <div className='lg:col-span-2'>
                <Label htmlFor='changes'>Patch notes</Label>
                <Textarea
                  id='changes'
                  name='changes'
                  placeholder='e.g. 3.8GHz 8-core 10th-generation Intel Core i7 processor, Turbo Boost up to 5.0GHz, Ram 16 GB DDR4 2300Mhz'
                  rows={6}
                  className='mt-1'
                  value={values.changes}
                  onChange={handleTextAreaChange}
                />
              </div>
              <div className='lg:col-span-2'>
                <ModuleVersionChangeLog
                  changeLog={moduleVersionSelected.changeLog}
                  moduleVersion={moduleVersionSelected}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Editer
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export const EndModuleVersionBetaModal: FC<ModuleVersion> = function (
  moduleVersionSelected: ModuleVersion
) {
  const { module } = useTypedSelector((store) => store.module)
  const { success } = useTypedSelector((store) => store.moduleVersion)
  const [isOpen, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const toast = useToast()

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    dispatch(endModuleVersionBeta(moduleVersionSelected))
  }
  useEffect(() => {
    if (isOpen && success) {
      toast(
        'success',
        `La version ${moduleVersionSelected.version} est passée master`
      )
      //refresh
      dispatch(getModule(module._id))
      dispatch(clearModuleVersionForm())
      //close
      setOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, module, success, moduleVersionSelected])
  return (
    <>
      <Button color='warning' onClick={() => setOpen(!isOpen)}>
        <HiAdjustments className='mr-2 text-lg' />
        <span className='hidden lg:block '>Passer en master</span>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen} size='md'>
        <Modal.Header className='px-3 pb-0 pt-3'>
          <span className='sr-only'>Passer master</span>
        </Modal.Header>
        <Modal.Body className='px-6 pb-6 pt-0'>
          <div className='flex flex-col items-center gap-y-6 text-center'>
            <HiOutlineStatusOnline className='text-7xl text-yellow-600' />
            <p className='text-lg text-gray-500 dark:text-gray-300'>
              Êtes vous sur de vouloir passer la version{' '}
              {moduleVersionSelected.version} du module {module.name} en master
              ?
            </p>
            <p className='text-lg text-gray-500 dark:text-gray-300'>
              Cette version remplacera l'ancienne version master et sera visible
              à tous les utilisateurs dès que le module passera en Actif.
            </p>

            <ModuleVersionChangeLog
              changeLog={moduleVersionSelected.changeLog}
              moduleVersion={moduleVersionSelected}
            />

            <div className='flex items-center gap-x-3'>
              <Button color='warning' onClick={handleSubmit}>
                Oui, je suis sur
              </Button>
              <Button color='gray' onClick={() => setOpen(false)}>
                Non, annuler
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
