import ResourceElement from './ResourceElement'
import env from 'react-dotenv'

const ThumbnailResource = ({ resource, size = 'lg' }) => {
  if (resource && resource.src) {
    const { src, public: isPublic } = resource

    if (isPublic) {
      return (
        <a href={src} target='_blank' rel='noreferrer'>
          <ResourceElement resource={resource} size={size} />
        </a>
      )
    } else {
      return (
        <a
          href={`${env.PROTOCOL}://${env.API_BASE_URL}/resources/${resource._id}`}
          target='_blank'
          rel='noreferrer'
        >
          <ResourceElement resource={resource} size={size} />
        </a>
      )
    }
  } else {
    return <></>
  }
}

export default ThumbnailResource
