import moment from 'moment'
import {
  ConfigureTaxonomiesModal,
  DeleteLearningObjectiveModal,
  EditLearningObjectiveModal,
} from '../pages/learning-objectives/list'
import { useEffect, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { LearningObjectiveTaxonomiesChart } from './charts/LearningObjectiveTaxonomiesChart'
import { useTypedSelector } from '../store'
import { LevelTaxonomy } from '../features/taxonomy/taxonomySlice'
import { Table } from 'flowbite-react'

const AccordionTableRow = ({
  index,
  learningObjective,
  rowOpened,
  handleClick,
}) => {
  const { enumSkills, enumColors } = useTypedSelector(
    (store) => store.allSkills
  )
  const [toggle, setToogle] = useState(false)
  const [seriesTaxonomies, setSeriesTaxonomies] = useState([])
  const [seriesSkills, setSeriesSkills] = useState([])
  const [seriesColors, setSeriesColors] = useState([])

  useEffect(() => {
    setSeriesTaxonomies(
      learningObjective.taxonomies.map((taxonomy) => taxonomy.weight)
    )
    setSeriesSkills(
      learningObjective.taxonomies.map((taxonomy) => enumSkills[taxonomy.skill])
    )
    setSeriesColors(
      learningObjective.taxonomies.map((taxonomy) => enumColors[taxonomy.skill])
    )
  }, [learningObjective, enumSkills, enumColors])

  useEffect(() => {
    if (rowOpened === index) {
      setToogle(true)
    } else {
      setToogle(false)
    }
  }, [rowOpened, index])

  return (
    <>
      <Table.Row
        key={`table-column-row1-${index}`}
        id={`table-column-header-${index}`}
        className='hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer transition'
        onClick={() => handleClick(index)}
      >
        <Table.Cell className='p-3 w-4'>
          {toggle ? (
            <FaChevronUp></FaChevronUp>
          ) : (
            <FaChevronDown></FaChevronDown>
          )}
        </Table.Cell>
        <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
          <div className='text-base font-semibold text-gray-900 dark:text-white'>
            <span>{learningObjective.name}</span>
          </div>
          <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
            <span>{learningObjective.lrsObjectId}</span>
          </div>
        </Table.Cell>
        <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white min-w-[12rem] max-w-[24rem] overflow-hidden text-ellipsis'>
          <span>{learningObjective.description}</span>
        </Table.Cell>
        <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
          {learningObjective.taxonomies &&
          learningObjective.taxonomies.length > 0 ? (
            <span>{learningObjective.taxonomies.length}</span>
          ) : (
            <span>0</span>
          )}
        </Table.Cell>
        <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
          {`${moment(learningObjective.updatedAt).fromNow()}`}
        </Table.Cell>
        <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
          <div className='flex items-center gap-x-3'>
            <EditLearningObjectiveModal {...learningObjective} />
            <DeleteLearningObjectiveModal {...learningObjective} />
          </div>
        </Table.Cell>
      </Table.Row>
      {toggle && (
        <Table.Row
          key={`table-column-row2-${index}`}
          className='flex-1 overflow-x-auto w-full'
          id={`table-column-body-${index}`}
        >
          <Table.Cell></Table.Cell>
          <Table.Cell>
            {seriesTaxonomies.length > 0 ? (
              <Table
                className='w-full divide-y divide-gray-200 dark:divide-gray-600'
                key={`sub-table-${index}`}
              >
                <Table.Head className='bg-primary-600'>
                  <Table.HeadCell className='bg-primary-600'>
                    Compétence
                  </Table.HeadCell>
                  <Table.HeadCell className='bg-primary-600'>
                    Niveau
                  </Table.HeadCell>
                  <Table.HeadCell className='bg-primary-600'>
                    Poids
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
                  {learningObjective.taxonomies.map((taxonomy) => {
                    return (
                      <Table.Row key={`taxonomy-table-${taxonomy._id}`}>
                        <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                          {enumSkills[taxonomy.skill]}
                        </Table.Cell>
                        <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                          {`${taxonomy.level} - ${
                            LevelTaxonomy['level' + taxonomy.level]
                          }`}
                        </Table.Cell>
                        <Table.Cell className='whitespace-nowrap border-t-0 p-4 align-middle text-xs'>
                          <div className='flex items-center'>
                            <span className='mr-2 text-xs font-medium'>
                              {taxonomy.weight}%
                            </span>
                            <div className='relative w-full'>
                              <div className='h-2 w-full rounded-sm bg-gray-200 dark:bg-gray-700'>
                                <div
                                  className='h-2 rounded-sm'
                                  style={{
                                    width: `${taxonomy.weight}%`,
                                    backgroundColor: enumColors[taxonomy.skill],
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table>
            ) : (
              <>
                <span className='font-medium'>
                  Pas de taxonomies à afficher...
                </span>
              </>
            )}
          </Table.Cell>
          <Table.Cell>
            {seriesTaxonomies.length > 0 && (
              <LearningObjectiveTaxonomiesChart
                seriesTaxonomies={seriesTaxonomies}
                seriesSkills={seriesSkills}
                seriesColors={seriesColors}
                key={`chart-${learningObjective._id}`}
              />
            )}
          </Table.Cell>
          <Table.Cell></Table.Cell>
          <Table.Cell></Table.Cell>
          <Table.Cell>
            <ConfigureTaxonomiesModal {...learningObjective} />
          </Table.Cell>
        </Table.Row>
      )}
    </>
  )
}
export default AccordionTableRow
