import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  addModuleThunk,
  changeModuleStatusThunk,
  editConfigModuleModalFormThunk,
  editModuleThunk,
  getModuleThunk,
  removeModuleThunk,
} from './moduleThunk'
import { ModuleVersion } from '../moduleVersion/moduleVersionSlice'
import { LearningObjective } from '../learningObjective/learningObjectiveSlice'
import { Resource } from '../resource/resourceSlice'
import { Trainer } from '../trainer/trainerSlice'

export const enum ModuleCategoryEnum {
  NOTCATEGORIZED = 'not-categorized',
  NR = 'nr',
  RSE = 'rse',
  CLIMATE = 'climate',
  PREVENTION = 'prevention',
  PERSONALDEV = 'personal-dev',
}

export interface Module {
  _id: string
  name: string
  shortResume: string
  longResume: any
  thumb: string | null
  thumbResource: Resource | null
  backgroundImage: string | null
  backgroundImageResource: Resource | null
  trainer: string | null
  trainerInfo: Trainer | null
  lrsObjectId: string
  tags: string[]
  updatedAt: Date
  status: string
  category: ModuleCategoryEnum
  numOfLaunched: number
  numOfFinished: number
  numOfSubscribed: number
  configModuleModal?: ConfigModuleModal
  lastVersion?: ModuleVersion
  learningObjectives?: LearningObjective[]
  countMasterVersions?: number
  countLearningObjectivesValid?: number
  totalLearningTime?: number
}

export interface ConfigModuleModal {
  mainColor: string
  burgerColor: string
  headerBackgroundColor: string
}

export interface ModuleForm {
  _id?: string
  name: string
  shortResume: string
  longResume: any
  tags: string[]
  thumb: string | null
  category: ModuleCategoryEnum
  backgroundImage: string | null
}

export interface ConfigModuleModalForm {
  _id: string
  configModuleModal: ConfigModuleModal
}

export interface ModuleStatusForm {
  _id: string
  status: string
}

export interface ModuleTrainerForm {
  _id: string
  trainer: string | null
}

export interface ModuleThumbForm {
  _id: string
  thumb: string | null
}

export interface ModuleBackgroundImageForm {
  _id: string
  backgroundImage: string | null
}

export interface ModuleState {
  isLoading: boolean
  module: Module
  error: string
}

const initialState: ModuleState = {
  isLoading: false,
  module: {} as Module,
  error: '',
}

export const getModule = createAsyncThunk(
  'module/get',
  async (id: string, thunkAPI) => {
    return getModuleThunk('/module', id, thunkAPI)
  }
)

export const addModule = createAsyncThunk(
  'module/new',
  async (module: ModuleForm, thunkAPI) => {
    return addModuleThunk('/module', module, thunkAPI)
  }
)

export const removeModule = createAsyncThunk(
  'module/remove',
  async (module: ModuleForm, thunkAPI) => {
    return removeModuleThunk('/module', module, thunkAPI)
  }
)

export const editModule = createAsyncThunk(
  'module/edit',
  async (module: ModuleForm, thunkAPI) => {
    return editModuleThunk('/module', module, thunkAPI)
  }
)

export const editThumbModule = createAsyncThunk(
  'module/edit',
  async (module: ModuleThumbForm, thunkAPI) => {
    return editModuleThunk('/module', module, thunkAPI)
  }
)

export const editBackgroundImageModule = createAsyncThunk(
  'module/edit',
  async (module: ModuleBackgroundImageForm, thunkAPI) => {
    return editModuleThunk('/module', module, thunkAPI)
  }
)

export const editTrainerModule = createAsyncThunk(
  'module/edit',
  async (module: ModuleTrainerForm, thunkAPI) => {
    return editModuleThunk('/module', module, thunkAPI)
  }
)

export const editConfigModuleModalForm = createAsyncThunk(
  'module/configModuleModalForm',
  async (configModuleModal: ConfigModuleModalForm, thunkAPI) => {
    return editConfigModuleModalFormThunk(
      '/module/:id/configmodal',
      configModuleModal,
      thunkAPI
    )
  }
)

export const changeModuleStatus = createAsyncThunk(
  'module/status',
  async (module: ModuleStatusForm, thunkAPI) => {
    return changeModuleStatusThunk('/module/:id/status', module, thunkAPI)
  }
)

const moduleSlice = createSlice({
  name: 'module',
  initialState,
  reducers: {
    clearModuleForm: () => initialState,
    newModuleVersion: (state, { payload }) => {
      state.module.lastVersion = payload
    },
    newLearningObjective: (state, { payload }) => {
      state.module.learningObjectives?.push(payload)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getModule.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(getModule.fulfilled, (state, { payload }) => {
        const { module } = payload
        state.module = module
        state.isLoading = false
      })
      .addCase(getModule.rejected, (state, { payload }) => {
        state.isLoading = false
        state.module = {} as Module
        state.error = payload as string
      })
      .addCase(addModule.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(addModule.fulfilled, (state, { payload }) => {
        const { module } = payload
        state.module = module
        state.isLoading = false
      })
      .addCase(addModule.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addCase(editModule.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(editModule.fulfilled, (state, { payload }) => {
        state.isLoading = false
        const { module } = payload
        state.module = module
      })
      .addCase(editModule.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addCase(editConfigModuleModalForm.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(editConfigModuleModalForm.fulfilled, (state, { payload }) => {
        state.isLoading = false
        const { module } = payload
        state.module = module
      })
      .addCase(editConfigModuleModalForm.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addCase(changeModuleStatus.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(changeModuleStatus.fulfilled, (state, { payload }) => {
        state.isLoading = false
        const { module } = payload
        state.module = module
      })
      .addCase(changeModuleStatus.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addCase(removeModule.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(removeModule.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(removeModule.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
  },
})

export const { clearModuleForm, newModuleVersion, newLearningObjective } =
  moduleSlice.actions

export default moduleSlice.reducer
