import env from 'react-dotenv'
import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const getAllBetaModuleFormAnswersThunk = async (_, thunkAPI) => {
  const { page, sort, filterBetaContact, filterModule } =
    thunkAPI.getState().allBetaModuleFormAnswers
  const limit = env.DEFAULT_LIMIT_PAGINATION
  let url = `/roselagon/beta-module-form-answers?sort=${sort}&page=${page}&limit=${limit}&betaContacts=${filterBetaContact}&modules=${filterModule}`

  try {
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
