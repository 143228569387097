import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  promoteUserToAdminThunk,
  demoteAdminToUserThunk,
  inviteBetaUserThunk,
  demoteBetaUserThunk,
  getUserThunk,
  deleteUserThunk,
} from './specialUserThunk'

export interface SpecialUser {
  _id: string
  email: string
  name: string
  lastName: string
  role: string
  isVerified: boolean
}

export interface SpecialUserState {
  isLoading: boolean
  specialUser: SpecialUser
  error: string
  success: boolean
}

const initialState: SpecialUserState = {
  isLoading: false,
  specialUser: {} as SpecialUser,
  error: '',
  success: false,
}

export const getUser = createAsyncThunk(
  'users/get',
  async (id: string, thunkAPI) => {
    return getUserThunk('/module', id, thunkAPI)
  }
)

export const deleteUser = createAsyncThunk(
  'users/delete',
  async (id: string, thunkAPI) => {
    return deleteUserThunk('/module', id, thunkAPI)
  }
)

export const promoteUserToAdmin = createAsyncThunk(
  'users/promote',
  async (email: string, thunkAPI) => {
    return promoteUserToAdminThunk('/user/promote', email, thunkAPI)
  }
)

export const demoteAdminToUser = createAsyncThunk(
  'users/demote',
  async (email: string, thunkAPI) => {
    return demoteAdminToUserThunk('/users/demote', email, thunkAPI)
  }
)

export const inviteBetaUser = createAsyncThunk(
  'users/invite-beta',
  async (email: string, thunkAPI) => {
    return inviteBetaUserThunk('/users/invite-beta', email, thunkAPI)
  }
)

export const demoteBetaUser = createAsyncThunk(
  'users/demote-beta',
  async (email: string, thunkAPI) => {
    return demoteBetaUserThunk('/users/demote-beta', email, thunkAPI)
  }
)

const specialUserSlice = createSlice({
  name: 'specialUser',
  initialState,
  reducers: {
    clearSpecialUserForm: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        const { user } = payload
        state.specialUser = user
        state.isLoading = false
      })
      .addCase(getUser.rejected, (state, { payload }) => {
        state.isLoading = false
        state.specialUser = {} as SpecialUser
        state.error = payload as string
      })
      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true
        state.error = ''
        state.success = false
      })
      .addCase(deleteUser.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.success = true
      })
      .addCase(deleteUser.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
        state.success = false
      })
      .addCase(promoteUserToAdmin.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(promoteUserToAdmin.fulfilled, (state, { payload }) => {
        state.specialUser = payload.user
        state.isLoading = false
      })
      .addCase(promoteUserToAdmin.rejected, (state, { payload }) => {
        state.isLoading = false
        state.specialUser = {} as SpecialUser
        state.error = payload as string
      })
      .addCase(demoteAdminToUser.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(demoteAdminToUser.fulfilled, (state, { payload }) => {
        state.specialUser = payload.user
        state.isLoading = false
      })
      .addCase(demoteAdminToUser.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addCase(inviteBetaUser.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(inviteBetaUser.fulfilled, (state, { payload }) => {
        state.specialUser = payload.user
        state.isLoading = false
      })
      .addCase(inviteBetaUser.rejected, (state, { payload }) => {
        state.isLoading = false
        state.specialUser = {} as SpecialUser
        state.error = payload as string
      })
      .addCase(demoteBetaUser.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(demoteBetaUser.fulfilled, (state, { payload }) => {
        state.specialUser = payload.user
        state.isLoading = false
      })
      .addCase(demoteBetaUser.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
  },
})

export const { clearSpecialUserForm } = specialUserSlice.actions

export default specialUserSlice.reducer
