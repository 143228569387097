import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  addSkillThunk,
  editSkillThunk,
  getSkillThunk,
  removeSkillThunk,
} from './skillThunk'

export interface Skill {
  _id: string
  color: string
  icon: string
  description: string
  name: string
  taxonomiesCount?: number
  taxonomiesByLevel?: any[]
}

export interface TaxonomyByLevel {
  _id: string
  weight: number
  level: number
  name: string
  learningTime: number
}

export interface SkillForm {
  _id?: string
  name: string
  color?: string
  description: string
  icon?: string
}

export interface SkillState {
  isLoading: boolean
  skill: Skill
  error: string
}

const initialState: SkillState = {
  isLoading: false,
  skill: {} as Skill,
  error: '',
}

export const getSkill = createAsyncThunk(
  'skill/get',
  async (id: string, thunkAPI) => {
    return getSkillThunk('/skills', id, thunkAPI)
  }
)

export const addSkill = createAsyncThunk(
  'skill/new',
  async (skill: SkillForm, thunkAPI) => {
    return addSkillThunk('/skills', skill, thunkAPI)
  }
)

export const removeSkill = createAsyncThunk(
  'skill/remove',
  async (skill: SkillForm, thunkAPI) => {
    return removeSkillThunk('/skills/:id', skill, thunkAPI)
  }
)

export const editSkill = createAsyncThunk(
  'skill/edit',
  async (skill: SkillForm, thunkAPI) => {
    return editSkillThunk('/skills/:id', skill, thunkAPI)
  }
)

const skillSlice = createSlice({
  name: 'skill',
  initialState,
  reducers: {
    clearSkillForm: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSkill.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(getSkill.fulfilled, (state, { payload }) => {
        const { skill } = payload
        state.skill = skill
        state.isLoading = false
      })
      .addCase(getSkill.rejected, (state, { payload }) => {
        state.isLoading = false
        state.skill = {} as Skill
        state.error = payload as string
      })
      .addCase(addSkill.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(addSkill.fulfilled, (state, { payload }) => {
        const { skill } = payload
        state.skill = skill
        state.isLoading = false
      })
      .addCase(addSkill.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addCase(editSkill.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(editSkill.fulfilled, (state, { payload }) => {
        state.isLoading = false
        const { skill } = payload
        state.skill = skill
      })
      .addCase(editSkill.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addCase(removeSkill.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(removeSkill.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(removeSkill.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
  },
})

export const { clearSkillForm } = skillSlice.actions

export default skillSlice.reducer
