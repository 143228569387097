import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllRecordingsThunk } from './allRecordingsThunk'

const initialFiltersState = {
  filterUser: [],
  filterModule: [],
  filterModuleVersion: 'all',
  filterProgress: 'all',
  filterFinished: 'all',
  sort: 'latest',
  sortOptions: ['latest', 'oldest', 'finished', 'firstFinished'],
}

const initialState = {
  isLoading: false,
  recordings: [],
  totalRecordings: 0,
  numOfPages: 1,
  page: 1,
  error: '',
  // stats: {},
  // monthlyApplications: [],
  ...initialFiltersState,
}

export const getAllRecordings = createAsyncThunk(
  'recordings/list',
  getAllRecordingsThunk
)

const allRecordingsSlice = createSlice({
  name: 'allRecordings',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true
    },
    hideLoading: (state) => {
      state.isLoading = false
    },
    handleChange: (state, { payload: { name, value } }) => {
      state.page = 1
      state[name] = value
    },
    clearFilters: (state) => {
      return { ...state, ...initialFiltersState }
    },
    changePage: (state, { payload }) => {
      state.page = payload
    },
    removeRecordingToDataTable: (state, { payload }) => {
      state.recordings = state.recordings.filter((recording) => {
        return recording._id !== payload._id
      })
      state.totalRecording--
    },
    clearAllRecordingState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRecordings.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(getAllRecordings.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.recordings = payload.recordings
        state.numOfPages = payload.numOfPages
        state.totalRecordings = payload.totalRecordings
      })
      .addCase(getAllRecordings.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
  },
})

export const {
  showLoading,
  hideLoading,
  handleChange,
  clearFilters,
  changePage,
  clearAllRecordingState,
  removeRecordingToDataTable,
} = allRecordingsSlice.actions

export default allRecordingsSlice.reducer
