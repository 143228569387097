import { FC } from "react";
import { IIconProps, IIconPropsInitialValues } from ".";

export const PlusIcon: FC<IIconProps> = ({ className }: IIconProps) => {
  return (
    <svg
      className={className || IIconPropsInitialValues.className}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path stroke="currentColor" strokeWidth="2" d="M5 12h14m-7 7V5" />
    </svg>
  );
};
export default PlusIcon;
