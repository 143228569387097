import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import DashboardPage from './pages/DashboardPage'
// import ForgotPasswordPage from './pages/authentication/forgot-password'
// import ResetPasswordPage from './pages/authentication/reset-password'
import SignInPage from './pages/authentication/sign-in'
// import SignUpPage from './pages/authentication/sign-up'
import ModulesListPage from './pages/modules/list'
import SkillsListPage from './pages/skills/list'
import RecordingsListPage from './pages/recordings/list'
import NotFoundPage from './pages/pages/404'
import ServerErrorPage from './pages/pages/500'
import MaintenancePage from './pages/pages/maintenance'
import PricingPage from './pages/pages/pricing'
import UserFeedPage from './pages/users/feed'
import UserListPage from './pages/administration/list'
import UserProfilePage from './pages/users/profile'
import UserSettingsPage from './pages/users/settings'
import FlowbiteWrapper from './components/flowbite-wrapper'

import { ProtectedRoute, Logout } from './pages'
import ModulePage from './pages/modules/single'
import ModulesVersionListPage from './pages/versions/list'
import LearningObjectivesListPage from './pages/learning-objectives/list'
import { SkillPage } from './pages/skills/single'
import { TagsPage } from './pages/tags/list'
import TrainersPage from './pages/trainers/list'
import MediasListPage from './pages/medias/list'
import VersionPage from './pages/versions/single'
import ActivityPage from './pages/activities/single'
import BDDControlPage from './pages/administration/bdd'
import BetaContactListPage from './pages/beta/register'
import LagoonFormAnswersListPage from './pages/beta/lagoonFormAnswers'
import ModuleFormAnswersListPage from './pages/beta/moduleFormAnswers'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            <ProtectedRoute>
              <FlowbiteWrapper nav={true} />
            </ProtectedRoute>
          }
        >
          <Route index element={<DashboardPage />} />
          <Route path='/skills' element={<SkillsListPage />} />
          <Route path='/skills/:id' element={<SkillPage />} />
          <Route path='/modules' element={<ModulesListPage />} />
          <Route path='/modules/:id' element={<ModulePage />} />
          <Route
            path='/modules/:moduleId/recordings'
            element={<RecordingsListPage />}
          />
          <Route
            path='/modules/:moduleId/lagoon-form-answers'
            element={<ModuleFormAnswersListPage />}
          />
          <Route path='/recordings' element={<RecordingsListPage />} />
          <Route path='/medias' element={<MediasListPage />} />
          <Route
            path='/modules/:id/versions'
            element={<ModulesVersionListPage />}
          />
          <Route
            path='/modules/:id/learning-objectives'
            element={<LearningObjectivesListPage />}
          />
          <Route path='/modules/:id/version/:id' element={<VersionPage />} />
          <Route
            path='/modules/:id/version/:id/activity/:id'
            element={<ActivityPage />}
          />
          <Route path='/beta/register' element={<BetaContactListPage />} />
          <Route
            path='/beta/register/:betaContactId/lagoon-form-answers'
            element={<LagoonFormAnswersListPage />}
          />
          <Route
            path='/beta/register/:betaContactId/module-form-answers'
            element={<ModuleFormAnswersListPage />}
          />
          <Route
            path='/beta/lagoon-form-answers'
            element={<LagoonFormAnswersListPage />}
          />
          <Route
            path='/beta/module-form-answers'
            element={<ModuleFormAnswersListPage />}
          />
          <Route path='/administration/users' element={<UserListPage />} />
          <Route
            path='/administration/users/:userId/recordings'
            element={<RecordingsListPage />}
          />
          <Route path='/administration/tags' element={<TagsPage />} />
          <Route path='/administration/trainers' element={<TrainersPage />} />
          <Route
            path='/administration/bddcontrol'
            element={<BDDControlPage />}
          />
          <Route path='/users/feed' element={<UserFeedPage />} />
          <Route path='/users/profile' element={<UserProfilePage />} />
          <Route path='/users/settings' element={<UserSettingsPage />} />
        </Route>
        <Route path='/authentication' element={<FlowbiteWrapper nav={false} />}>
          <Route path='/authentication/sign-in' element={<SignInPage />} />
        </Route>
        <Route path='/pages' element={<FlowbiteWrapper nav={false} />}>
          <Route path='/pages/pricing' element={<PricingPage />} />
          <Route path='/pages/maintenance' element={<MaintenancePage />} />
          <Route path='/pages/404' element={<NotFoundPage />} />
          <Route path='/pages/500' element={<ServerErrorPage />} />
        </Route>

        <Route path='logout' element={<Logout />} />
        <Route path='*' element={<Navigate to='/pages/404' />} />
      </Routes>
      {/* <ToastContainer position='top-center' /> */}
    </BrowserRouter>
  )
}

export default App
