import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { removeRecordingThunk } from './recordingThunk'
import { User } from '../user/userSlice'
import { Module } from '../module/moduleSlice'
import { ModuleVersion } from '../moduleVersion/moduleVersionSlice'
import { LearningObjective } from '../learningObjective/learningObjectiveSlice'
import { Interaction } from '../interaction/interactionSlice'

export interface Recording {
  _id: string
  module: string
  moduleVersion: string
  finished: Date | null
  progress: number
  score: number
  createdAt: Date
  updatedAt: Date
  user: string
  userInfo?: User
  moduleInfo?: Module
  moduleVersionInfo?: ModuleVersion
  currentActivity: string
  recordingLearningObjectives: RecordingLearningObjectives[]
  recordingInteractions: RecordingInteraction[]
}

export interface RecordingLearningObjectives {
  _id: string
  progress: number
  timeSpent: number
  score: number
  isUpToDate: boolean
  learningObjective: string
  learningObjectiveInfo?: LearningObjective
  createdAt: Date
  updatedAt: Date
}

export interface RecordingInteraction {
  _id: string
  recording: string
  interaction: string
  interactionInfo?: Interaction
  score?: number | null
  success?: boolean | null
  nbErrors?: number | null
  kind: string
  userAnswers: UserAnswers[]
  answered: number
  createdAt: Date
  updatedAt: Date
}

export interface UserAnswers {
  answer: string
  value?: any
  correct?: boolean
}

export interface RecordingForm {
  _id: string
}

export interface RecordingState {
  isLoading: boolean
  recording: Recording
  error: string
}

const initialState: RecordingState = {
  isLoading: false,
  recording: {} as Recording,
  error: '',
}

export const removeRecording = createAsyncThunk(
  'recording/remove',
  async (recording: RecordingForm, thunkAPI) => {
    return removeRecordingThunk('/recordings/:id', recording, thunkAPI)
  }
)

const recordingSlice = createSlice({
  name: 'recording',
  initialState,
  reducers: {
    clearRecordingForm: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(removeRecording.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(removeRecording.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(removeRecording.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
  },
})

export const { clearRecordingForm } = recordingSlice.actions

export default recordingSlice.reducer
