import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const getModuleVersionThunk = async (_, id, thunkAPI) => {
  try {
    let url = `/mversion/${id}`
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const addModuleVersionThunk = async (_, moduleVersion, thunkAPI) => {
  let url = `/mversion`
  try {
    const resp = await customFetch.post(url, moduleVersion)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const copyModuleVersionThunk = async (_, moduleVersion, thunkAPI) => {
  let url = `/mversion/copy`
  try {
    const resp = await customFetch.post(url, moduleVersion)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const removeModuleVersionThunk = async (_, moduleVersion, thunkAPI) => {
  let url = `/mversion/${moduleVersion._id}`
  try {
    const resp = await customFetch.delete(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const editModuleVersionThunk = async (_, moduleVersion, thunkAPI) => {
  let url = `/mversion/${moduleVersion._id}`
  try {
    const resp = await customFetch.patch(url, moduleVersion)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const endModuleVersionBetaThunk = async (_, moduleVersion, thunkAPI) => {
  let url = `/mversion/${moduleVersion._id}/endbeta`
  try {
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const getChangeLogInfoThunk = async (_, moduleVersion, thunkAPI) => {
  let url = `/mversion/${moduleVersion._id}/changelogcomplete`
  try {
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const toggleActivityStatusThunk = async (_, id, thunkAPI) => {
  let url = `/activities/${id}/togglestatus`
  try {
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const createLearningScenarioForVersionThunk = async (
  _,
  learningScenario,
  thunkAPI
) => {
  let url = `/mversion/${learningScenario.moduleVersion}/newls`
  try {
    const resp = await customFetch.post(url, learningScenario)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const updateLearningScenarioThunk = async (
  _,
  learningScenario,
  thunkAPI
) => {
  let url = `/learningscenarios/${learningScenario._id}`
  try {
    const resp = await customFetch.patch(url, learningScenario)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const updateActivityLearningScenarioOrderThunk = async (
  _,
  changeOrder,
  thunkAPI
) => {
  let url = `/learningscenarios/${changeOrder.learningScenario}/order`
  try {
    const resp = await customFetch.patch(url, {
      activity: changeOrder.activity,
      order: changeOrder.order,
    })
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
