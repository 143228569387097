import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllModulesVersionThunk } from './allModulesVersionThunk'

const initialFiltersState = {
  search: '',
  sort: 'latest',
  sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],
}

const initialState = {
  isLoading: false,
  modulesVersion: [],
  totalModulesVersion: 0,
  numOfPages: 1,
  page: 1,
  error: '',
  // stats: {},
  // monthlyApplications: [],
  ...initialFiltersState,
}

export const getAllModulesVersion = createAsyncThunk(
  'modulesVersion/list',
  getAllModulesVersionThunk
)

// export const showStats = createAsyncThunk('allJobs/showStats', showStatsThunk)

const allModulesVersionSlice = createSlice({
  name: 'allModuleVersion',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true
    },
    hideLoading: (state) => {
      state.isLoading = false
    },
    handleChange: (state, { payload: { name, value } }) => {
      state.page = 1
      state[name] = value
    },
    clearFilters: (state) => {
      return { ...state, ...initialFiltersState }
    },
    changePage: (state, { payload }) => {
      state.page = payload
    },
    addModuleVersionToDataTable: (state, { payload }) => {
      state.modulesVersion.unshift(payload)
      state.totalModulesVersion++
    },
    editModuleVersionToDataTable: (state, { payload }) => {
      state.modulesVersion = state.modulesVersion.map((moduleVersion) => {
        if (moduleVersion._id === payload._id) {
          moduleVersion = { ...payload }
        }
        return moduleVersion
      })
    },
    removeModuleVersionToDataTable: (state, { payload }) => {
      state.moduleVersion = state.modulesVersion.filter((moduleVersion) => {
        return moduleVersion._id !== payload._id
      })
      state.totalModulesVersion--
    },
    clearAllModuleVersionState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllModulesVersion.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(getAllModulesVersion.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.modulesVersion = payload.modulesVersion
        state.numOfPages = payload.numOfPages
        state.totalModulesVersion = payload.totalModulesVersion
      })
      .addCase(getAllModulesVersion.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
  },
})

export const {
  showLoading,
  hideLoading,
  handleChange,
  clearFilters,
  changePage,
  clearAllModuleVersionStateState,
  addModuleVersionToDataTable,
  editModuleVersionToDataTable,
  removeModuleVersionToDataTable,
} = allModulesVersionSlice.actions

export default allModulesVersionSlice.reducer
