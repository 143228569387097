import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllResourcesThunk } from './allResourcesThunk'

const initialFiltersState = {
  search: '',
  folder: '',
  searchPublic: 'all',
  searchType: 'all',
  sort: 'latest',
  sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],
}

const initialState = {
  isLoading: false,
  resources: [],
  totalResources: 0,
  numOfPages: 1,
  page: 1,
  error: '',
  // stats: {},
  // monthlyApplications: [],
  ...initialFiltersState,
}

export const getAllResources = createAsyncThunk(
  'resources/list',
  getAllResourcesThunk
)

const allResourcesSlice = createSlice({
  name: 'allResources',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true
    },
    hideLoading: (state) => {
      state.isLoading = false
    },
    handleChange: (state, { payload: { name, value } }) => {
      state.page = 1
      state[name] = value
    },
    clearFilters: (state) => {
      return { ...state, ...initialFiltersState }
    },
    changePage: (state, { payload }) => {
      state.page = payload
    },
    addResourceToDataTable: (state, { payload }) => {
      state.resources.unshift(payload)
      state.totalResources++
    },
    editResourceToDataTable: (state, { payload }) => {
      state.resources = state.resources.map((resource) => {
        if (resource._id === payload._id) {
          resource = { ...payload }
        }
        return resource
      })
    },
    removeResourceToDataTable: (state, { payload }) => {
      state.resources = state.resources.filter((resource) => {
        return resource._id !== payload._id
      })
      state.totalResources--
    },
    clearAllResourcesState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllResources.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(getAllResources.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.resources = payload.resources
        state.numOfPages = payload.numOfPages
        state.totalResources = payload.totalResources
      })
      .addCase(getAllResources.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
  },
})

export const {
  showLoading,
  hideLoading,
  handleChange,
  clearFilters,
  changePage,
  clearAllResourcesState,
  addResourceToDataTable,
  editResourceToDataTable,
  removeResourceToDataTable,
} = allResourcesSlice.actions

export default allResourcesSlice.reducer
