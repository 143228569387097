import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const getHistoryRecordingInteractionThunk = async (_, id, thunkAPI) => {
  let url = `/recordingsinteraction/${id}/history`
  try {
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
