import customFetch, {
  customMultipartPost,
  checkForUnauthorizedResponse,
} from '../../utils/axios'

export const addResourceThunk = async (_, resource, thunkAPI) => {
  let url = `/resources`
  try {
    const resp = await customMultipartPost.post(url, resource)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
export const removeResourceThunk = async (_, resource, thunkAPI) => {
  let url = `/resources/${resource._id}`
  try {
    const resp = await customFetch.delete(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
export const editResourceThunk = async (_, resource, thunkAPI) => {
  let url = `/resources/${resource._id}`
  try {
    const resp = await customMultipartPost.post(url, resource)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const moveResourceThunk = async (_, resource, thunkAPI) => {
  let url = `/resources/${resource._id}/move`
  try {
    const resp = await customFetch.post(url, resource)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const copyResourceThunk = async (_, resource, thunkAPI) => {
  let url = `/resources/${resource._id}/copy`
  try {
    const resp = await customFetch.post(url, resource)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
