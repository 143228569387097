import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllInteractionsThunk } from './allInteractionsThunk'

const initialFiltersState = {
  search: '',
  sort: 'latest',
  searchType: 'all',
  sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],
}

const initialState = {
  isLoading: false,
  interactions: [],
  activity: null,
  totalInteractions: 0,
  numOfPages: 1,
  page: 1,
  error: '',
  ...initialFiltersState,
}

export const getAllInteractions = createAsyncThunk(
  'interactions/list',
  getAllInteractionsThunk
)

const allInteractionsSlice = createSlice({
  name: 'allInteractions',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true
    },
    hideLoading: (state) => {
      state.isLoading = false
    },
    setActivity: (state, { payload: id }) => {
      state.activity = id
    },
    handleChange: (state, { payload: { name, value } }) => {
      state.page = 1
      state[name] = value
    },
    clearFilters: (state) => {
      return { ...state, ...initialFiltersState }
    },
    changePage: (state, { payload }) => {
      state.page = payload
    },
    addInteractionsToDataTable: (state, { payload }) => {
      state.interactions.push(payload)
      state.totalInteractions++
    },
    editInteractionsToDataTable: (state, { payload }) => {
      state.interactions = state.interactions.map((interaction) => {
        if (interaction._id === payload._id) {
          interaction = { ...payload }
        }
        return interaction
      })
    },
    removeInteractionsToDataTable: (state, { payload }) => {
      state.interactions = state.interactions.filter((interaction) => {
        return interaction._id !== payload._id
      })
      state.totalInteractions--
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllInteractions.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(getAllInteractions.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.interactions = payload.interactions
        state.numOfPages = payload.numOfPages
        state.totalInteractions = payload.totalInteractions
      })
      .addCase(getAllInteractions.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
  },
})

export const {
  showLoading,
  hideLoading,
  handleChange,
  clearFilters,
  changePage,
  addInteractionsToDataTable,
  editInteractionsToDataTable,
  removeInteractionsToDataTable,
  setActivity,
} = allInteractionsSlice.actions

export default allInteractionsSlice.reducer
