import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const addLearningObjectiveThunk = async (
  _,
  learningObjective,
  thunkAPI
) => {
  let url = `/learningobjectives`
  try {
    const resp = await customFetch.post(url, learningObjective)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const removeLearningObjectiveThunk = async (
  _,
  learningObjective,
  thunkAPI
) => {
  let url = `/learningobjectives/${learningObjective._id}`
  try {
    const resp = await customFetch.delete(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const editLearningObjectiveThunk = async (
  _,
  learningObjective,
  thunkAPI
) => {
  let url = `/learningobjectives/${learningObjective._id}`
  try {
    const resp = await customFetch.patch(url, learningObjective)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
export const editTaxonomiesFromLearningObjectiveThunk = async (
  _,
  learningObjective,
  thunkAPI
) => {
  let url = `/learningobjectives/${learningObjective._id}/taxonomies`
  try {
    const resp = await customFetch.patch(url, learningObjective)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
