import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllLearningObjectivesThunk } from './allLearningObjectivesThunk'

const initialFiltersState = {
  sort: 'oldest',
  sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],
}

const initialState = {
  isLoading: false,
  learningObjectives: [],
  totalLearningObjectives: 0,
  error: '',
  // stats: {},
  // monthlyApplications: [],
  ...initialFiltersState,
}

export const getAllLearningObjective = createAsyncThunk(
  'learningObjectives/list',
  getAllLearningObjectivesThunk
)

// export const showStats = createAsyncThunk('allJobs/showStats', showStatsThunk)

const allLearningObjectivesSlice = createSlice({
  name: 'allLearningObjectives',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true
    },
    hideLoading: (state) => {
      state.isLoading = false
    },
    handleChange: (state, { payload: { name, value } }) => {
      state.page = 1
      state[name] = value
    },
    clearFilters: (state) => {
      return { ...state, ...initialFiltersState }
    },
    changePage: (state, { payload }) => {
      state.page = payload
    },
    addLearningObjectiveToDataTable: (state, { payload }) => {
      state.learningObjective.unshift(payload)
      state.totalLearningObjectives++
    },
    editLearningObjectiveToDataTable: (state, { payload }) => {
      state.learningObjectives = state.learningObjectives.map(
        (learningObjective) => {
          if (learningObjective._id === payload._id) {
            learningObjective = { ...payload }
          }
          return learningObjective
        }
      )
    },
    removeLearningObjectiveToDataTable: (state, { payload }) => {
      state.learningObjectives = state.learningObjectives.filter(
        (learningObjective) => {
          return learningObjective._id !== payload._id
        }
      )
      state.totalLearningObjectives--
    },
    clearAllLearningObjectiveState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllLearningObjective.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(getAllLearningObjective.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.learningObjectives = payload.learningObjectives
        state.totalLearningObjectives = payload.totalLearningObjectives
      })
      .addCase(getAllLearningObjective.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
  },
})

export const {
  showLoading,
  hideLoading,
  handleChange,
  clearFilters,
  changePage,
  clearAllLearningObjectiveState,
  addLearningObjectiveToDataTable,
  editLearningObjectiveToDataTable,
  removeLearningObjectiveToDataTable,
} = allLearningObjectivesSlice.actions

export default allLearningObjectivesSlice.reducer
