import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { addTagThunk, editTagsThunk, removeTagThunk } from './tagThunk'

export interface Tag {
  _id: string
  title: string
  used: number
}

export interface TagSelectorValue {
  value: string
  label: string
}

export interface TagForm {
  _id?: string
  title: string
}

export interface TagState {
  isLoading: boolean
  tag: Tag
  error: string
}

const initialState: TagState = {
  isLoading: false,
  tag: {} as Tag,
  error: '',
}

export const addTag = createAsyncThunk(
  'tag/new',
  async (tag: TagForm, thunkAPI) => {
    return addTagThunk('/tags', tag, thunkAPI)
  }
)

export const removeTag = createAsyncThunk(
  'tag/remove',
  async (tag: TagForm, thunkAPI) => {
    return removeTagThunk('/tags/:id', tag, thunkAPI)
  }
)

export const editTag = createAsyncThunk(
  'tag/edit',
  async (tag: TagForm, thunkAPI) => {
    return editTagsThunk('/tags/:id', tag, thunkAPI)
  }
)

const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    clearTagForm: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addTag.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(addTag.fulfilled, (state, { payload }) => {
        const { tag } = payload
        state.tag = tag
        state.isLoading = false
      })
      .addCase(addTag.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addCase(editTag.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(editTag.fulfilled, (state, { payload }) => {
        state.isLoading = false
        const { tag } = payload
        state.tag = tag
      })
      .addCase(editTag.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addCase(removeTag.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(removeTag.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(removeTag.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
  },
})

export const { clearTagForm } = tagSlice.actions

export default tagSlice.reducer
