/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb, Button, Checkbox, Modal, Table } from 'flowbite-react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import {
  HiArrowRight,
  HiHome,
  HiOutlineExclamationCircle,
  HiPencilAlt,
  HiTrash,
} from 'react-icons/hi'
import { DataTableController, Loading, Pagination } from '../../components'
import { useAppDispatch, useTypedSelector } from '../../store'
import { useToast } from '../../hooks/useToast'
import {
  addSkillToDataTable,
  editSkillToDataTable,
  getAllSkills,
  removeSkillToDataTable,
  handleChange,
  changePage,
} from '../../features/allSkills/allSkillsSlice'
import {
  addSkill,
  clearSkillForm,
  editSkill,
  removeSkill,
  Skill,
  SkillForm,
} from '../../features/skill/skillSlice'
import SkillModalBody from '../../components/modal-body/Skill'
import { useNavigate } from 'react-router-dom'

const SkillsListPage: FC = function () {
  const { error, page, totalSkills, numOfPages, search, sort } =
    useTypedSelector((store) => store.allSkills)
  const { error: errorSkill } = useTypedSelector((store) => store.skill)
  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
    }
    if (errorSkill !== '') {
      toast('error', errorSkill)
      dispatch(clearSkillForm())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, errorSkill])

  useEffect(() => {
    dispatch(getAllSkills({}))
    //clear form
    dispatch(clearSkillForm())
  }, [dispatch, page, search, sort])
  return (
    <>
      <div className='block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex'>
        <div className='mb-1 w-full'>
          <div className='mb-4'>
            <Breadcrumb className='mb-4'>
              <Breadcrumb.Item href='#'>
                <div className='flex items-center gap-x-3'>
                  <HiHome className='text-xl' />
                  <span className='dark:text-white'>Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href='/skills'>Compétences</Breadcrumb.Item>
              <Breadcrumb.Item>Liste</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className='text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl'>
              Toutes les compétences
            </h1>
          </div>
          <div className='block items-center sm:flex'>
            <DataTableController
              placeholder='rechercher une compétence'
              handleChange={handleChange}
              sort={sort}
              search={search}
              initFilters={null}
            ></DataTableController>
            <div className='flex w-full items-center sm:justify-end'>
              <AddSkillModal />
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='overflow-x-auto'>
          <div className='inline-block min-w-full align-middle'>
            <div className='overflow-hidden shadow'>
              <SkillsTable />
            </div>
          </div>
        </div>
      </div>
      <Pagination
        numOfPages={numOfPages}
        total={totalSkills}
        page={page}
        changePage={changePage}
      />
    </>
  )
}

const AddSkillModal: FC = function () {
  const initialValues: SkillForm = {
    name: '',
    color: '#000000',
    description: '',
  }

  const [isOpen, setOpen] = useState(false)
  const [values, setValues] = useState<SkillForm>(initialValues)
  const dispatch = useAppDispatch()
  const { isLoading, skill } = useTypedSelector((store) => store.skill)
  const toast = useToast()

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const handleColorChange = (name: string, value: string) => {
    setValues({ ...values, [name]: value })
  }

  const handleTextAreaChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { name, description, color } = values
    if (name !== '' && description !== '' && color !== '') {
      dispatch(addSkill(values))
    } else {
      toast('error', `Vous avez besoin de renseigner les elements requis`)
    }
  }

  useEffect(() => {
    if (isOpen && skill && skill._id) {
      //clean
      setValues({
        name: '',
        description: '',
      })
      toast('success', `La compétence ${skill.name} a bien été créée`)
      //refresh list
      dispatch(addSkillToDataTable(skill))
      //clear form
      dispatch(clearSkillForm())
      //close
      setOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, skill, isLoading])

  return (
    <>
      <Button color='primary' onClick={() => setOpen(!isOpen)}>
        <FaPlus className='mr-3 text-sm' />
        Ajouter une compétence
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Ajouter compétence</strong>
          </Modal.Header>
          <SkillModalBody
            values={values}
            handleChange={handleChange}
            handleTextAreaChange={handleTextAreaChange}
            handleColorChange={handleColorChange}
          />
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Ajouter
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

const EditSkillModal: FC<{ skillSelected: Skill }> = function ({
  skillSelected,
}) {
  const initialValues: SkillForm = {
    _id: skillSelected._id,
    name: skillSelected.name,
    description: skillSelected.description,
    color: skillSelected.color,
  }

  const [isOpen, setOpen] = useState(false)
  const [values, setValues] = useState<SkillForm>(initialValues)
  const dispatch = useAppDispatch()
  const { isLoading, skill } = useTypedSelector((store) => store.skill)
  const toast = useToast()

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const handleColorChange = (name: string, value: string) => {
    setValues({ ...values, [name]: value })
  }

  const handleTextAreaChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { name, description, color, _id } = values
    if (name !== '' && description !== '' && _id !== '' && color !== '') {
      dispatch(editSkill(values))
    } else {
      toast('error', `Vous avez besoin de renseigner les elements requis`)
    }
  }

  useEffect(() => {
    if (isOpen && skill && skill._id) {
      toast('success', `La compétence ${skill.name} a bien été éditée`)
      //refresh list
      dispatch(editSkillToDataTable(skill))
      //clear form
      dispatch(clearSkillForm())
      //close
      setOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, skill, isLoading])

  return (
    <>
      <Button color='primary' onClick={() => setOpen(!isOpen)}>
        <HiPencilAlt className='mr-2 text-lg' />
        <span className='hidden lg:block '>Editer la compétence</span>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Editer compétence {skillSelected.name}</strong>
          </Modal.Header>
          <SkillModalBody
            values={values}
            handleChange={handleChange}
            handleTextAreaChange={handleTextAreaChange}
            handleColorChange={handleColorChange}
          />
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Editer
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

const DeleteSkillModal: FC<{ skillSelected: Skill }> = function ({
  skillSelected,
}) {
  const [isOpen, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const toast = useToast()
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    toast('success', `La compétence ${skillSelected.name} a bien été supprimée`)
    dispatch(removeSkill(skillSelected))
    dispatch(removeSkillToDataTable(skillSelected))
    setOpen(false)
  }
  return (
    <>
      <Button color='failure' onClick={() => setOpen(!isOpen)}>
        <HiTrash className='mr-2 text-lg' />
        <span className='hidden lg:block '>Supprimer la compétence</span>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen} size='md'>
        <Modal.Header className='px-3 pb-0 pt-3'>
          <span className='sr-only'>Supprimer la compétence</span>
        </Modal.Header>
        <Modal.Body className='px-6 pb-6 pt-0'>
          <div className='flex flex-col items-center gap-y-6 text-center'>
            <HiOutlineExclamationCircle className='text-7xl text-red-600' />
            <p className='text-lg text-gray-500 dark:text-gray-300'>
              Êtes vous sur de vouloir détruire la compétence{' '}
              {skillSelected.name}?
            </p>
            <div className='flex items-center gap-x-3'>
              <Button color='failure' onClick={handleSubmit}>
                Oui, je suis sur
              </Button>
              <Button color='gray' onClick={() => setOpen(false)}>
                Non, annuler
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const SkillsTable: FC = function () {
  const { skills, isLoading } = useTypedSelector((store) => store.allSkills)
  const castSkills = skills as Skill[]
  const navigate = useNavigate()
  if (isLoading) {
    return <Loading center />
  }
  if (skills.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>Pas de compétences à afficher...</span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>
          <span className='sr-only'>Selectionner tous</span>
          <Checkbox />
        </Table.HeadCell>
        <Table.HeadCell>Nom</Table.HeadCell>
        <Table.HeadCell>Description</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {castSkills.map((skill) => {
          return (
            <Table.Row
              key={skill._id}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='w-4 p-4'>
                <Checkbox />
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {skill.name}
                </div>
                {skill.taxonomiesCount ? (
                  <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                    Nombre de taxonomies : {skill.taxonomiesCount}
                  </div>
                ) : (
                  <>
                    <div
                      className='text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-400'
                      role='alert'
                    >
                      <span className='font-medium'>Pas de taxonomie</span>
                    </div>
                  </>
                )}
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white max-w-[200px] overflow-hidden text-ellipsis'>
                {skill.description}
              </Table.Cell>
              <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                <div className='flex items-center gap-x-3'>
                  <Button
                    color='purple'
                    disabled={
                      !skill.taxonomiesCount || skill.taxonomiesCount === 0
                    }
                    onClick={() => navigate(`./${skill._id}`)}
                  >
                    <HiArrowRight className='mr-2 text-lg' />
                    <span className='hidden lg:block '>
                      Impact des objectifs pédagogiques
                    </span>
                  </Button>
                  <EditSkillModal skillSelected={skill} />
                  <DeleteSkillModal skillSelected={skill} />
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default SkillsListPage
