import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useAppDispatch, useTypedSelector } from '../../store'
import { useToast } from '../../hooks/useToast'
import {
  addResourceToDataTable,
  changePage,
  clearFilters,
  editResourceToDataTable,
  getAllResources,
  handleChange,
  removeResourceToDataTable,
} from '../../features/allResources/allResourcesSlice'
import {
  Badge,
  Breadcrumb,
  Button,
  Checkbox,
  Kbd,
  Label,
  Modal,
  Table,
  TextInput,
} from 'flowbite-react'
import {
  HiArrowRight,
  HiHome,
  HiOutlineExclamationCircle,
  HiPencilAlt,
  HiTrash,
  HiUpload,
} from 'react-icons/hi'
import {
  DataTableController,
  Loading,
  Pagination,
  ThumbnailResource,
} from '../../components'
import {
  Resource,
  ResourceEditForm,
  ResourceForm,
  ResourceMoveCopyForm,
  addResource,
  clearResourceForm,
  copyResource,
  editResource,
  moveResource,
  removeResource,
} from '../../features/resource/resourceSlice'
import moment from 'moment'
import { FaPlus } from 'react-icons/fa'
import { GiConfirmed, GiMove } from 'react-icons/gi'
import { MdCopyAll } from 'react-icons/md'
import { ImImage } from 'react-icons/im'

const MediasListPage: FC = function () {
  const {
    error,
    page,
    totalResources,
    numOfPages,
    search,
    sort,
    searchType,
    searchPublic,
  } = useTypedSelector((store) => store.allResources)

  const { error: errorResource } = useTypedSelector((store) => store.resource)

  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
    }
    if (errorResource !== '') {
      toast('error', errorResource)
      dispatch(clearResourceForm())
    }
    // eslint-disable-next-line
  }, [error, errorResource])

  // useEffect(() => {
  //   //clear form
  //   dispatch(clearFilters())
  // }, [dispatch])

  useEffect(() => {
    //clear form
    // dispatch(clearResourceForm())
    dispatch(getAllResources({}))
  }, [dispatch, page, search, searchPublic, sort, searchType])

  return (
    <>
      <div className='block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex'>
        <div className='mb-1 w-full'>
          <div className='mb-4'>
            <Breadcrumb className='mb-4'>
              <Breadcrumb.Item href='#'>
                <div className='flex items-center gap-x-3'>
                  <HiHome className='text-xl' />
                  <span className='dark:text-white'>Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href='/modules'>Medias</Breadcrumb.Item>
              <Breadcrumb.Item>Liste</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className='text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl'>
              Tous les médias
            </h1>
          </div>
          <div className='block items-center sm:flex'>
            <DataTableController
              placeholder='rechercher un media'
              sort={sort}
              search={search}
              // @ts-ignore
              clean={clearFilters}
              handleChange={handleChange}
              initFilters={[
                {
                  name: 'searchType',
                  value: searchType,
                  control: 'image',
                  text: 'Images uniquement',
                },
                {
                  name: 'searchPublic',
                  value: searchPublic,
                  control: 'true',
                  text: 'Public uniquement',
                },
              ]}
            ></DataTableController>
            <div className='flex w-full items-center sm:justify-end'>
              <AddMediaModal />
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='overflow-x-auto'>
          <div className='inline-block min-w-full align-middle'>
            <div className='overflow-hidden shadow'>
              <MediasTable />
            </div>
          </div>
        </div>
      </div>
      <Pagination
        numOfPages={numOfPages}
        total={totalResources}
        page={page}
        changePage={changePage}
      />
    </>
  )
}

const MediasTable: FC = function () {
  const { resources, isLoading } = useTypedSelector(
    (store) => store.allResources
  )
  const castResources = resources as Resource[]

  // const navigate = useNavigate()
  if (isLoading) {
    return <Loading center />
  }
  if (resources.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>Pas de médias à afficher...</span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>
          <span className='sr-only'>Selectionner tous</span>
          <Checkbox />
        </Table.HeadCell>
        <Table.HeadCell>Root</Table.HeadCell>
        <Table.HeadCell>Miniature/Lien</Table.HeadCell>
        <Table.HeadCell>Infos</Table.HeadCell>
        <Table.HeadCell>Dossiers</Table.HeadCell>
        <Table.HeadCell>Dernière modification</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {castResources.map((resource, index) => {
          return (
            <Table.Row
              key={resource._id}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='w-4 p-4'>
                <Checkbox />
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white'>
                <div className='flex items-center'>
                  {resource.public ? (
                    <Badge color='gray'>
                      {/* <HiStatusOffline className='mr-2 text-lg' /> */}
                      <span>Public</span>
                    </Badge>
                  ) : (
                    <Badge color='primary'>
                      {/* <HiStatusOnline className='mr-2 text-lg' /> */}
                      <span>Privé</span>
                    </Badge>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400'>
                <ThumbnailResource resource={resource}></ThumbnailResource>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {resource.title}
                </div>
                <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                  {resource.type}
                </div>
                <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                  {resource.size} octets
                </div>
                <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                  utilisé: {resource.used}
                </div>
              </Table.Cell>
              <Table.Cell className='max-w-[200px] p-4 text-sm font-normal text-gray-500 dark:text-gray-400'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {resource.folder.split('/').map((f, index) => {
                    return (
                      <div key={`${resource._id}_${index}`}>
                        <span className='break-words leading-8'>/</span>
                        <Kbd>{f}</Kbd>
                      </div>
                    )
                  })}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                {`${moment(resource.updatedAt).fromNow()}`}
              </Table.Cell>
              <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                <div className='grid grid-cols-2 gap-3'>
                  <EditMediaModal resourceSelected={resource} />
                  <MoveMediaModal resourceSelected={resource} />
                  <CopyMediaModal resourceSelected={resource} />
                  <DeleteMediaModal resourceSelected={resource} />
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

const AddMediaModal: FC = function () {
  const initialValues: ResourceForm = {
    file: null,
    title: '',
    public: true,
    folder: '',
  }

  const [isOpen, setOpen] = useState(false)
  const [values, setValues] = useState<ResourceForm>(initialValues)
  const dispatch = useAppDispatch()
  const { isLoading, resource } = useTypedSelector((store) => store.resource)
  const toast = useToast()

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.checked
    setValues({ ...values, [name]: value })
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = (e.target as HTMLInputElement).files
    if (files) {
      setValues({ ...values, file: files[0] })
    }
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { file, title, folder } = values
    if (file !== null && title !== '' && folder !== '') {
      dispatch(addResource(values))
    } else {
      toast('error', `Vous avez besoin de renseigner les elements requis`)
    }
  }

  useEffect(() => {
    if (isOpen && resource && resource._id) {
      //clean
      setValues({
        file: null,
        title: '',
        public: true,
        folder: '',
      })
      toast('success', `Le média ${resource.title} a bien été créé`)
      //refresh list
      dispatch(addResourceToDataTable(resource))
      //clear form
      dispatch(clearResourceForm())
      //close
      setOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, resource, isLoading])

  return (
    <>
      <Button color='primary' onClick={() => setOpen(!isOpen)}>
        <FaPlus className='mr-3 text-sm' />
        Ajouter un média
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Ajouter média</strong>
          </Modal.Header>
          <Modal.Body>
            <div className='grid grid-cols-1 gap-6 lg:grid-cols-2'>
              <div>
                <Label htmlFor='title'>Nom du média</Label>
                <TextInput
                  id='title'
                  name='title'
                  placeholder={`Logo de l'entreprise A`}
                  className='mt-1'
                  type='text'
                  required={true}
                  value={values.title}
                  onChange={handleChange}
                />
              </div>
              <div>
                <Label htmlFor='folder'>Dossier de la resource</Label>
                <TextInput
                  id='folder'
                  name='folder'
                  placeholder={`test`}
                  className='mt-1'
                  type='text'
                  required={true}
                  value={values.folder}
                  onChange={handleChange}
                />
              </div>
              <div className='flex items-center gap-2'>
                <Checkbox
                  id='public'
                  name='public'
                  checked={values.public}
                  onChange={handleCheckboxChange}
                />
                <Label htmlFor='public'>
                  Votre resource est-elle publique ?
                </Label>
              </div>

              <div className='lg:col-span-2'>
                <div className='flex w-full items-center justify-center'>
                  <label className='flex h-32 w-full cursor-pointer flex-col rounded border-2 border-dashed border-gray-300 hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-700'>
                    <div className='flex flex-col items-center justify-center pb-6 pt-5'>
                      <HiUpload className='text-4xl text-gray-300' />
                      {values.file ? (
                        <>
                          <p className='py-1 text-sm text-gray-600 dark:text-gray-500'>
                            {values.file.name}
                          </p>
                          <GiConfirmed className='text-4xl text-green-600'></GiConfirmed>
                        </>
                      ) : (
                        <p className='py-1 text-sm text-gray-600 dark:text-gray-500'>
                          Thumbnail a uploader
                        </p>
                      )}
                    </div>
                    <input
                      type='file'
                      className='hidden'
                      onChange={handleFileChange}
                    />
                  </label>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Ajouter
            </Button>
            {isLoading && <Loading center />}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

const EditMediaModal: FC<{ resourceSelected: Resource }> = function ({
  resourceSelected,
}) {
  const initialValues: ResourceEditForm = {
    _id: resourceSelected._id,
    file: null,
    title: resourceSelected.title,
  }

  const [isOpen, setOpen] = useState(false)
  const [values, setValues] = useState<ResourceEditForm>(initialValues)
  const dispatch = useAppDispatch()
  const { isLoading, resource } = useTypedSelector((store) => store.resource)
  const toast = useToast()

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = (e.target as HTMLInputElement).files
    if (files) {
      setValues({ ...values, file: files[0] })
    }
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { title } = values
    if (title !== '') {
      dispatch(editResource(values))
    } else {
      toast('error', `Vous avez besoin de renseigner les elements requis`)
    }
  }

  useEffect(() => {
    if (isOpen && resource && resource._id) {
      toast('success', `Le ${resource.title} a bien été édité`)
      //refresh list
      dispatch(editResourceToDataTable(resource))
      //clear form
      dispatch(clearResourceForm())
      //close
      setOpen(false)
    }
  }, [toast, dispatch, isOpen, resource, isLoading])

  return (
    <>
      <Button color='primary' onClick={() => setOpen(!isOpen)}>
        <HiPencilAlt className='mr-2 text-lg' />
        <span className='hidden xl:block '>Editer le media</span>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Editer media {resourceSelected.title}</strong>
          </Modal.Header>
          <Modal.Body>
            <div className='grid grid-cols-1 gap-6 lg:grid-cols-2'>
              <div>
                <Label htmlFor='title'>Nom du média</Label>
                <TextInput
                  id='title'
                  name='title'
                  placeholder={`Logo de l'entreprise A`}
                  className='mt-1'
                  type='text'
                  required={true}
                  value={values.title}
                  onChange={handleChange}
                />
              </div>
              {!values.file && (
                <div className='lg:col-span-2'>
                  <ThumbnailResource
                    resource={resourceSelected}
                  ></ThumbnailResource>
                </div>
              )}

              <div className='lg:col-span-2'>
                <div className='flex w-full items-center justify-center'>
                  <label className='flex h-32 w-full cursor-pointer flex-col rounded border-2 border-dashed border-gray-300 hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-700'>
                    <div className='flex flex-col items-center justify-center pb-6 pt-5'>
                      <HiUpload className='text-4xl text-gray-300' />
                      {values.file ? (
                        <>
                          <p className='py-1 text-sm text-gray-600 dark:text-gray-500'>
                            {values.file.name}
                          </p>
                          <GiConfirmed className='text-4xl text-green-600'></GiConfirmed>
                        </>
                      ) : (
                        <p className='py-1 text-sm text-gray-600 dark:text-gray-500'>
                          Nouveau Thumbnail a uploader
                        </p>
                      )}
                    </div>
                    <input
                      type='file'
                      className='hidden'
                      onChange={handleFileChange}
                    />
                  </label>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Editer
            </Button>
            {isLoading && <Loading center />}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

const MoveMediaModal: FC<{ resourceSelected: Resource }> = function ({
  resourceSelected,
}) {
  const initialValues: ResourceMoveCopyForm = {
    _id: resourceSelected._id,
    public: resourceSelected.public,
    folder: resourceSelected.folder,
  }

  const [isOpen, setOpen] = useState(false)
  const [values, setValues] = useState<ResourceMoveCopyForm>(initialValues)
  const dispatch = useAppDispatch()
  const { isLoading, resource } = useTypedSelector((store) => store.resource)
  const toast = useToast()

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.checked
    setValues({ ...values, [name]: value })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { folder } = values
    if (folder !== '') {
      dispatch(moveResource(values))
    } else {
      toast('error', `Vous avez besoin de renseigner les elements requis`)
    }
  }

  useEffect(() => {
    if (isOpen && resource && resource._id) {
      toast('success', `Le ${resource.title} a bien été déplacé`)
      //refresh list
      dispatch(editResourceToDataTable(resource))
      //clear form
      dispatch(clearResourceForm())
      //close
      setOpen(false)
    }
  }, [toast, dispatch, isOpen, resource, isLoading])

  return (
    <>
      <Button color='primary' onClick={() => setOpen(!isOpen)}>
        <GiMove className='mr-2 text-lg' />
        <span className='hidden xl:block '>Déplacer le media</span>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Déplacer media {resourceSelected.title}</strong>
          </Modal.Header>
          <Modal.Body>
            <div className='grid grid-cols-1 gap-6 lg:grid-cols-2'>
              <div>
                <Label htmlFor='folder'>Dossier de la resource</Label>
                <TextInput
                  id='folder'
                  name='folder'
                  placeholder={`test`}
                  className='mt-1'
                  type='text'
                  required={true}
                  value={values.folder}
                  onChange={handleChange}
                />
              </div>
              <div className='flex items-center gap-2'>
                <Checkbox
                  id='public'
                  name='public'
                  checked={values.public}
                  onChange={handleCheckboxChange}
                />
                <Label htmlFor='public'>
                  Votre resource est-elle publique ?
                </Label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Déplacer
            </Button>
            {isLoading && <Loading center />}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

const CopyMediaModal: FC<{ resourceSelected: Resource }> = function ({
  resourceSelected,
}) {
  const initialValues: ResourceMoveCopyForm = {
    _id: resourceSelected._id,
    public: resourceSelected.public,
    folder: resourceSelected.folder,
  }

  const [isOpen, setOpen] = useState(false)
  const [values, setValues] = useState<ResourceMoveCopyForm>(initialValues)
  const dispatch = useAppDispatch()
  const { isLoading, resource } = useTypedSelector((store) => store.resource)
  const toast = useToast()

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    const value = e.target.checked
    setValues({ ...values, [name]: value })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { folder } = values
    if (folder !== '') {
      dispatch(copyResource(values))
    } else {
      toast('error', `Vous avez besoin de renseigner les elements requis`)
    }
  }

  useEffect(() => {
    if (isOpen && resource && resource._id) {
      toast('success', `Le ${resource.title} a bien été déplacé`)
      //refresh list
      dispatch(addResourceToDataTable(resource))
      //clear form
      dispatch(clearResourceForm())
      //close
      setOpen(false)
    }
  }, [toast, dispatch, isOpen, resource, isLoading])

  return (
    <>
      <Button color='primary' onClick={() => setOpen(!isOpen)}>
        <MdCopyAll className='mr-2 text-lg' />
        <span className='hidden xl:block '>Copier le media</span>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Copier media {resourceSelected.title}</strong>
          </Modal.Header>
          <Modal.Body>
            <div className='grid grid-cols-1 gap-6 lg:grid-cols-2'>
              <div>
                <Label htmlFor='folder'>Dossier de la resource</Label>
                <TextInput
                  id='folder'
                  name='folder'
                  placeholder={`test`}
                  className='mt-1'
                  type='text'
                  required={true}
                  value={values.folder}
                  onChange={handleChange}
                />
              </div>
              <div className='flex items-center gap-2'>
                <Checkbox
                  id='public'
                  name='public'
                  checked={values.public}
                  onChange={handleCheckboxChange}
                />
                <Label htmlFor='public'>
                  Votre resource est-elle publique ?
                </Label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Copier
            </Button>
            {isLoading && <Loading center />}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

const DeleteMediaModal: FC<{ resourceSelected: Resource }> = function ({
  resourceSelected,
}) {
  const [isOpen, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const toast = useToast()
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    toast('success', `Le média ${resourceSelected.title} a bien été supprimé`)
    dispatch(removeResource(resourceSelected))
    dispatch(removeResourceToDataTable(resourceSelected))
    setOpen(false)
  }
  return (
    <>
      <Button color='failure' onClick={() => setOpen(!isOpen)}>
        <HiTrash className='mr-2 text-lg' />
        <span className='hidden xl:block '>Supprimer le média</span>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen} size='md'>
        <Modal.Header className='px-3 pb-0 pt-3'>
          <span className='sr-only'>Supprimer média</span>
        </Modal.Header>
        <Modal.Body className='px-6 pb-6 pt-0'>
          <div className='flex flex-col items-center gap-y-6 text-center'>
            <HiOutlineExclamationCircle className='text-7xl text-red-600' />
            <p className='text-lg text-gray-500 dark:text-gray-300'>
              Êtes vous sur de vouloir détruire le média?
            </p>
            <div className='flex items-center gap-x-3'>
              <Button color='failure' onClick={handleSubmit}>
                Oui, je suis sur
              </Button>
              <Button color='gray' onClick={() => setOpen(false)}>
                Non, annuler
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default MediasListPage

// use this modal to choose media on simplified table
export const SelectMediaModal: FC<{
  setImageSelected: Dispatch<SetStateAction<Resource | null>>
}> = function ({ setImageSelected }) {
  const { resources, isLoading } = useTypedSelector(
    (store) => store.allResources
  )
  const {
    error,
    page,
    totalResources,
    numOfPages,
    search,
    sort,
    searchPublic,
    searchType,
  } = useTypedSelector((store) => store.allResources)

  const [isOpen, setOpen] = useState(false)

  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    if (isOpen) {
      //force select only images
      dispatch(getAllResources({}))
    }
  }, [dispatch, page, search, searchPublic, sort, searchType, isOpen])

  const castResources = resources as Resource[]

  if (isLoading) {
    return <Loading center />
  }

  return (
    <>
      <Button
        color='primary'
        className='w-full'
        onClick={() => setOpen(!isOpen)}
      >
        <ImImage className='mr-3 text-sm' />
        {/* Selectionner un média */}
      </Button>
      <Modal
        onClose={() => setOpen(false)}
        show={isOpen}
        size='3xl'
        className='max-h-screen'
      >
        <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
          <strong>Selectionner média</strong>
        </Modal.Header>
        <Modal.Body>
          <div className='block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex'>
            <div className='mb-1 w-full'>
              <div className='block items-center sm:flex'>
                <DataTableController
                  placeholder='rechercher un média'
                  handleChange={handleChange}
                  sort={sort}
                  search={search}
                  initFilters={[
                    {
                      name: 'searchType',
                      value: searchType,
                      control: 'image',
                      text: 'Images uniquement',
                      locked: true,
                    },
                    {
                      name: 'searchPublic',
                      value: searchPublic,
                      control: 'true',
                      text: 'Public uniquement',
                    },
                  ]}
                ></DataTableController>
                <div className='flex items-center sm:justify-end'>
                  <AddMediaModal />
                </div>
              </div>
            </div>
          </div>

          <div className='flex flex-col'>
            <div className='overflow-x-auto'>
              <div className='inline-block min-w-full align-middle'>
                <div className='overflow-hidden shadow'>
                  <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
                    <Table.Head className='bg-gray-100 dark:bg-gray-700'>
                      <Table.HeadCell>Root</Table.HeadCell>
                      <Table.HeadCell>Miniature/Lien</Table.HeadCell>
                      <Table.HeadCell>Infos</Table.HeadCell>
                      <Table.HeadCell>Actions</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
                      {castResources.map((resource) => {
                        return (
                          <Table.Row
                            key={resource._id}
                            className='hover:bg-gray-100 dark:hover:bg-gray-700'
                          >
                            <Table.Cell className='whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white'>
                              <div className='flex items-center'>
                                {resource.public ? (
                                  <Badge color='gray'>
                                    {/* <HiStatusOffline className='mr-2 text-lg' /> */}
                                    <span>Public</span>
                                  </Badge>
                                ) : (
                                  <Badge color='primary'>
                                    {/* <HiStatusOnline className='mr-2 text-lg' /> */}
                                    <span>Privé</span>
                                  </Badge>
                                )}
                              </div>
                            </Table.Cell>
                            <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400'>
                              <ThumbnailResource
                                resource={resource}
                              ></ThumbnailResource>
                            </Table.Cell>
                            <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal max-w-[200px] overflow-hidden text-ellipsis'>
                              <div className='text-base font-semibold text-gray-900 dark:text-white'>
                                {resource.title}
                              </div>
                              <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                                {resource.type}
                              </div>
                              <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                                {resource.size} octets
                              </div>
                              <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                                utilisé: {resource.used}
                              </div>
                            </Table.Cell>
                            <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                              <div className='grid grid-cols-2 gap-3'>
                                <Button
                                  color='purple'
                                  onClick={() => {
                                    setImageSelected(resource)
                                    setOpen(!isOpen)
                                  }}
                                >
                                  <HiArrowRight className='mr-2 text-lg' />
                                  Selectionner
                                </Button>
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        )
                      })}
                    </Table.Body>
                  </Table>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            numOfPages={numOfPages}
            total={totalResources}
            page={page}
            changePage={changePage}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
