import {
  Button,
  Checkbox,
  Dropdown,
  Label,
  Select,
  TextInput,
} from 'flowbite-react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { FaLock } from 'react-icons/fa'
import { MdCleaningServices } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { nanoid } from '@reduxjs/toolkit'

const DataTableController = ({
  placeholder,
  handleChange,
  sort = 'latest',
  sortOptions = ['latest', 'oldest', 'a-z', 'z-a'],
  search = '',
  searchAttribute = 'search',
  clean = null,
  selectOptions = [],
  initFilters,
}) => {
  const [localSearch, setLocalSearch] = useState(search)
  const [numFiltersActive, setNumFiltersActive] = useState(0)
  const [filters, setFilters] = useState(initFilters)
  const checkBoxRef = useRef([])
  const componentID = useMemo(() => nanoid(), [])

  const labelSort = {
    latest: 'Mis à jour récente',
    oldest: 'Mis à jour ancienne',
    'a-z': 'Titre Alphabétique',
    'z-a': 'Titre Anti-Alphabétique',
    finished: 'Terminé en dernier',
    firstFinished: 'Terminé en premier',
    category: 'Catégorie',
  }

  const dispatch = useDispatch()

  const debounce = () => {
    let timeoutID
    return (e) => {
      setLocalSearch(e.target.value)
      clearTimeout(timeoutID)
      if (handleChange) {
        timeoutID = setTimeout(() => {
          dispatch(handleChange({ name: e.target.name, value: e.target.value }))
        }, 1000)
      }
    }
  }

  const handleClean = () => {
    // TODO clean to defaut values
    if (clean) {
      dispatch(clean())
      setFilters(
        filters.map((filter) => {
          filter.value = 'all'
          return filter
        })
      )
    }
  }

  const handleSelectChange = (e) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    dispatch(handleChange({ name: name, value: value }))
  }

  const handleCheckboxChange = (index) => {
    //get checkbox child target if exist

    if (checkBoxRef.current && checkBoxRef.current[index]) {
      const name = checkBoxRef.current[index].name
      const checked = checkBoxRef.current[index].checked
      const value = checkBoxRef.current[index].value
      if (checked) {
        dispatch(handleChange({ name: name, value: value }))
        const newFilters = filters.map((filter) => {
          if (filter.name === name) {
            filter.value = value
          }
          return filter
        })
        setFilters(newFilters)
      } else {
        dispatch(handleChange({ name: name, value: 'all' }))
        const newFilters = filters.map((filter) => {
          if (filter.name === name) {
            filter.value = 'all'
          }
          return filter
        })
        setFilters(newFilters)
      }
    }
  }

  const optimizedDebounce = useMemo(() => {
    return debounce() // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (filters) {
      var countFilter = 0
      filters.map((filter) => {
        if (filter.value === filter.control) {
          countFilter++
        }
        return null
      })
      setNumFiltersActive(countFilter)
    }
  }, [filters])

  // apply lock value once
  useEffect(() => {
    if (filters) {
      filters.map((filter) => {
        if (filter.locked) {
          filter.value = filter.control
          dispatch(handleChange({ name: filter.name, value: filter.value }))
        }
        return null
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='mb-3 w-full flex items-center dark:divide-gray-700 sm:mb-0 sm:divide-x sm:divide-gray-100'>
      <form className='grid grid-cols-2 gap-6 lg:grid-cols-4 2xl:grid-cols-6'>
        {searchAttribute !== '' && (
          <div className='mb-1 grid grid-cols-1 gap-y-2'>
            <Label htmlFor={`search-${componentID}`}>Rechercher</Label>
            <div>
              <TextInput
                id={`search-${componentID}`}
                name={searchAttribute}
                placeholder={placeholder}
                onChange={optimizedDebounce}
                value={localSearch}
              />
            </div>
          </div>
        )}
        {selectOptions.map((select) => {
          return select
        })}

        <div className='mb-1 grid grid-cols-1 gap-y-2'>
          <Label htmlFor={`sort-table-${componentID}`}>Trier par</Label>
          <Select
            id={`sort-table-${componentID}`}
            name='sort'
            defaultValue={sort}
            onChange={(e) => handleSelectChange(e)}
          >
            {sortOptions.map((sortOption) => {
              const key = `sort-option-${sortOption}`
              return (
                <option key={key} value={sortOption}>
                  {labelSort[sortOption] ? labelSort[sortOption] : sortOption}
                </option>
              )
            })}
            {/* <option key={`sort-option-latest`} value={`latest`}>
              Plus recent
            </option>
            <option key={`sort-option-oldest`} value={`oldest`}>
              Plus ancien
            </option>
            <option key={`sort-option-alpha`} value={`a-z`}>
              Alphabétique
            </option>
            <option key={`sort-option-antialpha`} value={`z-a`}>
              Anti-Alphabétique
            </option> */}
          </Select>
        </div>
        {filters && (
          <div className='mb-1 grid grid-cols-1 gap-y-2'>
            <Label>Filtrer par</Label>
            <Dropdown
              label={`Filtres (${numFiltersActive})`}
              dismissOnClick={false}
              className='w-auto'
              color={'primary'}
            >
              {filters.map((filter, i) => (
                <Dropdown.Item
                  key={`Dropdown_${i}_${filter.name}`}
                  onClick={() => handleCheckboxChange(i)}
                  className={
                    !filter.locked
                      ? 'cursor-pointer'
                      : 'cursor-default pointer-events-none'
                  }
                >
                  {filter.locked ? (
                    <FaLock className='mr-1'></FaLock>
                  ) : (
                    <Checkbox
                      id={`${filter.name}_CheckBox-${componentID}-${i}`}
                      name={filter.name}
                      value={filter.control}
                      checked={filter.value === filter.control}
                      readOnly
                      className={'mr-1 cursor-pointer'}
                      ref={(el) => (checkBoxRef.current[i] = el)}
                    />
                  )}
                  <Label
                    htmlFor={`${filter.name}_CheckBox-${componentID}-${i}`}
                    className={
                      !filter.locked ? 'cursor-pointer' : 'cursor-default'
                    }
                  >
                    {filter.text}
                  </Label>
                </Dropdown.Item>
              ))}
            </Dropdown>
          </div>
        )}
        {clean && (
          <div className='mb-1 grid grid-cols-1 gap-y-2'>
            <Label htmlFor={`clean-filters-${componentID}`}>
              Vider la recherche
            </Label>
            <Button
              id={`clean-filters-${componentID}`}
              color='dark'
              onClick={() => handleClean()}
            >
              <MdCleaningServices className='mr-2 text-lg' />
            </Button>
          </div>
        )}
      </form>
    </div>
  )
}
export default DataTableController
