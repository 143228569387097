import { Button } from 'flowbite-react'
import type { FC } from 'react'
import svg from '../../assets/images/404.svg'
import { AngleLeftIcon } from '../../components/icons'

const NotFoundPage: FC = function () {
  return (
    <div className='flex min-h-screen flex-col items-center justify-center py-16 bg-gray-900'>
      <img alt='404' src={svg} className='w-64 pb-4' />
      <h1 className='mb-6 text-2xl font-bold text-white md:text-5xl'>
        Page non trouvée
      </h1>
      <p className='mb-6 w-4/5 max-w-xl text-center text-lg text-gray-300'>
        Oops! On dirait que vous avez suivi un mauvais lien. Si vous pensez que
        ce problème vient de nous, merci de nous le signaler.
      </p>
      <Button color={'primary'} href='/'>
        <div className='mr-1 flex items-center gap-x-2 text-white '>
          <AngleLeftIcon className='w-4 h-4' /> Retour à l'accueil
        </div>
      </Button>
    </div>
  )
}

export default NotFoundPage
