/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card, Checkbox, Label, TextInput } from 'flowbite-react'
import { useState, type FC, useEffect } from 'react'
import { useAppDispatch, useTypedSelector } from '../../store'
import {
  addRememberMeToLocalStorage,
  getRememberMeFromLocalStorage,
  removeRememberMeFromLocalStorage,
} from '../../utils/localStorage'
import { UserForm, loginAdminUser } from '../../features/user/userSlice'
import { useNavigate } from 'react-router-dom'
import { useToast } from '../../hooks/useToast'
import { useRedirection } from '../../utils/queryParams'

const SignInPage: FC = function () {
  const initialState: UserForm = {
    email: '',
    password: '',
    rememberMe: false,
  }

  const { isLoading, user, error } = useTypedSelector((state) => state.user) //getting data from store
  const [values, setValues] = useState<UserForm>(initialState)
  const [loggedAction, setLoggedAction] = useState<Boolean>(false)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const toast = useToast()
  const redirect = useRedirection()

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { email, password, rememberMe } = values
    if (!email || !password) {
      toast('error', 'Merci de renseigner tous les champs')
      return
    }

    if (rememberMe) {
      addRememberMeToLocalStorage({ email: email })
    } else {
      removeRememberMeFromLocalStorage()
    }
    setLoggedAction(true)
    dispatch(
      loginAdminUser({
        email: email,
        password: password,
        rememberMe: rememberMe,
      })
    )
  }

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const toggleRememberMe = () => {
    setValues({ ...values, rememberMe: !values.rememberMe })
  }

  useEffect(() => {
    if (user && user.role === 'admin' && loggedAction) {
      setLoggedAction(false)
      if (redirect) {
        window.location.href = redirect
      } else {
        toast('success', `Bonjour ${user.lastName}`)
        navigate('/')
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loggedAction])

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    if (getRememberMeFromLocalStorage() != null) {
      setValues({
        ...values,
        rememberMe: true,
        email: getRememberMeFromLocalStorage().email,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='flex flex-col items-center justify-center px-6 lg:h-screen lg:gap-y-12 bg-white dark:bg-gray-900'>
      <a href='/' className='my-6 flex items-center gap-x-1 lg:my-0'>
        <img alt='Lagoon logo' src='/logo192.png' className='mr-3 h-10' />
        <span className='self-center whitespace-nowrap text-2xl font-semibold dark:text-white'>
          Roselagon Admin Lagoon
        </span>
      </a>
      <Card
        horizontal
        imgSrc='/images/authentication/ecosystem-2-img.jpg'
        imgAlt=''
        className='w-full md:max-w-[1024px] md:[&>*]:w-full md:[&>*]:p-16 [&>img]:hidden md:[&>img]:w-96 md:[&>img]:p-0 lg:[&>img]:block'
      >
        <h1 className='mb-3 text-2xl font-bold dark:text-white md:text-3xl'>
          Se connecter à la plateforme admin de lagoon
        </h1>
        <form onSubmit={onSubmit}>
          <div className='mb-4 flex flex-col gap-y-3'>
            <Label htmlFor='email'>Votre email</Label>
            <TextInput
              id='email'
              name='email'
              placeholder='xxx@yyy.zzz'
              type='email'
              onChange={handleChange}
              required={true}
              value={values.email}
            />
          </div>
          <div className='mb-6 flex flex-col gap-y-3'>
            <Label htmlFor='password'>Votre mot de passe</Label>
            <TextInput
              id='password'
              name='password'
              placeholder='••••••••'
              type='password'
              onChange={handleChange}
              required={true}
              value={values.password}
            />
          </div>
          <div className='mb-6 flex items-center justify-between'>
            <div className='flex items-center gap-x-3'>
              <Checkbox
                id='rememberMe'
                name='rememberMe'
                onChange={toggleRememberMe}
                checked={values.rememberMe}
              />
              <Label htmlFor='rememberMe'>Se rappeler de moi</Label>
            </div>
            {/* <a
              href="#"
              className="w-1/2 text-right text-sm text-primary-600 dark:text-primary-300"
            >
              Lost Password?
            </a> */}
          </div>
          <div className='mb-6'>
            <Button
              type='submit'
              disabled={isLoading}
              className='w-full lg:w-auto'
              color='primary'
            >
              {isLoading ? 'Chargement ...' : 'Se connecter à votre compte'}
            </Button>
          </div>
          {/* <p className="text-sm text-gray-500 dark:text-gray-300">
            Not registered?&nbsp;
            <a href="#" className="text-primary-600 dark:text-primary-300">
              Create account
            </a>
          </p> */}
        </form>
      </Card>
    </div>
  )
}

export default SignInPage
