import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const getAllTagsThunk = async (_, thunkAPI) => {
  const { search, sort } = thunkAPI.getState().allTags
  let url = `/tags?sort=${sort}`
  if (search) {
    url = url + `&search=${search}`
  }

  try {
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
