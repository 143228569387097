import { Label, Modal, TextInput } from 'flowbite-react'
import { SelectMediaModal } from '../../pages/medias/list'
import { ThumbnailResource } from '..'
import { HiTrash } from 'react-icons/hi'

const TrainerModalBody = ({
  values,
  handleChange,
  imageSelected,
  setImageSelected,
}) => {
  return (
    <Modal.Body>
      <div className='grid grid-cols-1 gap-6 lg:grid-cols-2'>
        <div>
          <Label htmlFor='name'>Nom</Label>
          <TextInput
            id='name'
            name='name'
            placeholder=''
            className='mt-1'
            type='text'
            required={true}
            value={values.name}
            onChange={handleChange}
          />
        </div>
        <div>
          <Label htmlFor='lastName'>Prénom</Label>
          <TextInput
            id='lastName'
            name='lastName'
            placeholder=''
            className='mt-1'
            type='text'
            required={true}
            value={values.lastName}
            onChange={handleChange}
          />
        </div>
        <div>
          <Label htmlFor='email'>email</Label>
          <TextInput
            id='email'
            name='email'
            placeholder=''
            className='mt-1'
            type='email'
            required={true}
            value={values.email}
            onChange={handleChange}
          />
        </div>
        <div>
          <Label htmlFor='job'>Profession</Label>
          <TextInput
            id='job'
            name='job'
            placeholder='CEO'
            className='mt-1'
            type='text'
            required={false}
            value={values.job}
            onChange={handleChange}
          />
        </div>
        <div>
          <Label htmlFor='link'>Site internet</Label>
          <TextInput
            id='link'
            name='link'
            placeholder='CEO'
            className='mt-1'
            type='text'
            required={false}
            value={values.link}
            onChange={handleChange}
          />
        </div>
        <div className='lg:col-span-2'>
          <Label>Changer de thumbnail</Label>
          <SelectMediaModal setImageSelected={setImageSelected} />
        </div>

        {imageSelected && imageSelected._id && (
          <>
            <div className='lg:col-span-2'>
              <ThumbnailResource resource={imageSelected} />
            </div>
            <div className='cursor-pointer'>
              <span className='sr-only'>Supprimer</span>
              <HiTrash
                className='-mt-5 text-2xl text-red-600'
                onClick={() => setImageSelected({})}
              />
            </div>
          </>
        )}
      </div>
    </Modal.Body>
  )
}
export default TrainerModalBody
