import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const getModuleThunk = async (_, id, thunkAPI) => {
  try {
    let url = `/modules/${id}?nomydata=true`
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
export const addModuleThunk = async (_, module, thunkAPI) => {
  let url = `/modules`
  try {
    const resp = await customFetch.post(url, module)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
export const removeModuleThunk = async (_, module, thunkAPI) => {
  let url = `/modules/${module._id}`
  try {
    const resp = await customFetch.delete(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
export const editModuleThunk = async (_, module, thunkAPI) => {
  let url = `/modules/${module._id}`
  try {
    const resp = await customFetch.patch(url, module)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const editConfigModuleModalFormThunk = async (
  _,
  configModuleModal,
  thunkAPI
) => {
  let url = `/modules/${configModuleModal._id}/configmodal`
  try {
    const resp = await customFetch.patch(url, configModuleModal)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const changeModuleStatusThunk = async (_, module, thunkAPI) => {
  let url = `/modules/${module._id}/status`
  try {
    const resp = await customFetch.patch(url, module)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
