import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const getSingleBetaContactThunk = async (_, id, thunkAPI) => {
  try {
    let url = `/roselagon/betacontacts/${id}`
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const deleteBetaContactThunk = async (_, id, thunkAPI) => {
  try {
    let url = `/roselagon/betacontacts/${id}`
    const resp = await customFetch.delete(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const addBetaContactThunk = async (_, betaContact, thunkAPI) => {
  try {
    let url = `/roselagon/betacontacts/create-admin`
    const resp = await customFetch.post(url, {
      ...betaContact,
    })
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const updateBetaContactThunk = async (_, betaContact, thunkAPI) => {
  let url = `/roselagon/betacontacts/${betaContact._id}`
  try {
    const resp = await customFetch.patch(url, betaContact)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const inviteBetaUserThunk = async (_, betaContact, thunkAPI) => {
  try {
    const resp = await customFetch.post('/users/invite-beta', {
      betaContactId: betaContact._id,
    })
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
