import { FC, useEffect, useState } from 'react'
import { useAppDispatch, useTypedSelector } from '../../store'
import { useToast } from '../../hooks/useToast'
import {
  addTagToDataTable,
  changeInputValues,
  editTagToDataTable,
  getAllTags,
  removeTagToDataTable,
} from '../../features/allTags/allTagsSlice'
import { Breadcrumb, Button, Checkbox, Modal, Table } from 'flowbite-react'
import {
  HiHome,
  HiOutlineExclamationCircle,
  HiPencilAlt,
  HiTrash,
} from 'react-icons/hi'
import { DataTableController, Loading } from '../../components'
import {
  Tag,
  TagForm,
  addTag,
  clearTagForm,
  editTag,
  removeTag,
} from '../../features/tag/tagSlice'
import TagModalBody from '../../components/modal-body/Tag'
import { FaPlus } from 'react-icons/fa'

export const TagsPage: FC = function () {
  const { error, search, sort } = useTypedSelector((store) => store.allTags)
  const { error: errorTag } = useTypedSelector((store) => store.tag)
  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
    }
    if (errorTag !== '') {
      toast('error', errorTag)
      dispatch(clearTagForm())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, errorTag])

  useEffect(() => {
    //clear form
    dispatch(getAllTags({}))
  }, [dispatch, search, sort])
  return (
    <>
      <div className='block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex'>
        <div className='mb-1 w-full'>
          <div className='mb-4'>
            <Breadcrumb className='mb-4'>
              <Breadcrumb.Item href='#'>
                <div className='flex items-center gap-x-3'>
                  <HiHome className='text-xl' />
                  <span className='dark:text-white'>Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Tags</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className='text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl'>
              Tous les tags
            </h1>
          </div>
          <div className='block items-center sm:flex'>
            <DataTableController
              placeholder='rechercher un tag'
              handleChange={changeInputValues}
              sort={sort}
              search={search}
              initFilters={null}
            ></DataTableController>
            <div className='flex w-full items-center sm:justify-end'>
              <AddTagModal />
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='overflow-x-auto'>
          <div className='inline-block min-w-full align-middle'>
            <div className='overflow-hidden shadow'>
              <TagsTable />
            </div>
          </div>
        </div>
      </div>
      {/* <Pagination
        // numOfPages={numOfPages}
        total={totalTags}
        // page={page}
        // changePage={changePage}
      /> */}
    </>
  )
}

const TagsTable: FC = function () {
  const { tags, isLoading } = useTypedSelector((store) => store.allTags)
  const castTags = tags as Tag[]
  if (isLoading) {
    return <Loading center />
  }
  if (tags.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>Pas de tags à afficher...</span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>
          <span className='sr-only'>Selectionner tous</span>
          <Checkbox />
        </Table.HeadCell>
        <Table.HeadCell>Titre/ID</Table.HeadCell>
        <Table.HeadCell>Nombre d'utilisations</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {castTags.map((tag) => {
          return (
            <Table.Row
              key={tag._id}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='w-4 p-4'>
                <Checkbox />
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {tag.title}
                </div>
                <p className='truncate text-sm text-gray-500 dark:text-gray-400'>
                  {tag._id}
                </p>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                {tag.used}
              </Table.Cell>
              <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                <div className='flex items-center gap-x-3'>
                  <EditTagModal tagSelected={tag} />
                  <DeleteTagModal tagSelected={tag} />
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

const AddTagModal: FC = function () {
  const initialValues: TagForm = {
    title: '',
  }

  const [isOpen, setOpen] = useState(false)
  const [values, setValues] = useState<TagForm>(initialValues)
  const dispatch = useAppDispatch()
  const { isLoading, tag } = useTypedSelector((store) => store.tag)
  const toast = useToast()

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { title } = values
    if (title !== '') {
      dispatch(addTag(values))
    } else {
      toast('error', `Vous avez besoin de renseigner les elements requis`)
    }
  }

  useEffect(() => {
    if (isOpen && tag && tag._id) {
      //clean
      setValues({
        title: '',
      })
      toast('success', `Le tag ${tag.title} a bien été créé`)
      //refresh list
      dispatch(addTagToDataTable(tag))
      //clear form
      dispatch(clearTagForm())
      //close
      setOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, tag, isLoading])

  return (
    <>
      <Button color='primary' onClick={() => setOpen(!isOpen)}>
        <FaPlus className='mr-3 text-sm' />
        Ajouter un tag
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Ajouter tag</strong>
          </Modal.Header>
          <TagModalBody values={values} handleChange={handleChange} />
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Ajouter
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

const EditTagModal: FC<{ tagSelected: Tag }> = function ({ tagSelected }) {
  const initialValues: TagForm = {
    _id: tagSelected._id,
    title: tagSelected.title,
  }

  const [isOpen, setOpen] = useState(false)
  const [values, setValues] = useState<TagForm>(initialValues)
  const dispatch = useAppDispatch()
  const { isLoading, tag } = useTypedSelector((store) => store.tag)
  const toast = useToast()

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { title, _id } = values
    if (title !== '' && _id !== '') {
      dispatch(editTag(values))
    } else {
      toast('error', `Vous avez besoin de renseigner les elements requis`)
    }
  }

  useEffect(() => {
    if (isOpen && tag && tag._id) {
      toast('success', `Le tag ${tag.title} a bien été éditée`)
      //refresh list
      dispatch(editTagToDataTable(tag))
      //clear form
      dispatch(clearTagForm())
      //close
      setOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, tag, isLoading])

  return (
    <>
      <Button color='primary' onClick={() => setOpen(!isOpen)}>
        <HiPencilAlt className='mr-2 text-lg' />
        <span className='hidden lg:block '>Editer le tag</span>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Editer tag {tag.title}</strong>
          </Modal.Header>
          <TagModalBody values={values} handleChange={handleChange} />
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Editer
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

const DeleteTagModal: FC<{ tagSelected: Tag }> = function ({ tagSelected }) {
  const [isOpen, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const toast = useToast()
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    toast('success', `Le tag ${tagSelected.title} a bien été supprimé`)
    dispatch(removeTag(tagSelected))
    dispatch(removeTagToDataTable(tagSelected))
    setOpen(false)
  }
  return (
    <>
      <Button color='failure' onClick={() => setOpen(!isOpen)}>
        <HiTrash className='mr-2 text-lg' />
        <span className='hidden lg:block '>Supprimer le tag</span>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen} size='md'>
        <Modal.Header className='px-3 pb-0 pt-3'>
          <span className='sr-only'>Supprimer le tag</span>
        </Modal.Header>
        <Modal.Body className='px-6 pb-6 pt-0'>
          <div className='flex flex-col items-center gap-y-6 text-center'>
            <HiOutlineExclamationCircle className='text-7xl text-red-600' />
            <p className='text-lg text-gray-500 dark:text-gray-300'>
              Êtes vous sur de vouloir détruire le tag {tagSelected.title}?
            </p>
            <div className='flex items-center gap-x-3'>
              <Button color='failure' onClick={handleSubmit}>
                Oui, je suis sur
              </Button>
              <Button color='gray' onClick={() => setOpen(false)}>
                Non, annuler
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
