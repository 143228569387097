import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { deleteBetaModuleFormAnswersThunk } from './betaModuleFormAnswersThunk'
import { BetaContact } from '../betaContact/betaContactSlice'
import { Module } from '../module/moduleSlice'

export enum MotionVideoDuration {
  OK = 'ok',
  LONG = 'toolong',
  SHORT = 'tooshort',
}

export interface MotionMemorizationElements {
  visuals?: boolean
  narration?: boolean
  examples?: boolean
  soundDesign?: boolean
  other?: string
}

export interface BetaModuleFormAnswers {
  _id?: string
  betaContact: string
  module: string
  globalSatisfactionNote?: number
  globalKnowledgeProgressNote?: number
  globalBestAspects?: string
  globalImprovements?: string
  uiVisualNote?: number
  uiNavigationNote?: number
  uixLoadingAcceptable?: boolean
  uixReadability?: boolean
  uixInteraction?: boolean
  uixInteractionWhy?: string
  teachingContentRelevancyNote?: number
  teachingUnderstandingNote?: number
  teachingTimingNote?: number
  teachingExercisesGameNote?: number
  teachingShortFormatNote?: number
  motionDesignVideoNote?: number
  motionDesignAnimationsIllustrationsNote?: number
  motionVideoDuration?: MotionVideoDuration
  motionMemorizationElements?: MotionMemorizationElements
  suggestionsAboutYourExperience?: string
  suggestionRecommendationToOthers?: boolean
  suggestionRecommendationToOthersWhy?: string
  paramDeviceUsed?: string
  paramFormTimeElapsed?: number
  paramFormNbSaved?: number
  updatedAt: Date
  betaContactInfo: BetaContact
  moduleInfo: Module
}

export interface BetaModuleFormAnswersState {
  isLoading: boolean
  betaModuleFormAnswers: BetaModuleFormAnswers
  error: string
  success: boolean
}

const initialState: BetaModuleFormAnswersState = {
  isLoading: false,
  betaModuleFormAnswers: {} as BetaModuleFormAnswers,
  error: '',
  success: false,
}

export const deleteBetaModuleFormAnswers = createAsyncThunk(
  'roselagon/beta-module-form-answers/delete',
  async (id: string, thunkAPI) => {
    return deleteBetaModuleFormAnswersThunk(
      '/roselagon/betacontacts/',
      id,
      thunkAPI
    )
  }
)

const betaModuleFormAnswersSlice = createSlice({
  name: 'betaModuleFormAnswers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteBetaModuleFormAnswers.pending, (state) => {
        state.isLoading = true
        state.error = ''
        state.success = false
      })
      .addCase(deleteBetaModuleFormAnswers.fulfilled, (state) => {
        state.isLoading = false
        state.success = true
      })
      .addCase(deleteBetaModuleFormAnswers.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
        state.success = false
      })
  },
})

export default betaModuleFormAnswersSlice.reducer
