import axios from 'axios'
import { logout } from '../features/user/userSlice'
import env from 'react-dotenv'

export const backofficePath = `${env.PROTOCOL}://${env.API_BASE_URL}`

const customFetch = axios.create({
  baseURL: backofficePath,
  withCredentials: true,
})

export default customFetch

export const customMultipartPost = axios.create({
  baseURL: backofficePath,
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

customMultipartPost.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export const checkForUnauthorizedResponse = (error, thunkAPI) => {
  if (error.response.status === 401) {
    thunkAPI.dispatch(logout())
    return thunkAPI.rejectWithValue(
      `Vous n'avez pas l'autorisation de voir cette ressource !`
    )
  }
  return thunkAPI.rejectWithValue(error.response.data.msg)
}
