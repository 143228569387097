/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Breadcrumb,
  Button,
  Modal,
  Select,
  Table,
  TextInput,
} from 'flowbite-react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import {
  HiHome,
  HiOutlineExclamationCircle,
  HiPencilAlt,
  HiTrash,
} from 'react-icons/hi'
import { Loading } from '../../components'
import { useAppDispatch, useTypedSelector } from '../../store'
import { useToast } from '../../hooks/useToast'
import { useParams } from 'react-router-dom'
import {
  clearModuleForm,
  getModule,
  newLearningObjective,
} from '../../features/module/moduleSlice'
import { FaPlus } from 'react-icons/fa'
import {
  LearningObjective,
  LearningObjectiveForm,
  LearningObjectiveTaxonomiesForm,
  addLearningObjective,
  clearLearningObjectiveForm,
  editLearningObjective,
  editTaxonomiesFromLearningObjective,
  removeLearningObjective,
} from '../../features/learningObjective/learningObjectiveSlice'
import LearningObjectiveModalBody from '../../components/modal-body/LearningObjective'
import { getAllLearningObjective } from '../../features/allLearningObjectives/allLearningObjectivesSlice'
import AccordionTableRow from '../../components/AccordionTableRow'
import { getEnumSkills } from '../../features/allSkills/allSkillsSlice'
import { LevelTaxonomy, Taxonomy } from '../../features/taxonomy/taxonomySlice'

const LearningObjectivesListPage: FC = function () {
  const { error, sort } = useTypedSelector(
    (store) => store.allLearningObjectives
  )
  const { error: errorLearningObjective } = useTypedSelector(
    (store) => store.learningObjective
  )
  const { module, error: errorModule } = useTypedSelector(
    (store) => store.module
  )
  const { error: errorSkills } = useTypedSelector((store) => store.allSkills)
  const dispatch = useAppDispatch()
  const toast = useToast()
  const { id } = useParams()

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
    }
    if (errorLearningObjective !== '') {
      toast('error', errorLearningObjective)
      dispatch(clearLearningObjectiveForm())
    }
    if (errorModule !== '') {
      toast('error', errorModule)
      dispatch(clearModuleForm())
    }
    if (errorSkills !== '') {
      toast('error', errorSkills)
    }
    // eslint-disable-next-line
  }, [error, errorModule, errorLearningObjective, errorSkills])

  useEffect(() => {
    dispatch(getModule(id as string))
    dispatch(getEnumSkills({}))
  }, [id, dispatch])

  useEffect(() => {
    if (module && module._id) {
      dispatch(getAllLearningObjective({}))
    }
  }, [dispatch, sort, module])

  return (
    <>
      <div className='block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex'>
        <div className='mb-1 w-full'>
          <div className='mb-4'>
            <Breadcrumb className='mb-4'>
              <Breadcrumb.Item href='#'>
                <div className='flex items-center gap-x-3'>
                  <HiHome className='text-xl' />
                  <span className='dark:text-white'>Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href='/modules'>Modules</Breadcrumb.Item>
              <Breadcrumb.Item href={`/modules/${module._id}`}>
                {module.name}
              </Breadcrumb.Item>
              <Breadcrumb.Item>Objectifs pédagogiques</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className='text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl'>
              Toutes les objectifs pédagogiques de {module.name}
            </h1>
          </div>
          <div className='block items-center sm:flex'>
            <div className='flex w-full items-center sm:justify-end'>
              <AddLearningObjectiveModal />
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='overflow-x-auto'>
          <div className='inline-block min-w-full align-middle'>
            <div className='overflow-hidden shadow'>
              <LearningObjectivesTable />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const LearningObjectivesTable: FC = function () {
  const { learningObjectives, isLoading } = useTypedSelector(
    (store) => store.allLearningObjectives
  )
  const { isLoading: isLoadingSkills } = useTypedSelector(
    (store) => store.allSkills
  )
  const [rowOpened, setRowOpened] = useState(-1)

  const handleClick = (index: number) => {
    if (index !== rowOpened) {
      setRowOpened(index)
    } else {
      setRowOpened(-1)
    }
  }

  const castLearningObjectives = learningObjectives as LearningObjective[]
  if (isLoading || isLoadingSkills) {
    return <Loading center />
  }
  if (!learningObjectives || learningObjectives.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>
            Pas d'objectifs pédagogiques à afficher...
          </span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>
          <span className='sr-only'>Expand/Collapse Row</span>
        </Table.HeadCell>
        <Table.HeadCell>Nom</Table.HeadCell>
        <Table.HeadCell>Description</Table.HeadCell>
        <Table.HeadCell>Taxonomies</Table.HeadCell>
        <Table.HeadCell>Mis à jour le</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {castLearningObjectives.map((learningObjective, index) => {
          return (
            <AccordionTableRow
              learningObjective={learningObjective}
              index={index}
              rowOpened={rowOpened}
              handleClick={handleClick}
              key={`learning-objective-table-row-${index}`}
            />
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default LearningObjectivesListPage

export const AddLearningObjectiveModal: FC = function () {
  const { module: moduleSelected } = useTypedSelector((store) => store.module)

  let initialValues: LearningObjectiveForm = {
    module: '',
    learningTime: 120,
    name: '',
    description: '',
  }

  const { isLoading, learningObjective, success } = useTypedSelector(
    (store) => store.learningObjective
  )
  const [isOpen, setOpen] = useState(false)
  const [values, setValues] = useState<LearningObjectiveForm>({
    ...initialValues,
  })
  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (moduleSelected && moduleSelected._id) {
      setValues({ ...values, module: moduleSelected._id })
    }
    // eslint-disable-next-line
  }, [moduleSelected])

  useEffect(() => {
    if (!isOpen) {
      //clear props
      setValues({
        module: moduleSelected._id,
        learningTime: 120,
        name: '',
        description: '',
        color: '#000000',
      })
      //clear form
      dispatch(clearLearningObjectiveForm())
    }
  }, [isOpen, dispatch, moduleSelected])

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const handleColorChange = (name: string, value: string) => {
    setValues({ ...values, [name]: value })
  }

  const handleTextAreaChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { learningTime, name, description, module, color } = values
    if (
      learningTime !== 0 &&
      name !== '' &&
      description !== '' &&
      module !== '' &&
      color !== ''
    ) {
      dispatch(addLearningObjective(values))
    } else {
      toast('error', `Vous avez besoin de renseigner les elements requis`)
    }
  }

  useEffect(() => {
    if (isOpen && learningObjective && learningObjective._id && success) {
      toast(
        'success',
        `Une nouvelle version du module ${moduleSelected.name} a bien été crée`
      )
      //refresh list
      dispatch(newLearningObjective(learningObjective))
      //close
      setOpen(false)
    }
    // eslint-disable-next-line
  }, [isOpen, success])

  return (
    <>
      <Button color='primary' onClick={() => setOpen(!isOpen)}>
        <FaPlus className='mr-3 text-sm' />
        Ajouter un objectif pédagogique
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Ajouter un objectif pédagogique</strong>
          </Modal.Header>
          <LearningObjectiveModalBody
            values={values}
            handleColorChange={handleColorChange}
            handleChange={handleChange}
            handleTextAreaChange={handleTextAreaChange}
          />
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Ajouter
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export const DeleteLearningObjectiveModal: FC<{
  _id: string
  module: string
  color: string
  learningTime: number
  name: string
  lrsObjectId: string
  description: string
}> = function (learningObjectiveSelected: LearningObjectiveForm) {
  const { module } = useTypedSelector((store) => store.module)
  const { success } = useTypedSelector((store) => store.learningObjective)
  const [isOpen, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const toast = useToast()

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    dispatch(removeLearningObjective(learningObjectiveSelected))
  }
  useEffect(() => {
    if (isOpen && success) {
      toast(
        'success',
        `L'objectif ${learningObjectiveSelected.name} a bien été supprimé`
      )
      //refresh
      dispatch(getModule(module._id))
      dispatch(clearLearningObjectiveForm())
      //close
      setOpen(false)
    }
  }, [toast, dispatch, isOpen, module, success, learningObjectiveSelected])
  return (
    <>
      <Button color='failure' onClick={() => setOpen(!isOpen)}>
        <HiTrash className='mr-2 text-lg' />
        <span className='hidden xl:block '>Supprimer l'objectif</span>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen} size='md'>
        <Modal.Header className='px-3 pb-0 pt-3'>
          <span className='sr-only'>Supprimer objectif</span>
        </Modal.Header>
        <Modal.Body className='px-6 pb-6 pt-0'>
          <div className='flex flex-col items-center gap-y-6 text-center'>
            <HiOutlineExclamationCircle className='text-7xl text-red-600' />
            <p className='text-lg text-gray-500 dark:text-gray-300'>
              Êtes vous sur de vouloir détruire l'objectif{' '}
              {learningObjectiveSelected.name} du module {module.name} ?
            </p>
            <div className='flex items-center gap-x-3'>
              <Button color='failure' onClick={handleSubmit}>
                Oui, je suis sur
              </Button>
              <Button color='gray' onClick={() => setOpen(false)}>
                Non, annuler
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export const EditLearningObjectiveModal: FC<{
  _id: string
  module: string
  color: string
  learningTime: number
  name: string
  lrsObjectId: string
  description: string
}> = function (learningObjectiveSelected: LearningObjectiveForm) {
  const { module } = useTypedSelector((store) => store.module)

  const initialValues: LearningObjectiveForm = {
    module: module._id,
    color: learningObjectiveSelected.color,
    learningTime: learningObjectiveSelected.learningTime,
    name: learningObjectiveSelected.name,
    description: learningObjectiveSelected.description,
    _id: learningObjectiveSelected._id,
  }

  const { isLoading, learningObjective } = useTypedSelector(
    (store) => store.learningObjective
  )
  const [isOpen, setOpen] = useState(false)
  const [values, setValues] = useState<LearningObjectiveForm>(initialValues)
  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (isOpen) {
      setValues(initialValues)
    }
    // eslint-disable-next-line
  }, [isOpen])

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const handleColorChange = (name: string, value: string) => {
    setValues({ ...values, [name]: value })
  }

  const handleTextAreaChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { module, learningTime, name, description, _id, color } = values
    if (
      module !== '' &&
      learningTime !== 0 &&
      name !== '' &&
      description !== '' &&
      _id !== '' &&
      color !== ''
    ) {
      dispatch(editLearningObjective(values))
    } else {
      toast('error', `Vous avez besoin de renseigner les elements requis`)
    }
  }

  useEffect(() => {
    if (isOpen && learningObjective && learningObjective._id) {
      toast('success', `L'objectif ${learningObjective.name} a été mise à jour`)
      //refresh list
      dispatch(getModule(module._id))
      //clear form
      dispatch(clearLearningObjectiveForm())
      //close
      setOpen(false)
    }
  }, [toast, dispatch, isOpen, module, learningObjective])

  return (
    <>
      <Button color='primary' onClick={() => setOpen(!isOpen)}>
        <HiPencilAlt className='mr-2 text-lg' />
        <span className='hidden xl:block '>Editer l'objectif</span>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Editer l'objectif pédagogique</strong>
          </Modal.Header>
          <LearningObjectiveModalBody
            values={values}
            handleColorChange={handleColorChange}
            handleChange={handleChange}
            handleTextAreaChange={handleTextAreaChange}
          />
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Editer
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export const ConfigureTaxonomiesModal: FC<{
  _id: string
  module: string
  taxonomies: Taxonomy[]
}> = function (learningObjectiveSelected: LearningObjectiveTaxonomiesForm) {
  const { module } = useTypedSelector((store) => store.module)

  const initialValues: LearningObjectiveTaxonomiesForm = {
    module: module._id,
    _id: learningObjectiveSelected._id,
    taxonomies: learningObjectiveSelected.taxonomies,
  }

  const { isLoading, learningObjective } = useTypedSelector(
    (store) => store.learningObjective
  )

  const { isLoading: isLoadingSkills, enumSkills } = useTypedSelector(
    (store) => store.allSkills
  )
  const [isOpen, setOpen] = useState(false)
  const [values, setValues] =
    useState<LearningObjectiveTaxonomiesForm>(initialValues)
  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (isOpen) {
      setValues(initialValues)
    }
    // eslint-disable-next-line
  }, [isOpen])

  const handleTaxonomyChange = (
    e: React.FormEvent<HTMLInputElement>,
    index: number
  ) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    const editTaxonomies = values.taxonomies.map((taxonomy, i) => {
      if (index === i) {
        return { ...taxonomy, [name]: value }
      } else {
        return taxonomy
      }
    })

    setValues({
      ...values,
      taxonomies: [...editTaxonomies],
    })
  }

  const handleTaxonomySelectChange = (
    e: React.FormEvent<HTMLSelectElement>,
    index: number
  ) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value

    const editTaxonomies = values.taxonomies.map((taxonomy, i) => {
      if (index === i) {
        return { ...taxonomy, [name]: value }
      } else {
        return taxonomy
      }
    })

    setValues({
      ...values,
      taxonomies: [...editTaxonomies],
    })
  }

  const addNewTaxonomy = (e: React.MouseEvent<HTMLButtonElement>) => {
    const newTaxonomy: Taxonomy = {
      weight: 0,
      level: 1,
      skill: '',
      learningObjective: values._id,
    }
    setValues({
      ...values,
      taxonomies: [...values.taxonomies, { ...newTaxonomy }],
    })
  }

  const handleTaxonomyDelete = (
    e: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    const deleteTaxonomies = values.taxonomies.filter(
      (taxonomy, i) => i !== index
    )

    setValues({
      ...values,
      taxonomies: [...deleteTaxonomies],
    })
  }

  interface countSkills {
    [key: number | string]: number
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { module, _id, taxonomies } = values
    if (module !== '' && _id !== '') {
      if (taxonomies.length > 0) {
        const skillTable = taxonomies.map((taxonomy) => {
          return taxonomy.skill
        })
        if (skillTable.indexOf('') === -1) {
          const countSkills = skillTable.reduce(
            (count: countSkills, item: string) => {
              count[item] = count[item] + 1 || 1
              return count
            },
            {}
          )
          const koSkillArray = Object.values(countSkills).filter(
            (count) => (count as number) > 1
          )
          if (koSkillArray.length === 0) {
            let countWeight = 0
            taxonomies.map((taxonomy) => {
              const weight: string = taxonomy.weight.toString()
              countWeight += parseFloat(weight)
              return null
            })
            if (countWeight === 100) {
              dispatch(editTaxonomiesFromLearningObjective(values))
            } else {
              toast(
                'error',
                `La valeur total ne fait pas 100 -> ${countWeight} `
              )
            }
          } else {
            toast(
              'error',
              `Certaines taxonomies ont des compétences en doublon`
            )
          }
        } else {
          toast('error', `Certaines taxonomies n'ont pas de compétence`)
        }
      } else {
        toast('error', `Il faut au moins une taxonomie`)
      }
    } else {
      toast('error', `Vous avez besoin de renseigner les elements requis`)
    }
  }

  useEffect(() => {
    if (isOpen && learningObjective && learningObjective._id) {
      toast(
        'success',
        `Les taxonomies de ${learningObjective.name} ont été mises à jour`
      )
      //refresh list
      dispatch(getModule(module._id))
      //clear form
      dispatch(clearLearningObjectiveForm())
      //close
      setOpen(false)
    }
  }, [toast, dispatch, isOpen, module, learningObjective])

  if (isLoading || isLoadingSkills) {
    return <Loading center />
  }

  return (
    <>
      <Button color='primary' onClick={() => setOpen(!isOpen)}>
        <HiPencilAlt className='mr-2 text-lg' />
        <span className='hidden xl:block '>Contrôler les taxonomies</span>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Contrôler les taxonomies</strong>
          </Modal.Header>
          <Modal.Body>
            <div className='mb-1 w-full'>
              <div className='block items-center sm:flex'>
                <div className='flex w-full items-center sm:justify-end'>
                  <Button color='primary' onClick={addNewTaxonomy}>
                    <FaPlus className='mr-3 text-sm' />
                    Ajouter une taxonomie
                  </Button>
                </div>
              </div>
            </div>
            <div className='flex flex-col'>
              <div className='overflow-x-auto'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='overflow-hidden shadow'>
                    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
                      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
                        <Table.HeadCell>Compétence</Table.HeadCell>
                        <Table.HeadCell>Poids</Table.HeadCell>
                        <Table.HeadCell>Niveau</Table.HeadCell>
                        <Table.HeadCell>Supprimer</Table.HeadCell>
                      </Table.Head>
                      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
                        {values.taxonomies.map((taxonomy, index) => {
                          return (
                            <Table.Row
                              key={`table-modal-row-${index}`}
                              id={`table-modal-row-${index}`}
                              className='hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer transition'
                            >
                              <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                                <Select
                                  id={`table-modal-form-skill-${index}`}
                                  name='skill'
                                  onChange={(e) =>
                                    handleTaxonomySelectChange(e, index)
                                  }
                                  defaultValue={taxonomy.skill}
                                >
                                  <option
                                    key={`skill-option-unknown`}
                                    value=''
                                  ></option>
                                  ,
                                  {Object.entries(enumSkills).map(
                                    ([key, value]) => [
                                      key,
                                      <option
                                        key={`skill-option-${key}`}
                                        value={key}
                                      >
                                        {value as string}
                                      </option>,
                                    ]
                                  )}
                                </Select>
                              </Table.Cell>
                              <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                                <TextInput
                                  id={`table-modal-form-weight-${index}`}
                                  name='weight'
                                  type='number'
                                  min={0}
                                  max={100}
                                  step='0.5'
                                  required={true}
                                  value={taxonomy.weight}
                                  onChange={(e) =>
                                    handleTaxonomyChange(e, index)
                                  }
                                />
                              </Table.Cell>
                              <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                                <Select
                                  id={`table-modal-form-level-${index}`}
                                  name='level'
                                  onChange={(e) =>
                                    handleTaxonomySelectChange(e, index)
                                  }
                                  defaultValue={taxonomy.level}
                                >
                                  <option key={`level-option-1`} value='1'>
                                    {LevelTaxonomy.level1}
                                  </option>
                                  <option key={`level-option-2`} value='2'>
                                    {LevelTaxonomy.level2}
                                  </option>
                                  <option key={`level-option-3`} value='3'>
                                    {LevelTaxonomy.level3}
                                  </option>
                                </Select>
                                {/* <TextInput
                                  name='level'
                                  id={`table-modal-form-level-${index}`}
                                  type='number'
                                  min={1}
                                  max={3}
                                  required={true}
                                  value={taxonomy.level}
                                  onChange={(e) =>
                                    handleTaxonomyChange(e, index)
                                  }
                                /> */}
                              </Table.Cell>
                              <Table.Cell>
                                <Button
                                  color='failure'
                                  onClick={(e: any) =>
                                    handleTaxonomyDelete(e, index)
                                  }
                                >
                                  <HiTrash className='mr-2' />
                                </Button>
                              </Table.Cell>
                            </Table.Row>
                          )
                        })}
                      </Table.Body>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Changer
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}
