import Blocks from 'editorjs-blocks-react-renderer'
import { Button } from 'flowbite-react'
const EditorRender = ({ savedData, callback }) => {
  return (
    <>
      {savedData && savedData.version && (
        <Blocks
          data={savedData}
          config={{
            header: {
              className: 'font-bold',
            },
            list: {
              className: 'list-inside list-disc ml-14',
            },
            paragraph: {
              className: 'text-base',
            },
          }}
        />
      )}

      <Button color={'primary'} size={'md'} onClick={() => callback()}>
        Changer la description
      </Button>
    </>
  )
}
export default EditorRender
