import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const deleteBetaModuleFormAnswersThunk = async (_, id, thunkAPI) => {
  try {
    let url = `/roselagon/beta-module-form-answers/${id}`
    const resp = await customFetch.delete(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
