import Toast from './Toast'
import { useToastStateContext } from '../context/ToastContext'
export default function ToastContainer() {
  const { toasts } = useToastStateContext()
  return (
    <div
      className='fixed flex top-0 left-1/2 -translate-x-1/2'
      style={{ zIndex: 100 }}
    >
      <div className='max-w-xl mx-auto'>
        {toasts &&
          toasts.map((toast) => (
            <Toast
              id={toast.id}
              key={toast.id}
              type={toast.type}
              message={toast.message}
            />
          ))}
      </div>
    </div>
  )
}
