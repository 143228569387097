import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const getUserThunk = async (_, id, thunkAPI) => {
  try {
    let url = `/users/${id}`
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const deleteUserThunk = async (_, id, thunkAPI) => {
  try {
    let url = `/users/${id}`
    const resp = await customFetch.delete(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const promoteUserToAdminThunk = async (_, email, thunkAPI) => {
  try {
    const resp = await customFetch.post('/users/promote', {
      email: email,
    })
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const demoteAdminToUserThunk = async (_, email, thunkAPI) => {
  try {
    const resp = await customFetch.post('/users/demote', {
      email: email,
    })
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const inviteBetaUserThunk = async (_, email, thunkAPI) => {
  try {
    const resp = await customFetch.post('/users/invite-beta', {
      email: email,
    })
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const demoteBetaUserThunk = async (_, email, thunkAPI) => {
  try {
    const resp = await customFetch.post('/users/demote-beta', {
      email: email,
    })
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
