import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  getBDDControlModuleTestThunk,
  getBDDControlIntegretyThunk,
  getBDDExportModuleThunk,
  postBDDImportModuleThunk,
  resetAuthThunk,
  getBDDExportBetaContactsThunk,
  resetNotifBetaThunk,
} from './bddControlThunk'

export const getBDDControlModuleTest = createAsyncThunk(
  'bddControl/moduletest',
  async (noArgs: void, thunkAPI) => {
    return getBDDControlModuleTestThunk(
      '/bddControl/moduletest',
      noArgs,
      thunkAPI
    )
  }
)

export const getBDDExportModule = createAsyncThunk(
  'bddControl/exportmodule',
  async (id: string, thunkAPI) => {
    return getBDDExportModuleThunk('/bddControl/exportmodule', id, thunkAPI)
  }
)

export const getBDDExportBetaContacts = createAsyncThunk(
  'bddControl/exportbetacontacts',
  async (noArgs: void, thunkAPI) => {
    return getBDDExportBetaContactsThunk(
      '/bddControl/exportbetacontacts',
      thunkAPI
    )
  }
)

export const postBDDImportModule = createAsyncThunk(
  'bddControl/importmodule',
  async (data: any, thunkAPI) => {
    return postBDDImportModuleThunk('/bddControl/importmodule', data, thunkAPI)
  }
)

export const getBDDControlIntegrety = createAsyncThunk(
  'bddControl/integrity',
  async (noArgs: void, thunkAPI) => {
    return getBDDControlIntegretyThunk(
      '/bddControl/integrity',
      noArgs,
      thunkAPI
    )
  }
)

export const resetAuth = createAsyncThunk(
  'bddControl/resetauth',
  async (noArgs: void, thunkAPI) => {
    return resetAuthThunk('/bddControl/resetauth', noArgs, thunkAPI)
  }
)

export const resetNotifBeta = createAsyncThunk(
  'bddControl/resetNotifBeta',
  async (noArgs: void, thunkAPI) => {
    return resetNotifBetaThunk('/bddControl/resetNotifBeta', noArgs, thunkAPI)
  }
)

export interface BDDControl {
  isLoading: boolean
  msg: string
  data: any
  error: string
  success: boolean
}

const initialState: BDDControl = {
  isLoading: false,
  error: '',
  msg: '',
  data: null,
  success: false,
}

const bddControlSlice = createSlice({
  name: 'bddControl',
  initialState,
  reducers: { clearBddControl: () => initialState },
  extraReducers: (builder) => {
    builder
      .addCase(getBDDControlModuleTest.pending, (state) => {
        state.isLoading = true
        state.error = ''
        state.success = false
      })
      .addCase(getBDDControlModuleTest.fulfilled, (state, { payload }) => {
        const { msg } = payload
        state.msg = msg
        state.isLoading = false
        state.success = true
      })
      .addCase(getBDDControlModuleTest.rejected, (state, { payload }) => {
        state.isLoading = false
        state.msg = ''
        state.error = payload as string
        state.success = false
      })
      .addCase(getBDDExportModule.pending, (state) => {
        state.isLoading = true
        state.data = null
        state.msg = ''
        state.error = ''
      })
      .addCase(getBDDExportModule.fulfilled, (state, { payload }) => {
        const { msg, data } = payload
        state.msg = msg
        state.data = data
        state.isLoading = false
      })
      .addCase(getBDDExportBetaContacts.fulfilled, (state, { payload }) => {
        const { msg, data } = payload
        state.msg = msg
        state.data = data
        state.isLoading = false
      })
      .addCase(getBDDExportModule.rejected, (state, { payload }) => {
        state.isLoading = false
        state.msg = ''
        state.data = null
        state.error = payload as string
      })
      .addCase(postBDDImportModule.pending, (state) => {
        state.isLoading = true
        state.success = false
        state.msg = ''
        state.error = ''
      })
      .addCase(postBDDImportModule.fulfilled, (state, { payload }) => {
        const { msg } = payload
        state.msg = msg
        state.success = true
        state.isLoading = false
      })
      .addCase(postBDDImportModule.rejected, (state, { payload }) => {
        state.isLoading = false
        state.msg = ''
        state.success = false
        state.error = payload as string
      })
      .addCase(resetAuth.pending, (state) => {
        state.isLoading = true
        state.success = false
        state.msg = ''
        state.error = ''
      })
      .addCase(resetAuth.fulfilled, (state, { payload }) => {
        const { msg } = payload
        state.msg = msg
        state.success = true
        state.isLoading = false
      })
      .addCase(resetAuth.rejected, (state, { payload }) => {
        state.isLoading = false
        state.msg = ''
        state.success = false
        state.error = payload as string
      })
      .addCase(resetNotifBeta.pending, (state) => {
        state.isLoading = true
        state.success = false
        state.msg = ''
        state.error = ''
      })
      .addCase(resetNotifBeta.fulfilled, (state, { payload }) => {
        const { msg } = payload
        state.msg = msg
        state.success = true
        state.isLoading = false
      })
      .addCase(resetNotifBeta.rejected, (state, { payload }) => {
        state.isLoading = false
        state.msg = ''
        state.success = false
        state.error = payload as string
      })
      .addCase(getBDDControlIntegrety.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(getBDDControlIntegrety.fulfilled, (state, { payload }) => {
        const { msg } = payload
        state.msg = msg
        state.isLoading = false
      })
      .addCase(getBDDControlIntegrety.rejected, (state, { payload }) => {
        state.isLoading = false
        state.msg = ''
        state.error = state.error = payload as string
      })
  },
})

export const { clearBddControl } = bddControlSlice.actions

export default bddControlSlice.reducer
