import env from 'react-dotenv'
import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const getAllActivitiesThunk = async (_, thunkAPI) => {
  const { learningScenario, page } = thunkAPI.getState().allActivities
  const limit = env.DEFAULT_LIMIT_PAGINATION
  let url = `/activities?learningScenario=${learningScenario}&page=${page}&limit=${limit}`
  try {
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
