import { FC } from 'react'
import { IIconProps, IIconPropsInitialValues } from '.'

export const CloseIcon: FC<IIconProps> = ({ className }: IIconProps) => {
  return (
    <svg
      className={className || IIconPropsInitialValues.className}
      aria-hidden='true'
      xmlns='http://www.w3.org/2000/svg'
      fill='currentColor'
      viewBox='0 0 14 14'
    >
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6'
      />
    </svg>
  )
}
export default CloseIcon
