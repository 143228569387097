/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb, Button, Label, Modal, Select } from 'flowbite-react'
import { useEffect, type FC, useState } from 'react'
import { HiHome } from 'react-icons/hi'
import { useAppDispatch, useTypedSelector } from '../../store'
import { Pagination } from '../../components'
import { useToast } from '../../hooks/useToast'
import { useParams } from 'react-router-dom'

import {
  LearningScenario,
  LearningScenarioForm,
  NavigationEnum,
  clearModuleVersionSuccess,
  createLearningScenarioForVersion,
  getModuleVersion,
  updateLearningScenario,
} from '../../features/moduleVersion/moduleVersionSlice'
import { FaEdit, FaPlus } from 'react-icons/fa'
import {
  Activity,
  clearActivityForm,
} from '../../features/activity/activitySlice'
import {
  getAllActivities,
  setLearningScenario,
} from '../../features/allActivities/allActivitiesSlice'
import { changePage } from '../../features/allActivities/allActivitiesSlice'
import StateText from '../../components/StateText'
import { LearningObjective } from '../../features/learningObjective/learningObjectiveSlice'
import { ActivitiesTable, AddActivityModal } from '../activities/list'

const VersionPage: FC = function () {
  const { error, moduleVersion } = useTypedSelector(
    (store) => store.moduleVersion
  )
  const {
    error: errorActivities,
    totalActivities,
    numOfPages,
    page,
    learningObjectives,
    activities,
  } = useTypedSelector((store) => store.allActivities)
  const { error: errorActivity, success } = useTypedSelector(
    (store) => store.activity
  )
  const castLearningObjectives = learningObjectives as LearningObjective[]
  const castActivities = activities as Activity[]
  const dispatch = useAppDispatch()
  const toast = useToast()
  const { id } = useParams()

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
    }
    if (errorActivities !== '') {
      toast('error', errorActivities)
    }
    if (errorActivity !== '') {
      toast('error', errorActivity)
      dispatch(clearActivityForm())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorActivity, errorActivities])

  useEffect(() => {
    dispatch(getModuleVersion(id as string))
  }, [dispatch, id, activities])

  useEffect(() => {
    if (moduleVersion.learningScenario) {
      dispatch(setLearningScenario(moduleVersion.learningScenario))
      dispatch(getAllActivities({}))
    }
  }, [dispatch, success, moduleVersion.learningScenario, page])

  // not using is loading here cause refresh change scenario don't work correctly
  // if (isLoading) {
  //   return <Loading center />
  // }

  return (
    <>
      <div className='block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex'>
        <div className='mb-1 w-full'>
          <div className='mb-4'>
            <Breadcrumb className='mb-4'>
              <Breadcrumb.Item href='#'>
                <div className='flex items-center gap-x-3'>
                  <HiHome className='text-xl' />
                  <span className='dark:text-white'>Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href='/modules'>Modules</Breadcrumb.Item>
              <Breadcrumb.Item href={`/modules/${moduleVersion.module}`}>
                {moduleVersion.moduleInfo?.name || 'not found'}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{moduleVersion.version}</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className='text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl'>
              Configuration de la version {moduleVersion.version} du module{' '}
              {moduleVersion.moduleInfo?.name || 'not found'}
            </h1>
          </div>
          {moduleVersion.learningScenarioInfo ? (
            <>
              <div className='block items-center sm:flex mb-4'>
                <ChangeNavigationScenarioModal
                  learningScenario={moduleVersion.learningScenarioInfo}
                />
                {/* <DataTableController
                    placeholder='rechercher une version'
                    handleChange={handleChange}
                    sort={sort}
                    search={search}
                    initFilters={null}
                  ></DataTableController> */}
                {moduleVersion.isBeta && (
                  <div className='flex w-full items-center sm:justify-end'>
                    <AddActivityModal />
                  </div>
                )}
              </div>
              {castLearningObjectives && (
                <>
                  <h2 className='text-xl font-semibold text-gray-900 dark:text-white sm:text-lg'>
                    Status objectifs pédagogiques
                  </h2>
                  <div>
                    {castLearningObjectives.map((learningObjective) => {
                      var isLearningObjectiveExist = false
                      castActivities.map((activity) => {
                        if (
                          activity.learningObjective === learningObjective._id
                        ) {
                          isLearningObjectiveExist = true
                        }
                        return null
                      })
                      return (
                        <StateText
                          key={learningObjective._id}
                          bool={isLearningObjectiveExist}
                          text={learningObjective.name}
                        />
                      )
                    })}
                  </div>
                </>
              )}
            </>
          ) : (
            <AddLearningScenarioToVersion />
          )}
        </div>
      </div>
      {moduleVersion.learningScenarioInfo && (
        <>
          <div className='flex flex-col'>
            <div className='overflow-x-auto'>
              <div className='inline-block min-w-full align-middle'>
                <div className='overflow-hidden shadow'>
                  <ActivitiesTable />
                </div>
              </div>
            </div>
          </div>
          <Pagination
            numOfPages={numOfPages}
            total={totalActivities}
            page={page}
            changePage={changePage}
          />
        </>
      )}
    </>
  )
}

const AddLearningScenarioToVersion: FC = function () {
  const { moduleVersion, isLoading, success } = useTypedSelector(
    (store) => store.moduleVersion
  )

  let initialValues: LearningScenarioForm = {
    moduleVersion: '',
    navigation: 'LINEAR',
  }

  const [isOpen, setOpen] = useState(false)
  const [values, setValues] = useState<LearningScenarioForm>({
    ...initialValues,
  })
  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (moduleVersion && moduleVersion._id) {
      setValues({ ...values, moduleVersion: moduleVersion._id })
    }
    // eslint-disable-next-line
  }, [moduleVersion._id])

  useEffect(() => {
    if (!isOpen) {
      //clear props
      setValues({
        moduleVersion: moduleVersion._id,
        navigation: 'LINEAR',
      })
    }
    // eslint-disable-next-line
  }, [isOpen])

  const handleSelectChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { navigation, moduleVersion } = values
    if (moduleVersion !== '' && navigation !== '') {
      dispatch(createLearningScenarioForVersion(values))
    } else {
      toast('error', `Vous avez besoin de renseigner les elements requis`)
    }
  }

  useEffect(() => {
    if (isOpen && moduleVersion && moduleVersion._id && success) {
      toast('success', `Un nouveau scénario à été associé`)
      dispatch(clearModuleVersionSuccess())
      //close
      setOpen(false)
    }
    // eslint-disable-next-line
  }, [isOpen, success])

  return (
    <>
      <Button color='primary' onClick={() => setOpen(!isOpen)}>
        <FaPlus className='mr-3 text-sm' />
        Initialiser le scénario
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Initialiser le scénario</strong>
          </Modal.Header>
          <Modal.Body>
            <div className='grid grid-cols-1 gap-6 lg:grid-cols-2'>
              <div>
                <Label htmlFor='patch'>Choisir la navigation :</Label>
                <Select
                  id='navigation'
                  name='navigation'
                  className='mt-1'
                  onChange={handleSelectChange}
                  defaultValue={values.navigation}
                >
                  <option value={NavigationEnum.LINEAR}>
                    Linéaire (dans l'ordre)
                  </option>
                  <option value={NavigationEnum.FREE}>
                    Libre de faire les activités de son choix
                  </option>
                </Select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Ajouter
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export const ChangeNavigationScenarioModal: FC<{
  learningScenario: LearningScenario
}> = function ({ learningScenario }) {
  const { moduleVersion, isLoading, success } = useTypedSelector(
    (store) => store.moduleVersion
  )

  let initialValues: LearningScenarioForm = {
    ...learningScenario,
  }

  const [isOpen, setOpen] = useState(false)
  const [values, setValues] = useState<LearningScenarioForm>({
    ...initialValues,
  })
  const dispatch = useAppDispatch()
  const toast = useToast()

  const handleSelectChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { navigation, moduleVersion, _id } = values
    if (moduleVersion !== '' && navigation !== '' && _id !== '') {
      dispatch(updateLearningScenario(values))
    } else {
      toast('error', `Vous avez besoin de renseigner les elements requis`)
    }
  }

  useEffect(() => {
    if (isOpen && moduleVersion && moduleVersion._id && success) {
      toast(
        'success',
        `Le scénario de la version ${moduleVersion.version} a été mis à jour`
      )
      dispatch(clearModuleVersionSuccess())
      //close
      setOpen(false)
    }
    // eslint-disable-next-line
  }, [isOpen, moduleVersion, success])

  return (
    <>
      <Label>
        Navigation :{' '}
        {moduleVersion.learningScenarioInfo?.navigation ===
        NavigationEnum.LINEAR
          ? 'Linéaire'
          : 'Libre'}
      </Label>
      {moduleVersion.isBeta && (
        <Button color='primary' onClick={() => setOpen(!isOpen)}>
          <FaEdit className='mr-3 text-sm' />
        </Button>
      )}
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Editer le scénario</strong>
          </Modal.Header>
          <Modal.Body>
            <div className='grid grid-cols-1 gap-6 lg:grid-cols-2'>
              <div>
                <Label htmlFor='patch'>Changer la navigation :</Label>
                <Select
                  id='navigation'
                  name='navigation'
                  className='mt-1'
                  onChange={handleSelectChange}
                  defaultValue={values.navigation}
                >
                  <option value={NavigationEnum.LINEAR}>
                    Linéaire (dans l'ordre)
                  </option>
                  <option value={NavigationEnum.FREE}>
                    Libre de faire les activités de son choix
                  </option>
                </Select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Editer
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default VersionPage
