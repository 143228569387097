import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllBetaContactsThunk } from './allBetaContactsThunk'

const initialFiltersState = {
  search: '',
  role: 'special',
  sort: 'latest',
  sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],
  searchIsVerified: 'all',
  searchIsInvited: 'all',
  searchPrevention: 'all',
  searchNr: 'all',
  searchClimate: 'all',
  searchRse: 'all',
  searchOrigin: 'all',
}

const initialState = {
  betaContacts: [],
  isLoading: false,
  totalBetaContacts: 0,
  numOfPages: 1,
  page: 1,
  result: null,
  error: '',
  ...initialFiltersState,
}

export const getAllBetaContacts = createAsyncThunk(
  'betaContacts/get',
  getAllBetaContactsThunk
)

const allBetaContactsSlice = createSlice({
  name: 'allBetaContacts',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true
    },
    hideLoading: (state) => {
      state.isLoading = false
    },
    changeInputValues: (state, { payload: { name, value } }) => {
      state.page = 1
      state[name] = value
    },
    clearFilters: (state) => {
      return { ...state, ...initialFiltersState }
    },
    changePage: (state, { payload }) => {
      state.page = payload
    },
    clearAllBetaContactsState: () => initialState,
    removeBetaContactToDataTable: (state, { payload }) => {
      state.betaContacts = state.betaContacts.filter((b) => {
        return b._id !== payload._id
      })
      state.totalBetaContacts--
    },
    addBetaContactToDataTable: (state, { payload }) => {
      // edit first if exist
      var exist = false
      state.betaContacts = state.betaContacts.map((betaContact) => {
        if (betaContact._id === payload._id) {
          betaContact = { ...payload }
          exist = true
        }
        return betaContact
      })
      if (!exist) {
        state.betaContacts.unshift(payload)
        state.totalBetaContacts++
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBetaContacts.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(getAllBetaContacts.fulfilled, (state, { payload }) => {
        const { betaContacts, numOfPages, totalBetaContacts } = payload
        state.isLoading = false
        state.betaContacts = betaContacts
        state.totalBetaContacts = totalBetaContacts
        state.numOfPages = numOfPages
      })
      .addCase(getAllBetaContacts.rejected, (state, { payload }) => {
        state.isLoading = false
        state.betaContacts = []
        state.error = payload
      })
  },
})

export const {
  showLoading,
  hideLoading,
  changeInputValues,
  clearFilters,
  changePage,
  clearAllBetaContactsState,
  removeBetaContactToDataTable,
  addBetaContactToDataTable,
} = allBetaContactsSlice.actions

export default allBetaContactsSlice.reducer
