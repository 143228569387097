import { useState } from 'react'
import EditorRow from './EditorRow'
import EditorRender from './EditorRender'

const EditorSwitch = ({ value, callback }) => {
  const [isEditing, setEditing] = useState(false)
  const [data, setData] = useState(value)
  const saveData = (data) => {
    setEditing(false)
    setData(data)
    callback(data)
  }
  const editData = () => {
    setEditing(true)
  }

  return isEditing ? (
    <EditorRow initialData={data} callback={saveData} />
  ) : (
    <div className='grid gap-y-5 long-description text-white text-base font-normal leading-normal'>
      <EditorRender savedData={data} callback={editData} />
    </div>
  )
}
export default EditorSwitch
