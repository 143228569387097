import env from 'react-dotenv'
import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const getAllTrainersThunk = async (_, thunkAPI) => {
  const { page, search, sort } = thunkAPI.getState().allTrainers
  const limit = env.DEFAULT_LIMIT_PAGINATION
  let url = `/trainers?sort=${sort}&page=${page}&limit=${limit}`
  if (search) {
    url = url + `&search=${search}`
  }

  try {
    const resp = await customFetch.get(url)

    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
