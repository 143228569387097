import { useMemo, useState } from 'react'
import { groupBy } from '../utils/groupBy'
import { Button } from 'flowbite-react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../store'
import { getChangeLogInfo } from '../features/moduleVersion/moduleVersionSlice'
import { AngleLeftIcon, CloseIcon, OpenBookIcon, PlusIcon } from './icons'

const ModuleVersionChangeLog = ({ changeLog, moduleVersion }) => {
  const dispatch = useDispatch()
  const { changeLogInfo, isLoading } = useTypedSelector(
    (store) => store.moduleVersion
  )
  const [showDetails, setShowDetails] = useState(false)

  const formattedChangeLog = useMemo(() => {
    // group by actor
    const groupByActor = groupBy(changeLog, 'actor')
    let result = {}
    Object.entries(groupByActor).map((actor) => {
      const newActor = { ...actor }
      // if changeLogInfo insert info
      if (newActor[0] === 'activity' && changeLogInfo.activities) {
        newActor[1] = newActor[1].map((entry) => {
          return {
            ...entry,
            info: changeLogInfo.activities?.find(
              (a) => a.lrsObjectId === entry.lrsObjectId
            ),
          }
        })
      } else if (newActor[0] === 'interaction' && changeLogInfo.interactions) {
        newActor[1] = newActor[1].map((entry) => {
          return {
            ...entry,
            info: changeLogInfo.interactions?.find(
              (i) => i.lrsObjectId === entry.lrsObjectId
            ),
          }
        })
      }
      let temp = (result[newActor[0]] = groupBy(newActor[1], 'verb'))
      temp.length = newActor[1].length
      return temp
    })
    return result
  }, [changeLog, changeLogInfo])

  return (
    <div className='grid grid-cols-1 text-white'>
      <div className='my-3 flex'>
        <h2 className='text-xl mr-3'>
          Nombre de changements : {changeLog.length}
        </h2>
        {!showDetails && changeLog.length > 0 && (
          <Button
            color='dark'
            size={'xs'}
            disabled={isLoading}
            onClick={() => {
              setShowDetails(true)
              dispatch(getChangeLogInfo(moduleVersion))
            }}
          >
            détails
          </Button>
        )}
      </div>

      {formattedChangeLog && formattedChangeLog.activity && (
        <div className='mt-3'>
          <div className='flex'>
            <h3 className=''>
              Activités : {formattedChangeLog.activity.length}
            </h3>
          </div>
          <div className='my-3 space-y-4'>
            {formattedChangeLog.activity.add && (
              <>
                <div className='flex space-x-3'>
                  <PlusIcon className='ml-2 w-5 h-5' />
                  <span className='text-left text-base font-normal text-gray-500 dark:text-gray-400 w-full'>
                    {formattedChangeLog.activity.add.length} Créée(s)
                  </span>
                </div>
                <DisplayActivityInfo
                  entries={formattedChangeLog.activity.add}
                />
              </>
            )}
            {formattedChangeLog.activity.update && (
              <>
                <div className='flex space-x-3'>
                  <OpenBookIcon className='ml-2 w-5 h-5' />
                  <span className='text-left text-base font-normal text-gray-500 dark:text-gray-400 w-full'>
                    {formattedChangeLog.activity.update.length} Mise(s) à jour
                  </span>
                </div>
                <DisplayActivityInfo
                  entries={formattedChangeLog.activity.update}
                />
              </>
            )}
            {formattedChangeLog.activity.remove && (
              <>
                <div className='flex space-x-3'>
                  <CloseIcon className='ml-2 w-5 h-5' />
                  <span className='text-left text-base font-normal text-gray-500 dark:text-gray-400 w-full'>
                    {formattedChangeLog.activity.remove.length} Supprimée(s)
                  </span>
                </div>
                <DisplayActivityInfo
                  entries={formattedChangeLog.activity.remove}
                />
              </>
            )}
          </div>
        </div>
      )}
      {formattedChangeLog && formattedChangeLog.interaction && (
        <div className='mt-3'>
          <div className='flex'>
            <h3 className=''>
              Interactions : {formattedChangeLog.interaction.length}
            </h3>
          </div>
          <div className='my-3 space-y-4'>
            {formattedChangeLog.interaction.add && (
              <>
                <div className='flex space-x-3'>
                  <PlusIcon className='ml-2 w-5 h-5' />
                  <span className='text-left text-base font-normal text-gray-500 dark:text-gray-400 w-full'>
                    {formattedChangeLog.interaction.add.length} Créée(s)
                  </span>
                </div>
                <DisplayInteractionInfo
                  entries={formattedChangeLog.interaction.add}
                />
              </>
            )}
            {formattedChangeLog.interaction.update && (
              <>
                <div className='flex space-x-3'>
                  <OpenBookIcon className='ml-2 w-5 h-5' />
                  <span className='text-left text-base font-normal text-gray-500 dark:text-gray-400 w-full'>
                    {formattedChangeLog.interaction.update.length} Mise(s) à
                    jour
                  </span>
                </div>
                <DisplayInteractionInfo
                  entries={formattedChangeLog.interaction.update}
                />
              </>
            )}
            {formattedChangeLog.interaction.remove && (
              <>
                <div className='flex space-x-3'>
                  <CloseIcon className='ml-2 w-5 h-5' />
                  <span className='text-left text-base font-normal text-gray-500 dark:text-gray-400 w-full'>
                    {formattedChangeLog.interaction.remove.length} Supprimée(s)
                  </span>
                </div>
                <DisplayInteractionInfo
                  entries={formattedChangeLog.interaction.remove}
                />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const DisplayActivityInfo = ({ entries }) => {
  return entries ? (
    entries.map((entry) => {
      return entry.info ? (
        <div className='flex space-x-3 items-center' key={entry.info._id}>
          <AngleLeftIcon className='rotate-180 ml-8 w-3 h-3' />
          <span className='text-sm font-normal text-gray-500 dark:text-gray-400'>
            {entry.info.chapter}
          </span>
        </div>
      ) : null
    })
  ) : (
    <></>
  )
}

const DisplayInteractionInfo = ({ entries }) => {
  return entries ? (
    entries.map((entry) => {
      return entry.info ? (
        <div className='flex space-x-3 items-center' key={entry.info._id}>
          <AngleLeftIcon className='rotate-180 ml-8 w-3 h-3' />
          <span className='text-sm font-normal text-gray-500 dark:text-gray-400'>
            {entry.info.title}
          </span>
        </div>
      ) : null
    })
  ) : (
    <></>
  )
}

export default ModuleVersionChangeLog
