import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  addLearningObjectiveThunk,
  editLearningObjectiveThunk,
  editTaxonomiesFromLearningObjectiveThunk,
  removeLearningObjectiveThunk,
} from './learningObjectiveThunk'
import { Taxonomy } from '../taxonomy/taxonomySlice'

export interface LearningObjective {
  _id: string
  module: string
  color: string
  learningTime: number
  isActive: boolean
  status: string
  name: string
  lrsObjectId: string
  description: string
  avgScore: number
  numOfScores: number
  updatedAt: Date
  taxonomiesCount?: number
  taxonomies?: Taxonomy[]
}

export interface LearningObjectiveForm {
  _id?: string
  module: string
  color?: string
  learningTime: number
  name: string
  description: string
}

export interface LearningObjectiveTaxonomiesForm {
  taxonomies: Taxonomy[]
  _id: string
  module: string
}

export interface LearningObjectiveState {
  isLoading: boolean
  learningObjective: LearningObjective
  error: string
  success: boolean
}

const initialState: LearningObjectiveState = {
  isLoading: false,
  learningObjective: {} as LearningObjective,
  error: '',
  success: false,
}

export const addLearningObjective = createAsyncThunk(
  'learningobjective/new',
  async (learningObjective: LearningObjectiveForm, thunkAPI) => {
    return addLearningObjectiveThunk(
      '/learningobjective',
      learningObjective,
      thunkAPI
    )
  }
)

export const removeLearningObjective = createAsyncThunk(
  'learningobjective/remove',
  async (learningObjective: LearningObjectiveForm, thunkAPI) => {
    return removeLearningObjectiveThunk(
      '/learningobjectives/:id',
      learningObjective,
      thunkAPI
    )
  }
)

export const editLearningObjective = createAsyncThunk(
  'learningobjective/edit',
  async (learningObjective: LearningObjectiveForm, thunkAPI) => {
    return editLearningObjectiveThunk(
      '/learningobjectives/:id',
      learningObjective,
      thunkAPI
    )
  }
)

export const editTaxonomiesFromLearningObjective = createAsyncThunk(
  'learningobjective/taxonomies/edit',
  async (learningObjective: LearningObjectiveTaxonomiesForm, thunkAPI) => {
    return editTaxonomiesFromLearningObjectiveThunk(
      '/learningobjectives/:id/taxonomies',
      learningObjective,
      thunkAPI
    )
  }
)

const learningObjectiveSlice = createSlice({
  name: 'learningObjective',
  initialState,
  reducers: {
    clearLearningObjectiveForm: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addLearningObjective.pending, (state) => {
        state.isLoading = true
        state.error = ''
        state.success = false
      })
      .addCase(addLearningObjective.fulfilled, (state, { payload }) => {
        const { learningObjective } = payload
        state.learningObjective = learningObjective
        state.isLoading = false
        state.success = true
      })
      .addCase(addLearningObjective.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
        state.success = false
      })
      .addCase(editLearningObjective.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(editLearningObjective.fulfilled, (state, { payload }) => {
        const { learningObjective } = payload
        state.learningObjective = learningObjective
        state.isLoading = false
      })
      .addCase(editLearningObjective.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addCase(editTaxonomiesFromLearningObjective.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(
        editTaxonomiesFromLearningObjective.fulfilled,
        (state, { payload }) => {
          const { learningObjective } = payload
          state.learningObjective = learningObjective
          state.isLoading = false
        }
      )
      .addCase(
        editTaxonomiesFromLearningObjective.rejected,
        (state, { payload }) => {
          state.isLoading = false
          state.error = payload as string
        }
      )
      .addCase(removeLearningObjective.pending, (state) => {
        state.isLoading = true
        state.error = ''
        state.success = false
      })
      .addCase(removeLearningObjective.fulfilled, (state) => {
        state.isLoading = false
        state.learningObjective = {} as LearningObjective
        state.success = true
      })
      .addCase(removeLearningObjective.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
        state.success = false
      })
  },
})

export const { clearLearningObjectiveForm } = learningObjectiveSlice.actions

export default learningObjectiveSlice.reducer
