/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb } from 'flowbite-react'
import { useEffect, type FC } from 'react'
import { HiHome } from 'react-icons/hi'
import { useAppDispatch, useTypedSelector } from '../../store'
import { useToast } from '../../hooks/useToast'
import { useParams } from 'react-router-dom'
import { getSkill } from '../../features/skill/skillSlice'
import { Loading } from '../../components'
import TaxonomiesByLevelTable from '../../components/table/TaxonomiesByLevelTable'

export const SkillPage: FC = function () {
  const { error, isLoading, skill } = useTypedSelector((store) => store.skill)
  const dispatch = useAppDispatch()
  const toast = useToast()
  const { id } = useParams()

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    dispatch(getSkill(id as string))
  }, [dispatch, id])

  if (isLoading) {
    return <Loading center />
  }

  return (
    <>
      <div className='grid grid-cols-1 gap-y-6 px-4 pt-6 dark:bg-gray-900 xl:gap-4'>
        <div className='col-span-full'>
          <Breadcrumb className='mb-4'>
            <Breadcrumb.Item href='#'>
              <div className='flex items-center gap-x-3'>
                <HiHome className='text-xl' />
                <span className='dark:text-white'>Home</span>
              </div>
            </Breadcrumb.Item>
            <Breadcrumb.Item href='/skills'>Compétences</Breadcrumb.Item>
            <Breadcrumb.Item>{skill.name}</Breadcrumb.Item>
          </Breadcrumb>
          <h1 className='text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl'>
            Detail de la compétence
          </h1>
        </div>
        <div className='col-span-full'>
          <div className='grid grid-cols-1 gap-y-4'>
            <div className='mb-4 rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:p-6 xl:mb-0 xl:p-8 2xl:col-span-2'>
              <TaxonomiesByLevelTable />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
