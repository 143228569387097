import env from 'react-dotenv'
import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const getAllRecordingsThunk = async (_, thunkAPI) => {
  const {
    page,
    sort,
    filterUser,
    filterModule,
    filterModuleVersion,
    filterProgress,
    filterFinished,
  } = thunkAPI.getState().allRecordings
  const limit = env.DEFAULT_LIMIT_PAGINATION
  let url = `/recordings?sort=${sort}&page=${page}&limit=${limit}&user=${filterUser}&module=${filterModule}&moduleVersion=${filterModuleVersion}&progress=${filterProgress}&finished=${filterFinished}`

  try {
    const resp = await customFetch.get(url)

    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
