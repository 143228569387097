/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb, Button } from 'flowbite-react'
import { useEffect, type FC } from 'react'
import { HiHome } from 'react-icons/hi'
import { useAppDispatch, useTypedSelector } from '../../store'
import {
  clearBddControl,
  getBDDControlIntegrety,
  resetAuth,
  resetNotifBeta,
} from '../../features/bddControl/bddControlSlice'
import { useToast } from '../../hooks/useToast'

const BDDControlPage: FC = function () {
  const { error, msg, isLoading } = useTypedSelector(
    (store) => store.bddControl
  )
  const toast = useToast()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
      dispatch(clearBddControl())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    if (msg !== '') {
      toast('success', msg)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msg])

  return (
    <>
      <div className='block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex'>
        <div className='mb-1 w-full'>
          <div className='mb-4'>
            <Breadcrumb className='mb-4'>
              <Breadcrumb.Item href='#'>
                <div className='flex items-center gap-x-3'>
                  <HiHome className='text-xl' />
                  <span className='dark:text-white'>Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href='/administration/bddcontrol'>
                Contrôle de la base de données
              </Breadcrumb.Item>
            </Breadcrumb>
            <h1 className='text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl'>
              Contrôle de la base de données
            </h1>
          </div>
          <div className='grid grid-cols-1 gap-4'>
            <div>
              <Button
                color='primary'
                disabled={isLoading}
                onClick={() => {
                  dispatch(resetAuth())
                }}
              >
                Réinitialiser les connexions des utilisateurs
              </Button>
            </div>
            <div>
              <Button
                color='primary'
                disabled={isLoading}
                onClick={() => {
                  dispatch(resetNotifBeta())
                }}
              >
                Réinitialiser les notifications de relance des beta testeurs
              </Button>
            </div>
            <div>
              <Button
                color='primary'
                disabled
                onClick={() => {
                  dispatch(getBDDControlIntegrety())
                }}
              >
                Vérifier l'intégrité de la base de données
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BDDControlPage
