import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllTagsThunk } from './allTagsThunk'

const initialFiltersState = {
  search: '',
  sort: 'latest',
  sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],
}

const initialState = {
  isLoading: false,
  tags: [],
  totalTags: 0,
  error: '',
  ...initialFiltersState,
}

export const getAllTags = createAsyncThunk('tags', getAllTagsThunk)

// export const showStats = createAsyncThunk('allJobs/showStats', showStatsThunk)

const allTagsSlice = createSlice({
  name: 'allTags',
  initialState,
  reducers: {
    clearFilters: (state) => {
      return { ...state, ...initialFiltersState }
    },
    changeInputValues: (state, { payload: { name, value } }) => {
      state.page = 1
      state[name] = value
    },
    addTagToDataTable: (state, { payload }) => {
      state.tags.unshift(payload)
      state.totalTags++
    },
    editTagToDataTable: (state, { payload }) => {
      state.tags = state.tags.map((tag) => {
        if (tag._id === payload._id) {
          tag = { ...payload }
        }
        return tag
      })
    },
    removeTagToDataTable: (state, { payload }) => {
      state.tags = state.tags.filter((tag) => {
        return tag._id !== payload._id
      })
      state.totalTags--
    },
    clearAllTagsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTags.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(getAllTags.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.tags = payload.tags
        state.totalTags = payload.totalTags
      })
      .addCase(getAllTags.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
  },
})

export const {
  clearFilters,
  changePage,
  clearAllTagsStateState,
  addTagToDataTable,
  editTagToDataTable,
  removeTagToDataTable,
  changeInputValues,
} = allTagsSlice.actions

export default allTagsSlice.reducer
