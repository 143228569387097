/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Breadcrumb,
  Button,
  Checkbox,
  Kbd,
  Label,
  Modal,
  Select,
  Table,
} from 'flowbite-react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { HiHome, HiOutlineExclamationCircle, HiTrash } from 'react-icons/hi'
import { DataTableController, Loading, Pagination } from '../../components'
import { useAppDispatch, useTypedSelector } from '../../store'
import { useToast } from '../../hooks/useToast'
import {
  getAllBetaModuleFormAnswers,
  removeBetaModuleFormAnswersToDataTable,
  changePage,
  handleChange,
} from '../../features/allBetaModuleFormAnswers/allBetaModuleFormAnswersSlice'

import moment from 'moment'
import FilterBetaContactSelect from '../../components/filtersSelect/FilterBetaContactSelect'
import FilterModuleSelect from '../../components/filtersSelect/FilterModuleSelect'
import { useParams } from 'react-router-dom'
import {
  BetaModuleFormAnswers,
  deleteBetaModuleFormAnswers,
} from '../../features/betaModuleFormAnswers/betaModuleFormAnswersSlice'
import { BetaContact } from '../../features/betaContact/betaContactSlice'
import { Module } from '../../features/module/moduleSlice'

const ModuleFormAnswersListPage: FC = function () {
  const {
    error,
    page,
    totalBetaModuleFormAnswers,
    numOfPages,
    sort,
    filterBetaContact,
    filterModule,
    sortOptions,
  } = useTypedSelector((store) => store.allBetaModuleFormAnswers)
  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  // possibility to access this page directly in beta contactID
  const { betaContactId, moduleId } = useParams()
  const { betaContact } = useTypedSelector((store) => store.betaContact)
  const { module } = useTypedSelector((store) => store.module)

  enum TabModuleForm {
    GLOBAL = 'global',
    UI = 'ui',
    UIX = 'uix',
    TEACHING = 'teaching',
    MOTIONDESIGN = 'motion-design',
    SUGGESTION = 'suggestion',
    PARAMETERS = 'parameters',
  }

  const [tabTable, setTabTable] = useState<TabModuleForm>(TabModuleForm.GLOBAL)

  useEffect(() => {
    dispatch(getAllBetaModuleFormAnswers({}))
  }, [dispatch, page, sort, filterBetaContact, filterModule])
  return (
    <>
      <div className='block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex'>
        <div className='mb-1 w-full'>
          <div className='mb-4'>
            <Breadcrumb className='mb-4'>
              <Breadcrumb.Item href='#'>
                <div className='flex items-center gap-x-3'>
                  <HiHome className='text-xl' />
                  <span className='dark:text-white'>Home</span>
                </div>
              </Breadcrumb.Item>
              {betaContactId ? (
                <>
                  <Breadcrumb.Item href='/beta/list'>Beta</Breadcrumb.Item>
                  <Breadcrumb.Item href={`/beta/list/${betaContact._id}`}>
                    {betaContact.name} {betaContact.lastName}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    href={`/beta/list/${betaContact._id}/module-form-answers`}
                  >
                    Réponses au questionnaire de modules
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Liste</Breadcrumb.Item>
                </>
              ) : moduleId ? (
                <>
                  <Breadcrumb.Item href='/modules'>Modules</Breadcrumb.Item>
                  <Breadcrumb.Item href={`/modules/${module._id}`}>
                    {module.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    href={`/modules/${module._id}/module-form-answers`}
                  >
                    Réponses au questionnaire de modules
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Liste</Breadcrumb.Item>
                </>
              ) : (
                <>
                  <Breadcrumb.Item href={`/beta/module-form-answers`}>
                    Réponses au questionnaire de modules
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Liste</Breadcrumb.Item>
                </>
              )}
            </Breadcrumb>
            <h1 className='text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl'>
              Toutes les réponses au questionnaire de modules{' '}
              {betaContactId &&
                ` du beta testeur ${betaContact.name} ${betaContact.lastName}`}
            </h1>
          </div>
          <div className='block items-center sm:flex'>
            <DataTableController
              placeholder='rechercher un enregistrement'
              searchAttribute={''}
              handleChange={handleChange}
              sort={sort}
              sortOptions={sortOptions}
              selectOptions={[
                <div
                  key={'FilterBetaContactSelect'}
                  className='mb-1 grid grid-cols-1 gap-y-2'
                >
                  <Label htmlFor={`search-user`}>
                    Rechercher un utilisateur
                  </Label>
                  <FilterBetaContactSelect handleChange={handleChange} />
                </div>,
                <div
                  key={'FilterModuleSelect'}
                  className='mb-1 grid grid-cols-1 gap-y-2'
                >
                  <Label htmlFor={`search-module`}>Rechercher un module</Label>
                  <FilterModuleSelect
                    handleChange={handleChange}
                    filterModule={filterModule as unknown as string}
                  />
                </div>,
              ]}
              initFilters={[]}
              // initFilters={[
              //   {
              //     name: 'filterFinished',
              //     value: filterFinished,
              //     control: 'true',
              //     text: 'Uniquement les enregistrements terminés',
              //   },
              //   {
              //     name: 'filterFinished',
              //     value: filterFinished,
              //     control: 'false',
              //     text: 'Uniquement les enregistrements non terminés',
              //   },
              // ]}
              // @ts-ignore
              // clean={clearFilters}
            ></DataTableController>
            <div className='w-28 mb-1 grid grid-cols-1 gap-y-2'>
              <Label htmlFor='tabTable'>Changer d'onglet</Label>
              <Select
                id='tabTable'
                className='mt-1'
                onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                  setTabTable(e.currentTarget.value as TabModuleForm)
                }}
                defaultValue={tabTable}
              >
                <option value='global'>Global</option>
                <option value='ui'>UI/UIX</option>
                <option value='teaching'>Pédagogie</option>
                <option value='motion-design'>Motion Design</option>
                <option value='suggestion'>Suggestions</option>
                <option value='parameters'>Paramètres</option>
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='overflow-x-auto'>
          <div className='inline-block min-w-full align-middle'>
            <div className='overflow-hidden shadow'>
              {tabTable === TabModuleForm.GLOBAL ? (
                <BetaModuleFormAnswersGlobalTable />
              ) : tabTable === TabModuleForm.UI ? (
                <BetaModuleFormAnswersUIUIXTable />
              ) : tabTable === TabModuleForm.TEACHING ? (
                <BetaModuleFormAnswersTeachingTable />
              ) : tabTable === TabModuleForm.MOTIONDESIGN ? (
                <BetaModuleFormAnswersMotionDesignTable />
              ) : tabTable === TabModuleForm.SUGGESTION ? (
                <BetaModuleFormAnswersSuggestionsTable />
              ) : (
                tabTable === TabModuleForm.PARAMETERS && (
                  <BetaModuleFormAnswersParametersTable />
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <Pagination
        numOfPages={numOfPages}
        total={totalBetaModuleFormAnswers}
        page={page}
        changePage={changePage}
      />
    </>
  )
}

const DeleteBetaModuleFormAnswersModal: FC<{
  betaModuleFormAnswersSelected: BetaModuleFormAnswers
}> = function ({ betaModuleFormAnswersSelected }) {
  const [isOpen, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const toast = useToast()
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    toast('success', `L'enregistrement a bien été supprimé`)
    dispatch(
      deleteBetaModuleFormAnswers(
        betaModuleFormAnswersSelected._id
          ? betaModuleFormAnswersSelected._id
          : ''
      )
    )
    dispatch(
      removeBetaModuleFormAnswersToDataTable(betaModuleFormAnswersSelected)
    )
    setOpen(false)
  }
  return (
    <>
      <Button color='failure' onClick={() => setOpen(!isOpen)}>
        <HiTrash className='mr-2 text-lg' />
        <span className='hidden lg:block '>Supprimer</span>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen} size='md'>
        <Modal.Header className='px-3 pb-0 pt-3'>
          <span className='sr-only'>Supprimer l'enregistrement</span>
        </Modal.Header>
        <Modal.Body className='px-6 pb-6 pt-0'>
          <div className='flex flex-col items-center gap-y-6 text-center'>
            <HiOutlineExclamationCircle className='text-7xl text-red-600' />
            <p className='text-lg text-gray-500 dark:text-gray-300'>
              Êtes vous sur de vouloir détruire l'enregistrement ?
            </p>
            <div className='flex items-center gap-x-3'>
              <Button color='failure' onClick={handleSubmit}>
                Oui, je suis sur
              </Button>
              <Button color='gray' onClick={() => setOpen(false)}>
                Non, annuler
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const BetaModuleFormAnswersGlobalTable: FC = function () {
  const { betaModuleFormAnswers, isLoading } = useTypedSelector(
    (store) => store.allBetaModuleFormAnswers
  )
  const castBetaModuleFormAnswers =
    betaModuleFormAnswers as BetaModuleFormAnswers[]
  if (isLoading) {
    return <Loading center />
  }
  if (betaModuleFormAnswers.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>
            Pas d'enregistrements à afficher...
          </span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>
          <span className='sr-only'>Selectionner tous</span>
          <Checkbox />
        </Table.HeadCell>
        <Table.HeadCell>Utilisateur</Table.HeadCell>
        <Table.HeadCell>Module</Table.HeadCell>
        <Table.HeadCell>Expérience Globale</Table.HeadCell>
        <Table.HeadCell>Amélioration Compétences</Table.HeadCell>
        <Table.HeadCell>Apprécié</Table.HeadCell>
        <Table.HeadCell>A Améliorer</Table.HeadCell>
        <Table.HeadCell>Mis à jour le</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {castBetaModuleFormAnswers.map((entry) => {
          return (
            <Table.Row
              key={`${entry.betaContact}_global`}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='w-4 p-4'>
                <Checkbox />
              </Table.Cell>
              <UserTableCell betaContactInfo={entry.betaContactInfo} />
              <ModuleTableCell moduleInfo={entry.moduleInfo} />
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.globalSatisfactionNote
                    ? `${entry.globalSatisfactionNote}`
                    : ''}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.globalKnowledgeProgressNote
                    ? `${entry.globalKnowledgeProgressNote}`
                    : ''}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal'>
                <div className='text-sm font-normal text-white'>
                  {entry.globalBestAspects && `${entry.globalBestAspects}`}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal'>
                <div className='text-sm font-normal text-white'>
                  {entry.globalImprovements && `${entry.globalImprovements}`}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {`${moment(entry.updatedAt).fromNow()}`}
                </div>
              </Table.Cell>
              <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                <div className='flex items-center gap-x-3'>
                  <DeleteBetaModuleFormAnswersModal
                    betaModuleFormAnswersSelected={entry}
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

const BetaModuleFormAnswersUIUIXTable: FC = function () {
  const { betaModuleFormAnswers, isLoading } = useTypedSelector(
    (store) => store.allBetaModuleFormAnswers
  )
  const castBetaModuleFormAnswers =
    betaModuleFormAnswers as BetaModuleFormAnswers[]
  if (isLoading) {
    return <Loading center />
  }
  if (betaModuleFormAnswers.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>
            Pas d'enregistrements à afficher...
          </span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>
          <span className='sr-only'>Selectionner tous</span>
          <Checkbox />
        </Table.HeadCell>
        <Table.HeadCell>Utilisateur</Table.HeadCell>
        <Table.HeadCell>Module</Table.HeadCell>
        <Table.HeadCell>Visuel</Table.HeadCell>
        <Table.HeadCell>Navigation</Table.HeadCell>
        <Table.HeadCell>Chargement</Table.HeadCell>
        <Table.HeadCell>Accessibilité</Table.HeadCell>
        <Table.HeadCell>Interactions</Table.HeadCell>
        <Table.HeadCell>Mis à jour le</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {castBetaModuleFormAnswers.map((entry) => {
          return (
            <Table.Row
              key={`${entry.betaContact}_global`}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='w-4 p-4'>
                <Checkbox />
              </Table.Cell>
              <UserTableCell betaContactInfo={entry.betaContactInfo} />
              <ModuleTableCell moduleInfo={entry.moduleInfo} />
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.uiVisualNote ? `${entry.uiVisualNote}` : ''}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.uiNavigationNote ? `${entry.uiNavigationNote}` : ''}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal text-gray-500 dark:text-gray-400'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.uixLoadingAcceptable !== undefined ? (
                    entry.uixLoadingAcceptable === true ? (
                      <span className='text-green-500 inline'>Oui</span>
                    ) : (
                      <span className='text-red-500 inline'>Non</span>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal text-gray-500 dark:text-gray-400'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.uixReadability !== undefined ? (
                    entry.uixReadability === true ? (
                      <span className='text-green-500 inline'>Oui</span>
                    ) : (
                      <span className='text-red-500 inline'>Non</span>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal text-gray-500 dark:text-gray-400'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.uixInteraction !== undefined ? (
                    entry.uixInteraction === true ? (
                      <span className='text-green-500 inline'>Oui</span>
                    ) : (
                      <div className='grid'>
                        <span className='text-red-500 inline'>Non</span>
                        {entry.uixInteractionWhy &&
                          `${entry.uixInteractionWhy}`}
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {`${moment(entry.updatedAt).fromNow()}`}
                </div>
              </Table.Cell>
              <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                <div className='flex items-center gap-x-3'>
                  <DeleteBetaModuleFormAnswersModal
                    betaModuleFormAnswersSelected={entry}
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

const BetaModuleFormAnswersTeachingTable: FC = function () {
  const { betaModuleFormAnswers, isLoading } = useTypedSelector(
    (store) => store.allBetaModuleFormAnswers
  )
  const castBetaModuleFormAnswers =
    betaModuleFormAnswers as BetaModuleFormAnswers[]
  if (isLoading) {
    return <Loading center />
  }
  if (betaModuleFormAnswers.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>
            Pas d'enregistrements à afficher...
          </span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>
          <span className='sr-only'>Selectionner tous</span>
          <Checkbox />
        </Table.HeadCell>
        <Table.HeadCell>Utilisateur</Table.HeadCell>
        <Table.HeadCell>Module</Table.HeadCell>
        <Table.HeadCell>Pertinance</Table.HeadCell>
        <Table.HeadCell>Article et textes</Table.HeadCell>
        <Table.HeadCell>Exercices et jeux</Table.HeadCell>
        <Table.HeadCell>Temps passé</Table.HeadCell>
        <Table.HeadCell>Format courts</Table.HeadCell>
        <Table.HeadCell>Mis à jour le</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {castBetaModuleFormAnswers.map((entry) => {
          return (
            <Table.Row
              key={`${entry.betaContact}_global`}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='w-4 p-4'>
                <Checkbox />
              </Table.Cell>
              <UserTableCell betaContactInfo={entry.betaContactInfo} />
              <ModuleTableCell moduleInfo={entry.moduleInfo} />
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.teachingContentRelevancyNote
                    ? `${entry.teachingContentRelevancyNote}`
                    : ''}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.teachingUnderstandingNote
                    ? `${entry.teachingUnderstandingNote}`
                    : ''}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.teachingExercisesGameNote
                    ? `${entry.globalKnowledgeProgressNote}`
                    : ''}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.teachingTimingNote
                    ? `${entry.teachingTimingNote}`
                    : ''}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.teachingShortFormatNote
                    ? `${entry.teachingShortFormatNote}`
                    : ''}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {`${moment(entry.updatedAt).fromNow()}`}
                </div>
              </Table.Cell>
              <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                <div className='flex items-center gap-x-3'>
                  <DeleteBetaModuleFormAnswersModal
                    betaModuleFormAnswersSelected={entry}
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

const BetaModuleFormAnswersMotionDesignTable: FC = function () {
  const { betaModuleFormAnswers, isLoading } = useTypedSelector(
    (store) => store.allBetaModuleFormAnswers
  )
  const castBetaModuleFormAnswers =
    betaModuleFormAnswers as BetaModuleFormAnswers[]
  if (isLoading) {
    return <Loading center />
  }
  if (betaModuleFormAnswers.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>
            Pas d'enregistrements à afficher...
          </span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>
          <span className='sr-only'>Selectionner tous</span>
          <Checkbox />
        </Table.HeadCell>
        <Table.HeadCell>Utilisateur</Table.HeadCell>
        <Table.HeadCell>Module</Table.HeadCell>
        <Table.HeadCell>Videos</Table.HeadCell>
        <Table.HeadCell>Animations/Illustrations</Table.HeadCell>
        <Table.HeadCell>Durée</Table.HeadCell>
        <Table.HeadCell>Element(s) mémorisation</Table.HeadCell>
        <Table.HeadCell>Dernière modification</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {castBetaModuleFormAnswers.map((entry) => {
          return (
            <Table.Row
              key={`${entry.betaContact}_global`}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='w-4 p-4'>
                <Checkbox />
              </Table.Cell>
              <UserTableCell betaContactInfo={entry.betaContactInfo} />
              <ModuleTableCell moduleInfo={entry.moduleInfo} />
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.motionDesignVideoNote
                    ? `${entry.motionDesignVideoNote}`
                    : ''}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.motionDesignAnimationsIllustrationsNote
                    ? `${entry.motionDesignAnimationsIllustrationsNote}`
                    : ''}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal min-w-[190px]'>
                <div className='text-sm font-normal text-white'>
                  {entry.motionVideoDuration && (
                    <Kbd>{`${entry.motionVideoDuration}`}</Kbd>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal text-gray-500 dark:text-gray-400 min-w-[190px]'>
                <div className='text-sm font-normal text-gray-900 dark:text-white'>
                  {entry.motionMemorizationElements ? (
                    <div className='grid'>
                      {entry.motionMemorizationElements.visuals && (
                        <Kbd>visuels</Kbd>
                      )}
                      {entry.motionMemorizationElements.narration && (
                        <Kbd>narration</Kbd>
                      )}
                      {entry.motionMemorizationElements.examples && (
                        <Kbd>exemples</Kbd>
                      )}
                      {entry.motionMemorizationElements.soundDesign && (
                        <Kbd>sound design</Kbd>
                      )}
                      {entry.motionMemorizationElements.other !== '' &&
                        `${entry.motionMemorizationElements.other}`}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {`${moment(entry.updatedAt).fromNow()}`}
                </div>
              </Table.Cell>
              <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                <div className='flex items-center gap-x-3'>
                  <DeleteBetaModuleFormAnswersModal
                    betaModuleFormAnswersSelected={entry}
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

const BetaModuleFormAnswersSuggestionsTable: FC = function () {
  const { betaModuleFormAnswers, isLoading } = useTypedSelector(
    (store) => store.allBetaModuleFormAnswers
  )
  const castBetaModuleFormAnswers =
    betaModuleFormAnswers as BetaModuleFormAnswers[]
  if (isLoading) {
    return <Loading center />
  }
  if (betaModuleFormAnswers.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>
            Pas d'enregistrements à afficher...
          </span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>
          <span className='sr-only'>Selectionner tous</span>
          <Checkbox />
        </Table.HeadCell>
        <Table.HeadCell>Utilisateur</Table.HeadCell>
        <Table.HeadCell>Module</Table.HeadCell>
        <Table.HeadCell>Commentaires</Table.HeadCell>
        <Table.HeadCell>Recommandation</Table.HeadCell>
        <Table.HeadCell>Pourquoi</Table.HeadCell>
        <Table.HeadCell>Mis à jour le</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {castBetaModuleFormAnswers.map((entry) => {
          return (
            <Table.Row
              key={`${entry.betaContact}_global`}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='w-4 p-4'>
                <Checkbox />
              </Table.Cell>
              <UserTableCell betaContactInfo={entry.betaContactInfo} />
              <ModuleTableCell moduleInfo={entry.moduleInfo} />
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.suggestionsAboutYourExperience
                    ? `${entry.suggestionsAboutYourExperience}`
                    : ''}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal text-gray-500 dark:text-gray-400'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.suggestionRecommendationToOthers !== undefined ? (
                    entry.suggestionRecommendationToOthers === true ? (
                      <span className='text-green-500 inline'>Oui</span>
                    ) : (
                      <span className='text-red-500 inline'>Non</span>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.suggestionRecommendationToOthersWhy
                    ? `${entry.suggestionRecommendationToOthersWhy}`
                    : ''}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {`${moment(entry.updatedAt).fromNow()}`}
                </div>
              </Table.Cell>
              <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                <div className='flex items-center gap-x-3'>
                  <DeleteBetaModuleFormAnswersModal
                    betaModuleFormAnswersSelected={entry}
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

const BetaModuleFormAnswersParametersTable: FC = function () {
  const { betaModuleFormAnswers, isLoading } = useTypedSelector(
    (store) => store.allBetaModuleFormAnswers
  )
  const castBetaModuleFormAnswers =
    betaModuleFormAnswers as BetaModuleFormAnswers[]
  if (isLoading) {
    return <Loading center />
  }
  if (betaModuleFormAnswers.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>
            Pas d'enregistrements à afficher...
          </span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>
          <span className='sr-only'>Selectionner tous</span>
          <Checkbox />
        </Table.HeadCell>
        <Table.HeadCell>Utilisateur</Table.HeadCell>
        <Table.HeadCell>Module</Table.HeadCell>
        <Table.HeadCell>Device</Table.HeadCell>
        <Table.HeadCell>Temps passé</Table.HeadCell>
        <Table.HeadCell>Edité</Table.HeadCell>
        <Table.HeadCell>Mis à jour le</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {castBetaModuleFormAnswers.map((entry) => {
          return (
            <Table.Row
              key={`${entry.betaContact}_global`}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='w-4 p-4'>
                <Checkbox />
              </Table.Cell>
              <UserTableCell betaContactInfo={entry.betaContactInfo} />
              <ModuleTableCell moduleInfo={entry.moduleInfo} />
              <Table.Cell className='p-4 text-sm font-normal min-w-[400px]'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.paramDeviceUsed ? `${entry.paramDeviceUsed}` : ''}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal text-gray-500 dark:text-gray-400'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.paramFormTimeElapsed
                    ? `${entry.paramFormTimeElapsed}`
                    : ''}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal text-gray-500 dark:text-gray-400'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.paramFormNbSaved ? `${entry.paramFormNbSaved}` : ''}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {`${moment(entry.updatedAt).fromNow()}`}
                </div>
              </Table.Cell>
              <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                <div className='flex items-center gap-x-3'>
                  <DeleteBetaModuleFormAnswersModal
                    betaModuleFormAnswersSelected={entry}
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

const UserTableCell: FC<{ betaContactInfo: BetaContact }> = function ({
  betaContactInfo,
}) {
  return (
    <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
      <div className='text-base font-semibold text-gray-900 dark:text-white'>
        {`${betaContactInfo.name} ${betaContactInfo.lastName}`}
      </div>
      <div className='text-sm font-normal text-white'>
        {betaContactInfo.employment && (
          <>
            {betaContactInfo.employment.digitalTraining && (
              <div className='flex items-center'>
                <Kbd>Formation digitale</Kbd>
              </div>
            )}
            {betaContactInfo.employment.facetofaceTraining && (
              <div className='flex items-center'>
                <Kbd>Formation présentielle</Kbd>
              </div>
            )}
            {betaContactInfo.employment.futureChallenges && (
              <div className='flex items-center'>
                <Kbd>Enjeux climatiques</Kbd>
              </div>
            )}
            {betaContactInfo.employment.hr && (
              <div className='flex items-center'>
                <Kbd>RH</Kbd>
              </div>
            )}
            {betaContactInfo.employment.ceo && (
              <div className='flex items-center'>
                <Kbd>CEO</Kbd>
              </div>
            )}
            {betaContactInfo.employment.other && (
              <div className='flex items-center'>
                <span>{betaContactInfo.employment.other}</span>
              </div>
            )}
          </>
        )}
      </div>
    </Table.Cell>
  )
}

const ModuleTableCell: FC<{ moduleInfo: Module }> = function ({ moduleInfo }) {
  return (
    <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
      <div className='text-base font-semibold text-gray-900 dark:text-white'>
        {`${moduleInfo.name}`}
      </div>
      {/* <div className='text-sm font-normal text-white'>
        {betaContactInfo.employment && (
          <>
            {betaContactInfo.employment.digitalTraining && (
              <div className='flex items-center'>
                <Kbd>Formation digitale</Kbd>
              </div>
            )}
            {betaContactInfo.employment.facetofaceTraining && (
              <div className='flex items-center'>
                <Kbd>Formation présentielle</Kbd>
              </div>
            )}
            {betaContactInfo.employment.futureChallenges && (
              <div className='flex items-center'>
                <Kbd>Enjeux climatiques</Kbd>
              </div>
            )}
            {betaContactInfo.employment.hr && (
              <div className='flex items-center'>
                <Kbd>RH</Kbd>
              </div>
            )}
            {betaContactInfo.employment.ceo && (
              <div className='flex items-center'>
                <Kbd>CEO</Kbd>
              </div>
            )}
            {betaContactInfo.employment.other && (
              <div className='flex items-center'>
                <span>{betaContactInfo.employment.other}</span>
              </div>
            )}
          </>
        )}
      </div> */}
    </Table.Cell>
  )
}

export default ModuleFormAnswersListPage
