import {
  ActivityEnum,
  ActivityForm,
} from '../../features/activity/activitySlice'
import { Checkbox, Label, Modal, Select, TextInput } from 'flowbite-react'
import { LearningObjective } from '../../features/learningObjective/learningObjectiveSlice'
import { FC } from 'react'
import { useTypedSelector } from '../../store'

interface ActivityModalBodyProps {
  values: ActivityForm
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void
  subChaptersText: string
  handleSubChapterText: (e: React.FormEvent<HTMLInputElement>) => void
  handleSelectLearningObjectiveChange?: (
    e: React.FormEvent<HTMLSelectElement>
  ) => void
  activityType?: string
  handleSelectActivityChange?: (e: React.FormEvent<HTMLSelectElement>) => void
  handleOnlyOnce?: () => void
}

export const ActivityModalBody: FC<ActivityModalBodyProps> = ({
  values,
  handleChange,
  subChaptersText,
  handleSubChapterText,
  handleSelectLearningObjectiveChange,
  activityType,
  handleSelectActivityChange,
  handleOnlyOnce,
}: ActivityModalBodyProps) => {
  const { learningObjectives } = useTypedSelector(
    (store) => store.allActivities
  )

  const castLearningObjectives = learningObjectives as LearningObjective[]

  return (
    <Modal.Body>
      <div className='grid grid-cols-1 gap-6 lg:grid-cols-2'>
        <div>
          <Label htmlFor='chapter'>Chapitre</Label>
          <TextInput
            id='chapter'
            name='chapter'
            placeholder=''
            className='mt-1'
            type='text'
            required={true}
            value={values.chapter}
            onChange={handleChange}
          />
        </div>
        <div>
          <Label htmlFor='subChapters'>
            Sous chapitres (séparer les par un ;)
          </Label>
          <TextInput
            id='subChapters'
            name='subChapters'
            placeholder=''
            className='mt-1'
            type='text'
            value={subChaptersText}
            onChange={handleSubChapterText}
          />
        </div>
        {learningObjectives && learningObjectives.length > 0 && (
          <div>
            <Label htmlFor='learningObjective'>Objectif pédagogique</Label>
            <Select
              id='learningObjective'
              name='learningObjective'
              className='mt-1'
              onChange={handleSelectLearningObjectiveChange}
              defaultValue={values.learningObjective || ''}
            >
              <option value=''></option>
              {castLearningObjectives.map((learningObjective) => {
                return (
                  <option
                    key={learningObjective._id}
                    value={learningObjective._id}
                  >
                    {learningObjective.name}
                  </option>
                )
              })}
            </Select>
          </div>
        )}
        {activityType !== undefined &&
          handleSelectActivityChange !== undefined && (
            <div>
              <Label htmlFor='activityType'>Type d'activité</Label>
              <Select
                id='activityType'
                name='activityType'
                className='mt-1'
                onChange={handleSelectActivityChange}
                defaultValue={activityType}
              >
                <option value=''></option>
                <option value={ActivityEnum.WEBOBJECT}>
                  Web Object (projet web personnalisé)
                </option>
                {/* for now quiz isn't managed in admin Lagoon
                <option value={ActivityEnum.QUIZ}>
                  Quiz (automatisé par Lagoon)
                </option>
                */}
              </Select>
            </div>
          )}
        {/* Specificity fields in function of type */}
        {activityType === ActivityEnum.WEBOBJECT && (
          <div>
            <Label htmlFor='url'>Url</Label>
            <TextInput
              id='url'
              name='url'
              placeholder='http://localhost:4000'
              className='mt-1'
              type='text'
              required={true}
              value={values.url}
              onChange={handleChange}
            />
          </div>
        )}
        {activityType === ActivityEnum.QUIZ && (
          <div>
            <Checkbox
              id='onlyOnce'
              name='onlyOnce'
              className='mt-1 mr-1'
              onChange={handleOnlyOnce}
              checked={values.onlyOnce}
            />
            <Label htmlFor='onlyOnce'>Faisable seulement une fois</Label>
          </div>
        )}
      </div>
    </Modal.Body>
  )
}
