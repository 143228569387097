import env from 'react-dotenv'
import { FaFile, FaFilePdf } from 'react-icons/fa'

const ResourceElement = ({ resource, size = 'full' }) => {
  if (resource) {
    let width = 'full'
    switch (size) {
      case 'base':
        width = '8'
        break
      case 'lg':
        width = '24'
        break
      default:
        width = 'full'
        break
    }
    const mime = require('mime/lite')
    const { _id, src, type, public: isPublic } = resource
    return (
      <>
        {mime.getExtension(type) === 'png' ||
        mime.getExtension(type) === 'jpg' ||
        mime.getExtension(type) === 'jpeg' ||
        mime.getExtension(type) === 'avif' ||
        mime.getExtension(type) === 'webp' ? (
          <>
            {isPublic ? (
              <img
                className={`w-${width} h-${width} object-cover`}
                src={`${src}`}
                alt='thumbnail public'
              />
            ) : (
              <img
                className={`w-${width} h-${width} object-cover`}
                src={`${env.PROTOCOL}://${env.API_BASE_URL}/resources/${_id}`}
                alt='thumbnail privé'
              />
            )}
          </>
        ) : (
          <div className='flex items-center space-x-4'>
            {mime.getExtension(type) === 'pdf' ? (
              <>
                <div className='shrink-0'>
                  <FaFilePdf className='text-2xl text-gray-900 dark:text-white'></FaFilePdf>
                </div>
                <div className='min-w-0 flex-1 text-gray-900 dark:text-white'>
                  <span>Ouvrir le pdf</span>
                </div>
              </>
            ) : (
              <>
                <div className='shrink-0'>
                  <FaFile className='text-2xl text-gray-900 dark:text-white'></FaFile>
                </div>
                <div className='min-w-0 flex-1 text-gray-900 dark:text-white'>
                  <span>Ouvrir le fichier</span>
                </div>
              </>
            )}
          </div>
        )}
      </>
    )
  } else {
    return
  }
}
export default ResourceElement
