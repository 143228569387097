/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Breadcrumb,
  Button,
  Checkbox,
  Kbd,
  Label,
  Modal,
  Select,
  Table,
} from 'flowbite-react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { HiHome, HiOutlineExclamationCircle, HiTrash } from 'react-icons/hi'
import { DataTableController, Loading, Pagination } from '../../components'
import { useAppDispatch, useTypedSelector } from '../../store'
import { useToast } from '../../hooks/useToast'
import {
  getAllBetaLagoonFormAnswers,
  removeBetaLagoonFormAnswersToDataTable,
  changePage,
  handleChange,
} from '../../features/allBetaLagoonFormAnswers/allBetaLagoonFormAnswersSlice'

import moment from 'moment'
import FilterBetaContactSelect from '../../components/filtersSelect/FilterBetaContactSelect'
import { useParams } from 'react-router-dom'
import {
  BetaLagoonFormAnswers,
  DataInvestmentEnum,
  deleteBetaLagoonFormAnswers,
} from '../../features/betaLagoonFormAnswers/betaLagoonFormAnswersSlice'
import { BetaContact } from '../../features/betaContact/betaContactSlice'

const LagoonFormAnswersListPage: FC = function () {
  const {
    error,
    page,
    totalBetaLagoonFormAnswers,
    numOfPages,
    sort,
    filterBetaContact,
    sortOptions,
  } = useTypedSelector((store) => store.allBetaLagoonFormAnswers)
  // const { error: errorSkill } = useTypedSelector((store) => store.skill)
  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  // possibility to access this page directly in beta contactID
  const { betaContactId } = useParams()
  const { betaContact } = useTypedSelector((store) => store.betaContact)

  enum TabLagoonForm {
    GLOBAL = 'global',
    CONCEPT = 'concept',
    CONTENT = 'contenu',
    UI = 'ui',
    UIX = 'uix',
    DATA = 'data',
    PARAMETERS = 'parameters',
  }

  const [tabTable, setTabTable] = useState<TabLagoonForm>(TabLagoonForm.GLOBAL)

  useEffect(() => {
    dispatch(getAllBetaLagoonFormAnswers({}))
  }, [dispatch, page, sort, filterBetaContact])
  return (
    <>
      <div className='block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex'>
        <div className='mb-1 w-full'>
          <div className='mb-4'>
            <Breadcrumb className='mb-4'>
              <Breadcrumb.Item href='#'>
                <div className='flex items-center gap-x-3'>
                  <HiHome className='text-xl' />
                  <span className='dark:text-white'>Home</span>
                </div>
              </Breadcrumb.Item>
              {betaContactId ? (
                <>
                  <Breadcrumb.Item href='/beta/list'>Beta</Breadcrumb.Item>
                  <Breadcrumb.Item href={`/beta/list/${betaContact._id}`}>
                    {betaContact.name} {betaContact.lastName}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    href={`/beta/list/${betaContact._id}/lagoon-form-answers`}
                  >
                    Réponses au questionnaire Lagoon
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Liste</Breadcrumb.Item>
                </>
              ) : (
                <>
                  <Breadcrumb.Item href={`/beta/lagoon-form-answers`}>
                    Réponses au questionnaire Lagoon
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Liste</Breadcrumb.Item>
                </>
              )}
            </Breadcrumb>
            <h1 className='text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl'>
              Toutes les réponses au questionnaire Lagoon{' '}
              {betaContactId &&
                ` du beta testeur ${betaContact.name} ${betaContact.lastName}`}
            </h1>
          </div>
          <div className='block items-center sm:flex'>
            <DataTableController
              placeholder='rechercher un enregistrement'
              searchAttribute={''}
              handleChange={handleChange}
              sort={sort}
              sortOptions={sortOptions}
              selectOptions={[
                <div
                  key={'FilterBetaContactSelect'}
                  className='mb-1 grid grid-cols-1 gap-y-2'
                >
                  <Label htmlFor={`search-user`}>
                    Rechercher un utilisateur
                  </Label>
                  <FilterBetaContactSelect handleChange={handleChange} />
                </div>,
              ]}
              initFilters={[]}
              // initFilters={[
              //   {
              //     name: 'filterFinished',
              //     value: filterFinished,
              //     control: 'true',
              //     text: 'Uniquement les enregistrements terminés',
              //   },
              //   {
              //     name: 'filterFinished',
              //     value: filterFinished,
              //     control: 'false',
              //     text: 'Uniquement les enregistrements non terminés',
              //   },
              // ]}
              // @ts-ignore
              // clean={clearFilters}
            ></DataTableController>
            <div className='w-28 mb-1 grid grid-cols-1 gap-y-2'>
              <Label htmlFor='tabTable'>Changer d'onglet</Label>
              <Select
                id='tabTable'
                className='mt-1'
                onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                  setTabTable(e.currentTarget.value as TabLagoonForm)
                }}
                defaultValue={tabTable}
              >
                <option value='global'>Global</option>
                <option value='concept'>Concept</option>
                <option value='contenu'>Contenu</option>
                <option value='ui'>UI</option>
                <option value='uix'>UIX</option>
                <option value='data'>Data</option>
                <option value='parameters'>Paramètres</option>
              </Select>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='overflow-x-auto'>
          <div className='inline-block min-w-full align-middle'>
            <div className='overflow-hidden shadow'>
              {tabTable === TabLagoonForm.GLOBAL ? (
                <BetaLagoonFormAnswersGlobalTable />
              ) : tabTable === TabLagoonForm.CONCEPT ? (
                <BetaLagoonFormAnswersConceptTable />
              ) : tabTable === TabLagoonForm.CONTENT ? (
                <BetaLagoonFormAnswersContentTable />
              ) : tabTable === TabLagoonForm.UI ? (
                <BetaLagoonFormAnswersUITable />
              ) : tabTable === TabLagoonForm.UIX ? (
                <BetaLagoonFormAnswersUIXTable />
              ) : tabTable === TabLagoonForm.DATA ? (
                <BetaLagoonFormAnswersDataTable />
              ) : (
                tabTable === TabLagoonForm.PARAMETERS && (
                  <BetaLagoonFormAnswersParametersTable />
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <Pagination
        numOfPages={numOfPages}
        total={totalBetaLagoonFormAnswers}
        page={page}
        changePage={changePage}
      />
    </>
  )
}

const DeleteBetaLagoonFormAnswersModal: FC<{
  betaLagoonFormAnswersSelected: BetaLagoonFormAnswers
}> = function ({ betaLagoonFormAnswersSelected }) {
  const [isOpen, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const toast = useToast()
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    toast('success', `L'enregistrement a bien été supprimé`)
    dispatch(
      deleteBetaLagoonFormAnswers(
        betaLagoonFormAnswersSelected._id
          ? betaLagoonFormAnswersSelected._id
          : ''
      )
    )
    dispatch(
      removeBetaLagoonFormAnswersToDataTable(betaLagoonFormAnswersSelected)
    )
    setOpen(false)
  }
  return (
    <>
      <Button color='failure' onClick={() => setOpen(!isOpen)}>
        <HiTrash className='mr-2 text-lg' />
        <span className='hidden lg:block '>Supprimer</span>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen} size='md'>
        <Modal.Header className='px-3 pb-0 pt-3'>
          <span className='sr-only'>Supprimer l'enregistrement</span>
        </Modal.Header>
        <Modal.Body className='px-6 pb-6 pt-0'>
          <div className='flex flex-col items-center gap-y-6 text-center'>
            <HiOutlineExclamationCircle className='text-7xl text-red-600' />
            <p className='text-lg text-gray-500 dark:text-gray-300'>
              Êtes vous sur de vouloir détruire l'enregistrement ?
            </p>
            <div className='flex items-center gap-x-3'>
              <Button color='failure' onClick={handleSubmit}>
                Oui, je suis sur
              </Button>
              <Button color='gray' onClick={() => setOpen(false)}>
                Non, annuler
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const BetaLagoonFormAnswersGlobalTable: FC = function () {
  const { betaLagoonFormAnswers, isLoading } = useTypedSelector(
    (store) => store.allBetaLagoonFormAnswers
  )
  const castBetaLagoonFormAnswers =
    betaLagoonFormAnswers as BetaLagoonFormAnswers[]
  if (isLoading) {
    return <Loading center />
  }
  if (betaLagoonFormAnswers.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>
            Pas d'enregistrements à afficher...
          </span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>
          <span className='sr-only'>Selectionner tous</span>
          <Checkbox />
        </Table.HeadCell>
        <Table.HeadCell>Utilisateur</Table.HeadCell>
        <Table.HeadCell>Statisfaction Globale</Table.HeadCell>
        <Table.HeadCell>Meilleurs Aspects</Table.HeadCell>
        <Table.HeadCell>Améliorations</Table.HeadCell>
        <Table.HeadCell>Anomalies</Table.HeadCell>
        <Table.HeadCell>Fonctionalités futures</Table.HeadCell>
        <Table.HeadCell>Fonctionalités sans intéret</Table.HeadCell>
        <Table.HeadCell>Dernière modification</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {castBetaLagoonFormAnswers.map((entry) => {
          return (
            <Table.Row
              key={`${entry.betaContact}_global`}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='w-4 p-4'>
                <Checkbox />
              </Table.Cell>
              <UserTableCell betaContactInfo={entry.betaContactInfo} />
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.globalSatisfactionNote
                    ? `${entry.globalSatisfactionNote}`
                    : ''}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal text-gray-500 dark:text-gray-400 min-w-[190px]'>
                <div className='text-sm font-normal text-gray-900 dark:text-white'>
                  {entry.globalBestAspects ? (
                    <div className='grid'>
                      {entry.globalBestAspects.data && <Kbd>data</Kbd>}
                      {entry.globalBestAspects.moduleQuality && (
                        <Kbd>qualité des modules</Kbd>
                      )}
                      {entry.globalBestAspects.navigation && (
                        <Kbd>Navigation</Kbd>
                      )}
                      {entry.globalBestAspects.usageSimplicity && (
                        <Kbd>Simplicité d'utilisation</Kbd>
                      )}
                      {entry.globalBestAspects.trainingSubjects && (
                        <Kbd>sujets de formation</Kbd>
                      )}
                      {entry.globalBestAspects.other !== '' &&
                        `${entry.globalBestAspects.other}`}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal min-w-[400px]'>
                <div className='text-sm font-normal text-white'>
                  {entry.globalImprovements && `${entry.globalImprovements}`}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px]'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.globalDeviceAnomalies !== undefined ? (
                    entry.globalDeviceAnomalies === true ? (
                      <span className='text-red-500 inline'>Oui</span>
                    ) : (
                      <span className='text-green-500 inline'>Non</span>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal min-w-[400px]'>
                <div className='text-sm font-normal text-white'>
                  {entry.globalFuturesFeatures &&
                    `${entry.globalFuturesFeatures}`}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal min-w-[400px]'>
                <div className='text-sm font-normal text-white'>
                  {entry.globalCurrentFeaturesNoInterest &&
                    `${entry.globalCurrentFeaturesNoInterest}`}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {`${moment(entry.updatedAt).fromNow()}`}
                </div>
              </Table.Cell>
              <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                <div className='flex items-center gap-x-3'>
                  <DeleteBetaLagoonFormAnswersModal
                    betaLagoonFormAnswersSelected={entry}
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

const BetaLagoonFormAnswersConceptTable: FC = function () {
  const { betaLagoonFormAnswers, isLoading } = useTypedSelector(
    (store) => store.allBetaLagoonFormAnswers
  )
  const castBetaLagoonFormAnswers =
    betaLagoonFormAnswers as BetaLagoonFormAnswers[]
  if (isLoading) {
    return <Loading center />
  }
  if (betaLagoonFormAnswers.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>
            Pas d'enregistrements à afficher...
          </span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>
          <span className='sr-only'>Selectionner tous</span>
          <Checkbox />
        </Table.HeadCell>
        <Table.HeadCell>Utilisateur</Table.HeadCell>
        <Table.HeadCell>Concept Note</Table.HeadCell>
        <Table.HeadCell>LMS Usage</Table.HeadCell>
        <Table.HeadCell>LMS Contraintes</Table.HeadCell>
        <Table.HeadCell>LMS Valeur Ajoutée</Table.HeadCell>
        <Table.HeadCell>Dernière modification</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {castBetaLagoonFormAnswers.map((entry) => {
          return (
            <Table.Row
              key={`${entry.betaContact}_global`}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='w-4 p-4'>
                <Checkbox />
              </Table.Cell>
              <UserTableCell betaContactInfo={entry.betaContactInfo} />
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.conceptNote ? `${entry.conceptNote}` : ''}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal text-gray-500 dark:text-gray-400 min-w-[190px]'>
                <div className='text-sm font-normal text-gray-900 dark:text-white'>
                  {entry.conceptLMSUsage ? (
                    <div className='grid'>
                      {entry.conceptLMSUsage.cornerstone && (
                        <Kbd>Cornerstone</Kbd>
                      )}
                      {entry.conceptLMSUsage.learning360 && (
                        <Kbd>360Learning</Kbd>
                      )}
                      {entry.conceptLMSUsage.moodle && <Kbd>Moodle</Kbd>}
                      {entry.conceptLMSUsage.syfadis && <Kbd>Syfadis</Kbd>}
                      {entry.conceptLMSUsage.other !== '' &&
                        `${entry.conceptLMSUsage.other}`}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal min-w-[400px]'>
                <div className='text-sm font-normal text-gray-900 dark:text-white'>
                  {entry.conceptLMSConstraints ? (
                    <div className='grid'>
                      {entry.conceptLMSConstraints.dataQuality && (
                        <Kbd>Qualité de data</Kbd>
                      )}
                      {entry.conceptLMSConstraints.dataQuantity && (
                        <Kbd>Quantité de data</Kbd>
                      )}
                      {entry.conceptLMSConstraints.dataRepresentation && (
                        <Kbd>Réprésentation de la data</Kbd>
                      )}
                      {entry.conceptLMSConstraints.externalTools && (
                        <Kbd>outils externes</Kbd>
                      )}
                      {entry.conceptLMSConstraints.learnerProfiling && (
                        <Kbd>Profiling utilisateur</Kbd>
                      )}
                      {entry.conceptLMSConstraints.userAccounts && (
                        <Kbd>Gestions des apprenants</Kbd>
                      )}
                      {entry.conceptLMSConstraints.other !== '' &&
                        `${entry.conceptLMSConstraints.other}`}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px]'>
                <div className='text-sm font-normal text-gray-900 dark:text-white'>
                  {entry.conceptLMSVA ? (
                    <div className='grid'>
                      {entry.conceptLMSVA.dataQuality && (
                        <Kbd>Qualité de data</Kbd>
                      )}
                      {entry.conceptLMSVA.dataQuantity && (
                        <Kbd>Quantité de data</Kbd>
                      )}
                      {entry.conceptLMSVA.dataRepresentation && (
                        <Kbd>Réprésentation de la data</Kbd>
                      )}
                      {entry.conceptLMSVA.externalTools && (
                        <Kbd>outils externes</Kbd>
                      )}
                      {entry.conceptLMSVA.learnerProfiling && (
                        <Kbd>Profiling utilisateur</Kbd>
                      )}
                      {entry.conceptLMSVA.userAccounts && (
                        <Kbd>Gestions des apprenants</Kbd>
                      )}
                      {entry.conceptLMSVA.other !== '' &&
                        `${entry.conceptLMSVA.other}`}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {`${moment(entry.updatedAt).fromNow()}`}
                </div>
              </Table.Cell>
              <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                <div className='flex items-center gap-x-3'>
                  <DeleteBetaLagoonFormAnswersModal
                    betaLagoonFormAnswersSelected={entry}
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

const BetaLagoonFormAnswersContentTable: FC = function () {
  const { betaLagoonFormAnswers, isLoading } = useTypedSelector(
    (store) => store.allBetaLagoonFormAnswers
  )
  const castBetaLagoonFormAnswers =
    betaLagoonFormAnswers as BetaLagoonFormAnswers[]
  if (isLoading) {
    return <Loading center />
  }
  if (betaLagoonFormAnswers.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>
            Pas d'enregistrements à afficher...
          </span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>
          <span className='sr-only'>Selectionner tous</span>
          <Checkbox />
        </Table.HeadCell>
        <Table.HeadCell>Utilisateur</Table.HeadCell>
        <Table.HeadCell>Pertinance catalogue</Table.HeadCell>
        <Table.HeadCell>Autres sujets</Table.HeadCell>
        <Table.HeadCell>Contenu(s) favori</Table.HeadCell>
        <Table.HeadCell>Section privée</Table.HeadCell>
        <Table.HeadCell>Dernière modification</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {castBetaLagoonFormAnswers.map((entry) => {
          return (
            <Table.Row
              key={`${entry.betaContact}_global`}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='w-4 p-4'>
                <Checkbox />
              </Table.Cell>
              <UserTableCell betaContactInfo={entry.betaContactInfo} />
              <Table.Cell className='p-4 text-sm font-normal min-w-[400px]'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {`${entry.contentCatalogRelevant}`}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal min-w-[400px]'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {`${entry.contentCatalogRelevant}`}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal min-w-[400px]'>
                <div className='text-sm font-normal text-gray-900 dark:text-white'>
                  {entry.contentFavoriteType ? (
                    <div className='grid'>
                      {entry.contentFavoriteType.interactiveVideo && (
                        <Kbd>Vidéo intéractive</Kbd>
                      )}
                      {entry.contentFavoriteType.motionDesign && (
                        <Kbd>Motion design</Kbd>
                      )}
                      {entry.contentFavoriteType.podCast && <Kbd>Podcast</Kbd>}
                      {entry.contentFavoriteType.reportVideo && (
                        <Kbd>Reportage</Kbd>
                      )}
                      {entry.contentFavoriteType.other !== '' &&
                        `${entry.contentFavoriteType.other}`}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px]'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.contentPrivateSection !== undefined ? (
                    entry.contentPrivateSection === true ? (
                      <span className='inline'>Oui</span>
                    ) : (
                      <span className='inline'>Non</span>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {`${moment(entry.updatedAt).fromNow()}`}
                </div>
              </Table.Cell>
              <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                <div className='flex items-center gap-x-3'>
                  <DeleteBetaLagoonFormAnswersModal
                    betaLagoonFormAnswersSelected={entry}
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

const BetaLagoonFormAnswersUITable: FC = function () {
  const { betaLagoonFormAnswers, isLoading } = useTypedSelector(
    (store) => store.allBetaLagoonFormAnswers
  )
  const castBetaLagoonFormAnswers =
    betaLagoonFormAnswers as BetaLagoonFormAnswers[]
  if (isLoading) {
    return <Loading center />
  }
  if (betaLagoonFormAnswers.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>
            Pas d'enregistrements à afficher...
          </span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>
          <span className='sr-only'>Selectionner tous</span>
          <Checkbox />
        </Table.HeadCell>
        <Table.HeadCell>Utilisateur</Table.HeadCell>
        <Table.HeadCell>Ui Note</Table.HeadCell>
        <Table.HeadCell>Navigation Note</Table.HeadCell>
        <Table.HeadCell>Amélioration(s) page module</Table.HeadCell>
        <Table.HeadCell>Amélioration(s) page catalogue</Table.HeadCell>
        <Table.HeadCell>Dernière modification</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {castBetaLagoonFormAnswers.map((entry) => {
          return (
            <Table.Row
              key={`${entry.betaContact}_global`}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='w-4 p-4'>
                <Checkbox />
              </Table.Cell>
              <UserTableCell betaContactInfo={entry.betaContactInfo} />
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.uiAttractiveNote ? `${entry.uiAttractiveNote}` : ''}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.uiNavigation ? `${entry.uiNavigation}` : ''}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal min-w-[400px]'>
                <div className='text-sm font-normal text-white'>
                  {entry.uiPageModuleImprovements &&
                    `${entry.uiPageModuleImprovements}`}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal min-w-[400px]'>
                <div className='text-sm font-normal text-white'>
                  {entry.uiCategoryModuleImprovements &&
                    `${entry.uiCategoryModuleImprovements}`}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {`${moment(entry.updatedAt).fromNow()}`}
                </div>
              </Table.Cell>
              <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                <div className='flex items-center gap-x-3'>
                  <DeleteBetaLagoonFormAnswersModal
                    betaLagoonFormAnswersSelected={entry}
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

const BetaLagoonFormAnswersUIXTable: FC = function () {
  const { betaLagoonFormAnswers, isLoading } = useTypedSelector(
    (store) => store.allBetaLagoonFormAnswers
  )
  const castBetaLagoonFormAnswers =
    betaLagoonFormAnswers as BetaLagoonFormAnswers[]
  if (isLoading) {
    return <Loading center />
  }
  if (betaLagoonFormAnswers.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>
            Pas d'enregistrements à afficher...
          </span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>
          <span className='sr-only'>Selectionner tous</span>
          <Checkbox />
        </Table.HeadCell>
        <Table.HeadCell>Utilisateur</Table.HeadCell>
        <Table.HeadCell>Chargements acceptables</Table.HeadCell>
        <Table.HeadCell>Lisibilité</Table.HeadCell>
        <Table.HeadCell>Navigation entre les pages</Table.HeadCell>
        <Table.HeadCell>Dernière modification</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {castBetaLagoonFormAnswers.map((entry) => {
          return (
            <Table.Row
              key={`${entry.betaContact}_global`}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='w-4 p-4'>
                <Checkbox />
              </Table.Cell>
              <UserTableCell betaContactInfo={entry.betaContactInfo} />
              <Table.Cell className='p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px]'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.uixLoadingAcceptable !== undefined ? (
                    entry.uixLoadingAcceptable === true ? (
                      <span className='text-green-500 inline'>Oui</span>
                    ) : (
                      <span className='text-red-500 inline'>Non</span>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px]'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.uixReadability !== undefined ? (
                    entry.uixReadability === true ? (
                      <span className='text-green-500 inline'>Oui</span>
                    ) : (
                      <span className='text-red-500 inline'>Non</span>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal min-w-[400px]'>
                <div className='text-sm font-normal text-white'>
                  {entry.uixNavigationBetweenPages &&
                    `${entry.uixNavigationBetweenPages}`}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {`${moment(entry.updatedAt).fromNow()}`}
                </div>
              </Table.Cell>
              <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                <div className='flex items-center gap-x-3'>
                  <DeleteBetaLagoonFormAnswersModal
                    betaLagoonFormAnswersSelected={entry}
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

const BetaLagoonFormAnswersDataTable: FC = function () {
  const { betaLagoonFormAnswers, isLoading } = useTypedSelector(
    (store) => store.allBetaLagoonFormAnswers
  )
  const castBetaLagoonFormAnswers =
    betaLagoonFormAnswers as BetaLagoonFormAnswers[]
  if (isLoading) {
    return <Loading center />
  }
  if (betaLagoonFormAnswers.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>
            Pas d'enregistrements à afficher...
          </span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>
          <span className='sr-only'>Selectionner tous</span>
          <Checkbox />
        </Table.HeadCell>
        <Table.HeadCell>Utilisateur</Table.HeadCell>
        <Table.HeadCell>Données personnelles</Table.HeadCell>
        <Table.HeadCell>Dashboard User</Table.HeadCell>
        <Table.HeadCell>Dashboard Admin</Table.HeadCell>
        <Table.HeadCell>Surveillance des données</Table.HeadCell>
        <Table.HeadCell>Inscris</Table.HeadCell>
        <Table.HeadCell>Souscris</Table.HeadCell>
        <Table.HeadCell>Tarif utilisateur</Table.HeadCell>
        <Table.HeadCell>Comparaison budget</Table.HeadCell>
        <Table.HeadCell>Investissement</Table.HeadCell>
        <Table.HeadCell>Dernière modification</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {castBetaLagoonFormAnswers.map((entry) => {
          return (
            <Table.Row
              key={`${entry.betaContact}_global`}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='w-4 p-4'>
                <Checkbox />
              </Table.Cell>
              <UserTableCell betaContactInfo={entry.betaContactInfo} />
              <Table.Cell className='p-4 text-sm font-normal text-gray-500 dark:text-gray-400 min-w-[190px]'>
                <div className='text-sm font-normal text-gray-900 dark:text-white'>
                  {entry.dataMyPersonalData ? (
                    <div className='grid'>
                      {entry.dataMyPersonalData.hardSkills && (
                        <Kbd>hard skills</Kbd>
                      )}
                      {entry.dataMyPersonalData.softSkills && (
                        <Kbd>soft skills</Kbd>
                      )}
                      {entry.dataMyPersonalData.history && (
                        <Kbd>Historique</Kbd>
                      )}
                      {entry.dataMyPersonalData.moduleProgression && (
                        <Kbd>Progression</Kbd>
                      )}
                      {entry.dataMyPersonalData.scoreProgression && (
                        <Kbd>Score</Kbd>
                      )}
                      {entry.dataMyPersonalData.sharing && (
                        <Kbd>Partager mes résultats</Kbd>
                      )}
                      {entry.dataMyPersonalData.other !== '' &&
                        `${entry.dataMyPersonalData.other}`}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal text-gray-500 dark:text-gray-400 min-w-[190px]'>
                <div className='text-sm font-normal text-gray-900 dark:text-white'>
                  {entry.dataUserDashboard ? (
                    <div className='grid'>
                      {entry.dataUserDashboard.advices && <Kbd>Conseils</Kbd>}
                      {entry.dataUserDashboard.lastTrainings && (
                        <Kbd>Dernières formations</Kbd>
                      )}
                      {entry.dataUserDashboard.notifications && (
                        <Kbd>Notificiations</Kbd>
                      )}
                      {entry.dataUserDashboard.searchTraining && (
                        <Kbd>Recherche avancée</Kbd>
                      )}
                      {entry.dataUserDashboard.other !== '' &&
                        `${entry.dataUserDashboard.other}`}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal text-gray-500 dark:text-gray-400 min-w-[190px]'>
                <div className='text-sm font-normal text-gray-900 dark:text-white'>
                  {entry.dataAdminDashboard ? (
                    <div className='grid'>
                      {entry.dataAdminDashboard.bestTrainings && (
                        <Kbd>Meilleures formations</Kbd>
                      )}
                      {entry.dataAdminDashboard.lastUsers && (
                        <Kbd>Dernières inscriptions</Kbd>
                      )}
                      {entry.dataAdminDashboard.motivationCenter && (
                        <Kbd>Centre de motivation</Kbd>
                      )}
                      {entry.dataAdminDashboard.worstResults && (
                        <Kbd>Questions les plus difficiles</Kbd>
                      )}
                      {entry.dataAdminDashboard.other !== '' &&
                        `${entry.dataAdminDashboard.other}`}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal min-w-[190px]'>
                <div className='text-sm font-normal text-white'>
                  {entry.dataMonitoring && (
                    <Kbd>{`${entry.dataMonitoring}`}</Kbd>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal'>
                <div className='text-sm font-normal text-white'>
                  {entry.dataNbInscriptions && `${entry.dataNbInscriptions}`}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal'>
                <div className='text-sm font-normal text-white'>
                  {entry.dataNbSubscriptions && `${entry.dataNbSubscriptions}`}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal'>
                <div className='text-sm font-normal text-white'>
                  {entry.dataPriceRange && `${entry.dataPriceRange}`}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal'>
                <div className='text-sm font-normal text-white'>
                  {entry.dataPriceComparison && (
                    <Kbd>{`${entry.dataPriceComparison}`}</Kbd>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px]'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.dataInvestment !== undefined ? (
                    entry.dataInvestment === DataInvestmentEnum.YES ? (
                      <span className='text-green-500 inline'>Oui</span>
                    ) : entry.dataInvestment === DataInvestmentEnum.NO ? (
                      <span className='text-red-500 inline'>Non</span>
                    ) : (
                      <span className='text-gray-500 inline'>Ne sais pas</span>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {`${moment(entry.updatedAt).fromNow()}`}
                </div>
              </Table.Cell>
              <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                <div className='flex items-center gap-x-3'>
                  <DeleteBetaLagoonFormAnswersModal
                    betaLagoonFormAnswersSelected={entry}
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

const BetaLagoonFormAnswersParametersTable: FC = function () {
  const { betaLagoonFormAnswers, isLoading } = useTypedSelector(
    (store) => store.allBetaLagoonFormAnswers
  )
  const castBetaLagoonFormAnswers =
    betaLagoonFormAnswers as BetaLagoonFormAnswers[]
  if (isLoading) {
    return <Loading center />
  }
  if (betaLagoonFormAnswers.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>
            Pas d'enregistrements à afficher...
          </span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>
          <span className='sr-only'>Selectionner tous</span>
          <Checkbox />
        </Table.HeadCell>
        <Table.HeadCell>Utilisateur</Table.HeadCell>
        <Table.HeadCell>Temps passé</Table.HeadCell>
        <Table.HeadCell>Edité</Table.HeadCell>
        <Table.HeadCell>Dernière modification</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {castBetaLagoonFormAnswers.map((entry) => {
          return (
            <Table.Row
              key={`${entry.betaContact}_global`}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='w-4 p-4'>
                <Checkbox />
              </Table.Cell>
              <UserTableCell betaContactInfo={entry.betaContactInfo} />
              <Table.Cell className='p-4 text-sm font-normal text-gray-500 dark:text-gray-400'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.paramFormTimeElapsed
                    ? `${entry.paramFormTimeElapsed}`
                    : ''}
                </div>
              </Table.Cell>
              <Table.Cell className='p-4 text-sm font-normal text-gray-500 dark:text-gray-400'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {entry.paramFormNbSaved ? `${entry.paramFormNbSaved}` : ''}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {`${moment(entry.updatedAt).fromNow()}`}
                </div>
              </Table.Cell>
              <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                <div className='flex items-center gap-x-3'>
                  <DeleteBetaLagoonFormAnswersModal
                    betaLagoonFormAnswersSelected={entry}
                  />
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

const UserTableCell: FC<{ betaContactInfo: BetaContact }> = function ({
  betaContactInfo,
}) {
  return (
    <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
      <div className='text-base font-semibold text-gray-900 dark:text-white'>
        {`${betaContactInfo.name} ${betaContactInfo.lastName}`}
      </div>
      <div className='text-sm font-normal text-white'>
        {betaContactInfo.employment && (
          <>
            {betaContactInfo.employment.digitalTraining && (
              <div className='flex items-center'>
                <Kbd>Formation digitale</Kbd>
              </div>
            )}
            {betaContactInfo.employment.facetofaceTraining && (
              <div className='flex items-center'>
                <Kbd>Formation présentielle</Kbd>
              </div>
            )}
            {betaContactInfo.employment.futureChallenges && (
              <div className='flex items-center'>
                <Kbd>Enjeux climatiques</Kbd>
              </div>
            )}
            {betaContactInfo.employment.hr && (
              <div className='flex items-center'>
                <Kbd>RH</Kbd>
              </div>
            )}
            {betaContactInfo.employment.ceo && (
              <div className='flex items-center'>
                <Kbd>CEO</Kbd>
              </div>
            )}
            {betaContactInfo.employment.other && (
              <div className='flex items-center'>
                <span>{betaContactInfo.employment.other}</span>
              </div>
            )}
          </>
        )}
      </div>
    </Table.Cell>
  )
}

export default LagoonFormAnswersListPage
