import { Button, Modal, Table } from 'flowbite-react'
import { useEffect, type FC, useState } from 'react'
import {
  HiAdjustments,
  HiArrowNarrowDown,
  HiArrowNarrowUp,
  HiOutlineExclamationCircle,
  HiTrash,
} from 'react-icons/hi'
import { useAppDispatch, useTypedSelector } from '../../store'
import { Loading } from '../../components'
import { useToast } from '../../hooks/useToast'

import {
  clearChangeOrder,
  toggleActivityStatus,
  updateActivityLearningScenarioOrder,
} from '../../features/moduleVersion/moduleVersionSlice'
import { FaEdit, FaPlus } from 'react-icons/fa'
import {
  Activity,
  ActivityEnum,
  ActivityForm,
  Quiz,
  QuizForm,
  WebObject,
  WebObjectForm,
  addQuiz,
  addWebObject,
  clearActivityForm,
  editWebObject,
  editQuiz,
  removeActivity,
} from '../../features/activity/activitySlice'
import {
  addActivityToDataTable,
  editActivitiesToDataTable,
  getAllActivities,
} from '../../features/allActivities/allActivitiesSlice'
import { ActivityModalBody } from '../../components/modal-body/Activity'
import { useNavigate } from 'react-router-dom'
import CopyToClipBoard from '../../components/CopyToClipBoard'
import { MdClose, MdUpdate } from 'react-icons/md'

export const ActivitiesTable: FC = function () {
  const { activities, isLoading } = useTypedSelector(
    (store) => store.allActivities
  )
  const { moduleVersion, isLoading: isLoadingMV } = useTypedSelector(
    (store) => store.moduleVersion
  )
  const castActivities = activities as Activity[]
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  if (isLoading) {
    return <Loading center />
  }
  if (activities.length > 0) {
    return (
      <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
        <Table.Head className='bg-gray-100 dark:bg-gray-700'>
          <Table.HeadCell>Ordre</Table.HeadCell>
          <Table.HeadCell>Type</Table.HeadCell>
          <Table.HeadCell>Chapitre</Table.HeadCell>
          <Table.HeadCell>Objectif Pédagogique</Table.HeadCell>
          <Table.HeadCell>Actions</Table.HeadCell>
        </Table.Head>
        <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
          {castActivities.map((activity, index) => {
            const webobject =
              activity.kind === 'WebObject' ? (activity as WebObject) : null
            return (
              <Table.Row
                key={activity._id}
                className='hover:bg-gray-100 dark:hover:bg-gray-700'
              >
                <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                  {moduleVersion.isBeta ? (
                    <div className='flex items-center gap-x-3'>
                      <ChangeActivityOrderButton activity={activity} />
                    </div>
                  ) : (
                    <span>{index + 1}</span>
                  )}
                </Table.Cell>
                <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                  <div className='text-base font-semibold text-gray-900 dark:text-white'>
                    {activity.kind}
                  </div>

                  <div className='text-sm font-normal text-gray-500 dark:text-gray-400 flex'>
                    {activity.lrsObjectId}
                    <CopyToClipBoard textToCopy={activity.lrsObjectId} />
                  </div>
                  {moduleVersion.changeLog.find(
                    (entry) =>
                      entry.lrsObjectId === activity.lrsObjectId &&
                      entry.verb === 'add'
                  ) ? (
                    <div className='text-sm font-normal italic text-green-500 flex'>
                      Ajouté
                    </div>
                  ) : moduleVersion.changeLog.find(
                      (entry) =>
                        entry.lrsObjectId === activity.lrsObjectId &&
                        entry.verb === 'update'
                    ) ? (
                    <div className='text-sm font-normal italic text-primary-600 flex'>
                      <span>Mis à jour</span>
                      <Button
                        onClick={() =>
                          dispatch(toggleActivityStatus(activity._id as string))
                        }
                        color='dark'
                        size='xs'
                        className='ml-2'
                        disabled={isLoadingMV}
                      >
                        <MdClose className='h-3 w-3' />
                      </Button>
                    </div>
                  ) : (
                    <div className='text-sm font-normal italic text-gray-400 flex'>
                      <span>Non modifié</span>
                      <Button
                        onClick={() =>
                          dispatch(toggleActivityStatus(activity._id as string))
                        }
                        color='dark'
                        size='xs'
                        className='ml-2'
                        disabled={isLoadingMV}
                      >
                        <MdUpdate className='h-3 w-3' />
                      </Button>
                    </div>
                  )}
                </Table.Cell>
                <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400'>
                  <div className='text-base font-semibold text-gray-900 dark:text-white'>
                    {activity.chapter}
                  </div>
                  <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                    {activity.subChapters.toString()}
                  </div>
                  {webobject && (
                    <a
                      className='text-primary-600 font-normal underline'
                      href={webobject.url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {webobject.url}
                    </a>
                  )}
                </Table.Cell>
                <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[200px] overflow-hidden text-ellipsis'>
                  <div className='text-base font-semibold text-gray-900 dark:text-white'>
                    {activity.learningObjectiveInfo?.name}
                  </div>
                  <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                    Interactions : {activity.totalInteractions}
                  </div>
                </Table.Cell>
                <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                  <div className='flex items-center gap-x-3'>
                    <Button
                      color='primary'
                      onClick={() => navigate(`./activity/${activity._id}`)}
                    >
                      <HiAdjustments className='mr-2 text-lg' />
                      <span className='hidden lg:block '>
                        Gérer les intéractions
                      </span>
                    </Button>
                    {moduleVersion.isBeta && (
                      <>
                        {activity.kind === ActivityEnum.WEBOBJECT && (
                          <EditWebObjectModal
                            activitySelected={activity as WebObject}
                          />
                        )}
                        {activity.kind === ActivityEnum.QUIZ && (
                          <EditQuizModal activitySelected={activity as Quiz} />
                        )}
                        <DeleteActivityModal activity={activity} />
                      </>
                    )}
                  </div>
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    )
  } else {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>Pas d'activités à afficher...</span>
        </div>
      </>
    )
  }
}

export const AddActivityModal: FC = function () {
  const { moduleVersion } = useTypedSelector((store) => store.moduleVersion)

  let initialValues: ActivityForm = {
    learningScenario: '',
    chapter: '',
    subChapters: [],
    learningObjective: '',
  }

  const { isLoading, activity } = useTypedSelector((store) => store.activity)
  const [isOpen, setOpen] = useState(false)
  const [values, setValues] = useState<ActivityForm>({ ...initialValues })
  const [activityType, setActivityType] = useState<string>('')
  const [subChaptersText, setSubChaptersText] = useState<string>('')
  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (
      moduleVersion &&
      moduleVersion._id &&
      moduleVersion.learningScenarioInfo
    ) {
      setValues({
        ...values,
        learningScenario: moduleVersion.learningScenarioInfo._id,
      })
    }
    // eslint-disable-next-line
  }, [moduleVersion, moduleVersion.learningScenario])

  useEffect(() => {
    if (!isOpen) {
      //clear props
      setValues({
        learningScenario: moduleVersion.learningScenario || '',
        chapter: '',
        learningObjective: '',
        url: '',
        onlyOnce: false,
      })
      setActivityType('')
      setSubChaptersText('')
      //clear form
      dispatch(clearActivityForm())
    }
    // eslint-disable-next-line
  }, [isOpen])

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const handleSubChapterText = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    setSubChaptersText(value)
    setValues({ ...values, subChapters: value.split(';') })
  }

  const handleOnlyOnce = () => {
    setValues({ ...values, onlyOnce: !values.onlyOnce })
  }

  const handleSelectActivityChange = (
    e: React.FormEvent<HTMLSelectElement>
  ) => {
    const value = e.currentTarget.value
    setActivityType(value)
  }

  const handleSelectLearningObjectiveChange = (
    e: React.FormEvent<HTMLSelectElement>
  ) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { chapter, learningScenario } = values
    if (chapter !== '' && learningScenario !== '') {
      switch (activityType) {
        case ActivityEnum.WEBOBJECT:
          const webObjectForm: WebObjectForm = {
            ...values,
            url: values.url || '',
          }
          dispatch(addWebObject(webObjectForm))
          break
        case ActivityEnum.QUIZ:
          const quizForm: QuizForm = {
            ...values,
            onlyOnce: values.onlyOnce || false,
          }
          dispatch(addQuiz(quizForm))
          break

        default:
          toast('error', `Le type d'activité est introuvable`)
          break
      }
    } else {
      toast('error', `Vous avez besoin de renseigner les elements requis`)
    }
  }

  useEffect(() => {
    if (isOpen && activity && activity._id) {
      toast('success', `Une nouvelle activité ${activity.kind} a bien été crée`)
      //refresh list
      dispatch(addActivityToDataTable(activity))
      //clear form
      dispatch(clearActivityForm())
      //close
      setOpen(false)
    }
    // eslint-disable-next-line
  }, [isOpen, activity])

  return (
    <>
      <Button color='primary' onClick={() => setOpen(!isOpen)}>
        <FaPlus className='mr-3 text-sm' />
        Ajouter une activité
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Ajouter une activité</strong>
          </Modal.Header>
          <ActivityModalBody
            values={values}
            handleChange={handleChange}
            subChaptersText={subChaptersText}
            handleSubChapterText={handleSubChapterText}
            activityType={activityType}
            handleSelectActivityChange={handleSelectActivityChange}
            handleSelectLearningObjectiveChange={
              handleSelectLearningObjectiveChange
            }
            handleOnlyOnce={handleOnlyOnce}
          />
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Ajouter
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export const EditWebObjectModal: FC<{ activitySelected: WebObject }> =
  function ({ activitySelected }) {
    const { moduleVersion } = useTypedSelector((store) => store.moduleVersion)

    let initialValues: WebObjectForm = {
      _id: '',
      learningScenario: '',
      chapter: activitySelected.chapter,
      subChapters: [],
      learningObjective: activitySelected.learningObjective,
      url: '',
    }

    const { isLoading, activity } = useTypedSelector((store) => store.activity)
    const [isOpen, setOpen] = useState(false)
    const [values, setValues] = useState<WebObjectForm>({ ...initialValues })
    const [subChaptersText, setSubChaptersText] = useState<string>('')
    const dispatch = useAppDispatch()
    const toast = useToast()

    useEffect(() => {
      if (
        moduleVersion &&
        moduleVersion._id &&
        moduleVersion.learningScenarioInfo
      ) {
        setValues({
          ...values,
          learningScenario: moduleVersion.learningScenarioInfo._id,
        })
      }
      // eslint-disable-next-line
    }, [moduleVersion, moduleVersion.learningScenario])

    useEffect(() => {
      if (isOpen) {
        //clear form
        dispatch(clearActivityForm())
        setSubChaptersText(activitySelected.subChapters.join(';'))
        setValues({
          ...values,
          _id: activitySelected._id,
          learningScenario: moduleVersion.learningScenario || '',
          subChapters: activitySelected.subChapters,
          url: activitySelected.url,
        })
      }
      // eslint-disable-next-line
    }, [isOpen])

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name
      const value = e.currentTarget.value
      setValues({ ...values, [name]: value })
    }

    const handleSubChapterText = (e: React.FormEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value
      setSubChaptersText(value)
      setValues({ ...values, subChapters: value.split(';') })
    }

    const handleSelectLearningObjectiveChange = (
      e: React.FormEvent<HTMLSelectElement>
    ) => {
      const name = e.currentTarget.name
      const value = e.currentTarget.value
      setValues({ ...values, [name]: value })
    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const { chapter, learningScenario } = values
      if (chapter !== '' && learningScenario !== '') {
        dispatch(editWebObject(values))
      } else {
        toast('error', `Vous avez besoin de renseigner les elements requis`)
      }
    }

    useEffect(() => {
      if (isOpen && activity && activity._id) {
        toast('success', `L'activité ${activity.chapter} a été modifiée`)
        // edit in datatable
        dispatch(editActivitiesToDataTable(activity))
        //clear form
        dispatch(clearActivityForm())
        //close
        setOpen(false)
      }
      // eslint-disable-next-line
    }, [isOpen, activity])

    return (
      <>
        <Button color='primary' onClick={() => setOpen(!isOpen)}>
          <FaEdit className='mr-3 text-sm' />
          Editer une activité
        </Button>
        <Modal onClose={() => setOpen(false)} show={isOpen}>
          <form onSubmit={onSubmit}>
            <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
              <strong>Editer une activité</strong>
            </Modal.Header>
            <ActivityModalBody
              values={values}
              handleChange={handleChange}
              subChaptersText={subChaptersText}
              activityType={activitySelected.kind}
              handleSubChapterText={handleSubChapterText}
              handleSelectLearningObjectiveChange={
                handleSelectLearningObjectiveChange
              }
            />
            <Modal.Footer>
              <Button disabled={isLoading} color='primary' type='submit'>
                Editer
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    )
  }
export const EditQuizModal: FC<{ activitySelected: Quiz }> = function ({
  activitySelected,
}) {
  const { moduleVersion } = useTypedSelector((store) => store.moduleVersion)

  let initialValues: QuizForm = {
    _id: '',
    learningScenario: '',
    chapter: activitySelected.chapter,
    subChapters: [],
    learningObjective: activitySelected.learningObjective,
    onlyOnce: activitySelected.onlyOnce,
  }

  const { isLoading, activity } = useTypedSelector((store) => store.activity)
  const [isOpen, setOpen] = useState(false)
  const [values, setValues] = useState<QuizForm>({ ...initialValues })
  const [subChaptersText, setSubChaptersText] = useState<string>('')
  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (
      moduleVersion &&
      moduleVersion._id &&
      moduleVersion.learningScenarioInfo
    ) {
      setValues({
        ...values,
        learningScenario: moduleVersion.learningScenarioInfo._id,
      })
    }
    // eslint-disable-next-line
  }, [moduleVersion, moduleVersion.learningScenario])

  useEffect(() => {
    if (isOpen) {
      //clear form
      dispatch(clearActivityForm())
      setSubChaptersText(activitySelected.subChapters.join(';'))
      setValues({
        ...values,
        _id: activitySelected._id,
        learningScenario: moduleVersion.learningScenario || '',
        subChapters: activitySelected.subChapters,
        onlyOnce: activitySelected.onlyOnce,
      })
    }
    // eslint-disable-next-line
  }, [isOpen])

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const handleSubChapterText = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    setSubChaptersText(value)
    setValues({ ...values, subChapters: value.split(';') })
  }

  const handleOnlyOnce = () => {
    setValues({ ...values, onlyOnce: !values.onlyOnce })
  }

  const handleSelectLearningObjectiveChange = (
    e: React.FormEvent<HTMLSelectElement>
  ) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { chapter, learningScenario } = values
    if (chapter !== '' && learningScenario !== '') {
      dispatch(editQuiz(values))
    } else {
      toast('error', `Vous avez besoin de renseigner les elements requis`)
    }
  }

  useEffect(() => {
    if (isOpen && activity && activity._id) {
      toast('success', `L'activité ${activity.chapter} a été modifiée`)
      // edit in datatable
      dispatch(editActivitiesToDataTable(activity))
      //clear form
      dispatch(clearActivityForm())
      //close
      setOpen(false)
    }
    // eslint-disable-next-line
  }, [isOpen, activity])

  return (
    <>
      <Button color='primary' onClick={() => setOpen(!isOpen)}>
        <FaEdit className='mr-3 text-sm' />
        Editer une activité
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Editer une activité</strong>
          </Modal.Header>
          <ActivityModalBody
            values={values}
            handleChange={handleChange}
            subChaptersText={subChaptersText}
            activityType={activitySelected.kind}
            handleSubChapterText={handleSubChapterText}
            handleSelectLearningObjectiveChange={
              handleSelectLearningObjectiveChange
            }
            handleOnlyOnce={handleOnlyOnce}
          />
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Editer
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export const DeleteActivityModal: FC<{ activity: Activity }> = function ({
  activity,
}) {
  const { success } = useTypedSelector((store) => store.activity)
  const [isOpen, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const toast = useToast()

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    dispatch(removeActivity(activity))
  }
  useEffect(() => {
    if (isOpen && success) {
      toast('success', `L'activité ${activity.chapter} à bien été supprimée`)
      //refresh
      dispatch(clearActivityForm())
      //close
      setOpen(false)
    }
    // eslint-disable-next-line
  }, [isOpen, success])
  return (
    <>
      <Button color='failure' onClick={() => setOpen(!isOpen)}>
        <HiTrash className='mr-2 text-lg' />
        <span className='hidden lg:block '>Supprimer l'activité</span>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen} size='md'>
        <Modal.Header className='px-3 pb-0 pt-3'>
          <span className='sr-only'>Supprimer l'activité</span>
        </Modal.Header>
        <Modal.Body className='px-6 pb-6 pt-0'>
          <div className='flex flex-col items-center gap-y-6 text-center'>
            <HiOutlineExclamationCircle className='text-7xl text-red-600' />
            <p className='text-lg text-gray-500 dark:text-gray-300'>
              Êtes vous sur de vouloir détruire l'activité {activity.chapter} ?
            </p>
            <div className='flex items-center gap-x-3'>
              <Button color='failure' onClick={handleSubmit}>
                Oui, je suis sur
              </Button>
              <Button color='gray' onClick={() => setOpen(false)}>
                Non, annuler
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export const ChangeActivityOrderButton: FC<{ activity: Activity }> = function ({
  activity,
}) {
  const { totalActivities } = useTypedSelector((store) => store.allActivities)
  const { moduleVersion, isLoading, changeOrder } = useTypedSelector(
    (store) => store.moduleVersion
  )
  const [incOrder, setIncOrder] = useState(1)
  const [descOrder, setDescOrder] = useState(1)
  useEffect(() => {
    var inc = activity.order + 1
    if (inc > totalActivities) {
      inc = 1
    }
    setIncOrder(inc)
  }, [activity, totalActivities])
  useEffect(() => {
    var desc = activity.order - 1
    if (desc < 1) {
      desc = totalActivities
    }
    setDescOrder(desc)
  }, [activity, totalActivities])

  const dispatch = useAppDispatch()

  const OnClickButton = (order: number) => {
    dispatch(
      updateActivityLearningScenarioOrder({
        learningScenario: moduleVersion.learningScenario || '',
        activity: activity._id,
        order,
      })
    )
  }

  useEffect(() => {
    if (changeOrder) {
      dispatch(getAllActivities({}))
      dispatch(clearChangeOrder())
    }
    // eslint-disable-next-line
  }, [changeOrder])

  return (
    <>
      <span>{activity.order}</span>
      <Button
        disabled={isLoading}
        color='primary'
        size='xs'
        onClick={() => OnClickButton(incOrder)}
      >
        <HiArrowNarrowDown className='text-sm' />
        <span className='hidden'>Placer en dessous</span>
      </Button>
      <Button
        disabled={isLoading}
        color='primary'
        size='xs'
        onClick={() => OnClickButton(descOrder)}
      >
        <HiArrowNarrowUp className='text-sm' />
        <span className='hidden'>Placer au dessus</span>
      </Button>
    </>
  )
}
