import env from 'react-dotenv'
import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const getAllModulesVersionThunk = async (_, thunkAPI) => {
  const { module } = thunkAPI.getState().module
  const limit = env.DEFAULT_LIMIT_PAGINATION
  const { page, search, sort } = thunkAPI.getState().allModulesVersion
  let url = `/mversion?module=${module._id}&sort=${sort}&page=${page}&limit=${limit}`
  if (search) {
    url = url + `&search=${search}`
  }

  try {
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
