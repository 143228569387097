/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb } from 'flowbite-react'
import { useEffect, type FC } from 'react'
import { HiHome } from 'react-icons/hi'
import { useAppDispatch, useTypedSelector } from '../../store'
import { DataTableController, Loading, Pagination } from '../../components'
import { useToast } from '../../hooks/useToast'
import { useParams } from 'react-router-dom'
import { getActivity } from '../../features/activity/activitySlice'
import {
  changePage,
  getAllInteractions,
  setActivity,
  handleChange,
  clearFilters,
} from '../../features/allInteractions/allInteractionsSlice'
import { AddInteractionModal, InteractionsTable } from '../interactions/list'
import StateText from '../../components/StateText'
import { clearInteractionForm } from '../../features/interaction/interactionSlice'

const ActivityPage: FC = function () {
  const { error, activity, isLoading, moduleVersion } = useTypedSelector(
    (store) => store.activity
  )
  const {
    error: errorInteractions,
    totalInteractions,
    numOfPages,
    page,
    interactions,
    search,
    sort,
    searchType,
  } = useTypedSelector((store) => store.allInteractions)
  const { error: errorInteraction } = useTypedSelector(
    (store) => store.interaction
  )
  const dispatch = useAppDispatch()
  const toast = useToast()
  const { id } = useParams()

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
    }
    if (errorInteraction !== '') {
      toast('error', errorInteraction)
      dispatch(clearInteractionForm())
    }
    if (errorInteractions !== '') {
      toast('error', errorInteractions)
    }
    // eslint-disable-next-line
  }, [error, errorInteraction, errorInteractions])

  useEffect(() => {
    dispatch(getActivity(id as string))
  }, [dispatch, id, interactions])

  useEffect(() => {
    if (id) {
      dispatch(setActivity(id))
      dispatch(getAllInteractions({}))
    }
  }, [dispatch, id, page, search, sort, searchType])

  if (isLoading) {
    return <Loading center />
  }

  return (
    <>
      <div className='block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex'>
        <div className='mb-1 w-full'>
          <div className='mb-4'>
            <Breadcrumb className='mb-4'>
              <Breadcrumb.Item href='#'>
                <div className='flex items-center gap-x-3'>
                  <HiHome className='text-xl' />
                  <span className='dark:text-white'>Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href='/modules'>Modules</Breadcrumb.Item>
              <Breadcrumb.Item href={`/modules/${moduleVersion.module}`}>
                {moduleVersion.moduleInfo?.name || 'not found'}
              </Breadcrumb.Item>
              <Breadcrumb.Item
                href={`/modules/${moduleVersion.module}/version/${moduleVersion._id}`}
              >
                {moduleVersion.version}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{activity.chapter}</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className='text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl'>
              Mise en place des interactions de l'activité {activity.chapter} du
              module {moduleVersion.moduleInfo?.name || 'not found'}
            </h1>
          </div>
          {activity.learningObjectiveInfo && (
            <div className='mb-4'>
              <h2 className='text-xl font-semibold text-gray-900 dark:text-white sm:text-lg'>
                Objectif pédagogique associé
              </h2>
              <StateText
                key={activity.learningObjectiveInfo._id}
                bool={true}
                text={activity.learningObjectiveInfo.name}
              />
            </div>
          )}
          <>
            <div className='block items-center sm:flex mb-4'>
              <DataTableController
                placeholder='rechercher une interaction'
                handleChange={handleChange}
                sort={sort}
                search={search}
                // @ts-ignore
                clean={clearFilters}
                initFilters={[
                  {
                    name: 'searchType',
                    value: searchType,
                    control: 'Assessment',
                    text: 'Assessment uniquement',
                  },
                  {
                    name: 'searchType',
                    value: searchType,
                    control: 'Evaluation',
                    text: 'Evaluation uniquement',
                  },
                ]}
              ></DataTableController>
              {moduleVersion.isBeta && (
                <div className='flex w-full items-center sm:justify-end'>
                  <AddInteractionModal />
                </div>
              )}
            </div>
            {/* Afficher l'objectif pédagogique en cours */}
          </>
        </div>
      </div>
      {interactions && (
        <>
          <div className='flex flex-col'>
            <div className='overflow-x-auto'>
              <div className='inline-block min-w-full align-middle'>
                <div className='overflow-hidden shadow'>
                  <InteractionsTable />
                </div>
              </div>
            </div>
          </div>
          <Pagination
            numOfPages={numOfPages}
            total={totalInteractions}
            page={page}
            changePage={changePage}
          />
        </>
      )}
    </>
  )
}

export default ActivityPage
