import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllSkillsThunk, getEnumSkillsThunk } from './allSkillsThunk'

const initialFiltersState = {
  search: '',
  sort: 'latest',
  sortOptions: ['latest', 'oldest', 'a-z', 'z-a'],
}

const initialState = {
  isLoading: false,
  skills: [],
  totalSkills: 0,
  numOfPages: 1,
  page: 1,
  error: '',
  enumSkills: {},
  enumColors: {},
  // stats: {},
  // monthlyApplications: [],
  ...initialFiltersState,
}

export const getAllSkills = createAsyncThunk('skills/list', getAllSkillsThunk)

export const getEnumSkills = createAsyncThunk('skills/enum', getEnumSkillsThunk)

const allSkillsSlice = createSlice({
  name: 'allSkills',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true
    },
    hideLoading: (state) => {
      state.isLoading = false
    },
    handleChange: (state, { payload: { name, value } }) => {
      state.page = 1
      state[name] = value
    },
    clearFilters: (state) => {
      return { ...state, ...initialFiltersState }
    },
    changePage: (state, { payload }) => {
      state.page = payload
    },
    addSkillToDataTable: (state, { payload }) => {
      state.skills.unshift(payload)
      state.totalSkills++
    },
    editSkillToDataTable: (state, { payload }) => {
      state.skills = state.skills.map((skill) => {
        if (skill._id === payload._id) {
          skill = {
            ...skill,
            name: payload.name,
            description: payload.description,
            color: payload.color,
          }
        }
        return skill
      })
    },
    removeSkillToDataTable: (state, { payload }) => {
      state.skills = state.skills.filter((skill) => {
        return skill._id !== payload._id
      })
      state.totalSkills--
    },
    clearAllSkillState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSkills.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(getAllSkills.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.skills = payload.skills
        state.numOfPages = payload.numOfPages
        state.totalSkills = payload.totalSkills
      })
      .addCase(getAllSkills.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
      .addCase(getEnumSkills.pending, (state) => {
        state.isLoading = true
        state.error = ''
        state.enumSkills = {}
        state.enumColors = {}
      })
      .addCase(getEnumSkills.fulfilled, (state, { payload }) => {
        state.isLoading = false
        for (var i = 0; i < payload.skills.length; i++) {
          state.enumSkills[payload.skills[i]['_id']] = payload.skills[i]['name']
          state.enumColors[payload.skills[i]['_id']] =
            payload.skills[i]['color']
        }
        state.numOfPages = payload.numOfPages
        state.totalSkills = payload.totalSkills
      })
      .addCase(getEnumSkills.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
        state.enumSkills = {}
        state.enumColors = {}
      })
  },
})

export const {
  showLoading,
  hideLoading,
  handleChange,
  clearFilters,
  changePage,
  clearAllSkillsState,
  addSkillToDataTable,
  editSkillToDataTable,
  removeSkillToDataTable,
  enumSkills,
} = allSkillsSlice.actions

export default allSkillsSlice.reducer
