import { Label, Modal, TextInput } from 'flowbite-react'

const TagModalBody = ({ values, handleChange }) => {
  return (
    <Modal.Body>
      <div className='grid grid-cols-1 gap-6 lg:grid-cols-2'>
        <div>
          <Label htmlFor='title'>Nom du tag</Label>
          <TextInput
            id='title'
            name='title'
            placeholder='responsable'
            className='mt-1'
            type='text'
            required={true}
            value={values.title}
            onChange={handleChange}
          />
        </div>
      </div>
    </Modal.Body>
  )
}
export default TagModalBody
