import { Button, Modal, Table } from 'flowbite-react'
import { useState, type FC, useEffect } from 'react'

import { useAppDispatch, useTypedSelector } from '../../store'
import { Loading } from '../../components'
import {
  Answer,
  Assessment,
  AssessmentForm,
  Evaluation,
  EvaluationForm,
  Interaction,
  InteractionEnum,
  InteractionForm,
  addAssessment,
  addEvaluation,
  clearInteractionForm,
  editAssessment,
  editEvaluation,
  removeInteraction,
} from '../../features/interaction/interactionSlice'
import { useToast } from '../../hooks/useToast'
import {
  addInteractionsToDataTable,
  editInteractionsToDataTable,
  removeInteractionsToDataTable,
} from '../../features/allInteractions/allInteractionsSlice'
import { FaEdit, FaPlus } from 'react-icons/fa'
import { InteractionModalBody } from '../../components/modal-body/Interaction'
import { HiOutlineExclamationCircle, HiTrash } from 'react-icons/hi'
import CopyToClipBoard from '../../components/CopyToClipBoard'
import { MdClose, MdUpdate } from 'react-icons/md'
import { toggleInteractionStatus } from '../../features/activity/activitySlice'

export const InteractionsTable: FC = function () {
  const { interactions, isLoading } = useTypedSelector(
    (store) => store.allInteractions
  )
  const { moduleVersion, isLoading: isLoadingMV } = useTypedSelector(
    (store) => store.activity
  )
  const castInteractions = interactions as Interaction[]
  const dispatch = useAppDispatch()

  if (isLoading) {
    return <Loading center />
  }
  if (interactions.length > 0) {
    return (
      <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
        <Table.Head className='bg-gray-100 dark:bg-gray-700'>
          <Table.HeadCell>Type</Table.HeadCell>
          <Table.HeadCell>Titre</Table.HeadCell>
          <Table.HeadCell>Divers</Table.HeadCell>
          <Table.HeadCell>Actions</Table.HeadCell>
        </Table.Head>
        <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
          {castInteractions.map((interaction) => {
            return (
              <Table.Row
                key={interaction._id}
                className='hover:bg-gray-100 dark:hover:bg-gray-700'
              >
                <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                  <div className='text-base font-semibold text-gray-900 dark:text-white'>
                    {interaction.kind}
                  </div>

                  <div className='text-sm font-normal text-gray-500 dark:text-gray-400 flex'>
                    {interaction.lrsObjectId}
                    <CopyToClipBoard textToCopy={interaction.lrsObjectId} />
                  </div>

                  {moduleVersion.changeLog.find(
                    (entry) =>
                      entry.lrsObjectId === interaction.lrsObjectId &&
                      entry.verb === 'add'
                  ) ? (
                    <div className='text-sm font-normal italic text-green-500 flex'>
                      Ajouté
                    </div>
                  ) : moduleVersion.changeLog.find(
                      (entry) =>
                        entry.lrsObjectId === interaction.lrsObjectId &&
                        entry.verb === 'update'
                    ) ? (
                    <div className='text-sm font-normal italic text-primary-600 flex'>
                      <span>Mis à jour</span>
                      <Button
                        onClick={() =>
                          dispatch(
                            toggleInteractionStatus(interaction._id as string)
                          )
                        }
                        color='dark'
                        size='xs'
                        className='ml-2'
                        disabled={isLoadingMV}
                      >
                        <MdClose className='h-3 w-3' />
                      </Button>
                    </div>
                  ) : (
                    <div className='text-sm font-normal italic text-gray-400 flex'>
                      <span>Non modifié</span>
                      <Button
                        onClick={() =>
                          dispatch(
                            toggleInteractionStatus(interaction._id as string)
                          )
                        }
                        color='dark'
                        size='xs'
                        className='ml-2'
                        disabled={isLoadingMV}
                      >
                        <MdUpdate className='h-3 w-3' />
                      </Button>
                    </div>
                  )}
                </Table.Cell>
                <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[250px] overflow-hidden text-ellipsis'>
                  <div className='text-base font-semibold text-gray-900 dark:text-white'>
                    {interaction.title}
                  </div>
                  <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                    Nombre de tentatives {interaction.numOfLaunched}
                  </div>
                </Table.Cell>
                <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400 max-w-[400px] overflow-hidden text-ellipsis'>
                  <div className='text-base font-semibold text-gray-900 dark:text-white'>
                    {interaction.kind === InteractionEnum.ASSESSMENT && (
                      <>
                        {(interaction as Assessment).answers.map(
                          (answer, index) => {
                            return (
                              <div
                                key={`interaction_${interaction._id}_detail_${index}`}
                              >
                                <div>{answer.text}</div>
                                <div className='text-sm font-normal text-gray-500 dark:text-gray-400 flex'>
                                  {answer.lrsObjectId}
                                  <CopyToClipBoard
                                    textToCopy={answer.lrsObjectId}
                                  />
                                </div>
                              </div>
                            )
                          }
                        )}
                      </>
                    )}
                    {interaction.kind === InteractionEnum.EVALUATION && (
                      <>
                        {(interaction as Evaluation).answers.map(
                          (answer, index) => {
                            return (
                              <div
                                key={`interaction_${interaction._id}_detail_${index}`}
                              >
                                <div>
                                  {(interaction as Evaluation)
                                    .strictAnswerOrder && <>{index + 1} </>}
                                  {answer.text} {answer.correct && <>*</>}
                                </div>
                                <div className='text-sm font-normal text-gray-500 dark:text-gray-400 flex'>
                                  {answer.lrsObjectId}
                                  <CopyToClipBoard
                                    textToCopy={answer.lrsObjectId}
                                  />
                                </div>
                              </div>
                            )
                          }
                        )}
                      </>
                    )}
                  </div>
                </Table.Cell>
                <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                  <div className='flex items-center gap-x-3'>
                    {moduleVersion.isBeta && (
                      <>
                        {interaction.kind === InteractionEnum.ASSESSMENT && (
                          <EditAssessmentModal
                            interactionSelected={interaction as Assessment}
                          />
                        )}
                        {interaction.kind === InteractionEnum.EVALUATION && (
                          <EditEvaluationModal
                            interactionSelected={interaction as Evaluation}
                          />
                        )}
                        <DeleteInteractionModal interaction={interaction} />
                      </>
                    )}
                  </div>
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    )
  } else {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>Pas d'intéractions à afficher...</span>
        </div>
      </>
    )
  }
}

export const AddInteractionModal: FC = function () {
  const { activity } = useTypedSelector((store) => store.activity)

  let initialValues: InteractionForm = {
    title: '',
    activity: '',
    correction: true,
    answers: [],
  }

  const { isLoading, interaction } = useTypedSelector(
    (store) => store.interaction
  )
  const [isOpen, setOpen] = useState(false)
  const [values, setValues] = useState<InteractionForm>({ ...initialValues })
  const [interactionType, setInteractionType] = useState<string>('')
  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (activity && activity._id) {
      setValues({
        ...values,
        activity: activity._id,
      })
    }
    // eslint-disable-next-line
  }, [activity])

  useEffect(() => {
    if (!isOpen) {
      //clear props
      setValues({
        activity: activity._id || '',
        title: '',
        correction: true,
        answers: [],
      })
      setInteractionType('')
      //clear form
      dispatch(clearInteractionForm())
    }
    // eslint-disable-next-line
  }, [isOpen])

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const handleSelectInteractionChange = (
    e: React.FormEvent<HTMLSelectElement>
  ) => {
    const value = e.currentTarget.value
    setInteractionType(value)
  }

  const handleCheckbox = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const checked = e.currentTarget.checked
    setValues({ ...values, [name]: checked })
  }

  const handleAddAnswer = (e: React.MouseEvent<HTMLButtonElement>) => {
    const newAnswer: Answer = {
      text: '',
      url: '',
      correct: false,
    }
    setValues({
      ...values,
      answers: [...(values.answers || []), { ...newAnswer }],
    })
  }

  const handleDeleteAnswer = (
    e: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    const deleteAnswer = values.answers?.filter((answer, i) => i !== index)

    setValues({
      ...values,
      answers: [...(deleteAnswer || [])],
    })
  }

  const handleAnswerChange = (
    e: React.FormEvent<HTMLInputElement>,
    index: number
  ) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    const editAnswer = values.answers?.map((answer, i) => {
      if (index === i) {
        return { ...answer, [name]: value }
      } else {
        return answer
      }
    })

    setValues({
      ...values,
      answers: [...(editAnswer || [])],
    })
  }

  const handleAnswerCorrect = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const editAnswer = values.answers?.map((answer, i) => {
      if (index === i) {
        return { ...answer, correct: !answer.correct }
      } else {
        return answer
      }
    })

    setValues({
      ...values,
      answers: [...(editAnswer || [])],
    })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { title, activity } = values
    if (title !== '' && activity !== '') {
      switch (interactionType) {
        case InteractionEnum.ASSESSMENT:
          const assessmentForm: AssessmentForm = {
            ...values,
            answers: values.answers || [],
          }
          dispatch(addAssessment(assessmentForm))
          break
        case InteractionEnum.EVALUATION:
          const evaluationForm: EvaluationForm = {
            ...values,
            answers: values.answers || [],
            strictAnswerOrder: values.strictAnswerOrder || false,
            thresholdSuccess: values.thresholdSuccess || 100,
            correction: values.correction || true,
            weight: values.weight || 1,
          }
          dispatch(addEvaluation(evaluationForm))
          break

        default:
          toast('error', `Le type d'intéraction est introuvable`)
          break
      }
    } else {
      toast('error', `Vous avez besoin de renseigner les elements requis`)
    }
  }

  useEffect(() => {
    if (isOpen && interaction && interaction._id) {
      toast(
        'success',
        `Une nouvelle intéraction ${interaction.kind} a bien été créée`
      )
      //refresh list
      dispatch(addInteractionsToDataTable(interaction))
      //close
      setOpen(false)
    }
    // eslint-disable-next-line
  }, [isOpen, interaction])

  return (
    <>
      <Button color='primary' onClick={() => setOpen(!isOpen)}>
        <FaPlus className='mr-3 text-sm' />
        Ajouter une intéraction
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen} size={'5xl'}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Ajouter une intéraction</strong>
          </Modal.Header>
          <InteractionModalBody
            values={values}
            handleChange={handleChange}
            interactionType={interactionType}
            handleSelectInteractionChange={handleSelectInteractionChange}
            handleAddAnswer={handleAddAnswer}
            handleDeleteAnswer={handleDeleteAnswer}
            handleAnswerChange={handleAnswerChange}
            handleAnswerCorrect={handleAnswerCorrect}
            handleCheckbox={handleCheckbox}
          />
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Ajouter
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export const EditEvaluationModal: FC<{ interactionSelected: Evaluation }> =
  function ({ interactionSelected }) {
    const { activity } = useTypedSelector((store) => store.activity)

    let initialValues: EvaluationForm = {
      _id: '',
      activity: '',
      title: '',
      weight: 1,
      strictAnswerOrder: false,
      thresholdSuccess: 100,
      correction: true,
      answers: [],
    }

    const { isLoading, interaction } = useTypedSelector(
      (store) => store.interaction
    )
    const [isOpen, setOpen] = useState(false)
    const [values, setValues] = useState<EvaluationForm>({ ...initialValues })
    const dispatch = useAppDispatch()
    const toast = useToast()

    useEffect(() => {
      if (activity && activity._id) {
        setValues({
          ...values,
          activity: activity._id,
        })
      }
      // eslint-disable-next-line
    }, [activity])

    useEffect(() => {
      if (isOpen) {
        //clear form
        dispatch(clearInteractionForm())
        setValues({
          ...values,
          _id: interactionSelected._id,
          activity: interactionSelected.activity,
          title: interactionSelected.title,
          strictAnswerOrder: interactionSelected.strictAnswerOrder,
          answers: interactionSelected.answers,
          weight: interactionSelected.weight,
          thresholdSuccess: interactionSelected.thresholdSuccess,
          correction: interactionSelected.correction,
        })
      }
      // eslint-disable-next-line
    }, [isOpen])

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name
      const value = e.currentTarget.value
      setValues({ ...values, [name]: value })
    }

    const handleCheckbox = (e: React.FormEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name
      const checked = e.currentTarget.checked
      setValues({ ...values, [name]: checked })
    }

    const handleAddAnswer = (e: React.MouseEvent<HTMLButtonElement>) => {
      const newAnswer: Answer = {
        text: '',
        url: '',
        correct: false,
      }
      setValues({
        ...values,
        answers: [...(values.answers || []), { ...newAnswer }],
      })
    }

    const handleDeleteAnswer = (
      e: React.MouseEvent<HTMLButtonElement>,
      index: number
    ) => {
      const deleteAnswer = values.answers?.filter((answer, i) => i !== index)

      setValues({
        ...values,
        answers: [...(deleteAnswer || [])],
      })
    }

    const handleAnswerChange = (
      e: React.FormEvent<HTMLInputElement>,
      index: number
    ) => {
      const name = e.currentTarget.name
      const value = e.currentTarget.value
      const editAnswer = values.answers?.map((answer, i) => {
        if (index === i) {
          return { ...answer, [name]: value }
        } else {
          return answer
        }
      })

      setValues({
        ...values,
        answers: [...(editAnswer || [])],
      })
    }

    const handleAnswerCorrect = (
      e: React.ChangeEvent<HTMLInputElement>,
      index: number
    ) => {
      const editAnswer = values.answers?.map((answer, i) => {
        if (index === i) {
          return { ...answer, correct: !answer.correct }
        } else {
          return answer
        }
      })

      setValues({
        ...values,
        answers: [...(editAnswer || [])],
      })
    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const { title, weight, thresholdSuccess } = values
      if (title !== '' && weight && thresholdSuccess) {
        dispatch(editEvaluation(values))
      } else {
        toast('error', `Vous avez besoin de renseigner les elements requis`)
      }
    }

    useEffect(() => {
      if (isOpen && interaction && interaction._id) {
        toast('success', `L'intéraction  ${interaction.title} a été modifiée`)
        // edit in datatable
        dispatch(editInteractionsToDataTable(interaction))
        //clear form
        dispatch(clearInteractionForm())
        //close
        setOpen(false)
      }
      // eslint-disable-next-line
    }, [isOpen, interaction])

    return (
      <>
        <Button color='primary' onClick={() => setOpen(!isOpen)}>
          <FaEdit className='mr-3 text-sm' />
          Editer une interaction
        </Button>
        <Modal onClose={() => setOpen(false)} show={isOpen} size={'5xl'}>
          <form onSubmit={onSubmit}>
            <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
              <strong>Editer une interaction</strong>
            </Modal.Header>
            <InteractionModalBody
              values={values}
              handleChange={handleChange}
              interactionType={interactionSelected.kind}
              handleAddAnswer={handleAddAnswer}
              handleDeleteAnswer={handleDeleteAnswer}
              handleAnswerChange={handleAnswerChange}
              handleAnswerCorrect={handleAnswerCorrect}
              handleCheckbox={handleCheckbox}
            />
            <Modal.Footer>
              <Button disabled={isLoading} color='primary' type='submit'>
                Editer
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    )
  }

export const EditAssessmentModal: FC<{ interactionSelected: Assessment }> =
  function ({ interactionSelected }) {
    const { activity } = useTypedSelector((store) => store.activity)

    let initialValues: AssessmentForm = {
      _id: '',
      activity: '',
      title: '',
      answers: [],
    }

    const { isLoading, interaction } = useTypedSelector(
      (store) => store.interaction
    )
    const [isOpen, setOpen] = useState(false)
    const [values, setValues] = useState<AssessmentForm>({ ...initialValues })
    const dispatch = useAppDispatch()
    const toast = useToast()

    useEffect(() => {
      if (activity && activity._id) {
        setValues({
          ...values,
          activity: activity._id,
        })
      }
      // eslint-disable-next-line
    }, [activity])

    useEffect(() => {
      if (isOpen) {
        //clear form
        dispatch(clearInteractionForm())
        setValues({
          ...values,
          _id: interactionSelected._id,
          activity: interactionSelected.activity,
          title: interactionSelected.title,
          answers: interactionSelected.answers,
        })
      }
      // eslint-disable-next-line
    }, [isOpen])

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
      const name = e.currentTarget.name
      const value = e.currentTarget.value
      setValues({ ...values, [name]: value })
    }

    const handleAddAnswer = (e: React.MouseEvent<HTMLButtonElement>) => {
      const newAnswer: Answer = {
        text: '',
        url: '',
        correct: false,
      }
      setValues({
        ...values,
        answers: [...(values.answers || []), { ...newAnswer }],
      })
    }

    const handleDeleteAnswer = (
      e: React.MouseEvent<HTMLButtonElement>,
      index: number
    ) => {
      const deleteAnswer = values.answers?.filter((answer, i) => i !== index)

      setValues({
        ...values,
        answers: [...(deleteAnswer || [])],
      })
    }

    const handleAnswerChange = (
      e: React.FormEvent<HTMLInputElement>,
      index: number
    ) => {
      const name = e.currentTarget.name
      const value = e.currentTarget.value
      const editAnswer = values.answers?.map((answer, i) => {
        if (index === i) {
          return { ...answer, [name]: value }
        } else {
          return answer
        }
      })

      setValues({
        ...values,
        answers: [...(editAnswer || [])],
      })
    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const { title } = values
      if (title !== '') {
        dispatch(editAssessment(values))
      } else {
        toast('error', `Vous avez besoin de renseigner les elements requis`)
      }
    }

    useEffect(() => {
      if (isOpen && interaction && interaction._id) {
        toast('success', `L'intéraction  ${interaction.title} a été modifiée`)
        // edit in datatable
        dispatch(editInteractionsToDataTable(interaction))
        //clear form
        dispatch(clearInteractionForm())
        //close
        setOpen(false)
      }
      // eslint-disable-next-line
    }, [isOpen, interaction])

    return (
      <>
        <Button color='primary' onClick={() => setOpen(!isOpen)}>
          <FaEdit className='mr-3 text-sm' />
          Editer une interaction
        </Button>
        <Modal onClose={() => setOpen(false)} show={isOpen} size={'5xl'}>
          <form onSubmit={onSubmit}>
            <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
              <strong>Editer une interaction</strong>
            </Modal.Header>
            <InteractionModalBody
              values={values}
              handleChange={handleChange}
              interactionType={interactionSelected.kind}
              handleAnswerChange={handleAnswerChange}
              handleAddAnswer={handleAddAnswer}
              handleDeleteAnswer={handleDeleteAnswer}
            />
            <Modal.Footer>
              <Button disabled={isLoading} color='primary' type='submit'>
                Editer
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    )
  }

export const DeleteInteractionModal: FC<{ interaction: Interaction }> =
  function ({ interaction }) {
    const { success } = useTypedSelector((store) => store.interaction)
    const [isOpen, setOpen] = useState(false)
    const dispatch = useAppDispatch()
    const toast = useToast()

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      dispatch(removeInteraction(interaction))
    }
    useEffect(() => {
      if (isOpen && success) {
        toast(
          'success',
          `L'interaction ${interaction.title} à bien été supprimée`
        )
        //remove from store database
        dispatch(removeInteractionsToDataTable(interaction))
        //refresh
        dispatch(clearInteractionForm())
        //close
        setOpen(false)
      }
      // eslint-disable-next-line
    }, [isOpen, success])
    return (
      <>
        <Button color='failure' onClick={() => setOpen(!isOpen)}>
          <HiTrash className='mr-2 text-lg' />
          <span className='hidden lg:block '>Supprimer l'intéraction</span>
        </Button>
        <Modal onClose={() => setOpen(false)} show={isOpen} size='md'>
          <Modal.Header className='px-3 pb-0 pt-3'>
            <span className='sr-only'>Supprimer l'intéraction</span>
          </Modal.Header>
          <Modal.Body className='px-6 pb-6 pt-0'>
            <div className='flex flex-col items-center gap-y-6 text-center'>
              <HiOutlineExclamationCircle className='text-7xl text-red-600' />
              <p className='text-lg text-gray-500 dark:text-gray-300'>
                Êtes vous sur de vouloir détruire l'intéraction{' '}
                {interaction.title} ?
              </p>
              <div className='flex items-center gap-x-3'>
                <Button color='failure' onClick={handleSubmit}>
                  Oui, je suis sur
                </Button>
                <Button color='gray' onClick={() => setOpen(false)}>
                  Non, annuler
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  }
