import { Flowbite, useThemeMode } from 'flowbite-react'
import type { FC } from 'react'
import { useEffect } from 'react'
import { Outlet } from 'react-router'
import theme from '../flowbite-theme'
import NavbarSidebarLayout from '../layouts/navbar-sidebar'

const FlowbiteWrapper: FC<{ nav: boolean }> = function ({ nav }) {
  return (
    <Flowbite theme={{ theme, mode: 'dark' }}>
      <PersistFlowbiteThemeToLocalStorage />
      {nav ? (
        <NavbarSidebarLayout>
          <Outlet />
        </NavbarSidebarLayout>
      ) : (
        <Outlet />
      )}
    </Flowbite>
  )
}

const PersistFlowbiteThemeToLocalStorage: FC = function () {
  const { mode } = useThemeMode()

  useEffect(() => {
    localStorage.setItem('theme', mode)
  }, [mode])

  return <></>
}

export default FlowbiteWrapper
