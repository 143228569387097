import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../features/user/userSlice'
import { useNavigate } from 'react-router-dom'
import { useRedirection } from '../utils/queryParams'
import { useCleanToasts } from '../hooks/useCleanToasts'

const Logout = () => {
  const navigate = useNavigate()
  const redirect = useRedirection()
  const { error, user, isLoading } = useSelector((store) => store.user)
  const dispatch = useDispatch()
  const cleanToasts = useCleanToasts()

  useEffect(() => {
    if (user && user.userId) {
      dispatch(logout())
    }
  }, [dispatch, user])

  useEffect(() => {
    if (!isLoading && (!user || !user.userId || !user.role === 'admin')) {
      cleanToasts()
      // redirect to login page
      if (redirect) {
        navigate(`/authentication/sign-in?redirect=${redirect}`)
      } else {
        navigate('/authentication/sign-in')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, isLoading, user, error])

  return <></>
}
export default Logout
