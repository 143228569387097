import { Label, Modal, TextInput, Textarea } from 'flowbite-react'
import ColorPicker from '../ColorPicker'

export const LearningObjectiveModalBody = ({
  values,
  handleChange,
  handleTextAreaChange,
  handleColorChange,
}) => {
  return (
    <Modal.Body>
      <div className='grid grid-cols-1 gap-6 lg:grid-cols-2'>
        <div>
          <Label htmlFor='name'>Nom</Label>
          <TextInput
            id='name'
            name='name'
            placeholder='Etre sensibilisé à la démarche FOH'
            className='mt-1'
            type='text'
            required={true}
            value={values.name}
            onChange={handleChange}
          />
        </div>
        <div>
          <Label htmlFor='learningTime'>
            Temps d'apprentissage (en secondes)
          </Label>
          <TextInput
            id='learningTime'
            name='learningTime'
            placeholder='120'
            className='mt-1'
            type='number'
            min='0'
            required={true}
            value={values.learningTime}
            onChange={handleChange}
          />
        </div>
        <div>
          <Label>Couleur de l'objectif</Label>
          <ColorPicker initColor={values.color} onChange={handleColorChange} />
        </div>
        <div className='lg:col-span-2'>
          <Label htmlFor='description'>Description</Label>
          <Textarea
            id='description'
            name='description'
            placeholder='Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque pariatur esse doloribus possimus placeat corrupti minus incidunt necessitatibus id exercitationem. Reprehenderit exercitationem assumenda nobis eligendi quae, provident rerum accusamus reiciendis!'
            rows={6}
            className='mt-1'
            value={values.description}
            onChange={handleTextAreaChange}
          />
        </div>
      </div>
    </Modal.Body>
  )
}
export default LearningObjectiveModalBody
