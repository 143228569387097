import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const getBDDControlModuleTestThunk = async (_, noArgs, thunkAPI) => {
  try {
    let url = `/bddcontrol/moduletest`
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const getBDDExportModuleThunk = async (_, id, thunkAPI) => {
  try {
    let url = `/bddcontrol/exportmodule/${id}`
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const getBDDExportBetaContactsThunk = async (_, thunkAPI) => {
  try {
    let url = `/bddcontrol/exportbetacontacts`
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const postBDDImportModuleThunk = async (_, data, thunkAPI) => {
  try {
    let url = `/bddcontrol/importmodule`
    const resp = await customFetch.post(url, data)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const getBDDControlIntegretyThunk = async (_, noArgs, thunkAPI) => {
  try {
    let url = `/bddcontrol/integrity`
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const resetAuthThunk = async (_, noArgs, thunkAPI) => {
  try {
    let url = `/bddcontrol/resetauth`
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const resetNotifBetaThunk = async (_, noArgs, thunkAPI) => {
  try {
    let url = `/bddcontrol/resetnotifbeta`
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
