import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  getUserFromLocalStorage,
  addUserToLocalStorage,
  removeUserFromLocalStorage,
} from '../../utils/localStorage'
import { loginUserThunk, logoutThunk, showMeThunk } from './userThunk'

export interface User {
  userId: string
  email: string
  name: string
  lastName: string
  role: string
  currentPage: string
}

export interface UserForm {
  email: string
  password: string
  rememberMe: boolean
}

export interface UserState {
  isLoading: boolean
  user: User
  error: string
}

const initialState: UserState = {
  isLoading: false,
  user: getUserFromLocalStorage(),
  error: '',
}

export const showMe = createAsyncThunk('user/showMe', async (thunkAPI) => {
  return showMeThunk('/users/showMe', thunkAPI)
})

export const loginAdminUser = createAsyncThunk(
  'user/loginAdminUser',
  async (user: UserForm, thunkAPI) => {
    return loginUserThunk('/auth/login', user, thunkAPI)
  }
)

export const logout = createAsyncThunk('user/logout', async (thunkAPI) => {
  return logoutThunk('/auth/logout', thunkAPI)
})

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.user.currentPage = payload
    },
    callLogout: () => {
      window.location.href = `/logout?redirect=${window.location.href}`
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(showMe.pending, (state) => {
        state.isLoading = true
      })
      .addCase(showMe.fulfilled, (state, { payload }) => {
        const { user } = payload
        state.isLoading = false
        state.user = user
        addUserToLocalStorage(user)
      })
      .addCase(showMe.rejected, (state) => {
        state.isLoading = false
        state.user = {} as User
        removeUserFromLocalStorage()
      })
      .addCase(loginAdminUser.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(loginAdminUser.fulfilled, (state, { payload }) => {
        const { user } = payload
        if (user.role !== 'admin') {
          state.user = {} as User
          state.error = `Vous n'êtes pas admin`
        } else {
          state.user = user
          addUserToLocalStorage(user)
        }
        state.isLoading = false
      })
      .addCase(loginAdminUser.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addCase(logout.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoading = false
        state.user = {} as User
        removeUserFromLocalStorage()
      })
      .addCase(logout.rejected, (state, { payload }) => {
        state.isLoading = false
        state.user = {} as User
        state.error = payload as string
      })
  },
})

export const { setCurrentPage, callLogout } = userSlice.actions

export default userSlice.reducer
