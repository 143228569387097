import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const getActivityThunk = async (_, id, thunkAPI) => {
  try {
    let url = `/activities/${id}`
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const initActivityWebObjectThunk = async (_, webobject, thunkAPI) => {
  let url = `/webobjects`
  try {
    const resp = await customFetch.post(url, webobject)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const initActivityQuizThunk = async (_, quiz, thunkAPI) => {
  let url = `/quiz`
  try {
    const resp = await customFetch.post(url, quiz)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const updateWebObjectThunk = async (_, activity, thunkAPI) => {
  let url = `/webobjects/${activity._id}`
  try {
    const resp = await customFetch.patch(url, activity)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const updateQuizThunk = async (_, activity, thunkAPI) => {
  let url = `/quiz/${activity._id}`
  try {
    const resp = await customFetch.patch(url, activity)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const removeActivityThunk = async (_, activity, thunkAPI) => {
  let url = `/activities/${activity._id}`
  try {
    const resp = await customFetch.delete(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}

export const toggleInteractionStatusThunk = async (_, id, thunkAPI) => {
  let url = `/interactions/${id}/togglestatus`
  try {
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
