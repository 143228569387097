/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Badge,
  Breadcrumb,
  Button,
  Dropdown,
  Kbd,
  Label,
  Modal,
  Radio,
  Table,
  TextInput,
} from 'flowbite-react'
import type { FC } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { FaCheck, FaFileImport, FaPlus, FaUpload } from 'react-icons/fa'
import {
  HiArrowRight,
  HiHome,
  HiLockClosed,
  HiOutlineExclamationCircle,
  HiPencilAlt,
  HiStatusOffline,
  HiStatusOnline,
  HiTrash,
} from 'react-icons/hi'
import {
  DataTableController,
  Loading,
  Pagination,
  ThumbnailResource,
} from '../../components'
import { useAppDispatch, useTypedSelector } from '../../store'
import {
  addModuleToDataTable,
  clearFilters,
  editModuleToDataTable,
  getAllModules,
  handleChange,
  removeModuleToDataTable,
} from '../../features/allModules/allModulesSlice'
import { changePage } from '../../features/allModules/allModulesSlice'
import moment from 'moment/moment'
import { useToast } from '../../hooks/useToast'
import {
  addModule,
  changeModuleStatus,
  clearModuleForm,
  editModule,
  Module,
  ModuleCategoryEnum,
  ModuleForm,
  removeModule,
} from '../../features/module/moduleSlice'
import { useNavigate } from 'react-router-dom'
import { getAllTags } from '../../features/allTags/allTagsSlice'
import { TagSelector } from '../../components/TagSelector'
import { Tag, TagSelectorValue } from '../../features/tag/tagSlice'
import { SelectMediaModal } from '../medias/list'
import { Resource } from '../../features/resource/resourceSlice'
import {
  clearBddControl,
  getBDDControlModuleTest,
  postBDDImportModule,
} from '../../features/bddControl/bddControlSlice'
import { MdClose, MdRefresh } from 'react-icons/md'
import EditorSwitch from '../../components/Editor/EditorSwitch'

const ModulesListPage: FC = function () {
  const {
    error,
    page,
    totalModules,
    numOfPages,
    search,
    sort,
    searchStatus,
    sortOptions,
  } = useTypedSelector((store) => store.allModules)
  const { error: errorModule, module } = useTypedSelector(
    (store) => store.module
  )
  const {
    error: errorBDDControl,
    msg,
    success,
  } = useTypedSelector((store) => store.bddControl)
  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
    }
    if (errorModule !== '') {
      toast('error', errorModule)
      dispatch(clearModuleForm())
    }
    if (errorBDDControl !== '') {
      toast('error', errorBDDControl)
      dispatch(clearBddControl())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, errorModule, errorBDDControl])

  useEffect(() => {
    dispatch(getAllTags({}))
  }, [module.tags, dispatch])

  useEffect(() => {
    if (success) {
      toast('success', msg)
      dispatch(clearBddControl())
      // reload page
      dispatch(getAllModules({}))
    }
    // eslint-disable-next-line
  }, [msg, success])

  useEffect(() => {
    //clear form
    dispatch(clearModuleForm())
    dispatch(getAllModules({}))
  }, [dispatch, page, search, sort, searchStatus])
  return (
    <>
      <div className='block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex'>
        <div className='mb-1 w-full'>
          <div className='mb-4'>
            <Breadcrumb className='mb-4'>
              <Breadcrumb.Item href='#'>
                <div className='flex items-center gap-x-3'>
                  <HiHome className='text-xl' />
                  <span className='dark:text-white'>Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href='/modules'>Modules</Breadcrumb.Item>
              <Breadcrumb.Item>Liste</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className='text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl'>
              Tous les modules
            </h1>
          </div>
          <div className='block items-center sm:flex'>
            <DataTableController
              placeholder='rechercher un module'
              handleChange={handleChange}
              sort={sort}
              sortOptions={sortOptions}
              search={search}
              // @ts-ignore
              clean={clearFilters}
              initFilters={[
                {
                  name: 'searchStatus',
                  value: searchStatus,
                  control: 'approved',
                  text: 'Validé uniquement',
                },
              ]}
            ></DataTableController>
            <div className='flex w-full items-center sm:justify-end'>
              <Button
                color='primary'
                className='mr-4'
                onClick={() => {
                  dispatch(getBDDControlModuleTest())
                }}
              >
                <MdRefresh className='mr-3 text-sm' />
                Régénérer le module test
              </Button>
              <ImportModuleModal />
              <AddModuleModal />
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='overflow-x-auto'>
          <div className='inline-block min-w-full align-middle'>
            <div className='overflow-hidden shadow'>
              <ModulesTable />
            </div>
          </div>
        </div>
      </div>
      <Pagination
        numOfPages={numOfPages}
        total={totalModules}
        page={page}
        changePage={changePage}
      />
    </>
  )
}

const ImportModuleModal: FC = function () {
  const [isOpen, setOpen] = useState(false)
  const { isLoading, success, msg } = useTypedSelector(
    (store) => store.bddControl
  )
  const [file, setFile] = useState<File | null>(null)
  const [data, setData] = useState<any | null>(null)
  const form = useRef(null)
  const toast = useToast()
  const dispatch = useAppDispatch()

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = (e.target as HTMLInputElement).files
    if (files) {
      setFile(files[0])
    }
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (data) {
      try {
        dispatch(postBDDImportModule(JSON.parse(data)))
      } catch (error) {
        toast('error', `Le fichier n'est pas valide`)
      }
    }
  }

  useEffect(() => {
    if (file) {
      if (file.type === 'application/json') {
        try {
          let reader = new FileReader()
          reader.onload = function (ev) {
            // Output: The JSON data.
            setData(ev.target?.result)
          }
          reader.readAsText(file)
        } catch (error) {
          toast('error', error)
        }
      } else {
        toast('error', `Vous avez besoin d'un fichier au format json`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  useEffect(() => {
    if (isOpen && success) {
      toast('success', msg)
      // clear file
      setFile(null)
      setData(null)
      //clear form
      dispatch(clearBddControl())
      //close
      setOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, success, msg])

  useEffect(() => {
    if (!isOpen) {
      // clear file
      setFile(null)
      setData(null)
    }
  }, [isOpen])

  return (
    <>
      <Button color='primary' className='mr-4' onClick={() => setOpen(!isOpen)}>
        <FaFileImport className='mr-3 text-sm' />
        Importer un module
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit} ref={form}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Importer un module</strong>
          </Modal.Header>
          <Modal.Body>
            <div className='grid grid-cols-1 gap-6 lg:grid-cols-2'>
              <div className='lg:col-span-2'>
                <div className='flex w-full items-center justify-center'>
                  <label className='flex h-32 w-full cursor-pointer flex-col rounded border-2 border-dashed border-gray-300 hover:bg-gray-50 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-700'>
                    <div className='flex flex-col items-center justify-center pb-6 pt-5'>
                      <FaUpload className='text-4xl text-gray-300' />
                      {file ? (
                        <>
                          <p className='py-1 text-sm text-gray-600 dark:text-gray-500'>
                            {file.name}
                          </p>
                          {data ? (
                            <FaCheck className='text-4xl text-green-600'></FaCheck>
                          ) : (
                            <MdClose className='text-4xl text-red-600'></MdClose>
                          )}
                        </>
                      ) : (
                        <p className='py-1 text-sm text-gray-600 dark:text-gray-500'>
                          Fichier JSON a uploader
                        </p>
                      )}
                    </div>
                    <input
                      type='file'
                      className='hidden'
                      onChange={handleFileChange}
                    />
                  </label>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading || data === null}
              color='primary'
              type='submit'
            >
              Ajouter
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

const AddModuleModal: FC = function () {
  const initialValues: ModuleForm = {
    name: '',
    shortResume: '',
    longResume: {},
    tags: [],
    thumb: '',
    backgroundImage: '',
    category: ModuleCategoryEnum.NOTCATEGORIZED,
  }

  const [isOpen, setOpen] = useState(false)
  const [suggestionsTag, setSuggestionsTag] = useState(
    new Array<TagSelectorValue>()
  )
  const [tagsSelected, setTagsSelected] = useState(
    new Array<TagSelectorValue>()
  )
  const [thumbSelected, setThumbSelected] = useState({} as Resource | null)

  const [values, setValues] = useState<ModuleForm>(initialValues)
  const dispatch = useAppDispatch()
  const { isLoading, module } = useTypedSelector((store) => store.module)
  const { tags: alltags } = useTypedSelector((store) => store.allTags)
  const toast = useToast()
  const form = useRef(null)

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const handleDropDownChange = (mc: ModuleCategoryEnum) => {
    setValues({ ...values, category: mc })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // verify if thumbnail add modal is not open because it can call both onSubmit
    if (e.target === form.current) {
      const { name, shortResume } = values
      if (name !== '' && shortResume !== '') {
        dispatch(addModule(values))
      } else {
        toast('error', `Vous avez besoin de renseigner les elements requis`)
      }
    }
  }

  useEffect(() => {
    if (isOpen && module && module._id) {
      toast('success', `Le module ${module.name} a bien été crée`)
      //refresh list
      dispatch(addModuleToDataTable(module))
      //clear form
      dispatch(clearModuleForm())
      //close
      setOpen(false)
    }
  }, [toast, dispatch, isOpen, module, isLoading])

  useEffect(() => {
    const castTags = alltags as Tag[]
    setSuggestionsTag(
      castTags.map((tag) => {
        const newTag = { value: tag._id, label: tag.title }
        return newTag
      })
    )
  }, [alltags])

  useEffect(() => {
    if (!isOpen) {
      //clean
      setValues({
        ...initialValues,
      })
      setTagsSelected(new Array<TagSelectorValue>())
      setThumbSelected({} as Resource)
    }
    // eslint-disable-next-line
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      setValues({
        ...values,
        tags: tagsSelected.map((tag) => {
          return tag.value
        }),
      })
    }
    // eslint-disable-next-line
  }, [tagsSelected, isOpen])

  useEffect(() => {
    if (isOpen) {
      if (thumbSelected && thumbSelected._id) {
        setValues({
          ...values,
          thumb: thumbSelected._id,
        })
      } else {
        setValues({
          ...values,
          thumb: null,
        })
      }
    }
    // eslint-disable-next-line
  }, [thumbSelected, isOpen])

  return (
    <>
      <Button color='primary' onClick={() => setOpen(!isOpen)}>
        <FaPlus className='mr-3 text-sm' />
        Ajouter un module
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit} ref={form}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Ajouter module</strong>
          </Modal.Header>
          <Modal.Body>
            <div className='grid grid-cols-1 gap-6 lg:grid-cols-2'>
              <div>
                <Label htmlFor='name'>Nom du module</Label>
                <TextInput
                  id='name'
                  name='name'
                  placeholder='FOH 1'
                  className='mt-1'
                  type='text'
                  required={true}
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <span className='text-sm font-medium text-gray-900 dark:text-white'>
                  Categorie
                </span>
                <div className='mt-2'>
                  <Dropdown
                    id='category'
                    color={'primary'}
                    label={moduleCategoryToString(values.category)}
                  >
                    <Dropdown.Item
                      onClick={() =>
                        handleDropDownChange(ModuleCategoryEnum.NOTCATEGORIZED)
                      }
                    >
                      {moduleCategoryToString(
                        ModuleCategoryEnum.NOTCATEGORIZED
                      )}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        handleDropDownChange(ModuleCategoryEnum.NR)
                      }
                    >
                      {moduleCategoryToString(ModuleCategoryEnum.NR)}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        handleDropDownChange(ModuleCategoryEnum.CLIMATE)
                      }
                    >
                      {moduleCategoryToString(ModuleCategoryEnum.CLIMATE)}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        handleDropDownChange(ModuleCategoryEnum.RSE)
                      }
                    >
                      {moduleCategoryToString(ModuleCategoryEnum.RSE)}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        handleDropDownChange(ModuleCategoryEnum.PREVENTION)
                      }
                    >
                      {moduleCategoryToString(ModuleCategoryEnum.PREVENTION)}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        handleDropDownChange(ModuleCategoryEnum.PERSONALDEV)
                      }
                    >
                      {moduleCategoryToString(ModuleCategoryEnum.PERSONALDEV)}
                    </Dropdown.Item>
                  </Dropdown>
                </div>
              </div>
              <div>
                <Label htmlFor='shortResume'>
                  Petite description (-200 caractères)
                </Label>
                <TextInput
                  id='shortResume'
                  name='shortResume'
                  placeholder=''
                  className='mt-1'
                  type='text'
                  value={values.shortResume}
                  onChange={handleChange}
                />
              </div>
              <div>
                <Label>Tags</Label>
                <TagSelector
                  suggestions={[...suggestionsTag]}
                  tagsSelected={tagsSelected}
                  setTagsSelected={setTagsSelected}
                />
              </div>
              <div className='lg:col-span-2'>
                <span className='text-sm font-medium text-gray-900 dark:text-white'>
                  Grande description
                </span>
                <EditorSwitch
                  value={values.longResume?.version ? values.longResume : {}}
                  callback={(e: any) => setValues({ ...values, longResume: e })}
                />
              </div>
              <div className='lg:col-span-2'>
                <Label>Changer de thumbnail</Label>
                <SelectMediaModal setImageSelected={setThumbSelected} />
              </div>

              {thumbSelected && thumbSelected._id && (
                <>
                  <div className='lg:col-span-2'>
                    <ThumbnailResource resource={thumbSelected} />
                  </div>
                  <div className='cursor-pointer'>
                    <span className='sr-only'>Supprimer</span>
                    <HiTrash
                      className='-mt-5 text-2xl text-red-600'
                      onClick={() => setThumbSelected({} as Resource)}
                    />
                  </div>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Ajouter
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export const moduleCategoryToString = (v: ModuleCategoryEnum) => {
  return v === ModuleCategoryEnum.NOTCATEGORIZED
    ? 'Pas catégorisé'
    : v === ModuleCategoryEnum.NR
    ? 'Numérique Responsable'
    : v === ModuleCategoryEnum.CLIMATE
    ? 'Enjeux climatiques'
    : v === ModuleCategoryEnum.RSE
    ? 'RSE'
    : v === ModuleCategoryEnum.PREVENTION
    ? 'Prévention'
    : v === ModuleCategoryEnum.PERSONALDEV && 'Développement Personnel'
}

const EditModuleModal: FC<{ moduleSelected: Module }> = function ({
  moduleSelected,
}) {
  const initialValues: ModuleForm = {
    _id: moduleSelected._id,
    name: moduleSelected.name,
    shortResume: moduleSelected.shortResume,
    longResume: moduleSelected.longResume,
    tags: moduleSelected.tags,
    thumb: moduleSelected.thumb,
    backgroundImage: moduleSelected.backgroundImage,
    category: moduleSelected.category,
  }

  const [isOpen, setOpen] = useState(false)
  const [suggestionsTag, setSuggestionsTag] = useState(
    new Array<TagSelectorValue>()
  )
  const [tagsSelected, setTagsSelected] = useState(
    new Array<TagSelectorValue>()
  )

  const [thumbSelected, setThumbSelected] = useState(
    moduleSelected.thumbResource
  )
  const form = useRef(null)

  useEffect(() => {
    if (isOpen) {
      if (thumbSelected && thumbSelected._id) {
        setValues({
          ...values,
          thumb: thumbSelected._id,
        })
      } else {
        setValues({
          ...values,
          thumb: null,
        })
      }
    }
    // eslint-disable-next-line
  }, [thumbSelected, isOpen])

  useEffect(() => {
    if (!isOpen) {
      //clean
      setTagsSelected(new Array<TagSelectorValue>())
      setThumbSelected({} as Resource)
    }
  }, [isOpen])

  const [values, setValues] = useState<ModuleForm>(initialValues)
  const dispatch = useAppDispatch()
  const { isLoading, module } = useTypedSelector((store) => store.module)
  const { tags: alltags } = useTypedSelector((store) => store.allTags)
  const toast = useToast()

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    setValues({ ...values, [name]: value })
  }

  const handleDropDownChange = (mc: ModuleCategoryEnum) => {
    setValues({ ...values, category: mc })
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    // verify if thumbnail add modal is not open because it can call both onSubmit
    e.preventDefault()
    if (e.target === form.current) {
      const { name, shortResume } = values
      if (name !== '' && shortResume !== '') {
        dispatch(editModule(values))
      } else {
        toast('error', `Vous avez besoin de renseigner les elements requis`)
      }
    }
  }

  useEffect(() => {
    if (isOpen && module && module._id) {
      toast('success', `Le ${module.name} a bien été édité`)
      //refresh list
      dispatch(editModuleToDataTable(module))
      //clear form
      dispatch(clearModuleForm())
      //close
      setOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, module, isLoading])

  useEffect(() => {
    const castTags = alltags as Tag[]
    setSuggestionsTag(
      castTags.map((tag) => {
        const newTag = { value: tag._id, label: tag.title }
        return newTag
      })
    )
    if (isOpen) {
      setTagsSelected(
        castTags
          .filter((tag) => moduleSelected.tags.indexOf(tag._id) > -1)
          .map((tag) => {
            const newTag = { value: tag._id, label: tag.title }
            return newTag
          })
      )
    }
    // eslint-disable-next-line
  }, [alltags, isOpen])

  useEffect(() => {
    if (isOpen) {
      setValues({
        ...values,
        tags: tagsSelected.map((tag) => {
          return tag.value
        }),
      })
    }
    // eslint-disable-next-line
  }, [tagsSelected, isOpen])

  return (
    <>
      <Button color='primary' onClick={() => setOpen(!isOpen)}>
        <HiPencilAlt className='mr-2 text-lg' />
        Editer
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit} ref={form}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Editer module {moduleSelected.name}</strong>
          </Modal.Header>
          <Modal.Body>
            <div className='grid grid-cols-1 gap-6 lg:grid-cols-2'>
              <div>
                <Label htmlFor='name'>Nom du module</Label>
                <TextInput
                  id='name'
                  name='name'
                  placeholder='FOH 1'
                  className='mt-1'
                  type='text'
                  required={true}
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <span className='text-sm font-medium text-gray-900 dark:text-white'>
                  Categorie
                </span>
                <div className='mt-2'>
                  <Dropdown
                    id='category'
                    color={'primary'}
                    label={moduleCategoryToString(values.category)}
                  >
                    <Dropdown.Item
                      onClick={() =>
                        handleDropDownChange(ModuleCategoryEnum.NOTCATEGORIZED)
                      }
                    >
                      {moduleCategoryToString(
                        ModuleCategoryEnum.NOTCATEGORIZED
                      )}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        handleDropDownChange(ModuleCategoryEnum.NR)
                      }
                    >
                      {moduleCategoryToString(ModuleCategoryEnum.NR)}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        handleDropDownChange(ModuleCategoryEnum.CLIMATE)
                      }
                    >
                      {moduleCategoryToString(ModuleCategoryEnum.CLIMATE)}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        handleDropDownChange(ModuleCategoryEnum.RSE)
                      }
                    >
                      {moduleCategoryToString(ModuleCategoryEnum.RSE)}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        handleDropDownChange(ModuleCategoryEnum.PREVENTION)
                      }
                    >
                      {moduleCategoryToString(ModuleCategoryEnum.PREVENTION)}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        handleDropDownChange(ModuleCategoryEnum.PERSONALDEV)
                      }
                    >
                      {moduleCategoryToString(ModuleCategoryEnum.PERSONALDEV)}
                    </Dropdown.Item>
                  </Dropdown>
                </div>
              </div>
              <div>
                <Label htmlFor='shortResume'>
                  Petite description (-200 caractères)
                </Label>
                <TextInput
                  id='shortResume'
                  name='shortResume'
                  placeholder=''
                  className='mt-1'
                  type='text'
                  value={values.shortResume}
                  onChange={handleChange}
                  required={true}
                />
              </div>
              <div>
                <Label>Tags</Label>
                <TagSelector
                  suggestions={[...suggestionsTag]}
                  tagsSelected={tagsSelected}
                  setTagsSelected={setTagsSelected}
                />
              </div>
              <div className='lg:col-span-2'>
                <span className='text-sm font-medium text-gray-900 dark:text-white'>
                  Grande description
                </span>
                <EditorSwitch
                  value={values.longResume?.version ? values.longResume : {}}
                  callback={(e: any) => setValues({ ...values, longResume: e })}
                />
              </div>
              <div className='lg:col-span-2'>
                <Label>Changer de thumbnail</Label>
                <SelectMediaModal setImageSelected={setThumbSelected} />
              </div>
              {thumbSelected && thumbSelected._id && (
                <>
                  <div className='lg:col-span-2'>
                    <ThumbnailResource resource={thumbSelected} />
                  </div>
                  <div className='cursor-pointer'>
                    <span className='sr-only'>Supprimer</span>
                    <HiTrash
                      className='-mt-5 text-2xl text-red-600'
                      onClick={() => setThumbSelected({} as Resource)}
                    />
                  </div>
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Editer
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

const DeleteModuleModal: FC<{ moduleSelected: Module }> = function ({
  moduleSelected,
}) {
  const [isOpen, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const toast = useToast()
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    toast('success', `Le module ${moduleSelected.name} a bien été supprimé`)
    dispatch(removeModule(moduleSelected))
    dispatch(removeModuleToDataTable(moduleSelected))
    setOpen(false)
  }
  return (
    <>
      <Button color='failure' onClick={() => setOpen(!isOpen)}>
        <HiTrash className='mr-2 text-lg' />
        Supprimer
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen} size='md'>
        <Modal.Header className='px-3 pb-0 pt-3'>
          <span className='sr-only'>Supprimer module</span>
        </Modal.Header>
        <Modal.Body className='px-6 pb-6 pt-0'>
          <div className='flex flex-col items-center gap-y-6 text-center'>
            <HiOutlineExclamationCircle className='text-7xl text-red-600' />
            <p className='text-lg text-gray-500 dark:text-gray-300'>
              Êtes vous sur de vouloir détruire le module?
            </p>
            <div className='flex items-center gap-x-3'>
              <Button color='failure' onClick={handleSubmit}>
                Oui, je suis sur
              </Button>
              <Button color='gray' onClick={() => setOpen(false)}>
                Non, annuler
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export const ChangeStatusModuleModal: FC = function () {
  const { module } = useTypedSelector((store) => store.module)

  const { countMasterVersions, countLearningObjectivesValid, trainer } = module

  const disabledValidated = useMemo(() => {
    return (
      countMasterVersions === 0 ||
      !countLearningObjectivesValid ||
      trainer === undefined
    )
  }, [countMasterVersions, countLearningObjectivesValid, trainer])

  const [isOpen, setOpen] = useState(false)
  const [status, setStatus] = useState(module.status)
  const dispatch = useAppDispatch()
  const toast = useToast()

  const handleStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setStatus(value)
  }

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (status !== '') {
      const moduleObject = { _id: module._id, status: status }
      dispatch(changeModuleStatus(moduleObject))
    } else {
      toast('error', `Vous avez besoin de choisir le nouveau status`)
    }
  }

  return (
    <>
      {module.status === 'pending' && (
        <Button color='gray' onClick={() => setOpen(!isOpen)}>
          <HiStatusOffline className='mr-2 text-lg' />
          <span>En cours de validation</span>
        </Button>
      )}
      {module.status === 'approved' && (
        <Button color='primary' onClick={() => setOpen(!isOpen)}>
          <HiStatusOnline className='mr-2 text-lg' />
          <span>Validé</span>
        </Button>
      )}
      {module.status === 'closed' && (
        <Button color='dark' onClick={() => setOpen(!isOpen)}>
          <HiLockClosed className='mr-2 text-lg' />
          <span>Inactif</span>
        </Button>
      )}
      <Modal onClose={() => setOpen(false)} show={isOpen} size='2xl'>
        <Modal.Header className='px-3 pb-0 pt-3'>
          <span className='sr-only'>Changer Status</span>
        </Modal.Header>
        <Modal.Body className='px-6 pb-6 pt-0'>
          <div className='flex flex-col items-center text-center'>
            <h2 className='mb-4 text-2xl font-bold text-black dark:text-white'>
              Changer le status du module {module.name}
            </h2>
            <fieldset className='grid grid-cols-1 pt-9'>
              {/* <legend className='mb-4'>
                    Choisir le status du module {module.name}
                  </legend> */}
              <div className='flex flex-col rounded-lg bg-white p-6 dark:bg-gray-800'>
                <div className='flex-1'>
                  <h3 className='mb-4 text-2xl font-semibold text-gray-500 dark:text-gray-400'>
                    En cours de validation
                  </h3>
                  <p className='text-lg font-normal text-gray-500 dark:text-gray-400'>
                    N'apparait pas sur le catalogue pas prêt
                  </p>
                </div>
                <div className='flex items-center justify-center p-2'>
                  <Radio
                    id='pending-status'
                    name='status'
                    value='pending'
                    onChange={handleStatus}
                    checked={status === 'pending'}
                  />
                </div>
              </div>
              {!disabledValidated && (
                <div className='flex flex-col rounded-lg bg-white p-6 dark:bg-gray-800'>
                  <div className='flex-1'>
                    <h3 className='mb-4 text-2xl font-semibold text-primary-500 dark:text-primary-400'>
                      Validé
                    </h3>
                    <p className='text-lg font-normal text-primary-500 dark:text-primary-400'>
                      Apparait sur le catalogue, doit avoir une version en
                      master, au moins un objectif pédagogique et un formateur
                      pour fonctionner
                    </p>
                  </div>
                  <div className='flex items-center justify-center p-2'>
                    <Radio
                      id='approved-status'
                      name='status'
                      value='approved'
                      onChange={handleStatus}
                      checked={status === 'approved'}
                    />
                  </div>
                </div>
              )}
              <div className='flex flex-col rounded-lg bg-white p-6 dark:bg-gray-800'>
                <div className='flex-1'>
                  <h3 className='mb-4 text-2xl font-semibold text-red-500 dark:text-red-400'>
                    Inactif
                  </h3>
                  <p className='text-lg font-normal text-red-500 dark:text-red-400'>
                    Apparait sur le catalogue en inaccessible
                  </p>
                </div>
                <div className='flex items-center justify-center p-2'>
                  <Radio
                    id='closed-status'
                    name='status'
                    value='closed'
                    onChange={handleStatus}
                    checked={status === 'closed'}
                  />
                </div>
              </div>
            </fieldset>
            <div className='flex items-center gap-x-3'>
              <Button color='warning' onClick={onSubmit}>
                Oui, je suis sur
              </Button>
              <Button color='gray' onClick={() => setOpen(false)}>
                Non, annuler
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const ModulesTable: FC = function () {
  const { modules, isLoading } = useTypedSelector((store) => store.allModules)
  const { tags } = useTypedSelector((store) => store.allTags)
  const castTags = tags as Tag[]
  const castModule = modules as Module[]
  const navigate = useNavigate()
  if (isLoading) {
    return <Loading center />
  }
  if (modules.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>Pas de modules à afficher...</span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>Categorie/Status</Table.HeadCell>
        <Table.HeadCell>Thumbnail</Table.HeadCell>
        <Table.HeadCell>Nom du module</Table.HeadCell>
        <Table.HeadCell>Tags</Table.HeadCell>
        <Table.HeadCell>Dernière modification</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {castModule.map((module) => {
          return (
            <Table.Row
              key={module._id}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='whitespace-nowrap p-4 text-base font-normal text-white'>
                <div className='grid gap-2'>
                  <div className='flex items-center'>
                    <Badge color='primary'>
                      {moduleCategoryToString(module.category)}
                    </Badge>
                  </div>
                  <div className='flex items-center'>
                    {module.status === 'pending' && (
                      <Badge color='gray'>
                        {/* <HiStatusOffline className='mr-2 text-lg' /> */}
                        <span>En cours de validation</span>
                      </Badge>
                    )}
                    {module.status === 'testing' && (
                      <Badge color='purple'>
                        {/* <HiStatusOffline className='mr-2 text-lg' /> */}
                        <span>DEBUG</span>
                      </Badge>
                    )}
                    {module.status === 'approved' && (
                      <Badge color='primary'>
                        {/* <HiStatusOnline className='mr-2 text-lg' /> */}
                        <span>Validé</span>
                      </Badge>
                    )}
                    {module.status === 'closed' && (
                      <Badge color='dark'>
                        {/* <HiLockClosed className='mr-2 text-lg' /> */}
                        <span>Inactif</span>
                      </Badge>
                    )}
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400'>
                <ThumbnailResource resource={module.thumbResource} />
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-sm font-normal text-gray-500 dark:text-gray-400'>
                <div className='text-base font-semibold text-gray-900 dark:text-white'>
                  {module.name}
                </div>
                <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                  {module.lrsObjectId}
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 max-w-[200px] overflow-hidden text-ellipsis text-base font-medium text-gray-900 dark:text-white'>
                {tags.length > 0 && (
                  <div className='flex flex-wrap gap-1'>
                    {castTags
                      .filter((tag) => module.tags.indexOf(tag._id) > -1)
                      .map((tag) => {
                        return <Kbd key={tag._id}>{tag.title}</Kbd>
                      })}
                  </div>
                )}
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                {`${moment(module.updatedAt).fromNow()}`}
              </Table.Cell>
              <Table.Cell className='space-x-2 whitespace-nowrap p-4'>
                <div className='flex items-center gap-x-3'>
                  <Button
                    color='purple'
                    onClick={() => navigate(`./${module._id}`)}
                  >
                    <HiArrowRight className='mr-2 text-lg' />
                    Accès
                  </Button>
                  <EditModuleModal moduleSelected={module} />
                  {module.status !== 'approved' && (
                    <DeleteModuleModal moduleSelected={module} />
                  )}
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default ModulesListPage
