/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Avatar,
  Breadcrumb,
  Button,
  Label,
  Modal,
  Table,
  TextInput,
} from 'flowbite-react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import {
  HiHome,
  HiOutlineExclamationCircle,
  HiPlus,
  HiTrash,
} from 'react-icons/hi'
import { DataTableController, Loading, Pagination } from '../../components'
import { useAppDispatch, useTypedSelector } from '../../store'
import {
  changeInputValues,
  clearModal,
  getAllSpecialUsers,
  changePage,
  addSpecialUserToDataTable,
  removeSpecialUserToDataTable,
  clearFilters,
} from '../../features/allSpecialUsers/allSpecialUsersSlice'
import { useToast } from '../../hooks/useToast'
import {
  SpecialUser,
  clearSpecialUserForm,
  deleteUser,
  demoteAdminToUser,
  demoteBetaUser,
  promoteUserToAdmin,
} from '../../features/specialUser/specialUserSlice'
import profile_icon from '../../assets/images/profile_icon.svg'
import { useNavigate } from 'react-router-dom'

const UserListPage: FC = function () {
  const { error, page, totalUsers, numOfPages, search, sort, role } =
    useTypedSelector((store) => store.allSpecialUsers)
  const { error: specialUserError } = useTypedSelector(
    (store) => store.specialUser
  )
  const toast = useToast()
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getAllSpecialUsers({}))
  }, [dispatch, page, search, sort, role])

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
    }
    if (specialUserError !== '') {
      toast('error', specialUserError)
      dispatch(clearSpecialUserForm())
    }
    // eslint-disable-next-line
  }, [error, specialUserError])

  return (
    <>
      <div className='block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex'>
        <div className='mb-1 w-full'>
          <div className='mb-4'>
            <Breadcrumb className='mb-4'>
              <Breadcrumb.Item href='#'>
                <div className='flex items-center gap-x-3'>
                  <HiHome className='text-xl' />
                  <span className='dark:text-white'>Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href='/administration/users'>
                Users
              </Breadcrumb.Item>
              <Breadcrumb.Item>List</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className='text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl'>
              Administrateurs et beta testeurs
            </h1>
          </div>
          <div className='sm:flex'>
            <DataTableController
              placeholder='rechercher un utilisateur'
              handleChange={changeInputValues}
              sort={sort}
              search={search}
              // @ts-ignore
              clean={clearFilters}
              initFilters={[
                {
                  name: 'role',
                  value: role,
                  control: 'beta',
                  text: 'Beta uniquement',
                },
                {
                  name: 'role',
                  value: role,
                  control: 'admin',
                  text: 'Admin uniquement',
                },
              ]}
            ></DataTableController>
            <div className='ml-auto flex items-center space-x-2 sm:space-x-3'>
              {/* <AddBetaUserModal /> */}
              <AddAdminModal />
              {/* <Button color='gray'>
                <div className='flex items-center gap-x-3'>
                  <HiDocumentDownload className='text-xl' />
                  <span>Export</span>
                </div>
              </Button> */}
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='overflow-x-auto'>
          <div className='inline-block min-w-full align-middle'>
            <div className='overflow-hidden shadow'>
              <AllSpecialUsersTable />
            </div>
          </div>
        </div>
      </div>
      <Pagination
        numOfPages={numOfPages}
        total={totalUsers}
        page={page}
        changePage={changePage}
      />
    </>
  )
}

const AddAdminModal: FC = function () {
  const [isOpen, setOpen] = useState(false)
  const { specialUser, isLoading } = useTypedSelector(
    (store) => store.specialUser
  )
  const [emailUser, setEmailUser] = useState<string>('')
  const dispatch = useAppDispatch()
  const toast = useToast()

  useEffect(() => {
    if (specialUser?._id && isOpen) {
      toast('success', `Un nouvel administrateur a été ajouté`)
      //refresh list
      dispatch(addSpecialUserToDataTable(specialUser))
      dispatch(clearSpecialUserForm())
      setOpen(false)
    }
    // eslint-disable-next-line
  }, [toast, specialUser, isOpen])

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    setEmailUser(value)
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (emailUser) {
      dispatch(promoteUserToAdmin(emailUser))
    } else {
      toast('error', `Vous avez besoin de renseigner l'email`)
    }
  }

  return (
    <>
      <Button
        disabled={isLoading}
        color='primary'
        onClick={() => {
          dispatch(clearModal())
          setOpen(true)
        }}
      >
        <div className='flex items-center gap-x-3'>
          <HiPlus className='text-xl' />
          Ajouter un administrateur
        </div>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <form onSubmit={onSubmit}>
          <Modal.Header className='border-b border-gray-200 !p-6 dark:border-gray-700'>
            <strong>Ajouter un nouvel administrateur</strong>
          </Modal.Header>
          <Modal.Body>
            <div className='grid grid-cols-1 gap-6 sm:grid-cols-1'>
              <div>
                <Label htmlFor='searchNewAdminEmail'>
                  Entrez l'email, doit exister sur Lagoon
                </Label>
                <div className='mt-1'>
                  <TextInput
                    id='searchNewAdminEmail'
                    name='searchNewAdminEmail'
                    type='email'
                    placeholder='aaa@bbb.ccc'
                    onChange={handleChange}
                    value={emailUser}
                    required={true}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={isLoading} color='primary' type='submit'>
              Ajouter
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

const AllSpecialUsersTable: FC = function () {
  const { users, isLoading } = useTypedSelector(
    (store) => store.allSpecialUsers
  )
  const { user } = useTypedSelector((store) => store.user)
  const navigate = useNavigate()
  const castSpecialUser = users as SpecialUser[]
  if (isLoading) {
    return <Loading center />
  }
  if (users.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>
            Pas d'administrateurs à afficher...
          </span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>Nom</Table.HeadCell>
        <Table.HeadCell>Role</Table.HeadCell>
        <Table.HeadCell>Pays</Table.HeadCell>
        <Table.HeadCell>Status</Table.HeadCell>
        <Table.HeadCell>Actions</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {castSpecialUser.map((specialUser) => {
          return (
            <Table.Row
              key={specialUser._id}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='mr-12 flex items-center space-x-6 whitespace-nowrap p-4 lg:mr-0'>
                <Avatar
                  alt=''
                  img={profile_icon}
                  rounded
                  size='sm'
                  className='shrink-0'
                />
                <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                  <div className='text-base font-semibold text-gray-900 dark:text-white'>
                    {specialUser.lastName} {specialUser.name}
                  </div>
                  <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                    {specialUser.email}
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                {specialUser.role}
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                France
              </Table.Cell>
              {specialUser.isVerified ? (
                <Table.Cell className='whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white'>
                  <div className='flex items-center'>
                    <div className='mr-2 h-2.5 w-2.5 rounded-full bg-green-400'></div>{' '}
                    Active
                  </div>
                </Table.Cell>
              ) : (
                <Table.Cell className='whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white'>
                  <div className='flex items-center'>
                    <div className='mr-2 h-2.5 w-2.5 rounded-full bg-red-500'></div>{' '}
                    Doit valider son mdp
                  </div>
                </Table.Cell>
              )}

              <Table.Cell>
                <div className='flex items-center gap-x-3 whitespace-nowrap'>
                  <Button
                    color='purple'
                    onClick={() => navigate(`./${specialUser._id}/recordings`)}
                  >
                    Voir les enregistrements
                  </Button>
                  {/* display remove admin only if user is not the same that connected user */}
                  {user.userId !== specialUser._id &&
                    specialUser.role === 'admin' && (
                      <RemoveAdminModal email={specialUser.email} />
                    )}
                  {specialUser.role === 'beta' && (
                    <>
                      <RemoveBetaUserModal email={specialUser.email} />
                      <DeleteUserModal specialUser={specialUser} />
                    </>
                  )}
                </div>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

const RemoveAdminModal: FC<{ email: string }> = function ({ email }) {
  const [isOpen, setOpen] = useState(false)
  const { specialUser, isLoading } = useTypedSelector(
    (store) => store.specialUser
  )

  const dispatch = useAppDispatch()
  const toast = useToast()

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    dispatch(demoteAdminToUser(email))
  }

  useEffect(() => {
    if (specialUser?._id && isOpen) {
      dispatch(removeSpecialUserToDataTable(specialUser))
      dispatch(clearSpecialUserForm())
      toast('success', `Un administrateur a été retiré`)
      setOpen(false)
    }
    // eslint-disable-next-line
  }, [specialUser, isOpen])
  return (
    <>
      <Button
        color='failure'
        onClick={() => {
          setOpen(true)
        }}
      >
        <div className='flex items-center gap-x-2'>
          <HiTrash className='text-lg' />
          Retirer l'administrateur
        </div>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen} size='md'>
        <Modal.Header className='px-6 pb-0 pt-6'>
          <span className='sr-only'>Retirer l'administrateur</span>
        </Modal.Header>
        <Modal.Body className='px-6 pb-6 pt-0'>
          <div className='flex flex-col items-center gap-y-6 text-center'>
            <HiOutlineExclamationCircle className='text-7xl text-red-500' />
            <p className='text-xl text-gray-500'>
              Êtes vous sur de vouloir retirer {email} des admins ?
            </p>
            <div className='flex items-center gap-x-3'>
              <Button color='failure' onClick={handleSubmit}>
                Oui, je suis sur !
              </Button>
              <Button
                color='gray'
                disabled={isLoading}
                onClick={() => {
                  dispatch(clearModal())
                  setOpen(false)
                }}
              >
                Non, annuler
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const RemoveBetaUserModal: FC<{ email: string }> = function ({ email }) {
  const [isOpen, setOpen] = useState(false)
  const { specialUser, isLoading } = useTypedSelector(
    (store) => store.specialUser
  )

  const dispatch = useAppDispatch()
  const toast = useToast()

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    dispatch(demoteBetaUser(email))
  }

  useEffect(() => {
    if (specialUser?._id && isOpen) {
      dispatch(removeSpecialUserToDataTable(specialUser))
      dispatch(clearSpecialUserForm())
      toast('success', `Un beta testeur a été retiré`)
      setOpen(false)
    }
    // eslint-disable-next-line
  }, [toast, specialUser, isOpen])
  return (
    <>
      <Button
        color='failure'
        onClick={() => {
          setOpen(true)
        }}
      >
        <div className='flex items-center gap-x-2'>
          <HiTrash className='text-lg' />
          Retirer le beta testeur
        </div>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen} size='md'>
        <Modal.Header className='px-6 pb-0 pt-6'>
          <span className='sr-only'>Retirer le beta testeur</span>
        </Modal.Header>
        <Modal.Body className='px-6 pb-6 pt-0'>
          <div className='flex flex-col items-center gap-y-6 text-center'>
            <HiOutlineExclamationCircle className='text-7xl text-red-500' />
            <p className='text-xl text-gray-500'>
              Êtes vous sur de vouloir retirer {email} des beta testeurs ?
            </p>
            <div className='flex items-center gap-x-3'>
              <Button color='failure' onClick={handleSubmit}>
                Oui, je suis sur !
              </Button>
              <Button
                color='gray'
                disabled={isLoading}
                onClick={() => {
                  dispatch(clearModal())
                  setOpen(false)
                }}
              >
                Non, annuler
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const DeleteUserModal: FC<{ specialUser: SpecialUser }> = function ({
  specialUser,
}) {
  const [isOpen, setOpen] = useState(false)
  const { success, isLoading } = useTypedSelector((store) => store.specialUser)

  const dispatch = useAppDispatch()
  const toast = useToast()

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    dispatch(deleteUser(specialUser._id))
  }

  useEffect(() => {
    if (success && isOpen) {
      dispatch(removeSpecialUserToDataTable(specialUser))
      dispatch(clearSpecialUserForm())
      toast('success', `Un utilisateur a été supprimé`)
      setOpen(false)
    }
    // eslint-disable-next-line
  }, [toast, success, isOpen])
  return (
    <>
      <Button
        color='failure'
        onClick={() => {
          setOpen(true)
        }}
      >
        <div className='flex items-center gap-x-2'>
          <HiTrash className='text-lg' />
          Retirer l'utilisateur
        </div>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen} size='md'>
        <Modal.Header className='px-6 pb-0 pt-6'>
          <span className='sr-only'>Retirer l'utilisateur</span>
        </Modal.Header>
        <Modal.Body className='px-6 pb-6 pt-0'>
          <div className='flex flex-col items-center gap-y-6 text-center'>
            <HiOutlineExclamationCircle className='text-7xl text-red-500' />
            <p className='text-xl text-gray-500'>
              Retirer l'utilisateur {specialUser.email} ? Ces données seront
              anonymisées et sa liaison avec le profil béta perdu
            </p>
            <div className='flex items-center gap-x-3'>
              <Button color='failure' onClick={handleSubmit}>
                Oui, je suis sur !
              </Button>
              <Button
                color='gray'
                disabled={isLoading}
                onClick={() => {
                  dispatch(clearModal())
                  setOpen(false)
                }}
              >
                Non, annuler
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UserListPage
