import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllActivitiesThunk } from './allActivitiesThunk'

const initialFiltersState = {}

const initialState = {
  isLoading: false,
  activities: [],
  learningObjectives: [],
  learningScenario: null,
  totalActivities: 0,
  numOfPages: 1,
  page: 1,
  error: '',
  // stats: {},
  // monthlyApplications: [],
  ...initialFiltersState,
}

export const getAllActivities = createAsyncThunk(
  'activities/list',
  getAllActivitiesThunk
)

const allActivitiesSlice = createSlice({
  name: 'allActivities',
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true
    },
    hideLoading: (state) => {
      state.isLoading = false
    },
    setLearningScenario: (state, { payload: id }) => {
      state.learningScenario = id
    },
    handleChange: (state, { payload: { name, value } }) => {
      state.page = 1
      state[name] = value
    },
    clearFilters: (state) => {
      return { ...state, ...initialFiltersState }
    },
    changePage: (state, { payload }) => {
      state.page = payload
    },
    addActivityToDataTable: (state, { payload }) => {
      state.activities.push(payload)
      state.totalActivities++
    },
    editActivitiesToDataTable: (state, { payload }) => {
      state.activities = state.activities.map((activity) => {
        if (activity._id === payload._id) {
          activity = { ...payload }
        }
        return activity
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllActivities.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(getAllActivities.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.activities = payload.activities
        state.learningObjectives = payload.learningObjectives
        state.numOfPages = payload.numOfPages
        state.totalActivities = payload.totalActivities
      })
      .addCase(getAllActivities.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
  },
})

export const {
  showLoading,
  hideLoading,
  handleChange,
  clearFilters,
  changePage,
  addActivityToDataTable,
  editActivitiesToDataTable,
  setLearningScenario,
} = allActivitiesSlice.actions

export default allActivitiesSlice.reducer
