import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const getTaxonomyThunk = async (_, id, thunkAPI) => {
  try {
    let url = `/taxonomies/${id}`
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
export const addTaxonomyThunk = async (_, taxonomy, thunkAPI) => {
  let url = `/taxonomies`
  try {
    const resp = await customFetch.post(url, taxonomy)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
export const removeTaxonomyThunk = async (_, taxonomy, thunkAPI) => {
  let url = `/taxonomies/${taxonomy._id}`
  try {
    const resp = await customFetch.delete(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
export const editTaxonomyThunk = async (_, taxonomy, thunkAPI) => {
  let url = `/taxonomies/${taxonomy._id}`
  try {
    const resp = await customFetch.patch(url, taxonomy)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
