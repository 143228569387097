import { Label, Modal, TextInput, Textarea } from 'flowbite-react'
import { ColorPicker } from '..'

const SkillModalBody = ({
  values,
  handleChange,
  handleTextAreaChange,
  handleColorChange,
}) => {
  return (
    <Modal.Body>
      <div className='grid grid-cols-1 gap-6 lg:grid-cols-2'>
        <div>
          <Label htmlFor='name'>Nom de la compétence</Label>
          <TextInput
            id='name'
            name='name'
            placeholder='Facteur humain et communication'
            className='mt-1'
            type='text'
            required={true}
            value={values.name}
            onChange={handleChange}
          />
        </div>
        <div>
          <Label>Couleur de la compétence</Label>
          <ColorPicker initColor={values.color} onChange={handleColorChange} />
        </div>
        <div className='lg:col-span-2'>
          <Label htmlFor='description'>description</Label>
          <Textarea
            id='description'
            name='description'
            placeholder='Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque pariatur esse doloribus possimus placeat corrupti minus incidunt necessitatibus id exercitationem. Reprehenderit exercitationem assumenda nobis eligendi quae, provident rerum accusamus reiciendis!'
            rows={6}
            className='mt-1'
            value={values.description}
            onChange={handleTextAreaChange}
          />
        </div>
      </div>
    </Modal.Body>
  )
}
export default SkillModalBody
