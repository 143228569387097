import { FC, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useTypedSelector } from '../../store'
import { useToast } from '../../hooks/useToast'
import {
  changeInputValues,
  clearFilters,
  getAllBetaContacts,
} from '../../features/allBetaContacts/allBetaContactsSlice'
import { HiUser } from 'react-icons/hi'
import { Avatar, Button, Modal, Table } from 'flowbite-react'
import DataTableController from '../DataTableController'
import {
  BetaContact,
  clearBetaContactForm,
  getSingleBetaContact,
} from '../../features/betaContact/betaContactSlice'
import Loading from '../Loading'
import profile_icon from '../../assets/images/profile_icon.svg'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

const FilterBetaContactSelect: FC<{
  handleChange: ActionCreatorWithPayload<any, any>
}> = ({ handleChange }) => {
  const { filterBetaContact } = useTypedSelector(
    (store) => store.allBetaLagoonFormAnswers
  )

  const { error, search, sort, betaContacts } = useTypedSelector(
    (store: any) => store.allBetaContacts
  )

  // it's possible to preselect filter user or param if passing info in route
  const { betaContactId } = useParams()
  const { betaContact, error: errorBetaContact } = useTypedSelector(
    (store) => store.betaContact
  )

  useEffect(() => {
    if (betaContactId) {
      dispatch(getSingleBetaContact(betaContactId))
    } else {
      dispatch(clearBetaContactForm())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betaContactId])

  const [isOpen, setOpen] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState<BetaContact[]>([])

  useEffect(() => {
    if (betaContact._id) {
      const newBetaContact = [betaContact]
      setSelectedUsers(newBetaContact)
    } else {
      setSelectedUsers([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betaContact])

  const betaContactMinusSelectedUser = useMemo(() => {
    const castbetaContacts = betaContacts as BetaContact[]
    return castbetaContacts.filter(
      (s) => !selectedUsers.find((selected) => selected._id === s._id)
    )
  }, [selectedUsers, betaContacts])

  useEffect(() => {
    if (error !== '') {
      toast('error', error)
    }
    if (errorBetaContact !== '') {
      toast('error', errorBetaContact)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, errorBetaContact])

  const dispatch = useAppDispatch()
  const toast = useToast()

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(false)
  }

  useEffect(() => {
    // apply filter when closing and if filterUser is changed
    if (!isOpen) {
      const reducedSelectedUsers = selectedUsers.map((s) => {
        return s._id
      })
      if (betaContactId) {
        if (betaContact) {
          if (
            JSON.stringify(reducedSelectedUsers) !==
            JSON.stringify(filterBetaContact)
          ) {
            dispatch(
              handleChange({
                name: 'filterBetaContact',
                value: reducedSelectedUsers,
              })
            )
          }
        }
      } else {
        if (
          JSON.stringify(reducedSelectedUsers) !==
          JSON.stringify(filterBetaContact)
        )
          dispatch(
            handleChange({
              name: 'filterBetaContact',
              value: reducedSelectedUsers,
            })
          )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, selectedUsers, betaContactId, betaContact])

  const addSelectedUser = (newBetaContact: BetaContact) => {
    setSelectedUsers([...selectedUsers, newBetaContact])
  }

  const removeSelectedUser = (newBetaContact: BetaContact) => {
    setSelectedUsers(selectedUsers.filter((s) => s._id !== newBetaContact._id))
  }

  useEffect(() => {
    if (betaContact._id === undefined) {
      dispatch(getAllBetaContacts({}))
    }
  }, [dispatch, search, sort, betaContact, betaContactId])

  return (
    <>
      <Button
        id={'search-user'}
        color='primary'
        onClick={() => setOpen(!isOpen)}
        disabled={typeof betaContactId === 'string'}
      >
        <HiUser className='mr-2 text-lg' />
        <span className='hidden lg:block '>{`${selectedUsers.length} contact(s) beta selectionné(s)`}</span>
        <span className='block lg:hidden '>{`${selectedUsers.length} contact(s)`}</span>
      </Button>
      <Modal onClose={() => setOpen(false)} show={isOpen}>
        <Modal.Header
          theme={{
            base: `flex items-start justify-between rounded-t-2xl border-b p-5 dark:border-gray-600`,
            popup: 'border-b-0 p-2',
          }}
        >
          <span>Selectionnez un ou plusieurs utilisateur(s)</span>
        </Modal.Header>
        <Modal.Body className='p-5'>
          <div className='w-full lg:w-1/2 sm:flex'>
            <DataTableController
              placeholder='rechercher un utilisateur'
              handleChange={changeInputValues}
              sort={sort}
              search={search}
              // @ts-ignore
              clean={clearFilters}
            ></DataTableController>
          </div>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
            <div className='flex flex-col'>
              <h2 className='py-3 text-white text-bold'>
                Tableau de recherche
              </h2>
              <div className='overflow-x-auto'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='overflow-hidden shadow'>
                    <SearchTable
                      betaContacts={betaContactMinusSelectedUser}
                      onMoveCallback={addSelectedUser}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-col'>
              <h2 className='py-3 text-white text-bold'>
                Tableau de selection
              </h2>
              <div className='overflow-x-auto'>
                <div className='inline-block min-w-full align-middle'>
                  <div className='overflow-hidden shadow'>
                    <SelectedTable
                      betaContacts={selectedUsers}
                      onMoveCallback={removeSelectedUser}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className='self-end' color='primary' onClick={handleSubmit}>
            Fermer le filtre utilisateur
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const SearchTable: FC<{
  betaContacts: BetaContact[]
  onMoveCallback: (betaContact: BetaContact) => void
}> = function ({ betaContacts, onMoveCallback }) {
  const { isLoading } = useTypedSelector((store: any) => store.allBetaContacts)
  if (isLoading) {
    return <Loading center />
  }
  if (betaContacts.length === 0) {
    return (
      <>
        <div
          className='p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-primary-600'
          role='alert'
        >
          <span className='font-medium'>Pas d'utilisateurs à afficher...</span>
        </div>
      </>
    )
  }
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>Nom</Table.HeadCell>
        <Table.HeadCell>Fonction</Table.HeadCell>
        <Table.HeadCell>Status</Table.HeadCell>
        <Table.HeadCell>Selection</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {betaContacts.map((betaContact) => {
          return (
            <Table.Row
              key={betaContact._id}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='mr-12 flex items-center space-x-6 whitespace-nowrap p-4 lg:mr-0'>
                <Avatar
                  alt=''
                  img={profile_icon}
                  rounded
                  size='sm'
                  className='shrink-0'
                />
                <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                  <div className='text-base font-semibold text-gray-900 dark:text-white'>
                    {betaContact.lastName} {betaContact.name}
                  </div>
                  <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                    {betaContact.email}
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                {betaContact.employment && (
                  <>
                    {betaContact.employment.digitalTraining && (
                      <div className='flex items-center'>
                        <span>Formation digitale</span>
                      </div>
                    )}
                    {betaContact.employment.facetofaceTraining && (
                      <div className='flex items-center'>
                        <span>Formation présentielle</span>
                      </div>
                    )}
                    {betaContact.employment.futureChallenges && (
                      <div className='flex items-center'>
                        <span>Enjeux climatiques</span>
                      </div>
                    )}
                    {betaContact.employment.hr && (
                      <div className='flex items-center'>
                        <span>RH</span>
                      </div>
                    )}
                    {betaContact.employment.ceo && (
                      <div className='flex items-center'>
                        <span>CEO</span>
                      </div>
                    )}
                    {betaContact.employment.other && (
                      <div className='flex items-center'>
                        <span className='underline'>
                          {betaContact.employment.other}
                        </span>
                      </div>
                    )}
                  </>
                )}
              </Table.Cell>
              {betaContact.user ? (
                <Table.Cell className='whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white'>
                  <div className='flex items-center'>
                    <div className='mr-2 h-2.5 w-2.5 rounded-full bg-green-400'></div>{' '}
                    Invité
                  </div>
                </Table.Cell>
              ) : (
                <Table.Cell className='whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white'>
                  <div className='flex items-center'>
                    <div className='mr-2 h-2.5 w-2.5 rounded-full bg-red-500'></div>{' '}
                    Pas invité
                  </div>
                </Table.Cell>
              )}
              <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                <Button
                  color={'gray'}
                  onClick={() => onMoveCallback(betaContact)}
                >
                  <FaArrowRight className='h-4 w-4' />
                </Button>
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

const SelectedTable: FC<{
  betaContacts: BetaContact[]
  onMoveCallback: (betaContact: BetaContact) => void
}> = function ({ betaContacts, onMoveCallback }) {
  return (
    <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
      <Table.Head className='bg-gray-100 dark:bg-gray-700'>
        <Table.HeadCell>Déselection</Table.HeadCell>
        <Table.HeadCell>Nom</Table.HeadCell>
        <Table.HeadCell>Role</Table.HeadCell>
        <Table.HeadCell>Status</Table.HeadCell>
      </Table.Head>
      <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
        {betaContacts.map((betaContact) => {
          return (
            <Table.Row
              key={betaContact._id}
              className='hover:bg-gray-100 dark:hover:bg-gray-700'
            >
              <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                <Button
                  color={'gray'}
                  onClick={() => onMoveCallback(betaContact)}
                >
                  <FaArrowLeft className='h-4 w-4' />
                </Button>
              </Table.Cell>
              <Table.Cell className='mr-12 flex items-center space-x-6 whitespace-nowrap p-4 lg:mr-0'>
                <Avatar
                  alt=''
                  img={profile_icon}
                  rounded
                  size='sm'
                  className='shrink-0'
                />
                <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                  <div className='text-base font-semibold text-gray-900 dark:text-white'>
                    {betaContact.lastName} {betaContact.name}
                  </div>
                  <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                    {betaContact.email}
                  </div>
                </div>
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                {betaContact.employment && (
                  <>
                    {betaContact.employment.digitalTraining && (
                      <div className='flex items-center'>
                        <span>Formation digitale</span>
                      </div>
                    )}
                    {betaContact.employment.facetofaceTraining && (
                      <div className='flex items-center'>
                        <span>Formation présentielle</span>
                      </div>
                    )}
                    {betaContact.employment.futureChallenges && (
                      <div className='flex items-center'>
                        <span>Enjeux climatiques</span>
                      </div>
                    )}
                    {betaContact.employment.hr && (
                      <div className='flex items-center'>
                        <span>RH</span>
                      </div>
                    )}
                    {betaContact.employment.ceo && (
                      <div className='flex items-center'>
                        <span>CEO</span>
                      </div>
                    )}
                    {betaContact.employment.other && (
                      <div className='flex items-center'>
                        <span className='underline'>
                          {betaContact.employment.other}
                        </span>
                      </div>
                    )}
                  </>
                )}
              </Table.Cell>
              {betaContact.user ? (
                <Table.Cell className='whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white'>
                  <div className='flex items-center'>
                    <div className='mr-2 h-2.5 w-2.5 rounded-full bg-green-400'></div>{' '}
                    Invité
                  </div>
                </Table.Cell>
              ) : (
                <Table.Cell className='whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white'>
                  <div className='flex items-center'>
                    <div className='mr-2 h-2.5 w-2.5 rounded-full bg-red-500'></div>{' '}
                    Pas invité
                  </div>
                </Table.Cell>
              )}
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default FilterBetaContactSelect
