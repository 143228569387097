import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  addTaxonomyThunk,
  editTaxonomyThunk,
  getTaxonomyThunk,
  removeTaxonomyThunk,
} from './taxonomyThunk'

export interface Taxonomy {
  _id?: string
  weight: number
  level: number
  skill: string
  learningObjective: string
}

export const LevelTaxonomy = {
  level1: 'Connaissance',
  level2: 'Compréhension',
  level3: 'Application',
}

export interface TaxonomyForm {
  _id?: string
  weight: number
  level: number
  skill: string
  learningObjective: string
}

export interface TaxonomyState {
  isLoading: boolean
  taxonomy: Taxonomy
  error: string
}

const initialState: TaxonomyState = {
  isLoading: false,
  taxonomy: {} as Taxonomy,
  error: '',
}

export const getTaxonomy = createAsyncThunk(
  'taxonomy/get',
  async (id: string, thunkAPI) => {
    return getTaxonomyThunk('/taxonomies', id, thunkAPI)
  }
)

export const addTaxonomy = createAsyncThunk(
  'taxonomy/new',
  async (taxonomy: TaxonomyForm, thunkAPI) => {
    return addTaxonomyThunk('/taxonomies', taxonomy, thunkAPI)
  }
)

export const removeTaxonomy = createAsyncThunk(
  'taxonomy/remove',
  async (taxonomy: TaxonomyForm, thunkAPI) => {
    return removeTaxonomyThunk('/taxonomies/:id', taxonomy, thunkAPI)
  }
)

export const editTaxonomy = createAsyncThunk(
  'taxonomy/edit',
  async (taxonomy: TaxonomyForm, thunkAPI) => {
    return editTaxonomyThunk('/taxonomies/:id', taxonomy, thunkAPI)
  }
)

const taxonomySlice = createSlice({
  name: 'taxonomy',
  initialState,
  reducers: {
    clearTaxonomyForm: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTaxonomy.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(getTaxonomy.fulfilled, (state, { payload }) => {
        const { taxonomy } = payload
        state.taxonomy = taxonomy
        state.isLoading = false
      })
      .addCase(getTaxonomy.rejected, (state, { payload }) => {
        state.isLoading = false
        state.taxonomy = {} as Taxonomy
        state.error = payload as string
      })
      .addCase(addTaxonomy.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(addTaxonomy.fulfilled, (state, { payload }) => {
        const { taxonomy } = payload
        state.taxonomy = taxonomy
        state.isLoading = false
      })
      .addCase(addTaxonomy.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addCase(editTaxonomy.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(editTaxonomy.fulfilled, (state, { payload }) => {
        state.isLoading = false
        const { taxonomy } = payload
        state.taxonomy = taxonomy
      })
      .addCase(editTaxonomy.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addCase(removeTaxonomy.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(removeTaxonomy.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(removeTaxonomy.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
  },
})

export const { clearTaxonomyForm } = taxonomySlice.actions

export default taxonomySlice.reducer
