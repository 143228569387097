import { useCallback } from 'react'
import { ReactTags } from 'react-tag-autocomplete'

export const TagSelector = ({ suggestions, tagsSelected, setTagsSelected }) => {
  const classNames = {
    root: 'relative cursor-text block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500 p-2.5 rounded-lg',
    rootIsActive: '',
    rootIsDisabled: 'opacity-75',
    rootIsInvalid: '',
    label: 'hidden p-0',
    tagList: 'inline text-gray-900 dark:text-white',
    tagListItem: 'inline list-none',
    tag: 'react-tags__tag px-2 py-1.5 mr-1 text-md text-gray-800 bg-gray-100 border border-gray-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500',
    tagName: 'react-tags__tag-name text-gray-900 dark:text-white',
    comboBox: 'inline-block py-1.5 max-w-full',
    input:
      'react-tags__combobox-input text-gray-900 dark:text-white w-full border-0 p-0 m-0 bg-transparent',
    listBox:
      'react-tags__listbox block disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500 p-2.5 text-sm rounded-lg absolute z-1',
    option: 'react-tags__listbox-option py-1.5',
    optionIsActive: 'is-active',
    highlight: 'react-tags__listbox-option-highlight',
  }

  const onAdd = useCallback(
    (newTag) => {
      setTagsSelected([...tagsSelected, newTag])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tagsSelected]
  )

  const onDelete = useCallback(
    (tagIndex) => {
      setTagsSelected(tagsSelected.filter((_, i) => i !== tagIndex))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tagsSelected]
  )

  return (
    <ReactTags
      selected={tagsSelected}
      suggestions={[...suggestions]}
      allowNew={true}
      onAdd={onAdd}
      onDelete={onDelete}
      newOptionText='Ajouter %value%'
      deleteButtonText='Retirer %value% de la liste'
      noOptionsText='Pas de tags trouvé'
      placeholderText='Ajouter un tag'
      classNames={classNames}
    />
  )
}
