import { FC, useMemo } from 'react'
import {
  InteractionEnum,
  InteractionForm,
} from '../../features/interaction/interactionSlice'
import {
  Button,
  Checkbox,
  Label,
  Modal,
  Select,
  Table,
  TextInput,
} from 'flowbite-react'
import { FaPlus } from 'react-icons/fa'
import { HiTrash } from 'react-icons/hi'

interface InteractionModalBodyProps {
  values: InteractionForm
  handleChange: (e: React.FormEvent<HTMLInputElement>) => void
  interactionType?: string
  handleSelectInteractionChange?: (
    e: React.FormEvent<HTMLSelectElement>
  ) => void
  handleCheckbox?: (e: React.FormEvent<HTMLInputElement>) => void
  handleAddAnswer?: (e: React.MouseEvent<HTMLButtonElement>) => void
  handleDeleteAnswer?: (
    e: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => void
  handleAnswerChange?: (
    e: React.FormEvent<HTMLInputElement>,
    index: number
  ) => void
  handleAnswerCorrect?: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void
}

export const InteractionModalBody: FC<InteractionModalBodyProps> = ({
  values,
  handleChange,
  interactionType,
  handleSelectInteractionChange,
  handleAddAnswer,
  handleDeleteAnswer,
  handleAnswerChange,
  handleAnswerCorrect,
  handleCheckbox,
}: InteractionModalBodyProps) => {
  const isEdit = useMemo(() => {
    return values._id !== undefined
    // eslint-disable-next-line
  }, [])
  return (
    <Modal.Body>
      <div className='grid grid-cols-1 gap-6 lg:grid-cols-2'>
        {!isEdit && (
          <div className='col-span-2'>
            <Label htmlFor='_id'>
              ID (remplir uniquement si interaction déjà créée en local)
            </Label>
            <TextInput
              id='_id'
              name='_id'
              placeholder='66cee38b2fe7bc8cc7647df3'
              className='mt-1'
              type='text'
              value={values._id}
              onChange={handleChange}
            />
          </div>
        )}
        <div>
          <Label htmlFor='title'>Intitulé</Label>
          <TextInput
            id='title'
            name='title'
            placeholder='Le lien menant à ...'
            className='mt-1'
            type='text'
            required={true}
            value={values.title}
            onChange={handleChange}
          />
        </div>
        {interactionType !== undefined &&
          handleSelectInteractionChange !== undefined && (
            <div>
              <Label htmlFor='interactionType'>Type d'intéraction</Label>
              <Select
                id='interactionType'
                name='interactionType'
                className='mt-1'
                onChange={handleSelectInteractionChange}
                defaultValue={interactionType}
              >
                <option value=''></option>
                <option value={InteractionEnum.EVALUATION}>
                  Evaluation (question notée, influe sur score objectif
                  pédagogique)
                </option>
                <option value={InteractionEnum.ASSESSMENT}>
                  Assessment (enquête, lien, autre)
                </option>
              </Select>
            </div>
          )}
        {/* Specificity fields in function of type */}
        {interactionType === InteractionEnum.EVALUATION &&
          handleAnswerCorrect !== undefined &&
          handleCheckbox !== undefined &&
          handleAddAnswer !== undefined &&
          handleAnswerChange !== undefined &&
          handleDeleteAnswer !== undefined && (
            <>
              <div>
                <Label htmlFor='weight'>Poids de la question</Label>
                <TextInput
                  id='weight'
                  name='weight'
                  placeholder='1'
                  className='mt-1'
                  type='number'
                  required={true}
                  value={values.weight}
                  onChange={handleChange}
                />
              </div>
              <div>
                <Label htmlFor='thresholdSuccess'>
                  Seuil de réussite / 0-100%
                </Label>
                <TextInput
                  id='thresholdSuccess'
                  name='thresholdSuccess'
                  placeholder='1'
                  className='mt-1'
                  type='number'
                  min={0}
                  max={100}
                  required={true}
                  value={values.thresholdSuccess}
                  onChange={handleChange}
                />
              </div>
              <div className='self-center'>
                <Checkbox
                  id='correction'
                  name='correction'
                  className='mt-1 mr-1'
                  onChange={handleCheckbox}
                  checked={values.correction}
                />
                <Label htmlFor='correction'>
                  Affichage de la réussite et de la correction
                </Label>
              </div>
              <div className='self-center'>
                <Checkbox
                  id='strictAnswerOrder'
                  name='strictAnswerOrder'
                  className='mt-1 mr-1'
                  onChange={handleCheckbox}
                  checked={values.strictAnswerOrder}
                />
                <Label htmlFor='strictAnswerOrder'>
                  Réponses à classer dans un ordre précis
                </Label>
              </div>
              <div className='lg:col-span-2'>
                <div className='flex flex-col'>
                  <div className='overflow-x-auto'>
                    <div className='inline-block min-w-full align-middle'>
                      <div className='overflow-hidden shadow'>
                        <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
                          <Table.Head className='bg-gray-100 dark:bg-gray-700'>
                            <Table.HeadCell></Table.HeadCell>
                            {!isEdit && (
                              <Table.HeadCell>ID (optionnel)</Table.HeadCell>
                            )}
                            <Table.HeadCell>Texte Réponse</Table.HeadCell>
                            <Table.HeadCell>Réponse Correcte</Table.HeadCell>
                            <Table.HeadCell>Image (optionnel)</Table.HeadCell>
                            <Table.HeadCell>Supprimer</Table.HeadCell>
                          </Table.Head>
                          <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
                            {values.answers?.map((answer, index) => {
                              return (
                                <Table.Row
                                  key={`table-modal-row-${index}`}
                                  id={`table-modal-row-${index}`}
                                  className='hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer transition'
                                >
                                  <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                                    {values.strictAnswerOrder && (
                                      <>{index + 1}</>
                                    )}
                                  </Table.Cell>
                                  {!isEdit && (
                                    <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                                      <TextInput
                                        id={`table-modal-form-text-${index}`}
                                        name='_id'
                                        type='_id'
                                        value={answer._id}
                                        onChange={(e) =>
                                          handleAnswerChange(e, index)
                                        }
                                      />
                                    </Table.Cell>
                                  )}
                                  <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                                    <TextInput
                                      id={`table-modal-form-text-${index}`}
                                      name='text'
                                      type='text'
                                      required={true}
                                      value={answer.text}
                                      onChange={(e) =>
                                        handleAnswerChange(e, index)
                                      }
                                    />
                                  </Table.Cell>

                                  <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                                    <div>
                                      <Checkbox
                                        id={`table-modal-form-correct-${index}`}
                                        name='correct'
                                        className='mt-1 mr-1'
                                        onChange={(e) =>
                                          handleAnswerCorrect(e, index)
                                        }
                                        checked={answer.correct}
                                      />
                                      <Label
                                        htmlFor={`table-modal-form-correct-${index}`}
                                      ></Label>
                                    </div>
                                  </Table.Cell>
                                  <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                                    WIP
                                  </Table.Cell>
                                  <Table.Cell>
                                    <Button
                                      color='failure'
                                      onClick={(e: any) =>
                                        handleDeleteAnswer(e, index)
                                      }
                                    >
                                      <HiTrash className='mr-2' />
                                    </Button>
                                  </Table.Cell>
                                </Table.Row>
                              )
                            })}
                          </Table.Body>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Button color='primary' onClick={handleAddAnswer}>
                    <FaPlus className='mr-3 text-sm' />
                    Ajouter une réponse
                  </Button>
                </div>
              </div>
            </>
          )}
        {interactionType === InteractionEnum.ASSESSMENT &&
          handleAddAnswer !== undefined &&
          handleAnswerChange !== undefined &&
          handleDeleteAnswer !== undefined && (
            <div className='lg:col-span-2'>
              <div>
                <Button color='primary' onClick={handleAddAnswer}>
                  <FaPlus className='mr-3 text-sm' />
                  Ajouter une réponse
                </Button>
              </div>
              <div className='flex flex-col'>
                <div className='overflow-x-auto'>
                  <div className='inline-block min-w-full align-middle'>
                    <div className='overflow-hidden shadow'>
                      <Table className='min-w-full divide-y divide-gray-200 dark:divide-gray-600'>
                        <Table.Head className='bg-gray-100 dark:bg-gray-700'>
                          <Table.HeadCell></Table.HeadCell>
                          {!isEdit && (
                            <Table.HeadCell>ID (optionnel)</Table.HeadCell>
                          )}
                          <Table.HeadCell>Texte Réponse</Table.HeadCell>
                          <Table.HeadCell>Image (optionnel)</Table.HeadCell>
                          <Table.HeadCell>Supprimer</Table.HeadCell>
                        </Table.Head>
                        <Table.Body className='divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800'>
                          {values.answers?.map((answer, index) => {
                            return (
                              <Table.Row
                                key={`table-modal-row-${index}`}
                                id={`table-modal-row-${index}`}
                                className='hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer transition'
                              >
                                <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                                  {values.strictAnswerOrder && <>{index + 1}</>}
                                </Table.Cell>
                                <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                                  <TextInput
                                    id={`table-modal-form-text-${index}`}
                                    name='text'
                                    type='text'
                                    required={true}
                                    value={answer.text}
                                    onChange={(e) =>
                                      handleAnswerChange(e, index)
                                    }
                                  />
                                </Table.Cell>
                                {!isEdit && (
                                  <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                                    <TextInput
                                      id={`table-modal-form-text-${index}`}
                                      name='_id'
                                      type='_id'
                                      value={answer._id}
                                      onChange={(e) =>
                                        handleAnswerChange(e, index)
                                      }
                                    />
                                  </Table.Cell>
                                )}
                                <Table.Cell className='whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white'>
                                  WIP
                                </Table.Cell>
                                <Table.Cell>
                                  <Button
                                    color='failure'
                                    onClick={(e: any) =>
                                      handleDeleteAnswer(e, index)
                                    }
                                  >
                                    <HiTrash className='mr-2' />
                                  </Button>
                                </Table.Cell>
                              </Table.Row>
                            )
                          })}
                        </Table.Body>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </Modal.Body>
  )
}
