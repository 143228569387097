import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  addModuleVersionThunk,
  copyModuleVersionThunk,
  removeModuleVersionThunk,
  editModuleVersionThunk,
  endModuleVersionBetaThunk,
  getModuleVersionThunk,
  createLearningScenarioForVersionThunk,
  updateLearningScenarioThunk,
  updateActivityLearningScenarioOrderThunk,
  getChangeLogInfoThunk,
  toggleActivityStatusThunk,
} from './moduleVersionThunk'
import { Module } from '../module/moduleSlice'
import { Activity } from '../activity/activitySlice'
import { Interaction } from '../interaction/interactionSlice'

export interface LearningScenario {
  _id: string
  navigation: string
}

export enum ChangeLogVerbEnum {
  ADD = 'add',
  REMOVE = 'remove',
  UPDATE = 'update',
}

export enum ChangeLogActorEnum {
  ACTIVITY = 'activity',
  INTERACTION = 'interaction',
}

export interface ChangeLogEntry {
  verb: ChangeLogVerbEnum
  actor: ChangeLogActorEnum
  lrsObjectId: string
}

export interface ChangeLogInfo {
  activities: Activity[]
  interactions: Interaction[]
}

export interface ModuleVersion {
  _id: string
  module: string
  version: string
  changes: string
  isBeta: boolean
  patch: string
  updatedAt: Date
  moduleInfo: Module
  learningScenario?: string
  learningScenarioInfo?: LearningScenario
  changeLog: ChangeLogEntry[]
}

export interface NewModuleVersionForm {
  module: string
}
export interface ModuleVersionForm {
  _id?: string
  module: string
  changes: string
  version: string
  patch: string
}
export interface LearningScenarioForm {
  _id?: string
  moduleVersion?: string
  navigation: string
}

export interface ChangeOrderForm {
  learningScenario: string
  activity: string
  order: number
}

export enum NavigationEnum {
  FREE = 'FREE',
  LINEAR = 'LINEAR',
}

export enum SelectPatchVersion {
  NONE = '',
  PATCH = 'PATCH',
  MINOR = 'MINOR',
  MAJOR = 'MAJOR',
}

export interface ModuleVersionState {
  isLoading: boolean
  moduleVersion: ModuleVersion
  changeLogInfo: ChangeLogInfo
  error: string
  success: boolean
  changeOrder: boolean
}

const initialState: ModuleVersionState = {
  isLoading: false,
  moduleVersion: {} as ModuleVersion,
  changeLogInfo: {} as ChangeLogInfo,
  error: '',
  success: false,
  changeOrder: false,
}

export const getModuleVersion = createAsyncThunk(
  'modulesVersion/get',
  async (id: string, thunkAPI) => {
    return getModuleVersionThunk('/mversion', id, thunkAPI)
  }
)

export const addModuleVersion = createAsyncThunk(
  'modulesVersion/new',
  async (moduleVersion: NewModuleVersionForm, thunkAPI) => {
    return addModuleVersionThunk('/mversion', moduleVersion, thunkAPI)
  }
)

export const copyModuleVersion = createAsyncThunk(
  'modulesVersion/copy',
  async (moduleVersion: ModuleVersionForm, thunkAPI) => {
    return copyModuleVersionThunk('/mversion/copy', moduleVersion, thunkAPI)
  }
)

export const removeModuleVersion = createAsyncThunk(
  'modulesVersion/remove',
  async (moduleVersion: ModuleVersionForm, thunkAPI) => {
    return removeModuleVersionThunk('/mversion/:id', moduleVersion, thunkAPI)
  }
)

export const editModuleVersion = createAsyncThunk(
  'modulesVersion/edit',
  async (moduleVersion: ModuleVersionForm, thunkAPI) => {
    return editModuleVersionThunk('/mversion/:id', moduleVersion, thunkAPI)
  }
)
export const endModuleVersionBeta = createAsyncThunk(
  'modulesVersion/endbeta',
  async (moduleVersion: ModuleVersionForm, thunkAPI) => {
    return endModuleVersionBetaThunk(
      '/mversion/:id/endbeta',
      moduleVersion,
      thunkAPI
    )
  }
)

export const getChangeLogInfo = createAsyncThunk(
  'modulesVersion/getchangeloginfo',
  async (moduleVersion: ModuleVersionForm, thunkAPI) => {
    return getChangeLogInfoThunk(
      '/mversion/:id/getchangeloginfo',
      moduleVersion,
      thunkAPI
    )
  }
)

export const toggleActivityStatus = createAsyncThunk(
  'modulesVersion/toggleActivityStatus',
  async (id: string, thunkAPI) => {
    return toggleActivityStatusThunk('/activity/:id/togglestatus', id, thunkAPI)
  }
)

export const createLearningScenarioForVersion = createAsyncThunk(
  'modulesVersion/newls',
  async (learningScenario: LearningScenarioForm, thunkAPI) => {
    return createLearningScenarioForVersionThunk(
      '/mversion/:id/endbeta',
      learningScenario,
      thunkAPI
    )
  }
)

export const updateLearningScenario = createAsyncThunk(
  'learningscenarios/update',
  async (learningScenario: LearningScenarioForm, thunkAPI) => {
    return updateLearningScenarioThunk(
      '/learningscenarios/:id',
      learningScenario,
      thunkAPI
    )
  }
)

export const updateActivityLearningScenarioOrder = createAsyncThunk(
  'learningscenarios/order',
  async (changeOrder: ChangeOrderForm, thunkAPI) => {
    return updateActivityLearningScenarioOrderThunk(
      '/learningscenarios/:id',
      changeOrder,
      thunkAPI
    )
  }
)

const moduleVersionSlice = createSlice({
  name: 'moduleVersion',
  initialState,
  reducers: {
    clearModuleVersionForm: () => initialState,
    clearModuleVersionSuccess: (state) => {
      state.success = false
    },
    clearChangeOrder: (state) => {
      state.changeOrder = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getModuleVersion.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(getModuleVersion.fulfilled, (state, { payload }) => {
        const { moduleVersion } = payload
        state.moduleVersion = moduleVersion
        state.isLoading = false
      })
      .addCase(getModuleVersion.rejected, (state, { payload }) => {
        state.isLoading = false
        state.moduleVersion = {} as ModuleVersion
        state.error = payload as string
      })
      .addCase(addModuleVersion.pending, (state) => {
        state.isLoading = true
        state.error = ''
        state.success = false
      })
      .addCase(addModuleVersion.fulfilled, (state, { payload }) => {
        const { moduleVersion } = payload
        state.moduleVersion = moduleVersion
        state.isLoading = false
        state.success = true
      })
      .addCase(addModuleVersion.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
        state.success = false
      })
      .addCase(copyModuleVersion.pending, (state) => {
        state.isLoading = true
        state.error = ''
        state.success = false
      })
      .addCase(copyModuleVersion.fulfilled, (state, { payload }) => {
        const { moduleVersion } = payload
        state.moduleVersion = moduleVersion
        state.isLoading = false
        state.success = true
      })
      .addCase(copyModuleVersion.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
        state.success = false
      })
      .addCase(editModuleVersion.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(editModuleVersion.fulfilled, (state, { payload }) => {
        const { moduleVersion } = payload
        state.moduleVersion = moduleVersion
        state.isLoading = false
      })
      .addCase(editModuleVersion.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
      .addCase(removeModuleVersion.pending, (state) => {
        state.isLoading = true
        state.error = ''
        state.success = false
      })
      .addCase(removeModuleVersion.fulfilled, (state) => {
        state.isLoading = false
        state.moduleVersion = {} as ModuleVersion
        state.success = true
      })
      .addCase(removeModuleVersion.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
        state.success = false
      })
      .addCase(endModuleVersionBeta.pending, (state) => {
        state.isLoading = true
        state.error = ''
        state.success = false
      })
      .addCase(endModuleVersionBeta.fulfilled, (state) => {
        state.isLoading = false
        state.success = true
      })
      .addCase(endModuleVersionBeta.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
        state.success = false
      })
      .addCase(createLearningScenarioForVersion.pending, (state) => {
        state.isLoading = true
        state.error = ''
        state.success = false
      })
      .addCase(
        createLearningScenarioForVersion.fulfilled,
        (state, { payload }) => {
          const { learningScenario } = payload
          state.moduleVersion.learningScenario = learningScenario._id
          state.moduleVersion.learningScenarioInfo = learningScenario
          state.isLoading = false
          state.success = true
        }
      )
      .addCase(
        createLearningScenarioForVersion.rejected,
        (state, { payload }) => {
          state.isLoading = false
          state.error = payload as string
          state.success = false
        }
      )
      .addCase(updateLearningScenario.pending, (state) => {
        state.isLoading = true
        state.error = ''
        state.success = false
      })
      .addCase(updateLearningScenario.fulfilled, (state, { payload }) => {
        const { learningScenario } = payload
        state.moduleVersion.learningScenario = learningScenario._id
        state.moduleVersion.learningScenarioInfo = learningScenario
        state.isLoading = false
        state.success = true
      })
      .addCase(updateLearningScenario.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
        state.success = false
      })
      .addCase(updateActivityLearningScenarioOrder.pending, (state) => {
        state.isLoading = true
        state.error = ''
        state.changeOrder = false
      })
      .addCase(updateActivityLearningScenarioOrder.fulfilled, (state) => {
        state.isLoading = false
        state.changeOrder = true
      })
      .addCase(
        updateActivityLearningScenarioOrder.rejected,
        (state, { payload }) => {
          state.isLoading = false
          state.error = payload as string
          state.changeOrder = false
        }
      )
      .addCase(getChangeLogInfo.pending, (state) => {
        state.isLoading = true
        state.error = ''
        state.changeLogInfo = {} as ChangeLogInfo
      })
      .addCase(getChangeLogInfo.fulfilled, (state, { payload }) => {
        const { changeLogInfo } = payload
        state.isLoading = false
        state.changeLogInfo = changeLogInfo
      })
      .addCase(getChangeLogInfo.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
        state.changeLogInfo = {} as ChangeLogInfo
      })
      .addCase(toggleActivityStatus.pending, (state) => {
        state.isLoading = true
        state.error = ''
      })
      .addCase(toggleActivityStatus.fulfilled, (state, { payload }) => {
        const { changeLog } = payload
        state.isLoading = false
        state.moduleVersion.changeLog = changeLog
      })
      .addCase(toggleActivityStatus.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload as string
      })
  },
})

export const {
  clearModuleVersionForm,
  clearModuleVersionSuccess,
  clearChangeOrder,
} = moduleVersionSlice.actions

export default moduleVersionSlice.reducer
