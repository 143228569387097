import customFetch, { checkForUnauthorizedResponse } from '../../utils/axios'

export const getSkillThunk = async (_, id, thunkAPI) => {
  try {
    let url = `/skills/${id}`
    const resp = await customFetch.get(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
export const addSkillThunk = async (_, skill, thunkAPI) => {
  let url = `/skills`
  try {
    const resp = await customFetch.post(url, skill)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
export const removeSkillThunk = async (_, skill, thunkAPI) => {
  let url = `/skills/${skill._id}`
  try {
    const resp = await customFetch.delete(url)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
export const editSkillThunk = async (_, skill, thunkAPI) => {
  let url = `/skills/${skill._id}`
  try {
    const resp = await customFetch.patch(url, skill)
    return resp.data
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI)
  }
}
