import { configureStore } from '@reduxjs/toolkit'
import userSlice from './features/user/userSlice'
import moduleSlice from './features/module/moduleSlice'
import moduleVersionSlice from './features/moduleVersion/moduleVersionSlice'
import learningObjectiveSlice from './features/learningObjective/learningObjectiveSlice'
import skillSlice from './features/skill/skillSlice'
import recordingSlice from './features/recording/recordingSlice'
import historyRecordingInteractionSlice from './features/historyRecordingInteraction/historyRecordingInteractionSlice'
import taxonomySlice from './features/taxonomy/taxonomySlice'
import tagSlice from './features/tag/tagSlice'
import resourceSlice from './features/resource/resourceSlice'
import trainerSlice from './features/trainer/trainerSlice'
import specialUserSlice from './features/specialUser/specialUserSlice'
import activitySlice from './features/activity/activitySlice'
import interactionSlice from './features/interaction/interactionSlice'
import bddControlSlice from './features/bddControl/bddControlSlice'
import betaContactSlice from './features/betaContact/betaContactSlice'
import betaLagoonFormAnswersSlice from './features/betaLagoonFormAnswers/betaLagoonFormAnswersSlice'
import betaModuleFormAnswersSlice from './features/betaModuleFormAnswers/betaModuleFormAnswersSlice'
import allSpecialUsersSlice from './features/allSpecialUsers/allSpecialUsersSlice'
import allModulesSlice from './features/allModules/allModulesSlice'
import allModulesVersionSlice from './features/allModulesVersion/allModulesVersionSlice'
import allSkillsSlice from './features/allSkills/allSkillsSlice'
import allRecordingsSlice from './features/allRecordings/allRecordingsSlice'
import allLearningObjectivesSlice from './features/allLearningObjectives/allLearningObjectivesSlice'
import allTagsSlice from './features/allTags/allTagsSlice'
import allResourcesSlice from './features/allResources/allResourcesSlice'
import allTrainersSlice from './features/allTrainers/allTrainersSlice'
import allActivitiesSlice from './features/allActivities/allActivitiesSlice'
import allInteractionsSlice from './features/allInteractions/allInteractionsSlice'
import allBetaContactsSlice from './features/allBetaContacts/allBetaContactsSlice'
import allBetaLagoonFormAnswersSlice from './features/allBetaLagoonFormAnswers/allBetaLagoonFormAnswersSlice'
import allBetaModuleFormAnswersSlice from './features/allBetaModuleFormAnswers/allBetaModuleFormAnswersSlice'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

const store = configureStore({
  reducer: {
    user: userSlice,
    module: moduleSlice,
    moduleVersion: moduleVersionSlice,
    skill: skillSlice,
    recording: recordingSlice,
    historyRecordingInteraction: historyRecordingInteractionSlice,
    learningObjective: learningObjectiveSlice,
    taxonomy: taxonomySlice,
    tag: tagSlice,
    resource: resourceSlice,
    trainer: trainerSlice,
    specialUser: specialUserSlice,
    activity: activitySlice,
    interaction: interactionSlice,
    bddControl: bddControlSlice,
    betaContact: betaContactSlice,
    betaLagoonFormAnswers: betaLagoonFormAnswersSlice,
    betaModuleFormAnswers: betaModuleFormAnswersSlice,
    allModules: allModulesSlice,
    allModulesVersion: allModulesVersionSlice,
    allSkills: allSkillsSlice,
    allRecordings: allRecordingsSlice,
    allLearningObjectives: allLearningObjectivesSlice,
    allTags: allTagsSlice,
    allResources: allResourcesSlice,
    allTrainers: allTrainersSlice,
    allSpecialUsers: allSpecialUsersSlice,
    allActivities: allActivitiesSlice,
    allInteractions: allInteractionsSlice,
    allBetaContacts: allBetaContactsSlice,
    allBetaLagoonFormAnswers: allBetaLagoonFormAnswersSlice,
    allBetaModuleFormAnswers: allBetaModuleFormAnswersSlice,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

//Writing these here to prevent defining the types in every file
export const useAppDispatch = () => useDispatch<AppDispatch>() //This is used to perform action
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
// Used to get the data from the store in the component

export default store
